import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AUTH_TOKEN } from "../constants";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { MDBCol, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import "./../styles/Inputs.css";

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

class Login extends Component {
  state = {
    email: "",
    password: "",
    hidden: true,
  };

  _confirmLogin = async (data) => {
    const { token } = data.login;
    this._saveUserData(token);
    if (window.location.pathname !== "/login") {
      window.location.href = window.location.pathname;
    } else {
      window.location.href = "/";
    }
  };

  _saveUserData = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  render() {
    const { email, password, hidden } = this.state;
    return (
      <section id="login" style={{ backgroundColor: "#000" }}>
        <div
          style={{ minHeight: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <MDBCol
            sm="10"
            md="7"
            lg="5"
            xl="4"
            className="px-3 justify-content-center col-12"
          >
            <MDBCol
              sm="8"
              md="8"
              lg="10"
              className="col-8 offset-2 offset-xs-2 offset-sm-2 offset-md-2 offset-lg-1"
            >
              <img
                src="https://the-sponsor-hub.ams3.digitaloceanspaces.com/TheSponsorHub/TSH%20TM%202%20BLACK.png"
                alt="TSH Logo"
              />
            </MDBCol>
            <h1 style={title}>LOGIN</h1>
            <Mutation
              mutation={LOGIN_MUTATION}
              onCompleted={(data) => this._confirmLogin(data)}
            >
              {(login, { error, loading, data }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!loading && !data) {
                      login({ variables: { email, password } });
                    }
                  }}
                >
                  <MDBInput
                    id="input"
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    style={inputText}
                    type="email"
                    label="Email"
                    required
                    maxLength="80"
                  />
                  <div>
                    <MDBInput
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      style={inputText}
                      type={this.state.hidden ? "password" : "text"}
                      label="Password"
                      required
                      maxLength="80"
                    />
                    <div style={passwordIcon}>
                      {hidden ? (
                        <MDBIcon
                          style={{ color: "#f8f8ff" }}
                          icon="eye-slash"
                          onClick={() => this.setState({ hidden: !hidden })}
                        />
                      ) : (
                        <MDBIcon
                          style={{ color: "#f8f8ff" }}
                          icon="eye"
                          onClick={() => this.setState({ hidden: !hidden })}
                        />
                      )}
                    </div>
                  </div>
                  <div className="pb-3" style={{ marginTop: "-1rem" }}>
                    <a style={resetPasswordLink} href="/reset_password">
                      <p style={resetPasswordText}>Reset Password?</p>
                    </a>
                  </div>
                  {error ? (
                    <div className="flex justify-content-center pb-3">
                      <h5 style={inputError}>
                        {error.message.replace("GraphQL error: ", "")}
                      </h5>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="pb-3" style={{ textAlign: "justify" }}>
                    <p style={legalText}>
                      By Logging In, you agree to our
                      <a style={legalLink} href="/community_guidelines">
                        {" "}
                        Community Guidelines
                      </a>
                      ,
                      <a style={legalLink} href="/terms_and_conditions">
                        {" "}
                        Terms and Conditions
                      </a>{" "}
                      and
                      <a style={legalLink} href="/privacy_policy">
                        {" "}
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                  <div className="text-center">
                    <MDBBtn
                      style={submitButton}
                      color="white"
                      rounded
                      type="submit"
                    >
                      {loading ? "Logging In" : "Login"}
                    </MDBBtn>
                  </div>
                </form>
              )}
            </Mutation>
          </MDBCol>
        </div>
      </section>
    );
  }
}

export default withRouter(Login);

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const passwordIcon = {
  marginTop: "-55px",
  zIndex: "10",
  float: "right",
  position: "relative",
};

const inputError = {
  color: "red",
  fontFamily: "Oswald",
};

const legalText = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Roboto",
};

const legalLink = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Roboto",
  padding: "0",
  fontStyle: "italic",
};

const resetPasswordText = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const resetPasswordLink = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Roboto",
  padding: "0",
};

const submitButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};
