import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdown,
} from "mdbreact";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import "./../../styles/Video.css";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const USER_DETAILS_QUERY = gql`
  {
    user {
      id
      name
      isBusiness
      votes {
        id
        video {
          id
        }
      }
      follows {
        id
        user
        follow {
          id
        }
      }
    }
  }
`;

const FILMER_TRACKER_MUTATION = gql`
  mutation FilmerTrackerMutation($user: String!, $filmerInstagram: String!) {
    filmerTracker(user: $user, filmerInstagram: $filmerInstagram) {
      id
      user
      filmerInstagram
    }
  }
`;

const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

const VOTE_MUTATION = gql`
  mutation VoteMutation($videoId: ID!) {
    vote(videoId: $videoId) {
      id
      video {
        id
        votes {
          id
          user {
            id
          }
        }
      }
      user {
        id
      }
    }
  }
`;

const DELETE_VOTE_MUTATION = gql`
  mutation DeleteVoteMutation($id: ID!) {
    deleteVote(id: $id) {
      id
    }
  }
`;

const FOLLOW_MUTATION = gql`
  mutation FollowMutation($followId: ID!) {
    follow(followId: $followId) {
      id
      user
      follow {
        id
      }
    }
  }
`;

const DELETE_FOLLOW_MUTATION = gql`
  mutation DeleteFollowMutation($deleteId: ID!) {
    deleteFollow(id: $deleteId) {
      id
    }
  }
`;

const DELETE_VIDEO_MUTATION = gql`
  mutation DeleteVideoMutation($videoId: ID!) {
    deleteVideo(id: $videoId) {
      id
    }
  }
`;

const FOLLOW_VIDEOS_QUERY = gql`
  query FollowsQuery($currentUserId: ID!) {
    follows(id: $currentUserId) {
      follows {
        id
        user
        follow {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;

const CREW_MUTATION = gql`
  mutation CrewMutation($crewId: ID!) {
    crew(crewId: $crewId) {
      id
      user
      crew {
        id
      }
    }
  }
`;

const DELETE_CREW_MUTATION = gql`
  mutation DeleteCrewMutation($deleteId: ID!) {
    deleteCrew(id: $deleteId) {
      id
    }
  }
`;

const CREW_VIDEOS_QUERY = gql`
  query CrewQuery($currentUserId: ID!) {
    findCrew(id: $currentUserId) {
      crews {
        id
        user
        crew {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;

const SHARE_TRACKER_MUTATION = gql`
  mutation ShareTrackerMutation(
    $user: String!
    $userShared: String!
    $platform: String
  ) {
    shareTracker(user: $user, userShared: $userShared, platform: $platform) {
      id
      user
      userShared
    }
  }
`;

const REPORT_EDIT_MUTATION = gql`
  mutation ReportMutation($userId: ID!, $videoId: ID) {
    report(userId: $userId, videoId: $videoId) {
      id
    }
  }
`;

class Video extends Component {
  state = {
    voted: false,
    followed: false,
    followedCrew: false,
    shareColour: false,
    flagged: false,
    profileImageResize: true,
    businessLogoResize: true,
  };

  render() {
    var {
      voted,
      followed,
      followedCrew,
      shareColour,
      flagged,
      profileImageResize,
      businessLogoResize,
    } = this.state;
    return (
      <Query query={USER_DETAILS_QUERY}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <div></div>;
          if (error) return <div>Error</div>;

          const currentUserId = data.user.id;
          const numberOfVotes = data.user.votes.length;
          var id;
          var n;
          voted = false;
          for (n = 0; n < numberOfVotes; n++) {
            if (data.user.votes[n].video.id === this.props.video.id) {
              voted = true;
              id = data.user.votes[n].id;
            }
          }
          const user = this.props.video.postedBy.name;
          const userId = this.props.video.postedBy.id;
          const filename = this.props.video.filename;
          const filmerInstagram = this.props.video.filmerInstagram;
          const mimetype = this.props.video.mimetype;
          const videoId = this.props.video.id;
          var showFilmerInstagram = true;
          if (
            filmerInstagram === "@" ||
            filmerInstagram === null ||
            filmerInstagram === ""
          ) {
            showFilmerInstagram = false;
          }
          var stokedNumber = this.props.video.votes.length;
          var crewOnly = this.props.video.crewOnly;

          const businessLogo = this.props.video.postedBy.businessLogo;
          const profileImage = this.props.video.postedBy.profileImage;
          const userIsBusiness = this.props.video.postedBy.isBusiness;

          var views = 0;
          if (this.props.video.videoViews !== undefined) {
            views = this.props.video.videoViews.length;
          }

          return (
            <MDBCol xs="12" sm="12" md="12" lg="12" style={mainContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {businessLogo !== "" &&
                businessLogo !== null &&
                userIsBusiness ? (
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <div style={{ textAlign: "left" }}>
                        <a
                          style={{ padding: "0" }}
                          onClick={() => {
                            if (!loading && !data) {
                              profileTrackerMutation();
                            }
                          }}
                          href={`/profile/${userId}`}
                        >
                          <img
                            style={userImg}
                            src={
                              businessLogoResize
                                ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${userId}_50x50.jpeg`
                                : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${businessLogo}`
                            }
                            onError={() =>
                              this.setState({ businessLogoResize: false })
                            }
                            alt="Business Logo"
                          ></img>
                        </a>
                      </div>
                    )}
                  </Mutation>
                ) : profileImage !== "" &&
                  profileImage !== null &&
                  !userIsBusiness ? (
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <div style={{ textAlign: "left" }}>
                        <a
                          style={{ padding: "0" }}
                          onClick={() => {
                            if (!loading && !data) {
                              profileTrackerMutation();
                            }
                          }}
                          href={`/profile/${userId}`}
                        >
                          <img
                            style={userImg}
                            src={
                              profileImageResize
                                ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${userId}/${userId}_50x50.jpeg`
                                : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${userId}/${profileImage}`
                            }
                            onError={() =>
                              this.setState({ profileImageResize: false })
                            }
                            alt="Profile"
                          ></img>
                        </a>
                      </div>
                    )}
                  </Mutation>
                ) : (
                  <div style={{ marginRight: "-0.5rem" }}></div>
                )}
                <div>
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <div style={{ textAlign: "left" }}>
                        <a
                          style={{ padding: "0" }}
                          onClick={() => {
                            if (!loading && !data) {
                              profileTrackerMutation();
                            }
                          }}
                          href={`/profile/${userId}`}
                        >
                          <p style={nameText}>
                            {this.props.video.postedBy.name}
                          </p>
                        </a>
                        <p style={locationText}>
                          {this.props.video.postedBy.city},{" "}
                          {this.props.video.postedBy.country}
                        </p>
                      </div>
                    )}
                  </Mutation>
                </div>
                <div style={topContainer}>
                  {userId === currentUserId ? (
                    <div style={{ marginBottom: 20 }}>
                      <MDBDropdown dropleft>
                        <MDBDropdownToggle
                          id="editDropdown"
                          color="transparent"
                        >
                          <MDBIcon
                            style={{ marginTop: "8px", color: "#f8f8ff" }}
                            size="2x"
                            icon="ellipsis-h"
                          />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                          <div style={editUploadContainer}>
                            <MDBDropdownItem>
                              <Mutation
                                mutation={DELETE_VIDEO_MUTATION}
                                variables={{ videoId }}
                                onCompleted={() =>
                                  (window.location.href =
                                    window.location.pathname)
                                }
                              >
                                {(deleteVideoMutation, { loading, data }) => (
                                  <MDBBtn
                                    id="btnSM"
                                    size="sm"
                                    rounded
                                    style={deleteButton}
                                    onClick={() => {
                                      if (!loading && !data) {
                                        deleteVideoMutation();
                                      }
                                    }}
                                  >
                                    Delete
                                  </MDBBtn>
                                )}
                              </Mutation>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                              <MDBBtn
                                id="btnSM"
                                style={editButton}
                                size="sm"
                                rounded
                                href={`/edit/${videoId}`}
                              >
                                Edit
                              </MDBBtn>
                            </MDBDropdownItem>
                          </div>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "inline-flex",
                        verticalAlign: "middle",
                      }}
                    >
                      <Query
                        query={CREW_VIDEOS_QUERY}
                        variables={{ currentUserId }}
                      >
                        {({ loading, error, data, refetch }) => {
                          if (loading)
                            return (
                              <MDBIcon style={crewIcon} fas icon="user-plus" />
                            );
                          if (error) {
                            return (
                              <MDBIcon style={crewIcon} fas icon="user-plus" />
                            );
                          }
                          const numberOfCrew = data.findCrew.crews.length;
                          const crewId = userId;
                          var i;
                          var deleteId;
                          followedCrew = false;
                          for (i = 0; i < numberOfCrew; i++) {
                            if (data.findCrew.crews[i].crew === null) {
                              refetch();
                            } else {
                              if (data.findCrew.crews[i].crew.id === crewId) {
                                followedCrew = true;
                                deleteId = data.findCrew.crews[i].id;
                              }
                            }
                          }
                          return (
                            <>
                              {followedCrew === false ? (
                                <Mutation
                                  mutation={CREW_MUTATION}
                                  variables={{ crewId }}
                                >
                                  {(crewMutation, { loading }) => (
                                    <MDBIcon
                                      style={crewIcon}
                                      fas
                                      icon="user-plus"
                                      onClick={() => {
                                        if (!loading) {
                                          crewMutation();
                                          refetch();
                                        }
                                      }}
                                    />
                                  )}
                                </Mutation>
                              ) : (
                                <Mutation
                                  mutation={DELETE_CREW_MUTATION}
                                  variables={{ deleteId }}
                                >
                                  {(deleteCrewMutation, { loading }) => (
                                    <MDBIcon
                                      style={crewIcon}
                                      fas
                                      icon="users"
                                      onClick={() => {
                                        if (!loading) {
                                          deleteCrewMutation();
                                          refetch();
                                        }
                                      }}
                                    />
                                  )}
                                </Mutation>
                              )}
                            </>
                          );
                        }}
                      </Query>
                      {!crewOnly ? (
                        <div className="ml-3">
                          <MDBDropdown id="shareDropdown" dropleft>
                            <MDBDropdownToggle
                              id="editDropdown"
                              style={{ marginTop: "-2px" }}
                              color="transparent"
                            >
                              <MDBIcon
                                style={{ color: "#f8f8ff", fontSize: "1.5em" }}
                                fas
                                icon="share-alt"
                                onClick={() =>
                                  this.setState({ shareColour: !shareColour })
                                }
                              />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu basic>
                              <MDBDropdownItem style={{ marginTop: -10 }}>
                                <div
                                  style={{
                                    justifyContent: "space-around",
                                    display: "flex",
                                  }}
                                >
                                  <div style={{ backgroundColor: "#000" }}>
                                    <Mutation
                                      mutation={SHARE_TRACKER_MUTATION}
                                      variables={{
                                        user: currentUserId,
                                        userShared: userId,
                                        platform: "Email",
                                      }}
                                    >
                                      {(shareTrackerMutation, { loading }) => (
                                        <EmailShareButton
                                          url={`https://thesponsorhub.com/edits/${videoId}`}
                                          subject={`${user} - The Sponsor Hub`}
                                          body={`Check out ${user}'s gnarly Edit on The Sponsor Hub 🤙`}
                                        >
                                          <MDBIcon
                                            style={shareIcon}
                                            size="2x"
                                            icon="envelope"
                                            onClick={() => {
                                              if (!loading) {
                                                shareTrackerMutation();
                                              }
                                            }}
                                          />
                                        </EmailShareButton>
                                      )}
                                    </Mutation>
                                  </div>
                                  <div style={{ backgroundColor: "#000" }}>
                                    <Mutation
                                      mutation={SHARE_TRACKER_MUTATION}
                                      variables={{
                                        user: currentUserId,
                                        userShared: userId,
                                        platform: "Facebook",
                                      }}
                                    >
                                      {(shareTrackerMutation, { loading }) => (
                                        <FacebookShareButton
                                          quote={`Check out ${user}'s gnarly Edit on The Sponsor Hub 🤙`}
                                          url={`https://thesponsorhub.com/edits/${videoId}`}
                                        >
                                          <MDBIcon
                                            style={shareIcon}
                                            fab
                                            size="2x"
                                            icon="facebook-f"
                                            onClick={() => {
                                              if (!loading) {
                                                shareTrackerMutation();
                                              }
                                            }}
                                          />
                                        </FacebookShareButton>
                                      )}
                                    </Mutation>
                                  </div>
                                  <div style={{ backgroundColor: "#000" }}>
                                    <Mutation
                                      mutation={SHARE_TRACKER_MUTATION}
                                      variables={{
                                        user: currentUserId,
                                        userShared: userId,
                                        platform: "Twitter",
                                      }}
                                    >
                                      {(shareTrackerMutation, { loading }) => (
                                        <TwitterShareButton
                                          title={`Check out ${user}'s gnarly Edit on The Sponsor Hub 🤙`}
                                          url={`https://thesponsorhub.com/edits/${videoId}`}
                                        >
                                          <MDBIcon
                                            style={shareIcon}
                                            size="2x"
                                            fab
                                            icon="twitter"
                                            onClick={() => {
                                              if (!loading) {
                                                shareTrackerMutation();
                                              }
                                            }}
                                          />
                                        </TwitterShareButton>
                                      )}
                                    </Mutation>
                                  </div>
                                  <div style={{ backgroundColor: "#000" }}>
                                    <Mutation
                                      mutation={SHARE_TRACKER_MUTATION}
                                      variables={{
                                        user: currentUserId,
                                        userShared: userId,
                                        platform: "Whatsapp",
                                      }}
                                    >
                                      {(shareTrackerMutation, { loading }) => (
                                        <WhatsappShareButton
                                          title={`Check out ${user}'s gnarly Edit on The Sponsor Hub 🤙`}
                                          url={`https://thesponsorhub.com/edits/${videoId}`}
                                        >
                                          <MDBIcon
                                            style={shareIcon}
                                            size="2x"
                                            fab
                                            icon="whatsapp"
                                            onClick={() => {
                                              if (!loading) {
                                                shareTrackerMutation();
                                              }
                                            }}
                                          />
                                        </WhatsappShareButton>
                                      )}
                                    </Mutation>
                                  </div>
                                </div>
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        {userId !== currentUserId ? (
                          <Mutation
                            mutation={REPORT_EDIT_MUTATION}
                            variables={{ userId: currentUserId, videoId }}
                          >
                            {(reportMutation, { loading, data }) => (
                              <div className="ml-3">
                                {!flagged ? (
                                  <MDBIcon
                                    style={{
                                      color: "#f8f8ff",
                                      fontSize: "1.2em",
                                    }}
                                    fas
                                    icon="flag"
                                    onClick={() => {
                                      if (!loading && !data) {
                                        this.setState({ flagged: true });
                                        reportMutation();
                                      }
                                    }}
                                  />
                                ) : (
                                  <MDBIcon
                                    style={{ color: "red", fontSize: "1.2em" }}
                                    fas
                                    icon="flag"
                                    onClick={() =>
                                      this.setState({ flagged: false })
                                    }
                                  />
                                )}
                              </div>
                            )}
                          </Mutation>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  height: 300,
                  width: window.innerWidth >= 576 ? "auto" : 350,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {mimetype === "image/jpeg" || mimetype === "image/jpg" ? (
                  <img
                    id="image"
                    src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${userId}/${filename}`}
                    alt="Upload"
                  ></img>
                ) : (
                  <video id="video" controls preload="auto">
                    <source
                      src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${userId}/${filename}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              <div>
                <div style={{ maxWidth: "60%" }}>
                  <div style={{ display: "flex" }}>
                    <p style={numbersText}>{stokedNumber} Stokes</p>
                    <p style={numbersText}>
                      {views > 999
                        ? views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : views}{" "}
                      Views
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    {showFilmerInstagram ? (
                      <div style={{ textAlign: "left" }}>
                        <div style={{ display: "flex" }}>
                          <p style={detailsText}>Filmed by: </p>
                          <Mutation
                            mutation={FILMER_TRACKER_MUTATION}
                            variables={{ user, filmerInstagram }}
                          >
                            {(filmerTrackerMutation, { loading }) => (
                              <div style={{ textAlign: "left" }}>
                                <a
                                  style={{ padding: "0" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://www.instagram.com/${this.props.video.filmerInstagram}/`}
                                  onClick={() => {
                                    if (!loading) {
                                      filmerTrackerMutation();
                                    }
                                  }}
                                >
                                  <p style={detailsText}>
                                    {this.props.video.filmerInstagram}
                                  </p>
                                </a>
                              </div>
                            )}
                          </Mutation>
                        </div>
                        <p style={detailsText}>
                          {this.props.video.description.replace(/\n/g, " ")}
                        </p>
                      </div>
                    ) : (
                      <div style={{ textAlign: "left" }}>
                        <p style={detailsText}>
                          {this.props.video.description.replace(/\n/g, " ")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: showFilmerInstagram ? -50 : -35,
                  }}
                >
                  {userId !== currentUserId ? (
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <Query
                          query={FOLLOW_VIDEOS_QUERY}
                          variables={{ currentUserId }}
                        >
                          {({ loading, error, data, refetch }) => {
                            if (loading) return <div></div>;
                            if (error)
                              return (
                                <MDBBtn
                                  id="btnSM"
                                  outline
                                  rounded
                                  size="sm"
                                  color="white"
                                >
                                  Follow
                                </MDBBtn>
                              );
                            const numberOfFollows = data.follows.follows.length;
                            const followId = this.props.video.postedBy.id;
                            var i;
                            var deleteId;
                            followed = false;
                            for (i = 0; i < numberOfFollows; i++) {
                              if (data.follows.follows[i].follow === null) {
                                refetch();
                              } else {
                                if (
                                  data.follows.follows[i].follow.id === followId
                                ) {
                                  followed = true;
                                  deleteId = data.follows.follows[i].id;
                                }
                              }
                            }
                            return (
                              <div>
                                {followed === false ? (
                                  <Mutation
                                    mutation={FOLLOW_MUTATION}
                                    variables={{
                                      followId: this.props.video.postedBy.id,
                                    }}
                                  >
                                    {(followMutation, { loading }) => (
                                      <MDBBtn
                                        onClick={() => {
                                          if (!loading) {
                                            followMutation();
                                            refetch();
                                          }
                                        }}
                                        id="btnSM"
                                        outline
                                        rounded
                                        size="sm"
                                        color="white"
                                      >
                                        Follow
                                      </MDBBtn>
                                    )}
                                  </Mutation>
                                ) : (
                                  <Mutation
                                    mutation={DELETE_FOLLOW_MUTATION}
                                    variables={{ deleteId }}
                                  >
                                    {(deleteFollowMutation, { loading }) => (
                                      <MDBBtn
                                        onClick={() => {
                                          if (!loading) {
                                            deleteFollowMutation();
                                            refetch();
                                          }
                                        }}
                                        id="btnSM"
                                        rounded
                                        size="sm"
                                        color="white"
                                      >
                                        Following
                                      </MDBBtn>
                                    )}
                                  </Mutation>
                                )}
                              </div>
                            );
                          }}
                        </Query>
                      </div>
                      <div className="ml-3">
                        {voted === false ? (
                          <Mutation
                            mutation={VOTE_MUTATION}
                            variables={{ videoId: this.props.video.id }}
                          >
                            {(voteMutation, { loading }) => (
                              <img
                                src="/Edits/shaka_white_outline.png"
                                onClick={() => {
                                  if (!loading) {
                                    voteMutation();
                                    refetch();
                                  }
                                }}
                                style={shakaIcon}
                                alt="shaka outline"
                              />
                            )}
                          </Mutation>
                        ) : (
                          <Mutation
                            mutation={DELETE_VOTE_MUTATION}
                            variables={{ id }}
                          >
                            {(deleteVoteMutation, { loading }) => (
                              <img
                                src="/Edits/shaka_white_full.png"
                                onClick={() => {
                                  if (!loading) {
                                    deleteVoteMutation();
                                    refetch();
                                  }
                                }}
                                style={shakaIcon}
                                alt="shaka full"
                              />
                            )}
                          </Mutation>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #f8f8ff",
                  marginTop: showFilmerInstagram ? 20 : 10,
                }}
              ></div>
            </MDBCol>
          );
        }}
      </Query>
    );
  }
}

export default Video;

const mainContainer = {
  background: "#000",
  borderRadius: "0.4rem",
  textAlign: "center",
  padding: "0.25rem",
  justifyContent: "space-between",
  flexDirection: "column",
  display: "flex",
};

const userImg = {
  height: 40,
  width: 40,
  objectFit: "cover",
  borderRadius: "0.4rem",
};

const nameText = {
  color: "#f8f8ff",
  marginLeft: "0.5rem",
  marginBottom: 0,
  fontFamily: "Oswald",
  textTransform: "uppercase",
  fontSize: "1rem",
};

const locationText = {
  color: "#f8f8ff",
  marginLeft: "0.5rem",
  fontFamily: "Oswald",
  marginBottom: 0,
  fontSize: "0.8rem",
};

const topContainer = {
  right: 0,
  position: "absolute",
};

const crewIcon = {
  color: "#f8f8ff",
  fontSize: "1.2em",
};

const shareIcon = {
  backgroundColor: "#000",
  color: "#f8f8ff",
  marginLeft: "1rem",
};

const numbersText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  marginBottom: 0,
  fontSize: "0.85rem",
  marginRight: "0.5rem",
};

const detailsText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  marginBottom: 0,
  fontSize: "0.9rem",
  marginRight: "0.25rem",
};

const shakaIcon = {
  marginTop: "5px",
  width: "1.5em",
  height: "1.5em",
  objectFit: "cover",
};

const editUploadContainer = {
  right: "40px",
  textAlign: "center",
  position: "relative",
  display: "flex",
  marginTop: "-10px",
};

const deleteButton = {
  backgroundColor: "#000",
  border: "2px solid red",
  color: "red",
};

const editButton = {
  backgroundColor: "#000",
  border: "2px solid #f8f8ff",
  color: "#f8f8ff",
};
