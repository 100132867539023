import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";

const ContactSection = ({isLoggedIn}) => {
  return (
    <section id="contact" style={{backgroundColor: '#000'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 px-3">
          <h2 style={title} className="h2-responsive text-center mb-3 mt-3">
            CONTACT US
          </h2>
          <p className="mx-auto mt-4 py-2 text-center" style={contactText}>
            To get in contact, send us an email or drop us a message on our social media pages.
          </p>
          {window.innerWidth > 768 ?
            <MDBRow className="justify-content-center pt-2 pb-4">
              <MDBCol md="2" lg="1" style={{ flexGrow: '1' }} className="text-center">
                <a href="mailto:info@thesponsorhub.com">
                  <MDBIcon icon="envelope" size="2x" style={{color: '#f8f8ff'}} />
                </a>
              </MDBCol>
              <MDBCol md="2" lg="1" style={{ flexGrow: '1' }} className="text-center">
                <a href="https://www.instagram.com/thesponsorhub/?hl=en">
                  <MDBIcon fab icon="instagram" size="2x" style={{color: '#f8f8ff'}} />
                </a>
              </MDBCol>
              <MDBCol md="2" lg="1" style={{ flexGrow: '1' }} className="text-center">
                <a href="https://www.facebook.com/TheSponsorHub/">
                  <MDBIcon fab icon="facebook-f" size="2x" style={{color: '#f8f8ff'}} />
                </a>
              </MDBCol>
              <MDBCol md="2" lg="1" style={{ flexGrow: '1' }} className="text-center">
                <a href="https://twitter.com/thesponsorhub">
                  <MDBIcon fab icon="twitter" size="2x" style={{color: '#f8f8ff'}} />
                </a>
              </MDBCol>
            </MDBRow>
          :
            <div style={{display: 'flex', justifyContent: 'center'}} className="pt-2 pb-3">
              <a className="mx-3" href="mailto:info@thesponsorhub.com">
                <MDBIcon icon="envelope" size="2x" style={{color: '#f8f8ff'}} />
              </a>
              <a className="mx-3" href="https://www.instagram.com/thesponsorhub/?hl=en">
                <MDBIcon fab icon="instagram" size="2x" style={{color: '#f8f8ff'}} />
              </a>
              <a className="mx-3" href="https://www.facebook.com/TheSponsorHub/">
                <MDBIcon fab icon="facebook-f" size="2x" style={{color: '#f8f8ff'}} />
              </a>
              <a className="mx-3" href="https://twitter.com/thesponsorhub">
                <MDBIcon fab icon="twitter" size="2x" style={{color: '#f8f8ff'}} />
              </a>
            </div>
          }
          {!isLoggedIn ?
            <MDBRow className="justify-content-center pt-2">
              <MDBCol sm="12" md="4" lg="3" style={{ flexGrow: '1' }} className="text-center">
                <p style={legalText}><a style={legalLink} href="/community_guidelines">Community Guidelines</a></p>
              </MDBCol>
              <MDBCol sm="12" md="4" lg="3" style={{ flexGrow: '1' }} className="text-center">
                <p style={legalText}><a style={legalLink} href="/terms_and_conditions">Terms and Conditions</a></p>
              </MDBCol>
              <MDBCol sm="12" md="4" lg="3" style={{ flexGrow: '1' }} className="text-center">
                <p style={legalText}><a style={legalLink} href="/privacy_policy">Privacy Policy</a></p>
              </MDBCol>
            </MDBRow>
          :
            <></>
          }
          <div className="text-center font-weight-bolder py-3">
            <p style={copyrightText}> &copy; {new Date().getFullYear()} Copyright: <a style={copyrightLink} href="https://www.thesponsorhub.com"> TheSponsorHub.com. </a>All rights reserved</p>
          </div>
        </div>
      </MDBCol>
    </section>
  );
}

export default ContactSection;

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff'
}

const contactText = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  fontWeight: 400
}

const legalText = {
  display: 'inline-block', 
  fontFamily: 'Oswald'
}

const legalLink = {
  color: '#f8f8ff', 
  display: 'inline'
}

const copyrightText = {
  display: 'inline-block', 
  fontFamily: 'Oswald',
  color: '#f8f8ff'
}

const copyrightLink = {
  color: '#f8f8ff', 
  display: 'inline', 
  paddingLeft: '0', 
  paddingRight: '0'
}