import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import Video from './../Videos/Video'
import ErrorPage from './../Errors/ErrorMessage'
import Loading from './../Loading'
import InfiniteScroll from "react-infinite-scroll-component";
import { MDBRow, MDBBtn } from 'mdbreact'

const PAGE_SIZE = 6;
 
const USER_VIDEOS_QUERY = gql`
    query userVideosQuery($userId: ID!, $skip: Int, $last: Int){
        userVideos(id: $userId, skip: $skip, last: $last, orderBy: createdAt_ASC){
            videos{
            id
            filename
            description
            filmerInstagram
            mimetype
            crewOnly
            postedBy {
                id
                name
                category
                country
                city
                instagram
                email
                businessLogo
                profileImage
                isBusiness
            }
            votes {
                id
                user {
                id
                }
            }
            videoViews{
                id
            }
          }
        }
    }
`

class ProfileUploads extends Component {
    state = {
      profileUser: this.props.profileUser,
      currentUserId: this.props.currentUserId,
      videos: [],
      skip: 0,
      loadMore: false,
      firstLoad: true,
      hasMore: true,
    }

    fetchMoreData = () => {
        setTimeout(() => {
          this._executeFetchMore()
        }, 1000);
      };
    
      _executeFetchMore = async () => {
        var { profileUser, skip } = this.state
    
        const result = await this.props.client.query({
          query: USER_VIDEOS_QUERY,
          variables: { userId: profileUser.id , skip, last: PAGE_SIZE },
        })
        this.setState({ videos: [...this.state.videos, ...result.data.userVideos.videos] })
        if(result.data.userVideos.videos.length === 0){
          this.setState({hasMore: false})
        } else {
          this.setState({skip: this.state.skip + PAGE_SIZE})
          if(this.state.firstLoad){
            this._executeFetchMore()
          }
          this.setState({hasMore: true, firstLoad: false})
        }
      }

    render() {
      const { profileUser, currentUserId} = this.state
      return (
          <Query query={USER_VIDEOS_QUERY} variables={{userId: profileUser.id, skip: 0, last: PAGE_SIZE }}>
            {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorPage error={error.message} />

            return(
                <div className="pt-3" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
                    {data.userVideos.videos.length === 0 ?
                      <div className="py-2 px-3" style={editsText}>
                        <h5>{profileUser.name} currently has no Uploads.</h5>
                        {profileUser.id === currentUserId ?
                            <MDBBtn style={uploadButton} color="white" rounded outline href="/upload_edit">Upload an Edit</MDBBtn>
                        :
                            <></>
                        }
                      </div>
                    :
                    <InfiniteScroll
                      dataLength={this.state.videos.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.hasMore}
                      loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Edits...</h5></div>}
                      style={{minHeight: '100vh'}}
                    >
                      {data && this.state.firstLoad ?
                        <MDBRow className="justify-content-center">
                            {data.userVideos.videos.map(video => <Video key={video.id} video={video} />)}
                        </MDBRow>
                      :
                        <MDBRow className="justify-content-center">
                            {this.state.videos.map(video => <Video key={video.id} video={video} />)}
                        </MDBRow>
                      }
                      {!this.state.hasMore ?
                        <div className="pb-2" style={editsText} key={0}><h5>No More Edits!</h5></div>
                      :
                        <></>
                      }
                    </InfiniteScroll>
                    }
                </div>
            )
        }}
      </Query>
    );
  }
}

export default withApollo(ProfileUploads)

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const uploadButton = {
  minWidth: 150,
  paddingTop: '0.15rem',
  paddingBottom: '0.25rem',
  fontSize: 15,
  fontFamily: 'Oswald'
}