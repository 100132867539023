import React, { Component } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import Video from "./../Videos/Video";
import {
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSelectInput,
  MDBSelect,
} from "mdbreact";
import "./../../styles/Background.css";
import InfiniteScroll from "react-infinite-scroll-component";

const PAGE_SIZE = 6;

const ADVANCED_SEARCH_QUERY = gql`
  query AdvancedSearchQuery(
    $name: String
    $instagram: String
    $country: String
    $city: String
    $category: String
    $gender: String
    $orientation: String
    $resolution: String
    $filetype: String
    $skip: Int
    $last: Int
  ) {
    advancedSearch(
      name: $name
      instagram: $instagram
      country: $country
      city: $city
      category: $category
      gender: $gender
      orientation: $orientation
      resolution: $resolution
      filetype: $filetype
      skip: $skip
      last: $last
    ) {
      videos {
        id
        filename
        description
        filmerInstagram
        mimetype
        crewOnly
        postedBy {
          id
          name
          category
          country
          city
          instagram
          email
          businessLogo
          profileImage
          isBusiness
        }
        votes {
          id
          user {
            id
          }
        }
        videoViews {
          id
        }
      }
    }
  }
`;

class AdvancedSearch extends Component {
  state = {
    videos: [],
    name: "",
    gender: "",
    instagram: "",
    country: "",
    city: "",
    addSurf: true,
    addSkate: true,
    addSnowboard: true,
    addSki: true,
    addBMX: true,
    addMTB: true,
    addScooter: true,
    addClimbing: true,
    addOther: true,
    category: "",
    skip: 0,
    hasMore: true,
    firstLoad: true,
  };

  handleSelectChangeCountry = (country) => {
    this.setState({ country: country, skip: 0, videos: [], firstLoad: true });
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        skip: this.state.skip + PAGE_SIZE,
      });
      this._executeAdvancedSearch();
    }, 1000);
  };

  render() {
    const {
      name,
      gender,
      instagram,
      country,
      city,
      addSurf,
      addSkate,
      addSnowboard,
      addSki,
      addBMX,
      addMTB,
      addScooter,
      addClimbing,
      addOther,
      category,
      firstLoad,
    } = this.state;
    return (
      <div style={{ backgroundColor: "#000", minHeight: "100vh" }}>
        <div className="d-flex justify-content-center align-items-center">
          <MDBCol
            sm="10"
            md="7"
            lg="5"
            xl="4"
            className="px-3 pt-5 justify-content-center col-12"
          >
            <h2 style={title}>Search For An Athlete</h2>
            <MDBInput
              value={name}
              onChange={(e) => {
                var nameUppercase = e.target.value.replace(/\b\w/g, (c) =>
                  c.toUpperCase()
                );
                this.setState({
                  name: nameUppercase,
                  skip: 0,
                  videos: [],
                  firstLoad: true,
                });
              }}
              type="text"
              label="Name"
              style={inputText}
              maxLength="80"
            />
            <div>
              <h5 style={labelText}>Select Gender</h5>
              <div style={genderContainer}>
                <p
                  onClick={() => {
                    if (gender === "Male") {
                      this.setState({ gender: "" });
                    } else {
                      this.setState({ gender: "Male" });
                    }
                  }}
                  style={{
                    padding: "0.25rem",
                    border: "1px solid #202020",
                    borderRadius: "0.25rem",
                    fontFamily: "Oswald",
                    color: "#f8f8ff",
                    opacity: gender === "" || gender === "Male" ? 1 : 0.5,
                  }}
                >
                  Male
                </p>
                <p
                  onClick={() => {
                    if (gender === "Female") {
                      this.setState({ gender: "" });
                    } else {
                      this.setState({ gender: "Female" });
                    }
                  }}
                  style={{
                    padding: "0.25rem",
                    border: "1px solid #202020",
                    borderRadius: "0.25rem",
                    fontFamily: "Oswald",
                    color: "#f8f8ff",
                    opacity: gender === "" || gender === "Female" ? 1 : 0.5,
                  }}
                >
                  Female
                </p>
                <p
                  onClick={() => {
                    if (gender === "Other") {
                      this.setState({ gender: "" });
                    } else {
                      this.setState({ gender: "Other" });
                    }
                  }}
                  style={{
                    padding: "0.25rem",
                    border: "1px solid #202020",
                    borderRadius: "0.25rem",
                    fontFamily: "Oswald",
                    color: "#f8f8ff",
                    opacity: gender === "" || gender === "Other" ? 1 : 0.5,
                  }}
                >
                  Other
                </p>
                <p
                  onClick={() => {
                    if (gender === "Prefer Not To Say") {
                      this.setState({ gender: "" });
                    } else {
                      this.setState({ gender: "Prefer Not To Say" });
                    }
                  }}
                  style={{
                    padding: "0.25rem",
                    border: "1px solid #202020",
                    borderRadius: "0.25rem",
                    fontFamily: "Oswald",
                    color: "#f8f8ff",
                    opacity:
                      gender === "" || gender === "Prefer Not To Say" ? 1 : 0.5,
                  }}
                >
                  Prefer Not to Say
                </p>
              </div>
            </div>
            <MDBInput
              value={instagram}
              onChange={(e) => {
                this.setState({
                  instagram: e.target.value,
                  skip: 0,
                  videos: [],
                  firstLoad: true,
                });
              }}
              type="text"
              label="Instagram Handle"
              style={inputText}
              maxLength="80"
            />
            <div>
              <MDBSelect
                label="Country"
                required
                value={country}
                getTextContent={this.handleSelectChangeCountry}
              >
                <MDBSelectInput />
                <MDBSelectOptions search>
                  {country !== "" ? (
                    <MDBSelectOption value="">Remove Country</MDBSelectOption>
                  ) : (
                    <></>
                  )}
                  <MDBSelectOption value="United Kingdom">
                    United Kingdom
                  </MDBSelectOption>
                  <MDBSelectOption value="United States of America">
                    United States of America
                  </MDBSelectOption>
                  <MDBSelectOption value="Australia">Australia</MDBSelectOption>
                  <MDBSelectOption value="France">France</MDBSelectOption>
                  <MDBSelectOption value="New Zealand">
                    New Zealand
                  </MDBSelectOption>
                  <MDBSelectOption value="South Africa">
                    South Africa
                  </MDBSelectOption>
                  <MDBSelectOption value="Spain">Spain</MDBSelectOption>
                  <MDBSelectOption value="Afganistan">
                    Afghanistan
                  </MDBSelectOption>
                  <MDBSelectOption value="Albania">Albania</MDBSelectOption>
                  <MDBSelectOption value="Algeria">Algeria</MDBSelectOption>
                  <MDBSelectOption value="American Samoa">
                    American Samoa
                  </MDBSelectOption>
                  <MDBSelectOption value="Andorra">Andorra</MDBSelectOption>
                  <MDBSelectOption value="Angola">Angola</MDBSelectOption>
                  <MDBSelectOption value="Anguilla">Anguilla</MDBSelectOption>
                  <MDBSelectOption value="Antigua & Barbuda">
                    Antigua & Barbuda
                  </MDBSelectOption>
                  <MDBSelectOption value="Argentina">Argentina</MDBSelectOption>
                  <MDBSelectOption value="Armenia">Armenia</MDBSelectOption>
                  <MDBSelectOption value="Aruba">Aruba</MDBSelectOption>
                  <MDBSelectOption value="Austria">Austria</MDBSelectOption>
                  <MDBSelectOption value="Azerbaijan">
                    Azerbaijan
                  </MDBSelectOption>
                  <MDBSelectOption value="Bahamas">Bahamas</MDBSelectOption>
                  <MDBSelectOption value="Bahrain">Bahrain</MDBSelectOption>
                  <MDBSelectOption value="Bangladesh">
                    Bangladesh
                  </MDBSelectOption>
                  <MDBSelectOption value="Barbados">Barbados</MDBSelectOption>
                  <MDBSelectOption value="Belarus">Belarus</MDBSelectOption>
                  <MDBSelectOption value="Belgium">Belgium</MDBSelectOption>
                  <MDBSelectOption value="Belize">Belize</MDBSelectOption>
                  <MDBSelectOption value="Benin">Benin</MDBSelectOption>
                  <MDBSelectOption value="Bermuda">Bermuda</MDBSelectOption>
                  <MDBSelectOption value="Bhutan">Bhutan</MDBSelectOption>
                  <MDBSelectOption value="Bolivia">Bolivia</MDBSelectOption>
                  <MDBSelectOption value="Bonaire">Bonaire</MDBSelectOption>
                  <MDBSelectOption value="Bosnia & Herzegovina">
                    Bosnia & Herzegovina
                  </MDBSelectOption>
                  <MDBSelectOption value="Botswana">Botswana</MDBSelectOption>
                  <MDBSelectOption value="Brazil">Brazil</MDBSelectOption>
                  <MDBSelectOption value="British Indian Ocean Ter">
                    British Indian Ocean Ter
                  </MDBSelectOption>
                  <MDBSelectOption value="Brunei">Brunei</MDBSelectOption>
                  <MDBSelectOption value="Bulgaria">Bulgaria</MDBSelectOption>
                  <MDBSelectOption value="Burkina Faso">
                    Burkina Faso
                  </MDBSelectOption>
                  <MDBSelectOption value="Burundi">Burundi</MDBSelectOption>
                  <MDBSelectOption value="Cambodia">Cambodia</MDBSelectOption>
                  <MDBSelectOption value="Cameroon">Cameroon</MDBSelectOption>
                  <MDBSelectOption value="Canada">Canada</MDBSelectOption>
                  <MDBSelectOption value="Canary Islands">
                    Canary Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Cape Verde">
                    Cape Verde
                  </MDBSelectOption>
                  <MDBSelectOption value="Cayman Islands">
                    Cayman Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Central African Republic">
                    Central African Republic
                  </MDBSelectOption>
                  <MDBSelectOption value="Chad">Chad</MDBSelectOption>
                  <MDBSelectOption value="Channel Islands">
                    Channel Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Chile">Chile</MDBSelectOption>
                  <MDBSelectOption value="China">China</MDBSelectOption>
                  <MDBSelectOption value="Christmas Island">
                    Christmas Island
                  </MDBSelectOption>
                  <MDBSelectOption value="Cocos Island">
                    Cocos Island
                  </MDBSelectOption>
                  <MDBSelectOption value="Colombia">Colombia</MDBSelectOption>
                  <MDBSelectOption value="Comoros">Comoros</MDBSelectOption>
                  <MDBSelectOption value="Congo">Congo</MDBSelectOption>
                  <MDBSelectOption value="Cook Islands">
                    Cook Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Costa Rica">
                    Costa Rica
                  </MDBSelectOption>
                  <MDBSelectOption value="Cote DIvoire">
                    Cote DIvoire
                  </MDBSelectOption>
                  <MDBSelectOption value="Croatia">Croatia</MDBSelectOption>
                  <MDBSelectOption value="Cuba">Cuba</MDBSelectOption>
                  <MDBSelectOption value="Curaco">Curacao</MDBSelectOption>
                  <MDBSelectOption value="Cyprus">Cyprus</MDBSelectOption>
                  <MDBSelectOption value="Czech Republic">
                    Czech Republic
                  </MDBSelectOption>
                  <MDBSelectOption value="Denmark">Denmark</MDBSelectOption>
                  <MDBSelectOption value="Djibouti">Djibouti</MDBSelectOption>
                  <MDBSelectOption value="Dominica">Dominica</MDBSelectOption>
                  <MDBSelectOption value="Dominican Republic">
                    Dominican Republic
                  </MDBSelectOption>
                  <MDBSelectOption value="East Timor">
                    East Timor
                  </MDBSelectOption>
                  <MDBSelectOption value="Ecuador">Ecuador</MDBSelectOption>
                  <MDBSelectOption value="Egypt">Egypt</MDBSelectOption>
                  <MDBSelectOption value="El Salvador">
                    El Salvador
                  </MDBSelectOption>
                  <MDBSelectOption value="Equatorial Guinea">
                    Equatorial Guinea
                  </MDBSelectOption>
                  <MDBSelectOption value="Eritrea">Eritrea</MDBSelectOption>
                  <MDBSelectOption value="Estonia">Estonia</MDBSelectOption>
                  <MDBSelectOption value="Ethiopia">Ethiopia</MDBSelectOption>
                  <MDBSelectOption value="Falkland Islands">
                    Falkland Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Faroe Islands">
                    Faroe Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Fiji">Fiji</MDBSelectOption>
                  <MDBSelectOption value="Finland">Finland</MDBSelectOption>
                  <MDBSelectOption value="French Guiana">
                    French Guiana
                  </MDBSelectOption>
                  <MDBSelectOption value="French Polynesia">
                    French Polynesia
                  </MDBSelectOption>
                  <MDBSelectOption value="French Southern Ter">
                    French Southern Ter
                  </MDBSelectOption>
                  <MDBSelectOption value="Gabon">Gabon</MDBSelectOption>
                  <MDBSelectOption value="Gambia">Gambia</MDBSelectOption>
                  <MDBSelectOption value="Georgia">Georgia</MDBSelectOption>
                  <MDBSelectOption value="Germany">Germany</MDBSelectOption>
                  <MDBSelectOption value="Ghana">Ghana</MDBSelectOption>
                  <MDBSelectOption value="Gibraltar">Gibraltar</MDBSelectOption>
                  <MDBSelectOption value="Greece">Greece</MDBSelectOption>
                  <MDBSelectOption value="Greenland">Greenland</MDBSelectOption>
                  <MDBSelectOption value="Grenada">Grenada</MDBSelectOption>
                  <MDBSelectOption value="Guadeloupe">
                    Guadeloupe
                  </MDBSelectOption>
                  <MDBSelectOption value="Guam">Guam</MDBSelectOption>
                  <MDBSelectOption value="Guatemala">Guatemala</MDBSelectOption>
                  <MDBSelectOption value="Guinea">Guinea</MDBSelectOption>
                  <MDBSelectOption value="Guyana">Guyana</MDBSelectOption>
                  <MDBSelectOption value="Haiti">Haiti</MDBSelectOption>
                  <MDBSelectOption value="Hawaii">Hawaii</MDBSelectOption>
                  <MDBSelectOption value="Honduras">Honduras</MDBSelectOption>
                  <MDBSelectOption value="Hong Kong">Hong Kong</MDBSelectOption>
                  <MDBSelectOption value="Hungary">Hungary</MDBSelectOption>
                  <MDBSelectOption value="Iceland">Iceland</MDBSelectOption>
                  <MDBSelectOption value="Indonesia">Indonesia</MDBSelectOption>
                  <MDBSelectOption value="India">India</MDBSelectOption>
                  <MDBSelectOption value="Iran">Iran</MDBSelectOption>
                  <MDBSelectOption value="Iraq">Iraq</MDBSelectOption>
                  <MDBSelectOption value="Ireland">Ireland</MDBSelectOption>
                  <MDBSelectOption value="Isle of Man">
                    Isle of Man
                  </MDBSelectOption>
                  <MDBSelectOption value="Israel">Israel</MDBSelectOption>
                  <MDBSelectOption value="Italy">Italy</MDBSelectOption>
                  <MDBSelectOption value="Jamaica">Jamaica</MDBSelectOption>
                  <MDBSelectOption value="Japan">Japan</MDBSelectOption>
                  <MDBSelectOption value="Jordan">Jordan</MDBSelectOption>
                  <MDBSelectOption value="Kazakhstan">
                    Kazakhstan
                  </MDBSelectOption>
                  <MDBSelectOption value="Kenya">Kenya</MDBSelectOption>
                  <MDBSelectOption value="Kiribati">Kiribati</MDBSelectOption>
                  <MDBSelectOption value="Korea North">
                    Korea North
                  </MDBSelectOption>
                  <MDBSelectOption value="Korea Sout">
                    Korea South
                  </MDBSelectOption>
                  <MDBSelectOption value="Kuwait">Kuwait</MDBSelectOption>
                  <MDBSelectOption value="Kyrgyzstan">
                    Kyrgyzstan
                  </MDBSelectOption>
                  <MDBSelectOption value="Laos">Laos</MDBSelectOption>
                  <MDBSelectOption value="Latvia">Latvia</MDBSelectOption>
                  <MDBSelectOption value="Lebanon">Lebanon</MDBSelectOption>
                  <MDBSelectOption value="Lesotho">Lesotho</MDBSelectOption>
                  <MDBSelectOption value="Liberia">Liberia</MDBSelectOption>
                  <MDBSelectOption value="Libya">Libya</MDBSelectOption>
                  <MDBSelectOption value="Liechtenstein">
                    Liechtenstein
                  </MDBSelectOption>
                  <MDBSelectOption value="Lithuania">Lithuania</MDBSelectOption>
                  <MDBSelectOption value="Luxembourg">
                    Luxembourg
                  </MDBSelectOption>
                  <MDBSelectOption value="Macau">Macau</MDBSelectOption>
                  <MDBSelectOption value="Macedonia">Macedonia</MDBSelectOption>
                  <MDBSelectOption value="Madagascar">
                    Madagascar
                  </MDBSelectOption>
                  <MDBSelectOption value="Malaysia">Malaysia</MDBSelectOption>
                  <MDBSelectOption value="Malawi">Malawi</MDBSelectOption>
                  <MDBSelectOption value="Maldives">Maldives</MDBSelectOption>
                  <MDBSelectOption value="Mali">Mali</MDBSelectOption>
                  <MDBSelectOption value="Malta">Malta</MDBSelectOption>
                  <MDBSelectOption value="Marshall Islands">
                    Marshall Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Martinique">
                    Martinique
                  </MDBSelectOption>
                  <MDBSelectOption value="Mauritania">
                    Mauritania
                  </MDBSelectOption>
                  <MDBSelectOption value="Mauritius">Mauritius</MDBSelectOption>
                  <MDBSelectOption value="Mayotte">Mayotte</MDBSelectOption>
                  <MDBSelectOption value="Mexico">Mexico</MDBSelectOption>
                  <MDBSelectOption value="Midway Islands">
                    Midway Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Moldova">Moldova</MDBSelectOption>
                  <MDBSelectOption value="Monaco">Monaco</MDBSelectOption>
                  <MDBSelectOption value="Mongolia">Mongolia</MDBSelectOption>
                  <MDBSelectOption value="Montserrat">
                    Montserrat
                  </MDBSelectOption>
                  <MDBSelectOption value="Morocco">Morocco</MDBSelectOption>
                  <MDBSelectOption value="Mozambique">
                    Mozambique
                  </MDBSelectOption>
                  <MDBSelectOption value="Myanmar">Myanmar</MDBSelectOption>
                  <MDBSelectOption value="Nambia">Nambia</MDBSelectOption>
                  <MDBSelectOption value="Nauru">Nauru</MDBSelectOption>
                  <MDBSelectOption value="Nepal">Nepal</MDBSelectOption>
                  <MDBSelectOption value="Netherland Antilles">
                    Netherland Antilles
                  </MDBSelectOption>
                  <MDBSelectOption value="Netherlands">
                    Netherlands (Holland, Europe)
                  </MDBSelectOption>
                  <MDBSelectOption value="Nevis">Nevis</MDBSelectOption>
                  <MDBSelectOption value="New Caledonia">
                    New Caledonia
                  </MDBSelectOption>
                  <MDBSelectOption value="Nicaragua">Nicaragua</MDBSelectOption>
                  <MDBSelectOption value="Niger">Niger</MDBSelectOption>
                  <MDBSelectOption value="Nigeria">Nigeria</MDBSelectOption>
                  <MDBSelectOption value="Niue">Niue</MDBSelectOption>
                  <MDBSelectOption value="Norfolk Island">
                    Norfolk Island
                  </MDBSelectOption>
                  <MDBSelectOption value="Norway">Norway</MDBSelectOption>
                  <MDBSelectOption value="Oman">Oman</MDBSelectOption>
                  <MDBSelectOption value="Pakistan">Pakistan</MDBSelectOption>
                  <MDBSelectOption value="Palau Island">
                    Palau Island
                  </MDBSelectOption>
                  <MDBSelectOption value="Palestine">Palestine</MDBSelectOption>
                  <MDBSelectOption value="Panama">Panama</MDBSelectOption>
                  <MDBSelectOption value="Papua New Guinea">
                    Papua New Guinea
                  </MDBSelectOption>
                  <MDBSelectOption value="Paraguay">Paraguay</MDBSelectOption>
                  <MDBSelectOption value="Peru">Peru</MDBSelectOption>
                  <MDBSelectOption value="Phillipines">
                    Philippines
                  </MDBSelectOption>
                  <MDBSelectOption value="Pitcairn Island">
                    Pitcairn Island
                  </MDBSelectOption>
                  <MDBSelectOption value="Poland">Poland</MDBSelectOption>
                  <MDBSelectOption value="Portugal">Portugal</MDBSelectOption>
                  <MDBSelectOption value="Puerto Rico">
                    Puerto Rico
                  </MDBSelectOption>
                  <MDBSelectOption value="Qatar">Qatar</MDBSelectOption>
                  <MDBSelectOption value="Republic of Montenegro">
                    Republic of Montenegro
                  </MDBSelectOption>
                  <MDBSelectOption value="Republic of Serbia">
                    Republic of Serbia
                  </MDBSelectOption>
                  <MDBSelectOption value="Reunion">Reunion</MDBSelectOption>
                  <MDBSelectOption value="Romania">Romania</MDBSelectOption>
                  <MDBSelectOption value="Russia">Russia</MDBSelectOption>
                  <MDBSelectOption value="Rwanda">Rwanda</MDBSelectOption>
                  <MDBSelectOption value="St Barthelemy">
                    St Barthelemy
                  </MDBSelectOption>
                  <MDBSelectOption value="St Eustatius">
                    St Eustatius
                  </MDBSelectOption>
                  <MDBSelectOption value="St Helena">St Helena</MDBSelectOption>
                  <MDBSelectOption value="St Kitts-Nevis">
                    St Kitts-Nevis
                  </MDBSelectOption>
                  <MDBSelectOption value="St Lucia">St Lucia</MDBSelectOption>
                  <MDBSelectOption value="St Maarten">
                    St Maarten
                  </MDBSelectOption>
                  <MDBSelectOption value="St Pierre & Miquelon">
                    St Pierre & Miquelon
                  </MDBSelectOption>
                  <MDBSelectOption value="St Vincent & Grenadines">
                    St Vincent & Grenadines
                  </MDBSelectOption>
                  <MDBSelectOption value="Saipan">Saipan</MDBSelectOption>
                  <MDBSelectOption value="Samoa">Samoa</MDBSelectOption>
                  <MDBSelectOption value="Samoa American">
                    Samoa American
                  </MDBSelectOption>
                  <MDBSelectOption value="San Marino">
                    San Marino
                  </MDBSelectOption>
                  <MDBSelectOption value="Sao Tome & Principe">
                    Sao Tome & Principe
                  </MDBSelectOption>
                  <MDBSelectOption value="Saudi Arabia">
                    Saudi Arabia
                  </MDBSelectOption>
                  <MDBSelectOption value="Senegal">Senegal</MDBSelectOption>
                  <MDBSelectOption value="Seychelles">
                    Seychelles
                  </MDBSelectOption>
                  <MDBSelectOption value="Sierra Leone">
                    Sierra Leone
                  </MDBSelectOption>
                  <MDBSelectOption value="Singapore">Singapore</MDBSelectOption>
                  <MDBSelectOption value="Slovakia">Slovakia</MDBSelectOption>
                  <MDBSelectOption value="Slovenia">Slovenia</MDBSelectOption>
                  <MDBSelectOption value="Solomon Islands">
                    Solomon Islands
                  </MDBSelectOption>
                  <MDBSelectOption value="Somalia">Somalia</MDBSelectOption>
                  <MDBSelectOption value="Sri Lanka">Sri Lanka</MDBSelectOption>
                  <MDBSelectOption value="Sudan">Sudan</MDBSelectOption>
                  <MDBSelectOption value="Suriname">Suriname</MDBSelectOption>
                  <MDBSelectOption value="Swaziland">Swaziland</MDBSelectOption>
                  <MDBSelectOption value="Sweden">Sweden</MDBSelectOption>
                  <MDBSelectOption value="Switzerland">
                    Switzerland
                  </MDBSelectOption>
                  <MDBSelectOption value="Syria">Syria</MDBSelectOption>
                  <MDBSelectOption value="Tahiti">Tahiti</MDBSelectOption>
                  <MDBSelectOption value="Taiwan">Taiwan</MDBSelectOption>
                  <MDBSelectOption value="Tajikistan">
                    Tajikistan
                  </MDBSelectOption>
                  <MDBSelectOption value="Tanzania">Tanzania</MDBSelectOption>
                  <MDBSelectOption value="Thailand">Thailand</MDBSelectOption>
                  <MDBSelectOption value="Togo">Togo</MDBSelectOption>
                  <MDBSelectOption value="Tokelau">Tokelau</MDBSelectOption>
                  <MDBSelectOption value="Tonga">Tonga</MDBSelectOption>
                  <MDBSelectOption value="Trinidad & Tobago">
                    Trinidad & Tobago
                  </MDBSelectOption>
                  <MDBSelectOption value="Tunisia">Tunisia</MDBSelectOption>
                  <MDBSelectOption value="Turkey">Turkey</MDBSelectOption>
                  <MDBSelectOption value="Turkmenistan">
                    Turkmenistan
                  </MDBSelectOption>
                  <MDBSelectOption value="Turks & Caicos Is">
                    Turks & Caicos Is
                  </MDBSelectOption>
                  <MDBSelectOption value="Tuvalu">Tuvalu</MDBSelectOption>
                  <MDBSelectOption value="Uganda">Uganda</MDBSelectOption>
                  <MDBSelectOption value="Ukraine">Ukraine</MDBSelectOption>
                  <MDBSelectOption value="United Arab Erimates">
                    United Arab Emirates
                  </MDBSelectOption>
                  <MDBSelectOption value="Uraguay">Uruguay</MDBSelectOption>
                  <MDBSelectOption value="Uzbekistan">
                    Uzbekistan
                  </MDBSelectOption>
                  <MDBSelectOption value="Vanuatu">Vanuatu</MDBSelectOption>
                  <MDBSelectOption value="Vatican City State">
                    Vatican City State
                  </MDBSelectOption>
                  <MDBSelectOption value="Venezuela">Venezuela</MDBSelectOption>
                  <MDBSelectOption value="Vietnam">Vietnam</MDBSelectOption>
                  <MDBSelectOption value="Virgin Islands (Brit)">
                    Virgin Islands (Brit)
                  </MDBSelectOption>
                  <MDBSelectOption value="Virgin Islands (USA)">
                    Virgin Islands (USA)
                  </MDBSelectOption>
                  <MDBSelectOption value="Wake Island">
                    Wake Island
                  </MDBSelectOption>
                  <MDBSelectOption value="Wallis & Futana Is">
                    Wallis & Futana Is
                  </MDBSelectOption>
                  <MDBSelectOption value="Yemen">Yemen</MDBSelectOption>
                  <MDBSelectOption value="Zaire">Zaire</MDBSelectOption>
                  <MDBSelectOption value="Zambia">Zambia</MDBSelectOption>
                  <MDBSelectOption value="Zimbabwe">Zimbabwe</MDBSelectOption>
                </MDBSelectOptions>
              </MDBSelect>
            </div>
            <MDBInput
              value={city}
              onChange={(e) => {
                var cityUppercase = e.target.value.replace(/\b\w/g, (c) =>
                  c.toUpperCase()
                );
                this.setState({
                  city: cityUppercase,
                  skip: 0,
                  videos: [],
                  firstLoad: true,
                });
              }}
              type="text"
              label="City"
              style={inputText}
              maxLength="80"
            />
            <div>
              <MDBRow style={categoryRow}>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity: category === "" || category === "Surf" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addSurf) {
                        this.setState({ category: "Surf", addSurf: false });
                      } else {
                        this.setState({ category: "", addSurf: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Surf</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/surf.jpg"
                      alt="Surf"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity:
                        category === "" || category === "Skate" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addSkate) {
                        this.setState({ category: "Skate", addSkate: false });
                      } else {
                        this.setState({ category: "", addSkate: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Skate</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/skate.jpeg"
                      alt="Skate"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity:
                        category === "" || category === "Snowboard" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addSnowboard) {
                        this.setState({
                          category: "Snowboard",
                          addSnowboard: false,
                        });
                      } else {
                        this.setState({ category: "", addSnowboard: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Snowboard</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/snowboard.jpg"
                      alt="Snowboard"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity: category === "" || category === "Ski" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addSki) {
                        this.setState({ category: "Ski", addSki: false });
                      } else {
                        this.setState({ category: "", addSki: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Ski</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/ski.jpg"
                      alt="Ski"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity: category === "" || category === "BMX" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addBMX) {
                        this.setState({ category: "BMX", addBMX: false });
                      } else {
                        this.setState({ category: "", addBMX: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>BMX</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/bmx.jpg"
                      alt="BMX"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity: category === "" || category === "MTB" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addMTB) {
                        this.setState({ category: "MTB", addMTB: false });
                      } else {
                        this.setState({ category: "", addMTB: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>MTB</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/mtb.jpeg"
                      alt="MTB"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity:
                        category === "" || category === "Scooter" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addScooter) {
                        this.setState({
                          category: "Scooter",
                          addScooter: false,
                        });
                      } else {
                        this.setState({ category: "", addScooter: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Scooter</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/scooter.jpeg"
                      alt="Scooter"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity:
                        category === "" || category === "Climbing" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addClimbing) {
                        this.setState({
                          category: "Climbing",
                          addClimbing: false,
                        });
                      } else {
                        this.setState({ category: "", addClimbing: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Climbing</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/climbing.jpeg"
                      alt="Climbing"
                    />
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="4" className="col-4 mt-3">
                  <div
                    style={{
                      border: "1px solid #202020",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                      padding: "0.25rem",
                      opacity:
                        category === "" || category === "Other" ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (addOther) {
                        this.setState({ category: "Other", addOther: false });
                      } else {
                        this.setState({ category: "", addOther: true });
                      }
                    }}
                  >
                    <h5 style={categoryTitle}>Other</h5>
                    <img
                      style={categoryImg}
                      src="/Sport_Icons/Other.jpg"
                      alt="Other"
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
            <div className="text-center mt-4">
              <MDBBtn
                style={searchButton}
                rounded
                color="white"
                onClick={() => {
                  this._executeAdvancedSearch();
                  this.setState({ videos: [] });
                }}
              >
                Search
              </MDBBtn>
            </div>
          </MDBCol>
        </div>
        <div className="mt-5">
          <MDBCol lg="12" md="12" sm="12" className="col-12 px-3">
            <div>
              <h3
                style={title}
                className="pb-3 font-weight-bolder text-uppercase"
              >
                Edits
              </h3>
            </div>
            {!firstLoad ? (
              <div>
                {this.state.videos.length === 0 ? (
                  <div className="py-2">
                    <h5 style={editsText}>
                      There are currently no Edits matching your Search.
                    </h5>
                  </div>
                ) : (
                  <InfiniteScroll
                    dataLength={this.state.videos.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={
                      <div className="pb-2" style={editsText} key={0}>
                        <h5>Loading More Edits...</h5>
                      </div>
                    }
                  >
                    <MDBRow className="justify-content-center">
                      {this.state.videos.map((video) => (
                        <Video key={video.id} video={video} />
                      ))}
                    </MDBRow>
                    {!this.state.hasMore ? (
                      <div className="pb-2" style={editsText} key={0}>
                        <h5>No More Edits!</h5>
                      </div>
                    ) : (
                      <></>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            ) : (
              <></>
            )}
          </MDBCol>
        </div>
      </div>
    );
  }

  _executeAdvancedSearch = async () => {
    var {
      name,
      instagram,
      gender,
      country,
      city,
      category,
      orientation,
      resolution,
      filetype,
      skip,
    } = this.state;
    if (country === "Remove Country") {
      country = "";
    }
    const result = await this.props.client.query({
      query: ADVANCED_SEARCH_QUERY,
      fetchPolicy: "network-only",
      variables: {
        name,
        instagram,
        country,
        city,
        category,
        gender,
        orientation,
        resolution,
        filetype,
        skip,
        last: PAGE_SIZE,
      },
    });

    this.setState({
      videos: [...this.state.videos, ...result.data.advancedSearch.videos],
    });
    if (result.data.advancedSearch.videos.length === 0) {
      this.setState({ hasMore: false, firstLoad: false });
    } else {
      this.setState({ hasMore: true, firstLoad: false });
    }
  };
}

export default withApollo(AdvancedSearch);

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
};

const genderContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const categoryRow = {
  display: "flex",
  justifyContent: "space-evenly",
};

const categoryTitle = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "center",
  fontSize: "1rem",
};

const categoryImg = {
  borderRadius: "0.25rem",
  objectFit: "cover",
  width: "100%",
  height: "80px",
};

const searchButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};

const editsText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "center",
  textTransform: "uppercase",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};
