import React, { Component } from 'react'
import { MDBCol, MDBRow} from "mdbreact";

class FeaturesSection extends Component {
  render() {
    return (
      <section id="features" style={{backgroundColor: '#000'}}>
        <MDBCol lg="8" md="12" sm="12" xs="12" className="offset-lg-2">
          <div className="pt-5 px-3">
            <h2 style={title} className="h2-responsive text-center mb-3 mt-3">
              With over 100 brands onboard 
            </h2>
            <h2 style={title} className="h2-responsive text-center mb-3">
              Representing Surf, Skate, Snowboard, Ski, BMX, MTB, Scooter and Climbing
            </h2>
            <h5 style={secondaryTitle} className="mb-3">The Sponsor Hub is a platform for extreme sports athletes to showcase their skills, connect with like minded athletes, find established and exciting new brands at fantastic discounts and possibly secure sponsorship / ambassador roles. Allowing 'The Dream' to become a reality.</h5>
            <MDBRow style={{marginRight: -15, marginLeft: -15}}>
              <MDBCol lg="6" md="6" sm="12" xs="12" style={{display: 'grid', alignContent: 'center'}}>
                <div>
                  <div>
                    <h4 style={groupTitle} className="my-3">Athletes</h4>
                    <p style={groupText} className="mb-3">
                      The Sponsor Hub platform is a collaborative space for athletes to share clips, build crews, discover new spots, follow fellow athletes, access exclusive discounts, and find potential sponsorship and ambassadorial roles from our amazing collaborating brands.<br/>
                    </p>
                  </div>
                  <div className='pt-3'>
                    <h4 style={groupTitle} className="my-3">Businesses</h4>
                    <p style={groupText} className="mb-3">
                      The Sponsor Hub provides a platform for brands to discover new team members, influencers and ambassadors.
                    </p>
                    <p style={groupText} className="mb-3">
                      Further promotion is provided via exclusive discounts that allow all our athletes to benefit from being part of The Sponsor Hub community.
                    </p>
                  </div>
                  <h4 style={shareTheStokeText} className="py-3">
                    A one-stop-shop for all your needs and to share the stoke!
                  </h4>
                </div>
              </MDBCol>
              <MDBCol lg="6" md="6" sm="12" xs="12">
                <div>
                  <h2 style={title} className="h2-responsive text-center mb-2">
                    Download our App
                  </h2>
                  <div style={{display: 'flex', justifyContent: 'center', maxHeight: 550}}>
                    <video width={'100%'} muted autoPlay loop preload="auto">
                      <source src="https://the-sponsor-hub.ams3.digitaloceanspaces.com/LandingVideos/AppVideo.mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className='mt-3' style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <a target="_blank" rel="noopener noreferrer" style={{ padding: '0', color: 'black', textAlign: 'left' }} href={"https://play.google.com/store/apps/details?id=com.thesponsorhub"}>
                      <img height={40} src="/App_Stores/googlePlay.svg" alt="Google Play Store"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" style={{ padding: '0', color: 'black', textAlign: 'left' }} href={"https://apps.apple.com/gm/app/the-sponsor-hub/id1576680095"}>
                      <img height={40} src="/App_Stores/appStore.svg" alt="App Store"/>
                    </a>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </section>
    );
  }
}

export default FeaturesSection;

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff'
}

const secondaryTitle = {
  textAlign: 'justify', 
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

const groupTitle = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff', 
}

const groupText = {
  textAlign: 'justify', 
  color: '#f8f8ff', 
  fontFamily: 'Roboto'
}

const shareTheStokeText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}
