import React, { Component } from "react";
import { MDBCol } from "mdbreact";

class Loading extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#000'}}>
        <MDBCol sm="10" md="8" lg="4" className="px-3 offset-lg-4 offset-md-2 offset-sm-1 col-12" style={loadingColumn}>
          <img src="TSH_logos/TSH_ES_Black.png" alt="TSH Logo"/>
        </MDBCol>
      </div>
    );
  }
}

export default Loading;

const loadingColumn = {
  minHeight: '100vh', 
  display: 'flex', 
  alignItems: 'center'
}
