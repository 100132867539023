import React from "react";
import { MDBCol } from "mdbreact";
import "./../../styles/Legal.css";

const TermsAndConditions = () => {
  return (
    <div
      className="px-3 pt-4"
      style={{ backgroundColor: "#fff", textAlign: "justify" }}
    >
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Terms and Conditions</h1>
          <p className="legal-text">
            Please read these terms and conditions carefully before using this
            site and/or creating an account. By continuing to use this site or
            creating an account, you are deemed to have accepted these terms and
            conditions.
          </p>
          <br />
          <h3 className="legal-subheading">What's in these terms?</h3>
          <p className="legal-text">
            These terms tell you the rules for using our website{" "}
            <a className="legal-link" href="https://thesponsorhub.com">
              <p className="legal-text">https://thesponsorhub.com/</p>
            </a>{" "}
            (together "<p className="legal-text-bolder">our site</p>").{" "}
          </p>
          <br />
          <h3 className="legal-subheading">Who we are and how to contact us</h3>
          <p className="legal-text">
            <a className="legal-link" href="https://thesponsorhub.com">
              <p className="legal-text">https://thesponsorhub.com/</p>
            </a>{" "}
            is a site operated by The Sponsor Hub Ltd ("
            <p className="legal-text-bolder">The Sponsor Hub</p>") or ("
            <p className="legal-text-bolder">We</p>"). We are registered in
            England and Wales under company number 11467568 and have our
            registered office at Walk Mill, Kirkwhelpington, Newcastle Upon
            Tyne, Northumberland, England, NE19 2SB. We are a limited company.
          </p>
          <br />
          <p className="legal-text">
            To contact us, please email{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">
            By using our site you accept these terms
          </h3>
          <p className="legal-text">
            By using our site and/or creating an account on our site, you
            confirm that you accept these terms of use and that you agree to
            comply with them.
          </p>
          <br />
          <p className="legal-text">
            If you do not agree to these terms, you must not use our site.
          </p>
          <br />
          <p className="legal-text">
            We recommend that you print a copy of these terms for future
            reference.
          </p>
          <br />
          <p className="legal-text">
            You must be 18 years old to accept these terms and use our site. If
            you are under 18 years of age you may still use our site, but you
            must obtain consent from a parent or guardian to use this site and
            agree to these terms and conditions before using our site. Special
            terms regarding minors are set out below.
          </p>
          <br />
          <h3 className="legal-subheading">
            There are other terms that may apply to you
          </h3>
          <p className="legal-text">
            These terms of use refer to the following additional terms, which
            also apply to your use of our site:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                Our{" "}
                <a className="legal-link" href="/privacy_policy">
                  <p className="legal-text">Privacy Policy</p>
                </a>
                , see more below under How we use your personal data.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Our{" "}
                <a className="legal-link" href="cookies_policy">
                  <p className="legal-text">Cookie Policy</p>
                </a>
                , which sets out information about the cookies on our site.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">
            We may make changes to these terms
          </h3>
          <p className="legal-text">
            We amend these terms from time to time. Every time you wish to use
            our site, please check these terms to ensure you understand the
            terms that apply at that time.
          </p>
          <br />
          <h3 className="legal-subheading">We may make changes to our site</h3>
          <p className="legal-text">
            We may update and change our site from time to time to reflect
            changes to our products, our users' needs and our business
            priorities.
          </p>
          <br />
          <h3 className="legal-subheading">
            We may suspend or withdraw our site
          </h3>
          <p className="legal-text">
            We do not guarantee that our site, or any content on it, will always
            be available or be uninterrupted. We may suspend or withdraw or
            restrict the availability of all or any part of our site for
            business and operational reasons. We will try to give you reasonable
            notice of any suspension or withdrawal.
          </p>
          <br />
          <p className="legal-text">
            You are also responsible for ensuring that all persons who access
            our site through your internet connection are aware of these terms
            of use and other applicable terms and conditions, and that they
            comply with them.
          </p>
          <br />
          <h3 className="legal-subheading">
            We may transfer this Agreement to someone else
          </h3>
          <p className="legal-text">
            We may transfer our rights and obligations under these terms to
            another organisation. We will always tell you in writing if this
            happens and we will ensure that the transfer will not affect your
            rights under the contract.
          </p>
          <br />
          <h3 className="legal-subheading">
            You must keep your account details safe
          </h3>
          <p className="legal-text">
            If you create, or you are provided with, login details, password or
            any other account information or other piece of information as part
            of our account creation and security procedures ("
            <p className="legal-text-bolder">login details</p>"), you must treat
            such information as confidential. You must not disclose it to any
            third party.
          </p>
          <br />
          <p className="legal-text">
            We have the right to disable any accounts, login details, or
            passwords, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with any of the
            provisions of these terms of use.
          </p>
          <br />
          <p className="legal-text">
            You can also delete your account by clicking on the Edit Details
            section of your profile on the site and clicking Delete Account.
          </p>
          <br />
          <p className="legal-text">
            If you know or suspect that anyone other than you knows your login
            details, you must promptly notify us at{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>{" "}
            and change your login details. You can change your password and
            other login details through our change of password function which
            can be found in the Edit Details section of your profile on the
            site.
          </p>
          <br />
          <h3 className="legal-subheading">
            How you may use material on our site
          </h3>
          <p className="legal-text">
            We are the owner or the licensee of all intellectual property rights
            on our site, and in the material published on it. Those works are
            protected by copyright laws and treaties around the world. All such
            rights are reserved.
          </p>
          <br />
          <p className="legal-text">
            You may print off one copy, and may download extracts, of any
            page(s) from our site for your personal use and you may draw the
            attention of others within your organisation to content posted on
            our site.
          </p>
          <br />
          <p className="legal-text">
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </p>
          <br />
          <p className="legal-text">
            Our status (and that of any identified contributors) as the authors
            of content on our site must always be acknowledged (except where the
            content is user-generated). See Uploading content to our site
            section below and in particular the reservation of your rights to
            your content.
          </p>
          <br />
          <p className="legal-text">
            You must not use any part of the content on our site for commercial
            purposes without obtaining a licence to do so from us or our
            licensors.
          </p>
          <br />
          <p className="legal-text">
            If you print off, copy or download any part of our site in breach of
            these terms of use, your right to use our site will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </p>
          <br />
          <h3 className="legal-subheading">
            No text or data mining, or web scraping
          </h3>
          <p className="legal-text">
            You shall not conduct, facilitate, authorise or permit any text or
            data mining or web scraping in relation to our site or any services
            provided via, or in relation to, our site. This includes using (or
            permitting, authorising or attempting the use of):
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                Any "robot", "bot", "spider", "scraper" or other automated
                device, program, tool, algorithm, code, process or methodology
                to access, obtain, copy, monitor or republish any portion of the
                site or any data, content, information or services accessed via
                the same.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Any automated analytical technique aimed at analysing text and
                data in digital form to generate information which includes but
                is not limited to patterns, trends and correlations.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">
            Do not rely on information on this site
          </h3>
          <p className="legal-text">
            The content on our site is provided for general information and
            networking purposes only in order to allow sponsors and athletes to
            discover each other. It is not intended to amount to information or
            advice on which you should rely or manoeuvres, tricks or
            demonstrations that you should try or recreate. You must obtain
            professional or specialist advice before taking, or refraining from,
            any action on the basis of the content on our site or before trying
            or recreating any manoeuvres, tricks or demonstrations contained on
            our site.
          </p>
          <br />
          <p className="legal-text">
            Although we make reasonable efforts to update the information on our
            site, we make no representations, warranties or guarantees, whether
            express or implied, that the content on our site is accurate,
            complete or up to date.
          </p>
          <br />
          <p className="legal-text">
            You accept that there is no assurance or guarantee that you will
            find a sponsor or athlete to partner with on our site or that use of
            our site will result in any sponsorship or partnering opportunities.
          </p>
          <br />
          <h3 className="legal-subheading">
            We are not responsible for websites we link to
          </h3>
          <p className="legal-text">
            Where our site contains links to other sites and resources provided
            by third parties, these links are provided for your information
            only. Such links should not be interpreted as approval by us of
            those linked websites or information you may obtain from them.
          </p>
          <br />
          <p className="legal-text">
            We have no control over the contents of those sites or resources.
          </p>
          <br />
          <p className="legal-text">
            Any discount or other offers presented for third party products,
            services or websites, are provided to us by such third parties and
            we make no representations, warranties, guarantees or assurances
            that such offers, codes or links will be accurate or effective and
            you will be bound by the terms and conditions of such third party
            when using such offers, codes or links.
          </p>
          <br />
          <h3 className="legal-subheading">
            User-generated content is not approved by us
          </h3>
          <p className="legal-text">
            This website may include information, videos and materials uploaded
            by other users of the site, containing such information including
            but not limited to, third party discounts, suggested sports
            locations, and maps. This information and these materials have not
            been verified or approved by us. The views expressed by other users
            on our site do not represent our views or values. Videos and other
            content my contain manoeuvres, tricks or demonstrations posted by
            users, these should not be followed or recreated by you and we shall
            have no responsibility or liability for any loss, damages or injury
            resulting in your recreation of any manoeuvres, tricks or
            demonstrations that may be contained in users' content on the site
            or any other reliance whatsoever contained in the users' content or
            any other content on the site.
          </p>
          <br />
          <p className="legal-text">
            The Sponsor Hub does not endorse any user content on the Video
            Sharing Platform (VSP) or any opinion, recommendation, or advice
            expressed therein, and The Sponsor Hub expressly disclaims any and
            all liability in connection with any such content.
          </p>
          <br />
          <p className="legal-text">
            Our management and operation of this website will comply as required
            with Ofcom VSP regulations and we expressly reserve the right to
            engage with or remove content in accordance with such regulations.
          </p>
          <br />
          <h3 className="legal-subheading">
            How to complain about content uploaded by other users
          </h3>
          <p className="legal-text">
            If you wish to complain about content uploaded by other users,
            please contact us on{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">
            Our responsibility for loss or damage suffered by you
          </h3>
          <p className="legal-text">
            Nothing in these terms excludes or limits our liability for any
            death or personal injury caused by our negligence, liability for
            fraud or fraudulent misrepresentation, or any other liability that
            the law does not allow us to exclude or limit.
          </p>
          <br />
          <p className="legal-text">
            Please note that we generally only provide access to our website for
            domestic and private use. Unless otherwise expressly agreed by us in
            writing, you agree not to use our website for any commercial or
            business purposes, and we have no liability to you for any loss of
            profit, loss of business, business interruption, or loss of business
            opportunity.
          </p>
          <br />
          <p className="legal-text">
            Further to the above sections,{" "}
            <p className="legal-text-bolder">
              if you are an individual or business with permission from us to
              use our app for commercial purposes
            </p>
            :
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                We exclude all implied conditions, warranties, representations
                or other terms that may apply to our website or any content on
                it.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                We will not be liable to you for any loss or damage, whether in
                contract, tort (including negligence), breach of statutory duty,
                or otherwise, even if foreseeable, arising under or in
                connection with:
              </p>
              <br />
              <li>
                <p className="legal-text">
                  a) use of, or inability to use, our website; or
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  b) use of or reliance on any content displayed on our website.
                </p>
                <br />
              </li>
            </li>
            <li>
              <p className="legal-text">
                In particular, we will not be liable for loss of profits, sales,
                business, or revenue, business interruption, loss of anticipated
                savings; loss of business opportunity, goodwill or reputation;
                or any indirect or consequential loss or damage.
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            We will only use your personal information as set out in our{" "}
            <a className="legal-link" href="/privacy_policy">
              <p className="legal-text">Privacy Policy</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">Uploading content to our site</h3>
          <p className="legal-text">
            Whenever you make use of a feature that allows you to upload content
            to our site, or to make contact with other users of our site, you
            must comply with our{" "}
            <p className="legal-text-bolder">Acceptable Use</p> terms and{" "}
            <p className="legal-text-bolder">Content Standards</p> set out in
            the sections below.
          </p>
          <br />
          <p className="legal-text">
            You warrant that any such contribution does comply with those
            standards, and you will be liable to us and indemnify us for any
            breach of that warranty. This means you will be responsible for any
            loss or damage we suffer as a result of your breach of warranty.
          </p>
          <br />
          <p className="legal-text">
            You warrant that all rights in the content that you upload to the
            site or that you deliver to us to enable us to carry out any
            services for you, belong to you or that you have all necessary
            permissions from those that do own the rights in the content that
            you upload, to use that content in that way.
          </p>
          <br />
          <p className="legal-text">
            If any action or proceeding is brought by a third party against us
            in respect of any alleged infringement or any rights in respect of
            any content you upload to our site, then we shall be indemnified by
            you from and against all costs, damages, liabilities, and losses
            agreed to be paid by way of settlement or compromise by, or finally
            awarded against us as a result of or in connection with such claims
            of infringement.
          </p>
          <br />
          <p className="legal-text">
            Any content you upload to our site will be considered
            non-confidential and non-proprietary. You retain all of your
            ownership rights in your content, but you are required to grant us
            and other users of our site a limited licence to use, store, create
            databases from and copy that content and to distribute and make it
            available to third parties. The rights you license to us are
            described in{" "}
            <p className="legal-text-bolder">
              Rights you are giving us to use material you upload
            </p>
            .
          </p>
          <br />
          <p className="legal-text">
            We also have the right to disclose your identity to any third party
            who is claiming that any content posted or uploaded by you to our
            site constitutes a violation of their intellectual property rights,
            or of their right to privacy.
          </p>
          <br />
          <p className="legal-text">
            We have the right to remove any posting you make on our site if, in
            our opinion, your post does not comply with our{" "}
            <p className="legal-text-bolder">Acceptable Use</p> terms and{" "}
            <p className="legal-text-bolder">Content Standards</p> set out in
            the sections below.
          </p>
          <br />
          <p className="legal-text">
            You are solely responsible for securing and backing up your content.
          </p>
          <br />
          <p className="legal-text">
            Our management and operation of this website will comply as required
            with Ofcom VSP regulations and we expressly reserve the right to
            engage with or remove content in accordance with such regulations.
          </p>
          <br />
          <h3 className="legal-subheading">Acceptable Use</h3>
          <p className="legal-text-bolder">Prohibited uses</p>
          <br />
          <p className="legal-text">You may not use our site:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                In any way that breaches any applicable local, national or
                international law or regulation.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                In any way that is unlawful or fraudulent or has any unlawful or
                fraudulent purpose or effect.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                For the purpose of harming or attempting to harm minors in any
                way.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                To bully, insult, intimidate or humiliate any person.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                To send, knowingly receive, upload, download, use or re-use any
                material which does not comply with our content standards set
                out in the Content Standards section below.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                To transmit, or procure the sending of, any unsolicited or
                unauthorised advertising or promotional material or any other
                form of similar solicitation (spam).
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                To knowingly transmit any data, send or upload any material that
                contains viruses, Trojan horses, worms, time-bombs, keystroke
                loggers, spyware, adware or any other harmful programs or
                similar computer code designed to adversely affect the operation
                of any computer software or hardware.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">To upload terrorist content.</p>
              <br />
            </li>
          </ul>
          <p className="legal-text">You also agree:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                Not to reproduce, duplicate, copy or re-sell any part of our
                site in contravention of the provisions of these terms of
                website use.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Not to access without authority, interfere with, damage or
                disrupt:
              </p>
              <br />
              <li>
                <p className="legal-text">any part of our site;</p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  any equipment or network on which our site is stored;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  any software used in the provision of our site; or
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  any equipment or network or software owned or used by any
                  third party.
                </p>
                <br />
              </li>
            </li>
          </ul>
          <p className="legal-text-bolder">Interactive services</p>
          <br />
          <p className="legal-text">
            We may from time to time provide interactive services on our site,
            including, without limitation:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">Video-sharing facilities.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">Comments.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">Live streaming.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">Maps.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">Stokes.</p>
              <br />
            </li>
          </ul>
          <p className="legal-text-bolder">(interactive services.)</p>
          <br />
          <p className="legal-text">
            Where we do provide any interactive service, we will provide clear
            information to you about the kind of service offered, if it is
            moderated and what form of moderation is used (including whether it
            is human or technical).
          </p>
          <br />
          <p className="legal-text">
            We will do our best to assess any possible risks for users (and in
            particular, for children) from third parties when they use any
            interactive service provided on our app, and we will decide in each
            case whether it is appropriate to use moderation of the relevant
            service (including what kind of moderation to use) in the light of
            those risks. However, we expressly exclude our liability for any
            loss or damage arising from the use of any interactive service by a
            user in contravention of our{" "}
            <p className="legal-text-bolder">Content Standards</p>.
          </p>
          <br />
          <p className="legal-text">
            The use of any of our interactive services by a minor is subject to
            the consent of their parent or guardian. We advise parents who
            permit their children to use an interactive service that it is
            important that they communicate with their children about their
            safety online, as moderation is not fool proof. Minors who are using
            any interactive service should be made aware of the potential risks
            to them.
          </p>
          <br />
          <p className="legal-text">
            Where we do moderate an interactive service, we will normally
            provide you with a means of contacting the moderator, should a
            concern or difficulty arise.
          </p>
          <br />
          <p className="legal-text">We do not store terrorist content.</p>
          <br />
          <h3 className="legal-subheading">Content standards</h3>
          <p className="legal-text">
            These content standards apply to any and all material which you
            contribute to our site, including but not limited to, uploading
            video content, images, discounts or offers, third party links,
            submitting comments, messages to others, or contacting others
            through their details available on the site (
            <p className="legal-text-bolder">Contribution</p>), and to any
            interactive services associated with it.
          </p>
          <br />
          <p className="legal-text">
            The Content Standards must be complied with in spirit as well as to
            the letter. The standards apply to each part of any Contribution as
            well as to its whole.
          </p>
          <br />
          <p className="legal-text">
            The Sponsor Hub will determine, in its discretion, whether a
            Contribution breaches the Content Standards.
          </p>
          <br />
          <p className="legal-text">A Contribution must:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">Be accurate (where it states facts).</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Be genuinely held (where it states opinions).
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Comply with the law applicable in England and Wales and in any
                country from which it is posted.
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">A Contribution must not:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">Be defamatory of any person.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Be obscene, offensive, hateful or inflammatory.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Bully, insult, intimidate or humiliate.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Promote sexually explicit material.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Include child abuse or child sexual abuse material.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Promote violence.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Promote discrimination based on race, sex, religion,
                nationality, disability, sexual orientation or age.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Infringe any copyright, database right or trade mark of any
                other person.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Be likely to deceive any person.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Breach any legal duty owed to a third party, such as a
                contractual duty or a duty of confidence.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Promote any illegal content or activity.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Be in contempt of court.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Be threatening, abuse or invade another's privacy, or cause
                annoyance, inconvenience or needless anxiety.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Be likely to harass, upset, embarrass, alarm or annoy any other
                person.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Impersonate any person or misrepresent your identity or
                affiliation with any person.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Give the impression that the Contribution emanates from The
                Sponsor Hub, if this is not the case.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Advocate, promote, incite any party to commit, or assist any
                unlawful or criminal act such as (by way of example only)
                copyright infringement or computer misuse.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Contain a statement which you know or believe, or have
                reasonable grounds for believing, that members of the public to
                whom the statement is, or is to be, published are likely to
                understand as a direct or indirect encouragement or other
                inducement to the commission, preparation or instigation of acts
                of terrorism.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Contain any advertising or promote any services or web links to
                other sites.
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            For the avoidance of doubt, for any Contribution in the form of
            video content:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                You must not upload and you must tell us immediately, if you see
                a video that has been uploaded on our site, containing any of
                the following: criminal material (relating to terrorism, sexual
                exploitation of children, child pornography, racism and
                xenophobia), unclassified or unclassifiable videos, videos rated
                R18 or suitable for R18 rating and other material that might
                impair the physical, mental or moral development of persons
                under the age of 18 (restricted material).
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                You must not upload a video containing harmful material.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                You must not upload a video containing advertising for any of
                the following:
              </p>
              <br />
              <li>
                <p className="legal-text">
                  cigarettes and other tobacco products, electronic cigarettes
                  or electronic cigarette refill containers, and
                  prescription-only medicine; or
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">for alcoholic drinks.</p>
                <br />
              </li>
            </li>
            <li>
              <p className="legal-text">
                Any advertising included in a video you upload must not:
              </p>
              <br />
              <li>
                <p className="legal-text">
                  prejudice respect for human dignity;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  include or promote discrimination based on sex, racial or
                  ethnic origin, nationality, religion or belief, disability,
                  age or sexual orientation;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  encourage behaviour prejudicial to health or safety;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  encourage behaviour grossly prejudicial to the protection of
                  the environment;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  cause physical, mental or moral detriment to persons under the
                  age of 18;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  directly exhort such persons to purchase or rent goods or
                  services in a manner which exploits their inexperience or
                  credulity;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  directly encourage such persons to persuade their parents or
                  others to purchase or rent goods or services;
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  exploit the trust of such persons in parents, teachers or
                  others; or
                </p>
                <br />
              </li>
              <li>
                <p className="legal-text">
                  unreasonably show such persons in dangerous situations.
                </p>
                <br />
              </li>
            </li>
            <li>
              <p className="legal-text">
                You must use the functionality provided on our site to declare
                whether, as far as you know or can reasonably be expected to
                know, any video contains advertising.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">
            Breach of Acceptable Use or Content Standards
          </h3>
          <p className="legal-text">
            When we consider that a breach of{" "}
            <p className="legal-text-bolder">Acceptable Use</p> or{" "}
            <p className="legal-text-bolder">Content Standards</p> or any other
            breach of these terms and conditions, has occurred, we may take such
            action as we deem appropriate.
          </p>
          <br />
          <p className="legal-text">
            Failure to comply with these terms and conditions constitutes a
            material breach upon which you are permitted to use our site, and
            may result in our taking all or any of the following actions:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                Immediate, temporary or permanent withdrawal of your right to
                use our site.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Immediate, temporary or permanent removal of any Contribution
                uploaded by you to our site.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Issue of a warning to you.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Legal proceedings against you for reimbursement of all costs on
                an indemnity basis (including, but not limited to, reasonable
                administrative and legal costs) resulting from the breach.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Further legal action against you.</p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Disclosure of such information to law enforcement authorities as
                we reasonably feel is necessary or as required by law.
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            We exclude our liability for all action we may take in response to
            breaches of these terms and conditions. The actions we may take are
            not limited to those described above, and we may take any other
            action we reasonably deem appropriate.
          </p>
          <br />
          <p className="legal-text">
            In the instance that content you upload to, or your behaviour
            generally on, other websites or platforms is not compliant with or
            is in violation of our{" "}
            <p className="legal-text-bolder">Acceptable Use</p> or{" "}
            <p className="legal-text-bolder">Content Standards</p>, we reserve
            the right to delete your profile from our site, withdraw your right
            to use our site, and delete any content or Contribution of yours
            from our site, because your content or behaviour is not compliant
            with our <p className="legal-text-bolder">Acceptable Use</p> or{" "}
            <p className="legal-text-bolder">Content Standards</p> and/or any
            breach by you of acceptable use policies or content standards on any
            other social media site or other websites or platforms, will damage
            the reputation and business of The Sponsor Hub.
          </p>
          <br />
          <h3 className="legal-subheading">Minors</h3>
          <p className="legal-text">
            We will do our best to ensure that engagement between the platform
            and minors including uploaded content complies with all applicable
            laws and guidelines within our jurisdiction including but not
            limited to The ICO Childrens Code (
            <a
              className="legal-link"
              href="https://ico.org.uk/for-organisations/guide-to-data-protection/ico-codes-of-practice/age-appropriate-design-a-code-of-practice-for-online-services/"
            >
              <p className="legal-text">
                https://ico.org.uk/for-organisations/guide-to-data-protection/ico-codes-of-practice/age-appropriate-design-a-code-of-practice-for-online-services/
              </p>
            </a>
            ), Ofcom Video Sharing Platform Guidance (
            <a
              className="legal-link"
              href="https://www.ofcom.org.uk/__data/assets/pdf_file/0019/226306/vsp-industry-guide.pdf"
            >
              <p className="legal-text">
                https://www.ofcom.org.uk/__data/assets/pdf_file/0019/226306/vsp-industry-guide.pdf
              </p>
            </a>
            ) and the NSPCC Images Sharing Guidance (
            <a
              className="legal-link"
              href="https://learning.nspcc.org.uk/research-resources/briefings/photography-sharing-images-guidance"
            >
              <p className="legal-text">
                https://learning.nspcc.org.uk/research-resources/briefings/photography-sharing-images-guidance
              </p>
            </a>
            ). If you are uploading content or otherwise engaging with the
            platform in any way which involves the use of content including or
            featuring minors you must ensure that the content complies with all
            applicable laws and guidelines including those mentioned above and
            we accept no responsibility for any failure on the user’s part to do
            so. Also any inappropriate content of any nature involving minors
            will be reported to the relevant authorities.
          </p>
          <br />
          <h3 className="legal-subheading">
            Rights you are giving us to use material you upload
          </h3>
          <p className="legal-text">
            When you upload or post content to our site, you grant us the
            following rights to use that content:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                a worldwide, non-exclusive, royalty-free, transferable licence
                to use, reproduce, distribute, prepare derivative works of,
                display, and perform that user-generated content in connection
                with the service provided by the website and across different
                media including to promote the site or the service to expire
                when the user deletes the content from the site;
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                a worldwide, non-exclusive, royalty-free, transferable licence
                for other users, partners or advertisers to use the content for
                their purposes and/or in accordance with the functionality of
                the site to expire when the user deletes the content from the
                site.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">
            We are not responsible for viruses and you must not introduce them
          </h3>
          <p className="legal-text">
            We do not guarantee that our site will be secure or free from bugs
            or viruses.
          </p>
          <br />
          <p className="legal-text">
            You are responsible for configuring your information technology,
            computer programmes and platform to access our site. You should use
            your own virus protection software.
          </p>
          <br />
          <p className="legal-text">
            You must not misuse our site by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our site, the server on which our site is stored or any
            server, computer or database connected to our site. You must not
            attack our site via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you would
            commit a criminal offence under the Computer Misuse Act 1990. We
            will report any such breach to the relevant law enforcement
            authorities and we will co-operate with those authorities by
            disclosing your identity to them. In the event of such a breach,
            your right to use our site will cease immediately.
          </p>
          <br />
          <h3 className="legal-subheading">Rules about linking to our site</h3>
          <p className="legal-text">
            You may link to our home page, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it.
          </p>
          <br />
          <p className="legal-text">
            You must not establish a link in such a way as to suggest any form
            of association, approval or endorsement on our part where none
            exists.
          </p>
          <br />
          <p className="legal-text">
            You must not establish a link to our site in any website that is not
            owned by you.
          </p>
          <br />
          <p className="legal-text">
            Our site must not be framed on any other site, nor may you create a
            link to any part of our site other than the home page.
          </p>
          <br />
          <p className="legal-text">
            We reserve the right to withdraw linking permission without notice.
          </p>
          <br />
          <p className="legal-text">
            The website in which you are linking must comply in all respects
            with our <p className="legal-text-bolder">Acceptable Use</p>
            <br /> terms and{" "}
            <p className="legal-text-bolder">Content Standards</p> above.
          </p>
          <br />
          <p className="legal-text">
            If you wish to link to or make any use of content on our site other
            than that set out above, please contact{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">
            Which country's laws apply to any disputes?
          </h3>
          <p className="legal-text">
            If you are a consumer, please note that these terms of use, their
            subject matter and their formation, are governed by English law. You
            and we both agree that the courts of England and Wales will have
            exclusive jurisdiction except that if you are a resident of Northern
            Ireland you may also bring proceedings in Northern Ireland, and if
            you are resident of Scotland, you may also bring proceedings in
            Scotland.
          </p>
          <br />
          <p className="legal-text">
            If you are a business, these terms of use, their subject matter and
            their formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the exclusive jurisdiction
            of the courts of England and Wales.
          </p>
          <br />
          <h3 className="legal-subheading">Our trade marks are registered</h3>
          <p className="legal-text">
            The Sponsor Hub's logo is a UK registered trade mark of The Sponsor
            Hub Ltd. You are not permitted to use them without our approval,
            unless they are part of material you are using as permitted under{" "}
            <p className="legal-text-bolder">
              How you may use material on our site
            </p>{" "}
            section of these terms and conditions.
          </p>
          <br />
          <h3 className="legal-subheading">How to contact us</h3>
          <p className="legal-text">
            You can contact us by post, email or telephone if you have any
            questions about these Terms and Conditions or the information we
            hold about you, to exercise a right under data protection law or to
            make a complaint.
          </p>
          <br />
          <p className="legal-text">Our contact details are shown below:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                By email at{" "}
                <a className="legal-link" href="mailto:info@thesponsorhub.com">
                  <p className="legal-text">info@thesponsorhub.com</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                By writing to us at Walk Mill, Kirkwhelpington, Newcastle Upon
                Tyne, Northumberland NE19 2SB
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">By calling us on +44 (0)7966 593 208</p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">Do you need extra help?</h3>
          <p className="legal-text">
            If you would like this notice in another format (for example large
            print) please contact us (see above).
          </p>
          <br />
          <h3 className="legal-subheading">
            Changes to our Terms and Conditions
          </h3>
          <p className="legal-text">This policy was published on 12/3/22.</p>
          <br />
          <p className="legal-text">
            We may change our Terms and Conditons from time to time, when we do
            we will inform you via email, informing you of changes to this
            policy. Any such changes will become binding on you on your first
            use of our website after the changes have been made. You are
            therefore advised to also check this policy from time to time.
          </p>
          <br />
          <h3 className="legal-subheading">Thanks</h3>
          <h3 className="legal-subheading">The Sponsor Hub</h3>
          <p className="legal-text">
            This document was produced by our Legal Services Partner{" "}
            <a
              style={{ display: "inline", padding: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nqlegal.co.uk/"
            >
              <img
                height={20}
                style={{ marginTop: -2 }}
                src="/Partners/nqLegalLogo.png"
                alt="ngLegal"
              />
            </a>
            .
          </p>
        </div>
      </MDBCol>
    </div>
  );
};

export default TermsAndConditions;
