import React, { Component } from "react";
import { Mutation, Query, withApollo } from "react-apollo";
import gql from "graphql-tag";
import {
  MDBCol,
  MDBBtn,
  MDBRow,
  MDBFileInput,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
} from "mdbreact";
import "./../../styles/Forms.css";
import "./../../styles/Profile.css";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Loader from "react-dots-loader";
import "react-dots-loader/index.css";

const CONTACT_MUTATION = gql`
  mutation ContactUserMutation($name: String!, $userName: String!) {
    contactUser(user: $name, business: $userName) {
      id
      user
      business
    }
  }
`;

const INSTAGRAM_TRACKER_MUTATION = gql`
  mutation InstagramTrackerMutation($name: String!, $userInstagram: String!) {
    instagramTracker(user: $name, instagram: $userInstagram) {
      id
      user
      instagram
    }
  }
`;

const WEBSITE_TRACKER_MUTATION = gql`
  mutation WebsiteTrackerMutation(
    $userName: String!
    $name: String!
    $discountId: String
  ) {
    websiteTracker(business: $userName, user: $name, discountId: $discountId) {
      id
      business
      user
    }
  }
`;

const FOLLOW_MUTATION = gql`
  mutation FollowMutation($followId: ID!) {
    follow(followId: $followId) {
      id
      user
      follow {
        id
      }
    }
  }
`;

const DELETE_FOLLOW_MUTATION = gql`
  mutation DeleteFollowMutation($deleteId: ID!) {
    deleteFollow(id: $deleteId) {
      id
    }
  }
`;

const FOLLOW_VIDEOS_QUERY = gql`
  query FollowsQuery($id: ID!) {
    follows(id: $id) {
      follows {
        id
        user
        follow {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;

const ATHLETE_UPLOAD_FILE_STREAM = gql`
  mutation ProfileImageUpload($file: Upload!) {
    profileImageUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

const BUSINESS_UPLOAD_FILE_STREAM = gql`
  mutation BusinessLogoUpload($file: Upload!) {
    businessLogoUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

const SHARE_TRACKER_MUTATION = gql`
  mutation ShareTrackerMutation(
    $user: String!
    $userShared: String!
    $platform: String
  ) {
    shareTracker(user: $user, userShared: $userShared, platform: $platform) {
      id
      user
      userShared
    }
  }
`;

const CREW_MUTATION = gql`
  mutation CrewMutation($crewId: ID!) {
    crew(crewId: $crewId) {
      id
      user
      crew {
        id
      }
    }
  }
`;

const DELETE_CREW_MUTATION = gql`
  mutation DeleteCrewMutation($deleteId: ID!) {
    deleteCrew(id: $deleteId) {
      id
    }
  }
`;

const CREW_VIDEOS_QUERY = gql`
  query CrewQuery($currentUserId: ID!) {
    findCrew(id: $currentUserId) {
      crews {
        id
        user
        crew {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;

const CREW_QUERY = gql`
  query CrewQuery($userId: ID!) {
    crew(userId: $userId) {
      crews {
        id
        name
        country
        city
        category
        profileImage
        businessLogo
        isBusiness
      }
      pendingCrew {
        id
        name
        country
        city
        category
        profileImage
        businessLogo
        isBusiness
      }
    }
  }
`;

const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

const ADD_TO_CREW_MUTATION = gql`
  mutation addToCrewMutation($userId: ID!) {
    addToCrew(userId: $userId) {
      id
      user
      crew {
        id
      }
    }
  }
`;

const REMOVE_FROM_CREW_MUTATION = gql`
  mutation removeFromCrewMutation($userId: ID!) {
    removeFromCrew(userId: $userId) {
      id
    }
  }
`;

const FOLLOWER_COUNT_QUERY = gql`
  query FollowerCountQuery($userId: ID!) {
    followerCount(userId: $userId)
  }
`;

class ProfileDetails extends Component {
  state = {
    profileId: this.props.profileUser.id,
    id: this.props.currentUser.id,
    name: this.props.currentUser.name,
    isBusiness: this.props.currentUser.isBusiness,
    userId: this.props.profileUser.id,
    userName: this.props.profileUser.name,
    userEmail: this.props.profileUser.email,
    userGender: this.props.profileUser.gender,
    userInstagram: this.props.profileUser.instagram,
    userIsBusiness: this.props.profileUser.isBusiness,
    userCountry: this.props.profileUser.country,
    userCity: this.props.profileUser.city,
    userCategory: this.props.profileUser.category,
    userSponsors: this.props.profileUser.sponsors,
    userAthleteOrPhotographer: this.props.profileUser.athleteOrPhotographer,
    userBusinesslogo: this.props.profileUser.businessLogo,
    userWebsite: this.props.profileUser.website,
    userDiscounts: this.props.profileUser.discounts,
    profileImage: this.props.profileUser.profileImage,
    userAbout: this.props.profileUser.about,
    file: "",
    fileError: "",
    shareColour: false,
    numberOfFollows: 0,
    followedCrew: false,
    showPendingCrew: false,
    showCrew: false,
    profileImageResize: true,
    businessLogoResize: true,
  };

  fileChangeHandler = (files) => {
    this.setState({ file: files[0] });
  };

  getFollowerCount = async () => {
    const result = await this.props.client.query({
      query: FOLLOWER_COUNT_QUERY,
      fetchPolicy: "network-only",
      variables: { userId: this.state.profileId },
    });

    this.setState({ numberOfFollows: result.data.followerCount });
  };

  componentDidMount = () => {
    this.getFollowerCount();
  };

  render() {
    const {
      profileId,
      id,
      name,
      isBusiness,
      userId,
      userName,
      userGender,
      userInstagram,
      userIsBusiness,
      userCountry,
      userCity,
      userCategory,
      userSponsors,
      userAthleteOrPhotographer,
      userWebsite,
      userAbout,
      file,
      fileError,
      numberOfFollows,
      showPendingCrew,
      showCrew,
      profileImageResize,
      businessLogoResize,
    } = this.state;
    var {
      userBusinesslogo,
      profileImage,
      shareColour,
      followed,
      followedCrew,
    } = this.state;
    var isUserProfile = false;
    if (profileId === id) {
      isUserProfile = true;
    }
    const showCategory = userCategory.join(", ");
    return (
      <div className="px-3 pt-3">
        <MDBCol
          sm="12"
          md="8"
          lg="6"
          className="py-3 px-3 justify-content-center mb-4 offset-lg-3 offset-md-2"
        >
          <MDBDropdown id="shareDropdown" dropleft>
            <MDBDropdownToggle
              id="editDropdown"
              style={{ marginTop: "-2px" }}
              color="transparent"
            >
              <MDBIcon
                style={{ color: "#f8f8ff", fontSize: "1.5em" }}
                fas
                icon="share-alt"
                onClick={() => this.setState({ shareColour: !shareColour })}
              />
            </MDBDropdownToggle>
            <MDBDropdownMenu basic>
              <MDBDropdownItem style={{ marginTop: -10 }}>
                <div
                  style={{ justifyContent: "space-around", display: "flex" }}
                >
                  <div style={{ backgroundColor: "#000" }}>
                    <Mutation
                      mutation={SHARE_TRACKER_MUTATION}
                      variables={{
                        user: id,
                        userShared: userId,
                        platform: "Email",
                      }}
                    >
                      {(shareTrackerMutation, { loading }) => (
                        <EmailShareButton
                          url={`https://thesponsorhub.com/profile/${userId}`}
                          subject={`${userName} - The Sponsor Hub`}
                          body={`Check out ${userName}'s Profile on The Sponsor Hub 🤙`}
                        >
                          <MDBIcon
                            style={shareIcon}
                            size="2x"
                            icon="envelope"
                            onClick={() => {
                              if (!loading) {
                                shareTrackerMutation();
                              }
                            }}
                          />
                        </EmailShareButton>
                      )}
                    </Mutation>
                  </div>
                  <div style={{ backgroundColor: "#000" }}>
                    <Mutation
                      mutation={SHARE_TRACKER_MUTATION}
                      variables={{
                        user: id,
                        userShared: userId,
                        platform: "Facebook",
                      }}
                    >
                      {(shareTrackerMutation, { loading }) => (
                        <FacebookShareButton
                          quote={`Check out ${userName}'s Profile on The Sponsor Hub 🤙`}
                          url={`https://thesponsorhub.com/profile/${userId}`}
                        >
                          <MDBIcon
                            style={shareIcon}
                            fab
                            size="2x"
                            icon="facebook-f"
                            onClick={() => {
                              if (!loading) {
                                shareTrackerMutation();
                              }
                            }}
                          />
                        </FacebookShareButton>
                      )}
                    </Mutation>
                  </div>
                  <div style={{ backgroundColor: "#000" }}>
                    <Mutation
                      mutation={SHARE_TRACKER_MUTATION}
                      variables={{
                        user: id,
                        userShared: userId,
                        platform: "Twitter",
                      }}
                    >
                      {(shareTrackerMutation, { loading }) => (
                        <TwitterShareButton
                          title={`Check out ${userName}'s Profile on The Sponsor Hub 🤙`}
                          url={`https://thesponsorhub.com/profile/${userId}`}
                        >
                          <MDBIcon
                            style={shareIcon}
                            size="2x"
                            fab
                            icon="twitter"
                            onClick={() => {
                              if (!loading) {
                                shareTrackerMutation();
                              }
                            }}
                          />
                        </TwitterShareButton>
                      )}
                    </Mutation>
                  </div>
                  <div style={{ backgroundColor: "#000" }}>
                    <Mutation
                      mutation={SHARE_TRACKER_MUTATION}
                      variables={{
                        user: id,
                        userShared: userId,
                        platform: "Whatsapp",
                      }}
                    >
                      {(shareTrackerMutation, { loading }) => (
                        <WhatsappShareButton
                          title={`Check out ${userName}'s Profile on The Sponsor Hub 🤙`}
                          url={`https://thesponsorhub.com/profile/${userId}`}
                        >
                          <MDBIcon
                            style={shareIcon}
                            size="2x"
                            fab
                            icon="whatsapp"
                            onClick={() => {
                              if (!loading) {
                                shareTrackerMutation();
                              }
                            }}
                          />
                        </WhatsappShareButton>
                      )}
                    </Mutation>
                  </div>
                </div>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <div className="text-center">
            {userIsBusiness ? (
              <div>
                <img
                  style={userProfileImage}
                  src={
                    businessLogoResize
                      ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${userId}_500x500.jpeg`
                      : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${userBusinesslogo}`
                  }
                  onError={() => this.setState({ businessLogoResize: false })}
                  alt="Company Logo"
                ></img>
              </div>
            ) : (
              <>
                {profileImage !== null ? (
                  <div>
                    <img
                      style={userProfileImage}
                      src={
                        profileImageResize
                          ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${userId}/${userId}_500x500.jpeg`
                          : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${userId}/${profileImage}`
                      }
                      onError={() =>
                        this.setState({ profileImageResize: false })
                      }
                      alt="User Profile"
                    ></img>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <h3 style={title} className="text-center pt-3">
            {userName}
          </h3>
          <p style={detailsText} className="pt-3">
            {userAbout}
          </p>
          <div>
            {isBusiness && !userIsBusiness ? (
              <Mutation
                mutation={CONTACT_MUTATION}
                variables={{ name, userName }}
              >
                {(contactUserMutation, { loading, data }) => (
                  <div className="text-center">
                    <p style={contactText}>
                      To get in Contact with {userName} regarding sponsorship /
                      team rider / ambassadorial roles or content, drop us an
                      email at{" "}
                      <a
                        className="ml-1"
                        style={{ padding: 0, display: "inline" }}
                        href="mailto:info@thesponsorhub.com"
                        onClick={() => {
                          if (!loading && !data) {
                            contactUserMutation();
                          }
                        }}
                      >
                        <p style={contactText}> info@thesponsorhub.com.</p>
                      </a>
                    </p>
                  </div>
                )}
              </Mutation>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={highlightedText}>
                {userIsBusiness ? "Business" : userAthleteOrPhotographer}
              </p>
              <p style={highlightedText}>{numberOfFollows} Followers</p>
            </div>
            <div>
              <p style={detailsText}>
                {userCity.replace(/ /g, "")}, {userCountry}
              </p>
              <p style={detailsText}>{showCategory}</p>
              {!userIsBusiness && userAthleteOrPhotographer !== "Club" ? (
                <p style={highlightedText}>{userGender}</p>
              ) : (
                <></>
              )}
              {!userIsBusiness && userSponsors !== null ? (
                <>
                  {userSponsors.length > 2 ? (
                    <p style={detailsText}>Sponsors: {userSponsors}</p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="pt-2">
              <MDBRow
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {isUserProfile ? (
                  <MDBBtn
                    style={profileButtonWhite}
                    href="/edit_profile"
                    rounded
                    size="md"
                    color="white"
                  >
                    Edit Profile
                  </MDBBtn>
                ) : (
                  <>
                    <Query
                      query={FOLLOW_VIDEOS_QUERY}
                      variables={{ id }}
                      fetchPolicy="network-only"
                    >
                      {({ loading, error, data, refetch }) => {
                        if (loading) return <div>Fetching</div>;
                        if (error)
                          return (
                            <MDBBtn
                              style={profileButton}
                              outline
                              rounded
                              size="md"
                              color="white"
                            >
                              Follow
                            </MDBBtn>
                          );
                        const followingNumber = data.follows.follows.length;
                        const followId = userId;
                        var i;
                        var deleteId;
                        followed = false;
                        for (i = 0; i < followingNumber; i++) {
                          if (data.follows.follows[i].follow === null) {
                            refetch();
                          } else {
                            if (
                              data.follows.follows[i].follow.id === followId
                            ) {
                              followed = true;
                              deleteId = data.follows.follows[i].id;
                            }
                          }
                        }
                        return (
                          <>
                            {followed === false ? (
                              <Mutation
                                mutation={FOLLOW_MUTATION}
                                variables={{ followId }}
                                refetchQueries="FOLLOW_VIDEOS_QUERY"
                              >
                                {(followMutation, { loading }) => (
                                  <MDBBtn
                                    onClick={() => {
                                      if (!loading) {
                                        followMutation();
                                        refetch();
                                      }
                                      this.getFollowerCount();
                                    }}
                                    style={profileButton}
                                    outline
                                    size="md"
                                    rounded
                                    color="white"
                                  >
                                    Follow
                                  </MDBBtn>
                                )}
                              </Mutation>
                            ) : (
                              <Mutation
                                mutation={DELETE_FOLLOW_MUTATION}
                                variables={{ deleteId }}
                                refetchQueries="FOLLOW_VIDEOS_QUERY"
                              >
                                {(deleteFollowMutation, { loading }) => (
                                  <MDBBtn
                                    onClick={() => {
                                      if (!loading) {
                                        deleteFollowMutation();
                                        refetch();
                                      }
                                      this.getFollowerCount();
                                    }}
                                    style={profileButton}
                                    rounded
                                    size="md"
                                    color="white"
                                  >
                                    Following
                                  </MDBBtn>
                                )}
                              </Mutation>
                            )}
                          </>
                        );
                      }}
                    </Query>
                    <Query
                      query={CREW_VIDEOS_QUERY}
                      variables={{ currentUserId: id }}
                    >
                      {({ loading, error, data, refetch }) => {
                        if (loading)
                          return (
                            <MDBIcon style={crewIcon} fas icon="user-plus" />
                          );
                        if (error) {
                          return (
                            <MDBIcon style={crewIcon} fas icon="user-plus" />
                          );
                        }
                        const numberOfCrew = data.findCrew.crews.length;
                        const crewId = userId;
                        var i;
                        var deleteId;
                        followedCrew = false;
                        for (i = 0; i < numberOfCrew; i++) {
                          if (data.findCrew.crews[i].crew === null) {
                            refetch();
                          } else {
                            if (data.findCrew.crews[i].crew.id === crewId) {
                              followedCrew = true;
                              deleteId = data.findCrew.crews[i].id;
                            }
                          }
                        }
                        return (
                          <>
                            {followedCrew === false ? (
                              <Mutation
                                mutation={CREW_MUTATION}
                                variables={{ crewId }}
                              >
                                {(crewMutation, { loading }) => (
                                  <MDBIcon
                                    style={crewIcon}
                                    fas
                                    icon="user-plus"
                                    onClick={() => {
                                      if (!loading) {
                                        crewMutation();
                                        refetch();
                                      }
                                    }}
                                  />
                                )}
                              </Mutation>
                            ) : (
                              <Mutation
                                mutation={DELETE_CREW_MUTATION}
                                variables={{ deleteId }}
                              >
                                {(deleteCrewMutation, { loading }) => (
                                  <MDBIcon
                                    style={crewIcon}
                                    fas
                                    icon="users"
                                    onClick={() => {
                                      if (!loading) {
                                        deleteCrewMutation();
                                        refetch();
                                      }
                                    }}
                                  />
                                )}
                              </Mutation>
                            )}
                          </>
                        );
                      }}
                    </Query>
                  </>
                )}
                {userIsBusiness ? (
                  <Mutation
                    mutation={WEBSITE_TRACKER_MUTATION}
                    variables={{
                      userName: userId,
                      name: id,
                      discountId: "Profile",
                    }}
                  >
                    {(websiteTrackerMutation, { loading }) => (
                      <MDBBtn
                        color="white"
                        rounded
                        outline
                        style={profileButton}
                        onClick={() => {
                          if (!loading) {
                            websiteTrackerMutation();
                          }
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={userWebsite}
                        size="md"
                      >
                        Website
                      </MDBBtn>
                    )}
                  </Mutation>
                ) : (
                  <></>
                )}
                {/* {userIsBusiness && showAnalytics && id === userId ?
                  <MDBBtn style={profileButton} color="white" rounded outline href="/analytics" size="md">Analytics</MDBBtn>
                :
                  <></>
                } */}
                {id === "ckck7xbsv025f0741j230h9w7" &&
                userId === "ckck7xbsv025f0741j230h9w7" ? (
                  <MDBBtn
                    style={profileButton}
                    color="white"
                    rounded
                    outline
                    href="/send_emails"
                    size="md"
                  >
                    Send Emails
                  </MDBBtn>
                ) : (
                  <></>
                )}
                {(id === "ckck7xbsv025f0741j230h9w7" &&
                  userId === "ckck7xbsv025f0741j230h9w7") ||
                (id === "cl5zb0o5g3ejb0741sdv500i1" &&
                  userId === "cl5zb0o5g3ejb0741sdv500i1") ? (
                  <MDBBtn
                    style={profileButton}
                    color="white"
                    rounded
                    outline
                    href="/statistics"
                    size="md"
                  >
                    Statistics
                  </MDBBtn>
                ) : (
                  <></>
                )}
                {id === "ckck7xbsv025f0741j230h9w7" &&
                userId === "ckck7xbsv025f0741j230h9w7" ? (
                  <MDBBtn
                    style={profileButton}
                    color="white"
                    rounded
                    outline
                    href="/user_map"
                    size="md"
                  >
                    User Map
                  </MDBBtn>
                ) : (
                  <></>
                )}
                <Mutation
                  mutation={INSTAGRAM_TRACKER_MUTATION}
                  variables={{ name, userInstagram }}
                >
                  {(instagramTrackerMutation, { loading }) => (
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.instagram.com/${userInstagram}/`}
                      onClick={() => {
                        if (!loading) {
                          instagramTrackerMutation();
                        }
                      }}
                    >
                      <MDBIcon style={instagramIcon} fab icon="instagram" />
                    </a>
                  )}
                </Mutation>
              </MDBRow>
            </div>
            {isUserProfile ? (
              <>
                <MDBCol
                  sm="12"
                  md="12"
                  lg="10"
                  className="justify-content-center offset-lg-1"
                >
                  <div>
                    <MDBFileInput
                      btnColor="black"
                      btnTitle={
                        isBusiness ? "Upload New Logo" : "Upload Profile Image"
                      }
                      value={file}
                      textFieldTitle="Upload"
                      type="file"
                      getValue={this.fileChangeHandler}
                      className="text-white font-weight-bolder"
                    />
                  </div>
                </MDBCol>
                {fileError !== "" ? (
                  <div className="mb-3" style={{ textAlign: "center" }}>
                    <p style={fileErrorText}>{fileError}</p>
                  </div>
                ) : (
                  <></>
                )}
                {isBusiness ? (
                  <Mutation
                    mutation={BUSINESS_UPLOAD_FILE_STREAM}
                    onCompleted={() =>
                      (window.location.href = `/profile/${userId}`)
                    }
                  >
                    {(businessLogoUpload, { data, loading, error }) => {
                      return (
                        <div className="text-center">
                          {loading && (
                            <div>
                              <p style={loadingText}>
                                Your Logo is being uploaded to the cloud ...
                              </p>
                              <Loader size={8} color="white" />
                            </div>
                          )}
                          {error && (
                            <p style={errorText}>
                              {error.message ===
                              "Network error: NetworkError when attempting to fetch resource."
                                ? "Connection Timed Out: Please Try Again"
                                : error.message.includes("jwt expired")
                                ? "Token Expired. Please Login again"
                                : error.message.replace("GraphQL error: ", "")}
                            </p>
                          )}
                          {file !== "" && !data && !loading ? (
                            <MDBBtn
                              color="white"
                              style={profileUploadButton}
                              rounded
                              onClick={() => {
                                const { file } = this.state;
                                const mimetype = file.type;
                                if (file.size > 50000000) {
                                  this.setState({
                                    fileError:
                                      "File too big, please upload a file of size less than 50Mb",
                                  });
                                } else if (
                                  (mimetype === "image/jpeg" ||
                                    mimetype === "image/png" ||
                                    mimetype === "image/jpg") &&
                                  !loading &&
                                  !data
                                ) {
                                  this.setState({ fileError: "" });
                                  businessLogoUpload({
                                    variables: { file: file },
                                  });
                                } else {
                                  this.setState({
                                    fileError:
                                      "Image type not recognised, please upload file of type PNG, JPG or JPEG",
                                  });
                                }
                              }}
                            >
                              Upload New Logo
                            </MDBBtn>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    }}
                  </Mutation>
                ) : (
                  <Mutation
                    mutation={ATHLETE_UPLOAD_FILE_STREAM}
                    onCompleted={() =>
                      (window.location.href = `/profile/${userId}`)
                    }
                  >
                    {(profileImageUpload, { data, loading, error }) => {
                      return (
                        <div className="text-center">
                          {loading && (
                            <div>
                              <p style={loadingText}>
                                Your Image is being uploaded to the cloud ...
                              </p>
                              <Loader size={8} color="white" />
                            </div>
                          )}
                          {error && (
                            <p style={{ textAlign: "center" }}>
                              {error.message ===
                              "Network error: NetworkError when attempting to fetch resource."
                                ? "Connection Timed Out: Please Try Again"
                                : error.message.includes("jwt expired")
                                ? "Token Expired. Please Login again"
                                : error.message.replace("GraphQL error: ", "")}
                            </p>
                          )}
                          {file !== "" && !data && !loading ? (
                            <MDBBtn
                              color="white"
                              rounded
                              style={profileUploadButton}
                              onClick={() => {
                                const { file } = this.state;
                                const mimetype = file.type;
                                if (file.size > 50000000) {
                                  this.setState({
                                    fileError:
                                      "File too big, please upload a file of size less than 50Mb",
                                  });
                                } else if (
                                  (mimetype === "image/jpeg" ||
                                    mimetype === "image/png" ||
                                    mimetype === "image/jpg") &&
                                  !loading &&
                                  !data
                                ) {
                                  this.setState({ fileError: "" });
                                  profileImageUpload({
                                    variables: { file: file },
                                  });
                                } else {
                                  this.setState({
                                    fileError:
                                      "Image type not recognised, please upload file of type PNG, JPG or JPEG",
                                  });
                                }
                              }}
                            >
                              Upload New Profile Image
                            </MDBBtn>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    }}
                  </Mutation>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {isUserProfile ? (
            <div>
              <Query
                query={CREW_QUERY}
                variables={{ userId }}
                fetchPolicy="network-only"
              >
                {({ loading, error, data, refetch }) => {
                  if (error || loading) return <></>;

                  if (data) {
                    return (
                      <div style={{ display: "grid" }}>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <h5
                            style={detailsText}
                            onClick={() =>
                              this.setState({
                                showPendingCrew: !showPendingCrew,
                              })
                            }
                          >
                            Crew Pending
                          </h5>
                          {showPendingCrew ? (
                            <MDBIcon
                              style={crewSelectIcon}
                              fas
                              icon="angle-up"
                              onClick={() =>
                                this.setState({ showPendingCrew: false })
                              }
                            />
                          ) : (
                            <MDBIcon
                              style={crewSelectIcon}
                              fas
                              icon="angle-down"
                              onClick={() =>
                                this.setState({ showPendingCrew: true })
                              }
                            />
                          )}
                        </div>
                        {showPendingCrew ? (
                          <>
                            {data.crew.pendingCrew.length === 0 ? (
                              <div>
                                <p style={detailsText}>
                                  You currently have no Pending Crew Members.
                                </p>
                              </div>
                            ) : (
                              <MDBRow
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {data.crew.pendingCrew.map((user) => (
                                  <CrewUser
                                    key={user.id}
                                    user={user}
                                    userId={userId}
                                    inCrew={true}
                                    refetch={refetch}
                                  />
                                ))}
                              </MDBRow>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        <div
                          className="mt-3"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <h5
                            style={detailsText}
                            onClick={() =>
                              this.setState({ showCrew: !showCrew })
                            }
                          >
                            Your Crew
                          </h5>
                          {showCrew ? (
                            <MDBIcon
                              style={crewSelectIcon}
                              fas
                              icon="angle-up"
                              onClick={() => this.setState({ showCrew: false })}
                            />
                          ) : (
                            <MDBIcon
                              style={crewSelectIcon}
                              fas
                              icon="angle-down"
                              onClick={() => this.setState({ showCrew: true })}
                            />
                          )}
                        </div>
                        {showCrew ? (
                          <>
                            {data.crew.crews.length === 0 ? (
                              <div>
                                <p style={detailsText}>
                                  You currently have no Crew Members. Go add
                                  some Athletes to your Crew!
                                </p>
                              </div>
                            ) : (
                              <MDBRow
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {data.crew.crews.map((user) => (
                                  <CrewUser
                                    key={user.id}
                                    user={user}
                                    userId={userId}
                                    inCrew={false}
                                    refetch={refetch}
                                  />
                                ))}
                              </MDBRow>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }
                }}
              </Query>
            </div>
          ) : (
            <></>
          )}
        </MDBCol>
      </div>
    );
  }
}

class CrewUser extends Component {
  state = {
    profileImageResize: true,
    businessLogoResize: true,
  };
  render() {
    const user = this.props.user;
    const userId = this.props.userId;
    const inCrew = this.props.inCrew;
    const { profileImageResize, businessLogoResize } = this.state;
    return (
      <MDBCol sm="12" md="6" style={columnSetUp}>
        <div style={crewsBox}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {user.businessLogo !== "" &&
            user.businessLogo !== null &&
            user.isBusiness ? (
              <Mutation
                mutation={USER_PROFILE_MUTATION}
                variables={{ currentUserId: userId, userId: user.id }}
              >
                {(profileTrackerMutation, { loading, data }) => (
                  <div style={{ textAlign: "left", marginLeft: 3 }}>
                    <a
                      style={{ padding: "0" }}
                      onClick={() => {
                        if (!loading && !data) {
                          profileTrackerMutation();
                        }
                      }}
                      href={`/profile/${user.id}`}
                    >
                      <img
                        style={userImg}
                        src={
                          businessLogoResize
                            ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${user.id}_50x50.jpeg`
                            : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${user.businessLogo}`
                        }
                        onError={() =>
                          this.setState({ businessLogoResize: false })
                        }
                        alt="Business Logo"
                      ></img>
                    </a>
                  </div>
                )}
              </Mutation>
            ) : user.profileImage !== "" &&
              user.profileImage !== null &&
              !user.isBusiness ? (
              <Mutation
                mutation={USER_PROFILE_MUTATION}
                variables={{ currentUserId: userId, userId: user.id }}
              >
                {(profileTrackerMutation, { loading, data }) => (
                  <div style={{ textAlign: "left", marginLeft: 3 }}>
                    <a
                      style={{ padding: "0" }}
                      onClick={() => {
                        if (!loading && !data) {
                          profileTrackerMutation();
                        }
                      }}
                      href={`/profile/${user.id}`}
                    >
                      <img
                        style={userImg}
                        src={
                          profileImageResize
                            ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${user.id}/${user.id}_50x50.jpeg`
                            : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${user.id}/${user.profileImage}`
                        }
                        onError={() =>
                          this.setState({ profileImageResize: false })
                        }
                        alt="Profile"
                      ></img>
                    </a>
                  </div>
                )}
              </Mutation>
            ) : (
              <></>
            )}
            <div style={{ marginLeft: 5 }}>
              <Mutation
                mutation={USER_PROFILE_MUTATION}
                variables={{ currentUserId: userId, userId: user.id }}
              >
                {(profileTrackerMutation, { loading, data }) => (
                  <a
                    style={{ padding: "0" }}
                    onClick={() => {
                      if (!loading && !data) {
                        profileTrackerMutation();
                      }
                    }}
                    href={`/profile/${user.id}`}
                  >
                    <p style={crewsText}>{user.name}</p>
                  </a>
                )}
              </Mutation>
              <p style={crewsTextSmall}>
                {user.city}, {user.country}
              </p>
              <p style={crewsTextSmall}>{user.category.join(", ")}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: inCrew ? -57 : -41,
              marginRight: 5,
              marginBottom: inCrew ? 0 : 14,
            }}
          >
            {inCrew ? (
              <div style={{ display: "inline" }}>
                <div style={{ marginBottom: 2 }}>
                  <Mutation
                    mutation={ADD_TO_CREW_MUTATION}
                    variables={{ userId: user.id }}
                    onCompleted={() => this.props.refetch()}
                  >
                    {(addToCrewMutation, { loading }) => (
                      <MDBIcon
                        style={crewSelectIcon}
                        fas
                        icon="plus"
                        onClick={() => {
                          if (!loading) {
                            addToCrewMutation();
                          }
                        }}
                      />
                    )}
                  </Mutation>
                </div>
                <div style={{ marginTop: 2 }}>
                  <Mutation
                    mutation={REMOVE_FROM_CREW_MUTATION}
                    variables={{ userId: user.id }}
                    onCompleted={() => this.props.refetch()}
                  >
                    {(removeFromCrewMutation, { loading }) => (
                      <MDBIcon
                        style={crewSelectIcon}
                        fas
                        icon="minus"
                        onClick={() => {
                          if (!loading) {
                            removeFromCrewMutation();
                          }
                        }}
                      />
                    )}
                  </Mutation>
                </div>
              </div>
            ) : (
              <div>
                <Mutation
                  mutation={REMOVE_FROM_CREW_MUTATION}
                  variables={{ userId: user.id }}
                  onCompleted={() => this.props.refetch()}
                >
                  {(removeFromCrewMutation, { loading }) => (
                    <MDBIcon
                      style={crewSelectIcon}
                      fas
                      icon="minus"
                      onClick={() => {
                        if (!loading) {
                          removeFromCrewMutation();
                        }
                      }}
                    />
                  )}
                </Mutation>
              </div>
            )}
          </div>
        </div>
      </MDBCol>
    );
  }
}

export default withApollo(ProfileDetails);

const userProfileImage = {
  height: "12rem",
  width: "12rem",
  objectFit: "cover",
  borderRadius: "0.4rem",
};

const title = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textTransform: "uppercase",
};

const highlightedText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textTransform: "uppercase",
};

const detailsText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "justify",
};

const contactText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "justify",
  display: "inline-flex",
};

const profileButton = {
  fontSize: "14px",
  fontFamily: "Oswald",
  paddingTop: "0.15rem",
  paddingBottom: "0.3rem",
};

const profileButtonWhite = {
  fontSize: "14px",
  fontFamily: "Oswald",
  paddingTop: "0.25rem",
  paddingBottom: "0.37rem",
};

const crewIcon = {
  color: "#f8f8ff",
  fontSize: "1.5em",
};

const crewSelectIcon = {
  color: "#f8f8ff",
  fontSize: "1.5em",
  marginLeft: "0.75rem",
};

const instagramIcon = {
  color: "#f8f8ff",
  fontSize: "1.75em",
};

const profileUploadButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  maxWidth: 300,
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};

const fileErrorText = {
  color: "red",
  fontFamily: "Oswald",
  marginBottom: "0.5rem",
};

const errorText = {
  color: "red",
  fontFamily: "Oswald",
};

const shareIcon = {
  backgroundColor: "#000",
  color: "#f8f8ff",
  marginLeft: "1rem",
};

const userImg = {
  height: 60,
  width: "auto",
  objectFit: "cover",
  borderRadius: "0.4rem",
};

const crewsText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textTransform: "uppercase",
  padding: 0,
  marginTop: 0,
  marginBottom: 0,
  fontSize: 16,
};

const crewsTextSmall = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  padding: 0,
  marginTop: 0,
  marginBottom: 0,
  fontSize: 14,
};

const columnSetUp = {
  padding: 0,
  marginLeft: 0,
  marginRight: 0,
};

const crewsBox = {
  border: "1px solid #202020",
  borderRadius: "0.4rem",
  marginTop: 5,
  marginRight: 10,
};

const loadingText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  marginBottom: "0.5rem",
};
