import React, { Component } from "react";
import { Query, Mutation, withApollo } from "react-apollo";
import gql from "graphql-tag";
import Loading from "../Loading";
import ErrorMessage from "../Errors/ErrorMessage";
import { MDBCol, MDBInput, MDBBtn } from "mdbreact";

const USER_CATEGORY_QUERY = gql`
  query {
    user {
      id
      category
      isBusiness
      age
      websiteCookies
      newUser
    }
  }
`;

const UPDATE_USER_SETTINGS_MUTATION = gql`
  mutation UpdateSettingsMutation(
    $id: ID!
    $marketingOptIn: Boolean
    $websiteCookies: Boolean
    $appCookies: Boolean
  ) {
    userSettingsUpdate(
      id: $id
      marketingOptIn: $marketingOptIn
      websiteCookies: $websiteCookies
      appCookies: $appCookies
    ) {
      id
    }
  }
`;

const NEW_USER_MUTATION = gql`
  mutation NewUserMutation($id: ID!, $newUser: Boolean!) {
    newUser(id: $id, newUser: $newUser) {
      id
      newUser
    }
  }
`;
var loadedOnce = true;

class Tutorial extends Component {
  state = {
    pageIndex: 0,
    acceptCookies: false,
  };

  tutorialComplete = async (id) => {
    await this.props.client.mutate({
      mutation: NEW_USER_MUTATION,
      variables: { id, newUser: false },
    });
  };

  render() {
    var { pageIndex, acceptCookies } = this.state;

    return (
      <Query query={USER_CATEGORY_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorMessage error={error.message} />;

          const id = data.user.id;
          const isBusiness = data.user.isBusiness;
          const age = data.user.age;
          if (data.user.websiteCookies && loadedOnce)
            this.setState({ acceptCookies: true });
          if (data.user.newUser || data.user.newUser === null)
            this.tutorialComplete(id);

          if (data) loadedOnce = false;
          return (
            <div
              style={{ minHeight: "100vh", backgroundColor: "#000" }}
              className="d-flex justify-content-center align-items-center"
            >
              <MDBCol
                sm="10"
                md="8"
                lg="6"
                xl="4"
                className="px-3 mt-5 justify-content-center col-12"
              >
                {pageIndex === 0 ? (
                  <div>
                    <h2 style={title}>Welcome to The Sponsor Hub</h2>
                    <div className="pt-3">
                      <p style={detailsText}>
                        The Sponsor Hub is committed to be a safe, supportive
                        and inclusive platform for all our members to enjoy.
                      </p>
                      <div>
                        <p style={detailsText}>
                          These are the core values of the extreme sports
                          community, along with being environmentally aware,
                          custodians of the environment we play in and
                          supportive of these local communities and pioneering
                          businesses who keep the essence of our sports alive.
                        </p>
                      </div>
                      <div>
                        {age !== "6-9" && age !== "10-12" ? (
                          <Mutation mutation={UPDATE_USER_SETTINGS_MUTATION}>
                            {(userSettingsUpdate, { loading }) => (
                              <div>
                                <p style={detailsText}>
                                  Cookies help us customize and improve your
                                  website experience. For further details, see
                                  our{" "}
                                  <a
                                    href="/cookies_policy"
                                    style={{
                                      display: "inline-flex",
                                      padding: 0,
                                    }}
                                  >
                                    <p style={detailsText}>Cookies Policy.</p>
                                  </a>
                                </p>
                                <div
                                  style={{
                                    marginTop: "-0.75rem",
                                    marginBottom: "0.5rem",
                                  }}
                                >
                                  <MDBInput
                                    label="Accept Cookies?"
                                    type="checkbox"
                                    id="checkbox"
                                    value={acceptCookies}
                                    checked={acceptCookies}
                                    onChange={(e) => {
                                      if (!loading) {
                                        this.setState({
                                          acceptCookies: !acceptCookies,
                                        });
                                        userSettingsUpdate({
                                          variables: {
                                            id,
                                            websiteCookies: !acceptCookies,
                                          },
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Mutation>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <p style={detailsText}>
                          To help us maintain these values we have a few simple
                          rules.
                        </p>
                        <h5 style={valueTitle}>Be cool.</h5>
                        <ul>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Upload sick Edits, showcase your skills and share
                              your passion.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Tag your photographer / videographer so we can
                              credit them.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Make use of the great discounts offered by our
                              collaborating brands.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Enter competitions, build Crews and find spots to
                              rip.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Most importantly, have fun, enjoy the platform and
                              share the Stoke!
                            </p>
                          </li>
                        </ul>
                        <h5 style={valueTitle}>Don’t be salty.</h5>
                        <ul>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Don’t post obscene, hateful, or objectionable
                              videos.{" "}
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Don’t post edits with explicit audio.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Don’t post copyrighted content without permission.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              For further information see our{" "}
                              <a
                                href="/terms_and_conditions"
                                style={{ display: "inline-flex", padding: 0 }}
                              >
                                <p style={detailsLinkText}>
                                  Terms and Conditions.
                                </p>
                              </a>
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Athletes, don’t contact brands directly, if they
                              are interested we will tell you.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Brands, don’t contact athletes directly, if you’re
                              interested tell us and we will sound them out.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Have respect for our shared space and all the
                              shredders in our community.
                            </p>
                          </li>
                        </ul>
                        <h5 style={valueTitle}>
                          If you see something off, tell us.
                        </h5>
                        <ul>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              If something seems off, get in{" "}
                              <a
                                href="/information#contact"
                                style={{ display: "inline-flex", padding: 0 }}
                              >
                                <p style={detailsLinkText}>Contact.</p>
                              </a>
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Our Trust & Safety team reviews every report
                              received, and we will take action when we see
                              someone violating our guidelines.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Sometimes that just means giving someone a
                              warning; sometimes it means revoking certain
                              privileges or accounts entirely.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              The best way to avoid anything like that happening
                              to you?
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              Don’t be salty, be cool and share the stoke.
                            </p>
                          </li>
                          <li style={{ color: "#f8f8ff" }}>
                            <p style={detailsText}>
                              As our community grows and changes, so will the
                              way we evaluate and enforce these guidelines. It’s
                              up to all of us to ensure that The Sponsor Hub
                              remains a safe and fun space.
                            </p>
                          </li>
                        </ul>
                        <h5 style={title}>
                          Thank you for being a part of The Sponsor Hub
                          community.
                        </h5>
                        <h5 style={title}>Stay safe and keep shredding!</h5>
                      </div>
                    </div>
                    <div className="py-2" style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => (window.location.href = "/")}
                      >
                        Skip
                      </MDBBtn>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => this.setState({ pageIndex: 1 })}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : pageIndex === 1 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Exploring Athletes Edits
                    </h4>
                    <p style={detailsText}>
                      On the platform you'll be able to watch Edits from
                      Athletes from all over the world!
                    </p>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 10,
                      }}
                    >
                      <img
                        src="/Tutorial/Video_Description.jpg"
                        alt="Video Description"
                      />
                    </div>
                    <p style={detailsText}>
                      Crews will only be formed if you've added each other to
                      your Crew. You can add as many users to your Crew, as well
                      as accept and manage your Crew within your Profile.
                    </p>{" "}
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <MDBBtn
                          style={nextButton}
                          color="white"
                          rounded
                          onClick={() => this.setState({ pageIndex: 2 })}
                        >
                          Next
                        </MDBBtn>
                      </div>
                    </div>
                  </div>
                ) : pageIndex === 2 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Exploring Discounts
                    </h4>
                    <p style={detailsText}>
                      On the platform you'll also have access to Local, National
                      and International Discounts.
                    </p>
                    <div
                      className="pb-3"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <img
                        src="/Tutorial/Discount_Description.jpg"
                        alt="Discount Description"
                      />
                    </div>
                    <p style={detailsText}>
                      If the Discount is only available in Store, you will need
                      to download our app in order to access the Discount.
                    </p>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => this.setState({ pageIndex: 3 })}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : pageIndex === 3 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Uploading Edits
                    </h4>
                    <p style={detailsText}>
                      To Upload an Edit, head to the Video Camera icon or Upload
                      Edit in the sidebar, fill in the details and post it!
                    </p>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <img
                        src="/Tutorial/Upload_Edit_Description.jpg"
                        alt="Upload Edit Description"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => {
                          if (isBusiness) {
                            this.setState({ pageIndex: 4 });
                          } else {
                            this.setState({ pageIndex: 5 });
                          }
                        }}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : pageIndex === 4 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Uploading Discounts
                    </h4>
                    <p style={detailsText}>
                      To Upload a Discount, head to the Percentage icon or
                      Upload Discount in the sidebar, fill in the details and
                      post it!
                    </p>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <img
                        src="/Tutorial/Upload_Discount_Description.jpg"
                        alt="Upload Discount Description"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => this.setState({ pageIndex: 5 })}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : pageIndex === 5 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      {isBusiness
                        ? "Adding your Business and Spots to our Map"
                        : "Adding Spots to our Map"}
                    </h4>
                    {isBusiness ? (
                      <div>
                        <p style={detailsText}>
                          To add your Business to our Map and share Spots with
                          your Crew, head to Pin icon or TSH Map in the sidebar.
                        </p>
                        <p style={detailsText}>
                          Find the Location on the Map, then Double Press the
                          Location on the Map.
                        </p>
                        <p style={detailsText}>
                          This will bring up the form for you to add a Spot.
                        </p>
                        <p style={detailsText}>
                          If this Location is your Business, make sure to click
                          the checkbox!
                        </p>
                        <p style={detailsText}>
                          Your Business will now be divable on our Map for all
                          Athletes who do the same Sports as you!
                        </p>
                        <p style={detailsText}>
                          You can then div Spots and Businesses by clicking The
                          Sponsor Hub Pins on the Map.
                        </p>
                        <p style={detailsText}>
                          Businesses will show with a Black TSH Pin, whilst
                          Spots will show with a White TSH Pin.
                        </p>
                        <p style={detailsText}>
                          On Businesses, you can also see Discounts from them,
                          as well as Edits from them. On Spots, you can see
                          Edits from the Locations by your Crew.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p style={detailsText}>
                          To add and share Spots with your Crew, head to Pin
                          icon or TSH Map in the sidebar.
                        </p>
                        <p style={detailsText}>
                          Find the Location on the Map, then Double Press the
                          Location on the Map.
                        </p>
                        <p style={detailsText}>
                          This will bring up the form for you to add a Spot.
                        </p>
                        <p style={detailsText}>
                          You can then div Spots and Businesses by clicking The
                          Sponsor Hub Pins on the Map.
                        </p>
                        <p style={detailsText}>
                          Businesses will show with a Black TSH Pin, whilst
                          Spots will show with a White TSH Pin.
                        </p>
                        <p style={detailsText}>
                          On Businesses, you can also see Discounts from them,
                          as well as Edits from them. On Spots, you can see
                          Edits from the Locations by your Crew.
                        </p>
                      </div>
                    )}
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 5,
                      }}
                    >
                      <img
                        src="/Tutorial/Map_Description.jpg"
                        alt="Map Description"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => {
                          if (isBusiness) {
                            this.setState({ pageIndex: 6 });
                          } else {
                            this.setState({ pageIndex: 7 });
                          }
                        }}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : pageIndex === 6 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Sponsoring Athletes
                    </h4>
                    <p style={detailsText}>
                      To find Athletes you can use our Search for Athletes
                      feature. This can be found in the Menu.
                    </p>
                    <p style={detailsText}>
                      To Sponsor Athletes, drop us an Email at
                      info@thesponsorhub.com, with the name of the Athlete.
                    </p>
                    <p style={detailsText}>
                      We will then get in touch with the Athlete and if they're
                      happy for their Contact details to be shared with you, we
                      will share these details with you.
                    </p>
                    <p style={detailsText}>
                      Please then keep us updated, so we can share and promote
                      this Sponsorship.
                    </p>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 5,
                      }}
                    >
                      <img
                        src="/Tutorial/Advanced_Search.jpg"
                        alt="Advanced Search Description"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => this.setState({ pageIndex: 7 })}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : pageIndex === 7 ? (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Profiles
                    </h4>
                    <p style={detailsText}>
                      When viewing Athletes Profiles, you can see details about
                      them, Follow them, add them to your Crew, view their
                      Instagram, share their Profile and see their Uploads.
                    </p>
                    <div
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <img
                        src="/Tutorial/athlete_profile.jpg"
                        alt="Athlete Profile Description"
                      />
                    </div>
                    <p style={detailsText}>
                      When viewing Business Profiles, you can see details about
                      them, Follow them, add them to your Crew, go to their
                      Website, view their Instagram, share their Profile and see
                      their Uploads and Discounts.
                    </p>
                    <div
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <img
                        src="/Tutorial/business_profile.jpg"
                        alt="Business Profile Description"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => this.setState({ pageIndex: 8 })}
                      >
                        Next
                      </MDBBtn>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4 className="mb-4" style={title}>
                      Your Profile
                    </h4>
                    {isBusiness ? (
                      <div>
                        <p style={detailsText}>
                          On your Profile, you can see your details, Edit your
                          Profile, go to your Website, go to your Instagram,
                          upload a Profile Image, Accept and Remove Crew
                          members, as well as view your Edits, Stokes and
                          Discounts.
                        </p>
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/Tutorial/your_profile_business.jpg"
                            alt="Your Profile Business Description"
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p style={detailsText}>
                          On your Profile, you can see your details, Edit your
                          Profile, go to your Instagram, upload a Profile Image,
                          Accept and Remove Crew members, as well as view your
                          Edits, Stokes.
                        </p>
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/Tutorial/your_profile_athlete.jpg"
                            alt="Your Profile Athlete Description"
                          />
                        </div>
                      </div>
                    )}
                    <p style={detailsText}>
                      In the Menu, you can also check out your Crews Edits,
                      Local Athletes, Edits and Discounts from other sports,
                      highest Stoked Edits, information about The Sponsor Hub
                      and access our Legal documents.
                    </p>
                    <div style={{ textAlign: "center" }}>
                      <MDBBtn
                        style={nextButton}
                        color="white"
                        rounded
                        onClick={() => {
                          window.location.href = "/";
                        }}
                      >
                        Finish
                      </MDBBtn>
                    </div>
                  </div>
                )}
              </MDBCol>
            </div>
          );
        }}
      </Query>
    );
  }
}

const title = {
  color: "#f8f8ff",
  textAlign: "center",
  fontFamily: "Oswald",
  textTransform: "uppercase",
};

const valueTitle = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  marginTop: "1.5rem",
};

const detailsText = {
  color: "#f8f8ff",
  textAlign: "justify",
  fontFamily: "Oswald",
};

const detailsLinkText = {
  color: "#f8f8ff",
  textAlign: "justify",
  fontFamily: "Oswald",
  marginBottom: 0,
};

const nextButton = {
  fontFamily: "Oswald",
  fontSize: "15px",
  width: "35%",
  paddingTop: "0.15rem",
  paddingBottom: "0.22rem",
};

export default withApollo(Tutorial);
