import React, { Component } from "react";
import { AUTH_TOKEN } from "../constants";
import { Mutation, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { MDBCol, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import "./../styles/Background.css";
import "./../styles/Forms.css";
import PasswordStrengthBar from "react-password-strength-bar";

const SIGNUP_MUTATION = gql`
  mutation SignupMutation($name: String!, $email: String!, $password: String!) {
    signup(name: $name, email: $email, password: $password) {
      token
    }
  }
`;

class CreateAccount extends Component {
  state = {
    email: "",
    password: "",
    name: "",
    hidden: true,
    emailInfo: false,
    passwordInfo: false,
    validationError: "",
  };

  _confirmSignUp = async (data) => {
    const { token } = data.signup;
    this._saveUserData(token);
    window.location.href = "/";
  };

  _saveUserData = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  updateErrorMessage = (error) => {
    this.setState({ validationError: error });
  };

  render() {
    const {
      email,
      password,
      name,
      hidden,
      emailInfo,
      passwordInfo,
      validationError,
    } = this.state;
    return (
      <section id="create-an-account" style={{ backgroundColor: "#000" }}>
        <div className="d-flex justify-content-center align-items-center pt-5">
          <MDBCol
            sm="10"
            md="7"
            lg="6"
            xl="5"
            className="px-3 justify-content-center col-12"
          >
            <Mutation
              mutation={SIGNUP_MUTATION}
              onCompleted={(data) => {
                this._confirmSignUp(data);
              }}
            >
              {(signup, { error, loading }) => {
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.setState({ validationError: "" });
                      if (name.length === 0) {
                        this.setState({
                          validationError:
                            "Please enter your Name or Business Name",
                        });
                      } else if (
                        !this.validateEmail(email) ||
                        email.length === 0
                      ) {
                        this.setState({
                          validationError: "Please enter a valid Email Address",
                        });
                      } else if (password.length < 6) {
                        this.setState({
                          validationError:
                            "Please enter a Password greater than 5 characters long",
                        });
                      } else {
                        signup({
                          variables: {
                            name,
                            email,
                            password,
                          },
                        });
                      }
                    }}
                  >
                    <div>
                      <MDBCol
                        sm="8"
                        md="6"
                        lg="6"
                        className="col-6 offset-3 offset-sm-2 offset-md-3 offset-lg-3"
                      >
                        <img
                          src="TSH_logos/TSH_WHITE_LOGO.png"
                          alt="TSH Logo"
                        />
                      </MDBCol>
                      <h2 className="pb-3" style={title}>
                        Join The Sponsor Hub Community
                      </h2>
                      <MDBInput
                        value={name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        type="text"
                        label={"Name"}
                        style={inputText}
                        required
                        maxLength="80"
                      />
                      <MDBInput
                        value={email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        type="email"
                        label="Email"
                        style={inputText}
                        required
                        maxLength="80"
                      />
                      <div style={infoIcon}>
                        <MDBIcon
                          style={{ color: "#f8f8ff" }}
                          onClick={() =>
                            this.setState({ emailInfo: !emailInfo })
                          }
                          icon="question-circle"
                        />
                      </div>
                      {emailInfo ? (
                        <div>
                          <p style={infoText}>
                            Your Email Address will be used for brands to get in
                            contact with you regarding sponsorship / ambassador
                            opportunities.
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      <MDBInput
                        value={password}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        type={this.state.hidden ? "password" : "text"}
                        label="Password"
                        style={inputText}
                        required
                        maxLength="80"
                        minLength="6"
                      />
                      <div style={passwordIcon}>
                        {hidden ? (
                          <MDBIcon
                            style={{ color: "#f8f8ff" }}
                            icon="eye-slash"
                            onClick={() => this.setState({ hidden: !hidden })}
                          />
                        ) : (
                          <MDBIcon
                            style={{ color: "#f8f8ff" }}
                            icon="eye"
                            onClick={() => this.setState({ hidden: !hidden })}
                          />
                        )}
                      </div>
                      <div style={{ marginTop: -5 }}>
                        <PasswordStrengthBar password={password} />
                      </div>
                      <div
                        style={{ position: "relative", zIndex: 2 }}
                        onClick={() =>
                          this.setState({ passwordInfo: !passwordInfo })
                        }
                      >
                        <p style={passwordInfoText}>
                          What makes a strong password?
                        </p>
                      </div>
                      <div>
                        {passwordInfo ? (
                          <p style={passwordInfoDescription}>
                            A strong password consists of at least six
                            characters (and the more characters, the stronger
                            the password) that are a combination of upper and
                            lowercase letters, numbers and symbols (@, #, $, %,
                            etc.)
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div style={{ textAlign: "justify", marginTop: "1.5rem" }}>
                      <p style={legalText}>
                        By Creating an Account, you agree to our
                        <a style={legalLink} href="/community_guidelines">
                          {" "}
                          Community Guidelines
                        </a>
                        ,
                        <a style={legalLink} href="/terms_and_conditions">
                          {" "}
                          Terms and Conditions
                        </a>{" "}
                        and
                        <a style={legalLink} href="/privacy_policy">
                          {" "}
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                    <div className="pt-2" style={{ textAlign: "center" }}>
                      {error && (
                        <p style={errorText}>
                          {error.message.replace("GraphQL error: ", "")}
                        </p>
                      )}
                    </div>
                    <div className="pt-2" style={{ textAlign: "center" }}>
                      {validationError !== "" && (
                        <p style={errorText}>{validationError}</p>
                      )}
                    </div>
                    <div style={navigationContainer}>
                      <MDBBtn
                        style={createAccountButton}
                        color="white"
                        rounded
                        type="submit"
                      >
                        {loading ? "Creating Account" : "Create Account"}
                      </MDBBtn>
                    </div>
                  </form>
                );
              }}
            </Mutation>
          </MDBCol>
        </div>
      </section>
    );
  }
}

export default withApollo(CreateAccount);

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const infoIcon = {
  marginTop: "-55px",
  zIndex: "10",
  float: "right",
  position: "relative",
};

const infoText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  textAlign: "justify",
};

const passwordIcon = {
  marginTop: "-55px",
  zIndex: "10",
  float: "right",
  position: "relative",
};

const passwordInfoText = {
  marginTop: -20,
  fontSize: 14,
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "400",
};

const passwordInfoDescription = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  marginTop: -15,
  textAlign: "justify",
  fontSize: 14,
};

const legalText = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const legalLink = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Oswald",
  padding: "0",
  fontStyle: "italic",
};

const navigationContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "0.5rem",
};

const errorText = {
  color: "red",
  fontFamily: "Oswald",
};

const createAccountButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  minWidth: 250,
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};
