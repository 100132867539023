import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBView } from "mdbreact";

const Blog = () => {
  return (
    <MDBCard className="my-3" style={{ boxShadow: 'none', backgroundColor: '#000' }}>
      <MDBCardBody>
        <h2 className="my-5" style={title}>
          Recent posts
        </h2>
        <MDBRow>
          <MDBCol lg="7" md="9">
            <h6 className="mb-3" style={subTitle}>
              <MDBIcon style={{color: '#f8f8ff'}} icon="building" className="pr-2" />
                Business
              </h6>
            <h3 className="mb-3 p-0" style={titleText}>
              Releasing The Sponsor Hub App
            </h3>
            <p style={detailsText}>
            We are stoked to announce that we have just released The Sponsor Hub App for both IOS and Android devices. We are extremely proud of the App, which has 
              more features to engaged you in the community. A huge thank you and well done to everyone at TSH for making this possible.
              With the release of the App we have also added new features, including Profile pictures for Athletes and a specific feed for you to share your uploads with your Crew.
              We hope you enjoy the App and look forward to building a community of extreme sports Athletes!
            </p>
            <p style={detailsText}>20/05/2021</p>
          </MDBCol>
          <MDBCol lg="3" md="3" className="d-flex justify-content-center offset-lg-2">
            <MDBView className="rounded mb-lg-0 mb-2 justify-content-center">
              <img
                className="img"
                src="/Blog/App_Launch_Login.jpg"
                style={{height: "300px"}}
                alt="TSH App"
              />
            </MDBView>
          </MDBCol>
        </MDBRow>
        <hr className="mb-4 mt-3 hr-light" />
        <MDBRow>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src="/Blog/bmx_background1.jpg"
                alt="BMX"
              />
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <h6 className="mb-3" style={subTitle}>
              <MDBIcon style={{color: '#f8f8ff'}} icon="building" className="pr-2" />
                Business
              </h6>
            <h3 className="mb-3 p-0" style={titleText}>
              Expansion Into New Sports
            </h3>
            <p style={detailsText}>
              We are stoked to annouce that we have expanded into two further sports, Scootering and BMX. We are super excited to launch these sports and look 
              forward to seeing sick clips and images from athletes, as well as collaborating with amazing Scootering and BMX brands. We also decided to split
              Snowsports into Skiing and Snowboarding. This means The Sponsor Hub platform now operates within six extreme sports categories: Surf, Skate, Ski,
              Snowboard, Scooter and BMX. 
            </p>
            <p style={detailsText}>5/12/2020</p>
          </MDBCol>
        </MDBRow>
        <hr className="mb-4 mt-3 hr-light" />
        <MDBRow>
          <MDBCol lg="7">
            <h6 className="mb-3" style={subTitle}>
              <MDBIcon style={{color: '#f8f8ff'}} icon="building" className="pr-2" />
                Business
              </h6>
            <h3 className="mb-3 p-0" style={titleText}>
              The New Improved Platform
            </h3>
            <p style={detailsText}>
              After a successfull launch and a lot of lessons learnt, we have been working hard to build you the best platform for athletes.
              We are extremely excited to introduce to you the new Sponsor Hub platform.
              This platform is a collaborative space for athletes to share clips, follow fellow athletes and brands, find ambassadorial roles and access 
              exclusive discounts from our amazing collaborating brands. A one-stop-shop for all your needs and to share the stoke!
              Thank you for all the support so far, we hope you enjoy the new, improved site.
            </p>
            <p style={detailsText}>16/10/2020</p>
          </MDBCol>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-2">
              <img
                className="img-fluid"
                src="/Blog/blog3.jpg"
                alt=""
              />
            </MDBView>
          </MDBCol>
        </MDBRow>
        <hr className="mb-4 mt-3 hr-light" />
        <MDBRow>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src="/Blog/brands.png"
                alt=""
              />
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <h6 className="mb-3" style={subTitle}>
              <MDBIcon style={{color: '#f8f8ff'}} icon="building" className="pr-2" />
                Business
              </h6>
            <h3 className="mb-3 p-0" style={titleText}>
              Launch Brands
            </h3>
            <p style={detailsText}>
              As of our launch, we have over 40 amazing brands signed up to the site. You can check out all the brands we have onboard
               <a style={{ color: '#f8f8ff', fontFamily: 'Roboto', display: 'inline', fontStyle: 'italic', padding: '0' }} href="/information#brands"> here</a>.
              We have a wide variety of brands covering all you'd ever need for your chosen sport.
              We strive to connect athletes with brands who are local to them, as well as connecting brands with local athletes.
              We want to thank all the brands who came onboard for our launch, as without you, we wouldn't have this wonderful new platform.
            </p>
            <p style={detailsText}>22/06/2020</p>
          </MDBCol>
        </MDBRow>
        <hr className="mb-4 mt-3 hr-light" />
        <MDBRow>
          <MDBCol lg="7">
            <h6 className="mb-3" style={subTitle}>
              <MDBIcon style={{color: '#f8f8ff'}} icon="building" className="pr-2" />
                Business
              </h6>
            <h3 className="mb-3 p-0" style={titleText}>
              Platform Launch
            </h3>
            <p style={detailsText}>
              The Sponsor Hub Team have been working tirelessly on the new platform and we are pleased to announce its launch.
              We look forward to both athletes and businesses utilising the platform to further their careers and grow their businesses.
              The launch of the new platform marks a new era for extreme sports, and we thank you for joining us on this adventure.
            </p>
            <p style={detailsText}>15/06/2020</p>
          </MDBCol>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-2">
              <img
                className="img-fluid"
                src="/Blog/login.jpg"
                alt=""
              />
            </MDBView>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
}

export default Blog;

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald',
  textTransform: 'uppercase'
}

const subTitle = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald',
  textTransform: 'uppercase',
  display: 'inline-flex'
}


const titleText = {
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const detailsText = {
  textAlign: 'justify', 
  color: '#f8f8ff', 
  fontFamily: 'Roboto'
}