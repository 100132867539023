import React from "react";
import "./../../styles/Legal.css";
import { MDBCol } from "mdbreact";

const CookiePolicy = () => {
  return (
    <div
      className="px-3 pt-4"
      style={{ backgroundColor: "#fff", textAlign: "justify" }}
    >
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Cookie Policy</h1>
          <p className="legal-text">
            The Sponsor Hub is committed to be a safe, supportive and inclusive
            platform for all our members to enjoy. Please read this Cookie
            Policy carefully as it contains important information on who we are
            and how we use cookies on our website. This policy should be read
            together with our website{" "}
            <a className="legal-link" href="privacy_policy">
              <p className="legal-text">Privacy Policy</p>
            </a>{" "}
            which sets out how and why we collect, store, use and share personal
            information generally, as well as your rights in relation to your
            personal information and details of how to contact us and
            supervisory authorities if you have a complaint.
          </p>
          <h3 className="legal-subheading">Who we are</h3>
          <p className="legal-text">
            <a className="legal-link" href="https://thesponsorhub.com">
              <p className="legal-text">https://thesponsorhub.com</p>
            </a>{" "}
            (our <p className="legal-text-bolder">website</p>) is operated by
            The Sponsor Hub Limited (
            <p className="legal-text-bolder">The Sponsor Hub</p>, or,{" "}
            <p className="legal-text-bolder">we</p>, or,{" "}
            <p className="legal-text-bolder">us</p>, or,{" "}
            <p className="legal-text-bolder">our</p>). We are an extreme sports
            digital marketing and social media platform where athletes can
            publish their skills and talents to the world, meet likeminded
            sportspersons, and hook up with extreme sports brands.
          </p>
          <h3 className="legal-subheading">Our website</h3>
          <p className="legal-text">
            Throughout our website we may link to other websites owned and
            operated by certain trusted third parties to make additional
            products and services available to you. These other third party
            websites may also use cookies or similar technologies in accordance
            with their own separate policies. For privacy information relating
            to these other third party websites, please consult their policies
            as appropriate.
          </p>
          <h3 className="legal-subheading">Cookies</h3>
          <p className="legal-text">
            A cookie is a small text file which is placed onto your device (eg
            computer, smartphone or other electronic device) when you use our
            website. We use cookies on our website. These help us to recognise
            you and your device and store some information about your
            preferences or past actions.
          </p>
          <br />
          <p className="legal-text">
            For example, we may monitor how many times you visit the website,
            which pages you go to, location data and the originating domain name
            of your internet service provider. This information helps us to
            build a profile of our users and to offer users the best experience
            possible. Some of this data will be aggregated or statistical, which
            means that we will not be able to identify you individually.
          </p>
          <br />
          <p className="legal-text">
            For further information on our use of cookies, including a detailed
            list of your information which we and others may collect through
            cookies, please see below.
          </p>
          <p className="legal-text">
            For further information on cookies generally, including how to
            control and manage them, visit the guidance on cookies published by
            the UK Information Commissioner’s Office,{" "}
            <a className="legal-link" href="www.aboutcookies.org">
              <p className="legal-text">www.aboutcookies.org</p>
            </a>{" "}
            or{" "}
            <a className="legal-link" href="www.allaboutcookies.org">
              <p className="legal-text">www.allaboutcookies.org</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">
            Consent to use cookies and changing settings
          </h3>
          <p className="legal-text">
            We will ask for your consent to place cookies or other similar
            technologies on your device, except where they are essential for us
            to provide you with a service that you have requested (eg.
            Authentication Token placed by The Sponsor Hub).
          </p>
          <br />
          <p className="legal-text">
            You can withdraw any consent to the use of cookies or manage any
            other cookie preferences by heading to your Profile, clicking 'Edit
            Details' and changing cookie settings within the 'Settings' tab.
          </p>
          <br />
          <h3 className="legal-subheading">Our use of cookies</h3>
          <p className="legal-text">
            <p className="legal-text-bolder">The cookies we use</p>:
            Authentication, The Sponsor Hub Ltd
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Name</p>: Auth_Token
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Purpose</p>: This is an app
            authentication service provided by The Sponsor Hub Ltd, which uses
            cookies to store an authentication token on your device, allowing
            for automatic Log In.
            <br /> Auth_Token remain duration lifetime of the users Log In
            session on The Sponsor Hub Website.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">
              Whether cookie is essential for us to provide you with a service
              that you have requested and whether we will seek your consent
              before we place the cookie
            </p>
            : Yes, essential (we will therefore not request your consent before
            placing this cookie)
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">The cookies we use</p>: Universal
            Analytics (Google Inc)
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Name</p>: _ga, _gid
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Purpose</p>: This is a web
            analytics service provided by Google Inc which uses cookies to show
            us how visitors found and explored the website and how we can
            enhance their experience. It provides us with information about the
            behaviour of our visitors (eg how long they stayed on the website,
            the average number of pages viewed) and also tells us how many
            visitors we have had.
            <br /> This cookie collects information in an anonymous form
            including: Traffic Channel, Time of Day Accessed, Session Duration,
            Bounce Rate, Number of Sessions per User, Average Session Duration,
            Browser, Operating System, Service Provider, Screen Resolution, Time
            on Page and Percentage Exit on Page. Or personal information
            including: Location (Country and City), Language, Age, Gender.
            <br /> _ga remain duration 2 years.
            <br /> _gid remain duration 24 hours.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">
              Whether cookie is essential for us to provide you with a service
              that you have requested and whether we will seek your consent
              before we place the cookie
            </p>
            : No, will therefore request your consent before placing this
            cookie.
          </p>
          <br />
          <h3 className="legal-subheading">
            Third party access to the cookies
          </h3>
          <p className="legal-text">
            The cookies we use will only be accessed by us and those third
            parties named in the table above for the purposes referred to in
            this cookie policy. Those cookies will not be accessed by any other
            third party.
          </p>
          <br />
          <h3 className="legal-subheading">
            How to turn off all cookies and consequences of doing so
          </h3>
          <p className="legal-text">
            If you do not want to accept any cookies, you may be able to change
            your browser settings so that cookies (including those which are
            essential to the services requested) are not accepted. If you do
            this, please be aware that you may lose some of the functionality of
            our website.
          </p>
          <br />
          <p className="legal-text">
            For further information about cookies and how to disable them please
            go to the guidance on cookies published by the UK Information
            Commissioner’s Office,{" "}
            <a className="legal-link" href="www.aboutcookies.org">
              <p className="legal-text">www.aboutcookies.org</p>
            </a>{" "}
            or{" "}
            <a className="legal-link" href="www.allaboutcookies.org">
              <p className="legal-text">www.allaboutcookies.org</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">How to contact us</h3>
          <p className="legal-text">
            Please contact us if you have any questions about this cookie policy
            or the information we hold about you.
          </p>
          <br />
          <p className="legal-text">
            If you wish to contact us please send an email to{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>{" "}
            or write to Walk Mill, Kirkwhelpington, Newcastle Upon Tyne,
            Northumberland, England, NE19 2SB or call +44 (0)7966 593 208.
          </p>
          <br />
          <h3 className="legal-subheading">Do you need extra help?</h3>
          <p className="legal-text">
            If you would like this notice in another format (for example large
            print) please contact us (see above).
          </p>
          <br />
          <h3 className="legal-subheading">Changes to this policy</h3>
          <p className="legal-text">This policy was published on 12/3/22.</p>
          <br />
          <p className="legal-text">
            We may change this policy from time to time, when we do we will
            inform you via email, informing you of changes to this policy. Any
            such changes will become binding on you on your first use of our
            website after the changes have been made. You are therefore advised
            to also check this policy from time to time.
          </p>
          <br />
          <h3 className="legal-subheading">Thanks</h3>
          <h3 className="legal-subheading">The Sponsor Hub</h3>
          <p className="legal-text">
            This document was produced by our Legal Services Partner{" "}
            <a
              style={{ display: "inline", padding: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nqlegal.co.uk/"
            >
              <img
                height={20}
                style={{ marginTop: -2 }}
                src="/Partners/nqLegalLogo.png"
                alt="ngLegal"
              />
            </a>
            .
          </p>
        </div>
      </MDBCol>
    </div>
  );
};

export default CookiePolicy;
