import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBCol, MDBBtn, MDBInput, MDBIcon } from "mdbreact";
import './../../styles/Background.css'
import './../../styles/Forms.css'
import Loading from './../Loading'
import ErrorPage from './../Errors/ErrorMessage'
import PasswordStrengthBar from 'react-password-strength-bar';

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($id: ID!, $oldPassword: String!, $newPassword: String!) {
    changePassword(id: $id, oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`

const USER_ID_QUERY = gql`
  query{
    user{
      id
      category
    }
  }
`

class ChangePassword extends Component {
  render() {
    return (
      <Query query={USER_ID_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />

          const id = data.user.id

          return (
            <div style={{backgroundColor: '#000', minHeight: '100vh'}} className="d-flex py-5 justify-content-center align-items-center">
              <MDBCol sm="12" md="7" lg="5" xl="4" className="px-3 justify-content-center col-12">
                <ChangePasswordForm id={id} />
              </MDBCol>
            </div>
          )
        }}
      </Query >
    )
  }
}

class ChangePasswordForm extends Component {

  state = {
    id: this.props.id,
    oldPassword: '',
    newPassword: '',
    hiddenOldPass: true,
    hiddenNewPass: true,
    passwordInfo: false,
    validationError: ''
  }

  render() {
    const { id, oldPassword, newPassword, hiddenOldPass, hiddenNewPass, passwordInfo, validationError } = this.state
    return (
      <div>
        <h2 style={title}>CHANGE PASSWORD</h2>
        <Mutation mutation={CHANGE_PASSWORD_MUTATION} onCompleted={() => window.location.href = `/profile/${id}`}>
          {(changePassword, { error, loading, data }) => (
            <form onSubmit={e => {
              e.preventDefault();
              if(newPassword.length < 6){
                this.setState({validationError: 'Please enter a New Password greater than 5 characters long'})
              }else{
                if(!loading && !data){
                  changePassword({ variables: { id, oldPassword, newPassword } })
                }
              }
            }}>
              <div>
                <MDBInput
                  value={oldPassword}
                  onChange={e => this.setState({ oldPassword: e.target.value })}
                  type={hiddenOldPass ? "password" : "text"}
                  label="Enter Old Password"
                  style={inputText}
                  required
                  maxLength="80"
                />
                <div style={passwordIcon}>
                  {hiddenOldPass ? (
                    <MDBIcon style={{color: '#f8f8ff'}} icon="eye-slash" onClick={() => this.setState({ hiddenOldPass: !hiddenOldPass })} />
                  ) : (
                    <MDBIcon style={{color: '#f8f8ff'}} icon="eye" onClick={() => this.setState({ hiddenOldPass: !hiddenOldPass })} />
                  )}
                </div>
              </div>
              <div>
                <MDBInput
                  value={newPassword}
                  onChange={e => this.setState({ newPassword: e.target.value })}
                  type={hiddenNewPass ? "password" : "text"}
                  label="Enter New Password"
                  style={inputText}
                  required
                  maxLength="80"
                />
                <div style={passwordIcon}>
                  {hiddenNewPass ? (
                    <MDBIcon style={{color: '#f8f8ff'}} icon="eye-slash" onClick={() => this.setState({ hiddenNewPass: !hiddenNewPass })} />
                  ) : (
                    <MDBIcon style={{color: '#f8f8ff'}} icon="eye" onClick={() => this.setState({ hiddenNewPass: !hiddenNewPass })} />
                  )}
                </div>
              </div>
              <div style={{marginTop: -5}}>
                  <PasswordStrengthBar password={newPassword} />
              </div>
              <div style={{position: 'relative', zIndex: 2}} onClick={() => this.setState({passwordInfo: !passwordInfo})}>
                  <p style={passwordInfoText}>What makes a strong password?</p>
              </div>
              <div>
                  {passwordInfo ?
                      <p style={passwordInfoDescription}>A strong password consists of at least six characters (and the more characters, the stronger the password) that are a combination of upper and lowercase letters, numbers and symbols (@, #, $, %, etc.)</p>
                  :
                      <></>
                  }
              </div>
              <div className='py-2' style={{textAlign: 'center'}}>
                {error &&
                  <p style={errorText}>{error.message.includes('jwt expired') ?
                  'Token Expired. Please Login again' : error.message.replace('GraphQL error: ', '')}</p>
                }
                {validationError !== "" && <p style={errorText}>{validationError}</p>}
              </div>
              <div className="text-center">
                <div className="flex justify-content-center">
                  <MDBBtn style={changePasswordButton} color="white" rounded type="submit">{!loading ? "Change Password" : "Changing Password"}</MDBBtn>
                </div>
              </div>
            </form>
          )}
        </Mutation>
      </div>
    )
  }
}

export default ChangePassword

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  marginBottom: "1.5rem"
}

const inputText = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

const passwordIcon = { 
  marginTop: '-55px', 
  zIndex: '10', 
  float: 'right', 
  position: 'relative' 
}

const passwordInfoText = {
  marginTop: -20, 
  fontSize: 14, 
  color:'#f8f8ff', 
  fontFamily: 'Roboto', 
  fontWeight: '400'
}

const passwordInfoDescription = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  fontWeight: '300',
  marginBottom: '1rem',
  marginTop: -15,
  textAlign: 'justify',
  fontSize: 14, 
}

const errorText = {
  color: 'red',
  fontFamily: 'Oswald'
}

const changePasswordButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: '50%',
  paddingTop: '0.25rem',
  paddingBottom: '0.2rem'
}
