import React, { Component } from 'react'
import Video from './Video'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBRow } from 'mdbreact'
import ErrorPage from './../Errors/404.js'
import Loading from './../Loading'

const COMPETITION_VIDEO_FEED_QUERY = gql`
  query CompetitionFeed($filter: String, $category: String){
    competitionFeed(filter: $filter, category: $category, orderBy: createdAt_ASC) {
      videos {
        id
        filename
        description
        filmerInstagram
        mimetype
        crewOnly
        postedBy {
          id
          name
          category
          country
          city
          instagram
          email
          businessLogo
          profileImage
          isBusiness
        }
        votes {
          id
          user {
            id
          }
        }
        videoViews{
          id
        }
      }
    }
  }
`

class CompetitionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.match.params.category,
      sportCategory: this.props.match.params.sportCategory
    }
  }
  render() {
    const {category, sportCategory} = this.state
    if(category !== "15andundermale" && category !== "15andunderfemale" && category !== "16+male" && category !== "16+female"){
      return(
        <ErrorPage location={window.location}/>
      )
    } else {
    return (
      <div style={{backgroundColor: '#000', minHeight: '100vh'}}>
        <div className="pt-5">
          <h2 style={title} className="mt-3">Competition Edits</h2>
        </div>
        <CompetitionVideos category={category} sportCategory={sportCategory} />
      </div>
    )
    }
  }
}

class CompetitionVideos extends Component {
  state = {
    filter: this.props.category,
    category: this.props.sportCategory
  }
  render() {
    const {filter, category} = this.state
    return (
      <Query query={COMPETITION_VIDEO_FEED_QUERY} variables={{ filter, category }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />

         const videosToRender = data.competitionFeed.videos.reverse()

          return (
            <MDBRow className="justify-content-center pb-5">
              {videosToRender.map(video => <Video key={video.id} video={video} />)}
            </MDBRow>
          )
        }}
      </Query>
    )
  }
}

export default CompetitionsPage

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald',
  textTransform: 'uppercase'
}

