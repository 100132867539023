import React, { Component } from 'react'
import Video from './Video'
import { Query, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBRow, MDBBtn } from 'mdbreact'
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorPage from './../Errors/ErrorMessage'

const PAGE_SIZE = 6

const VIDEO_INTERNATIONAL_FEED_QUERY = gql`
  query VideoFeed($filter: String, $skip: Int, $last: Int){
    videoFeed(filter: $filter, skip: $skip, last: $last) {
      videos {
        id
        filename
        description
        filmerInstagram
        mimetype
        crewOnly
        postedBy {
          id
          name
          category
          country
          city
          instagram
          email
          businessLogo
          profileImage
          isBusiness
        }
        votes {
          id
          user {
            id
          }
        }
        videoViews{
          id
        }
      }
    }
  }
`

class VideosInternational extends Component {
  state = {
    videos: [],
    skip: 0,
    loadMore: false,
    firstLoad: true,
    hasMore: true,
    category: this.props.category
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this._executeFetchMore()
    }, 1000);
  };

  _executeFetchMore = async () => {
    var { category, skip } = this.state

    const result = await this.props.client.query({
      query: VIDEO_INTERNATIONAL_FEED_QUERY,
      variables: { filter: category, skip, last: PAGE_SIZE },
    })
    this.setState({ videos: [...this.state.videos, ...result.data.videoFeed.videos] })
    if(result.data.videoFeed.videos.length === 0){
      this.setState({hasMore: false})
    } else {
      this.setState({skip: this.state.skip + PAGE_SIZE})
      if(this.state.firstLoad){
        this._executeFetchMore()
      }
      this.setState({hasMore: true, firstLoad: false})
    }
  }

  render() {
    const {category} = this.state
    return (
      <Query query={VIDEO_INTERNATIONAL_FEED_QUERY} variables={{filter: category, skip: 0, last: PAGE_SIZE}}>
        {({ data, loading, error }) => {
          if(loading) return <div style={{minHeight: '100vh', backgroundColor: 'transparent'}}></div>
          if(error) return <ErrorPage error={error.message} />

          return (
            <div className="pt-3" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
              {data.videoFeed.videos.length === 0 ?
                <div className="py-2" style={editsText}>
                  <h5>There are currently no {category} Videos Internationally.</h5>
                  <MDBBtn style={uploadButton} color="white" rounded outline href="/upload_edit">Upload an Edit</MDBBtn>
                </div>
              :
              <InfiniteScroll
                dataLength={this.state.videos.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Edits...</h5></div>}
              >
                {data && this.state.firstLoad ?
                  <MDBRow className="justify-content-center">
                      {data.videoFeed.videos.map(video => <Video key={video.id} video={video} />)}
                  </MDBRow>
                :
                  <MDBRow className="justify-content-center">
                      {this.state.videos.map(video => <Video key={video.id} video={video} />)}
                  </MDBRow>
                }
                {!this.state.hasMore ?
                  <div className="pb-2" style={editsText} key={0}><h5>No More Edits!</h5></div>
                :
                  <></>
                }
              </InfiniteScroll>
              }
            </div>
          )
        }}
      </Query>
    );
  }
}
  
export default withApollo(VideosInternational)

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const uploadButton = {
  minWidth: 150,
  paddingTop: '0.15rem',
  paddingBottom: '0.25rem',
  fontSize: 15,
  fontFamily: 'Oswald'
}
