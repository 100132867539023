import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBCol, MDBBtn, MDBInput, MDBIcon } from "mdbreact";
import './../styles/Background.css'
import './../styles/Forms.css'

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($email: String!, $verification: String!, $newPassword: String!) {
    resetPassword(email: $email, verification: $verification, newPassword: $newPassword) {
      id
    }
  }
`

const SEND_EMAIL_MUTATION = gql`
  mutation SendEmailMutation($email: String!) {
    sendEmail(email: $email) {
      id
    }
  }
`

class ResetPassword extends Component {
  state = {
    email: '',
    verification: '',
    newPassword: '',
    hiddenNewPass: true,
    sendEmail: false,
  }

  render() {
    const { email, verification, newPassword, hiddenNewPass, sendEmail } = this.state
    return (
      <div style={{backgroundColor: '#000', minHeight: '100vh'}} className="d-flex justify-content-center align-items-center">
        <MDBCol sm="12" md="7" lg="5" xl="4" className="px-3 justify-content-center col-12">
          <h2 style={title}>RESET PASSWORD</h2>
          <Mutation mutation={RESET_PASSWORD_MUTATION} onCompleted={() => window.location.href = `/login`}>
            {(resetPassword, { error, loading, data }) => (
              <form onSubmit={e => {
                e.preventDefault();
                if(!loading && !data){
                  resetPassword({ variables: { email, verification, newPassword} })
                }
              }}>
                <MDBInput
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  type="text"
                  label="Enter your Email"
                  style={inputText}
                  required
                  maxLength="80"
                />
                {!sendEmail ? 
                  <Mutation mutation={SEND_EMAIL_MUTATION} variables={{ email }} onCompleted={() => this.setState({sendEmail: !sendEmail})}>
                    {(sendEmail, {error, loading, data}) => (       
                      <div className="text-center pt-1">
                        {error &&
                          <p style={errorText}>{error.message.replace('GraphQL error: ', '')}</p>
                        }
                        {!data ?
                          <div className="flex justify-content-center">
                              <MDBBtn style={sendEmailButton} color="white" outline rounded onClick={() => {if(!loading){ sendEmail()}}}>{!loading ? "Send Password Reset Email" : "Sending"}</MDBBtn>
                          </div>
                        :
                          <></>
                        }
                      </div>
                    )}
                  </Mutation>
                :
                    <></>
                }
                <div>
                  <MDBInput
                    value={verification}
                    onChange={e => this.setState({ verification: e.target.value })}
                    type="text"
                    label="Enter 9 digit verification"
                    style={inputText}
                    required
                    maxLength="9"
                  />
                  <div>
                    <MDBInput
                      value={newPassword}
                      onChange={e => this.setState({ newPassword: e.target.value })}
                      type={hiddenNewPass ? "password" : "text"}
                      label="Enter New Password"
                      style={inputText}
                      required
                      maxLength="80"
                    />
                    <div style={passwordIcon}>
                      {hiddenNewPass ? (
                        <MDBIcon style={{color: '#f8f8ff'}} icon="eye-slash" onClick={() => this.setState({ hiddenNewPass: !hiddenNewPass })} />
                      ) : (
                        <MDBIcon style={{color: '#f8f8ff'}} icon="eye" onClick={() => this.setState({ hiddenNewPass: !hiddenNewPass })} />
                      )}
                    </div>
                  </div>
                  <div className='text-center'>
                    {error &&
                      <p style={errorText}>{error.message.replace('GraphQL error: ', '')}</p>
                    }
                  </div>
                  <div className="text-center pt-2">
                    <div className="flex justify-content-center">
                      <MDBBtn style={resetPasswordButton} color="white" rounded type="submit">{!loading ? "Reset Password" : "Resetting Password"}</MDBBtn>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Mutation>
        </MDBCol>
      </div>
    )
  }
}

export default ResetPassword

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  marginBottom: "1.5rem"
}

const inputText = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

const passwordIcon = { 
  marginTop: '-55px', 
  zIndex: '10', 
  float: 'right', 
  position: 'relative' 
}

const errorText = {
  color: 'red',
  fontFamily: 'Oswald'
}

const resetPasswordButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: '50%',
  paddingTop: '0.22rem',
  paddingBottom: '0.2rem'
}

const sendEmailButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: 'auto',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem'
}
