import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import {
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBFileInput,
  MDBIcon,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import "./../../styles/Forms.css";
import "./../../styles/Background.css";
import { AUTH_TOKEN } from "../../constants";
import Loading from "../Loading";
import ErrorPage from "../Errors/ErrorMessage";
import Loader from "react-dots-loader";
import "react-dots-loader/index.css";

const UPDATE_USER_MUTATION = gql`
  mutation UpdateMutation(
    $id: ID!
    $email: String!
    $name: String!
    $gender: String
    $instagram: String!
    $country: String!
    $city: String!
    $category: [String!]
    $sponsors: String
    $athleteOrPhotographer: String
    $isBusiness: Boolean!
    $website: String
    $businessLogo: String
    $businessRegion: String
    $marketingOptIn: Boolean
    $about: String
    $age: String
    $websiteCookies: Boolean
    $appCookies: Boolean
    $businessIsBricks: Boolean
  ) {
    userUpdate(
      id: $id
      email: $email
      name: $name
      gender: $gender
      instagram: $instagram
      country: $country
      city: $city
      category: $category
      sponsors: $sponsors
      athleteOrPhotographer: $athleteOrPhotographer
      isBusiness: $isBusiness
      website: $website
      businessLogo: $businessLogo
      businessRegion: $businessRegion
      marketingOptIn: $marketingOptIn
      about: $about
      age: $age
      websiteCookies: $websiteCookies
      appCookies: $appCookies
      businessIsBricks: $businessIsBricks
    ) {
      id
      email
      name
      gender
      instagram
      country
      city
      category
      sponsors
      athleteOrPhotographer
      isBusiness
      website
      businessLogo
      businessRegion
      about
      age
    }
  }
`;

const USER_ID_QUERY = gql`
  query {
    user {
      id
      email
      name
      gender
      instagram
      country
      city
      category
      sponsors
      about
      age
      athleteOrPhotographer
      isBusiness
      website
      businessLogo
      businessRegion
      newUser
      marketingOptIn
      websiteCookies
      appCookies
      informationOptIn
    }
  }
`;

const UPLOAD_FILE_STREAM = gql`
  mutation BusinessLogoUpload($file: Upload!) {
    businessLogoUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccountMutation {
    deleteAccount {
      id
    }
  }
`;

const UPDATE_USER_SETTINGS_MUTATION = gql`
  mutation UpdateSettingsMutation(
    $id: ID!
    $marketingOptIn: Boolean
    $websiteCookies: Boolean
    $appCookies: Boolean
    $informationOptIn: Boolean
  ) {
    userSettingsUpdate(
      id: $id
      marketingOptIn: $marketingOptIn
      websiteCookies: $websiteCookies
      appCookies: $appCookies
      informationOptIn: $informationOptIn
    ) {
      id
    }
  }
`;

class EditProfile extends Component {
  state = {
    activeItemTabs: "1",
  };

  toggleTabs = (tab) => () => {
    if (this.state.activeItemTabs !== tab) {
      this.setState({
        activeItemTabs: tab,
      });
    }
  };
  render() {
    return (
      <Query query={USER_ID_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorPage error={error.message} />;

          const user = data.user;

          return (
            <div style={{ backgroundColor: "#000", minHeight: "100vh" }}>
              <h2 className="pt-5 mt-3" style={title}>
                EDIT PROFILE
              </h2>
              <div className="classic-tabs">
                <MDBNav
                  classicTabs
                  color="transparent"
                  className="justify-content-center"
                >
                  <MDBNavItem className="mx-4">
                    <MDBNavLink
                      link
                      to="#"
                      active={this.state.activeItemTabs === "1"}
                      onClick={this.toggleTabs("1")}
                    >
                      <h3 id="tabTitle" style={title}>
                        DETAILS
                      </h3>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem className="mx-4">
                    <MDBNavLink
                      link
                      to="#"
                      active={this.state.activeItemTabs === "2"}
                      onClick={this.toggleTabs("2")}
                    >
                      <h3 id="tabTitle" style={title}>
                        SETTINGS
                      </h3>
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItemTabs}>
                  <MDBTabPane tabId="1">
                    <MDBCol
                      sm="12"
                      md="8"
                      lg="6"
                      xl="4"
                      className="px-3 offset-md-2 offset-lg-3 offset-xl-4 col-12 pb-3"
                    >
                      <EditProfileForm user={user} />
                    </MDBCol>
                  </MDBTabPane>
                  <MDBTabPane tabId="2">
                    <MDBCol
                      sm="12"
                      md="8"
                      lg="6"
                      xl="4"
                      className="px-3 offset-md-2 offset-lg-3 offset-xl-4 col-12"
                    >
                      <Settings user={user} />
                    </MDBCol>
                  </MDBTabPane>
                </MDBTabContent>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

class EditProfileForm extends Component {
  state = {
    id: this.props.user.id,
    email: this.props.user.email,
    name: this.props.user.name,
    about: this.props.user.about,
    age: this.props.user.age,
    instagram: this.props.user.instagram,
    country: this.props.user.country,
    city: this.props.user.city,
    category: this.props.user.category,
    addSurf: true,
    addSkate: true,
    addSnowboard: true,
    addSki: true,
    addBMX: true,
    addMTB: true,
    addScooter: true,
    addClimbing: true,
    addApparel: true,
    addOther: true,
    gender: this.props.user.gender,
    sponsors: this.props.user.sponsors,
    athleteOrPhotographer: this.props.user.athleteOrPhotographer,
    isBusiness: this.props.user.isBusiness,
    website: this.props.user.website,
    businessIsBricks: this.props.user.businessIsBricks,
    businessLogo: this.props.user.businessLogo,
    businessRegion: this.props.user.businessRegion,
    newUser: this.props.user.newUser,
    file: "",
    fileError: "",
    emailInfo: false,
    instagramInfo: false,
    cityInfo: false,
    disableUpload: false,
    deleteAccount: false,
  };

  fileChangeHandler = (files) => {
    this.setState({ file: files[0] });
  };

  handleSelectChangeCountry = (country) => {
    this.setState({ country: country });
  };

  validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  render() {
    const {
      id,
      email,
      name,
      about,
      age,
      gender,
      country,
      city,
      category,
      addSurf,
      addSkate,
      addSnowboard,
      addSki,
      addBMX,
      addMTB,
      addScooter,
      addClimbing,
      addApparel,
      addOther,
      sponsors,
      athleteOrPhotographer,
      isBusiness,
      website,
      businessLogo,
      businessRegion,
      businessIsBricks,
      fileError,
      file,
      disableUpload,
      emailInfo,
      cityInfo,
      instagramInfo,
      deleteAccount,
      validationError,
    } = this.state;
    var instagram = this.state.instagram;
    instagram = instagram.replace("@", "");
    instagram = "@" + instagram;
    console.log(country);
    return (
      <div>
        <Mutation
          mutation={UPDATE_USER_MUTATION}
          onCompleted={() => (window.location.href = `/profile/${id}`)}
        >
          {(userUpdate, { error, loading, data }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (isBusiness) {
                  if (name === null || name.length === 0) {
                    this.setState({
                      validationError: "Please enter your Business Name",
                    });
                  } else if (
                    email === null ||
                    !this.validateEmail(email) ||
                    email.length === 0
                  ) {
                    this.setState({
                      validationError: "Please enter a valid Email Address",
                    });
                  } else if (about === null || about.length === 0) {
                    this.setState({
                      validationError: "Please add About You",
                    });
                  } else if (instagram === null || instagram.length < 2) {
                    this.setState({
                      validationError: "Please add your Instagram Handle",
                    });
                  } else if (city === null || city.length === 0) {
                    this.setState({
                      validationError: "Please add your City",
                    });
                  } else if (country === null || country.length === 0) {
                    this.setState({
                      validationError: "Please select your Country",
                    });
                  } else if (category === null || category.length === 0) {
                    this.setState({
                      validationError: "Please select a Sport",
                    });
                  } else if (
                    website === null ||
                    !website.includes("http") ||
                    website.length === 0
                  ) {
                    this.setState({
                      validationError:
                        "Please enter your Website Address in the form https://",
                    });
                  } else if (
                    businessRegion === null ||
                    businessRegion.length === 0
                  ) {
                    this.setState({
                      validationError: "Please select your Business Region",
                    });
                  } else if (
                    businessLogo === null ||
                    businessLogo.length === 0
                  ) {
                    this.setState({
                      validationError: "Please upload your Business Logo",
                    });
                  } else {
                    instagram = instagram.replace("@", "");
                    if (!loading && !data) {
                      userUpdate({
                        variables: {
                          id,
                          email,
                          name,
                          gender,
                          instagram,
                          country,
                          city,
                          category,
                          sponsors,
                          athleteOrPhotographer,
                          isBusiness,
                          website,
                          businessLogo,
                          businessRegion,
                          about,
                        },
                      });
                    }
                  }
                } else {
                  if (name === null || name.length === 0) {
                    this.setState({
                      validationError: "Please enter your Business Name",
                    });
                  } else if (
                    email === null ||
                    !this.validateEmail(email) ||
                    email.length === 0
                  ) {
                    this.setState({
                      validationError: "Please enter a valid Email Address",
                    });
                  } else if (about === null || about.length === 0) {
                    this.setState({
                      validationError: "Please add About You",
                    });
                  } else if (
                    age === undefined ||
                    age === null ||
                    age.length === 0
                  ) {
                    this.setState({
                      validationError: "Please select your Age",
                    });
                  } else if (instagram === null || instagram.length < 2) {
                    this.setState({
                      validationError: "Please add your Instagram Handle",
                    });
                  } else if (city === null || city.length === 0) {
                    this.setState({
                      validationError: "Please add your City",
                    });
                  } else if (country === null || country.length === 0) {
                    this.setState({
                      validationError: "Please select your Country",
                    });
                  } else if (category === null || category.length === 0) {
                    this.setState({
                      validationError: "Please select a Sport",
                    });
                  } else if (gender === null || gender.length === 0) {
                    this.setState({
                      validationError: "Please select a Gender",
                    });
                  } else if (
                    athleteOrPhotographer === null ||
                    athleteOrPhotographer.length === 0
                  ) {
                    this.setState({
                      validationError: "Please select a Group",
                    });
                  } else {
                    instagram = instagram.replace("@", "");
                    if (!loading && !data) {
                      userUpdate({
                        variables: {
                          id,
                          email,
                          name,
                          gender,
                          instagram,
                          country,
                          city,
                          category,
                          sponsors,
                          athleteOrPhotographer,
                          isBusiness,
                          website,
                          businessLogo,
                          businessRegion,
                          age,
                          about,
                        },
                      });
                    }
                  }
                }
              }}
            >
              <MDBInput
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                type="text"
                label={isBusiness ? "Business Name" : "Full Name"}
                style={inputText}
                required
                maxLength="80"
              />
              <div>
                <MDBInput
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  type="email"
                  label="Email"
                  style={inputText}
                  required
                  maxLength="80"
                />
                <div style={infoIcon}>
                  <MDBIcon
                    style={{ color: "#f8f8ff" }}
                    onClick={() => this.setState({ emailInfo: !emailInfo })}
                    icon="question-circle"
                  />
                </div>
                {emailInfo ? (
                  <div style={{ marginBottom: "2.5rem" }}>
                    <p style={infoText}>
                      Your email address will be used for brands to get in
                      contact with you regarding sponsorship / ambassador
                      opportunities.
                    </p>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              <div style={{ marginTop: "-1rem", marginBottom: "2rem" }}>
                <a style={{ padding: "0rem" }} href="/change_password">
                  <p style={labelText}>Change Password</p>
                </a>
              </div>
              <MDBInput
                value={about}
                onChange={(e) => this.setState({ about: e.target.value })}
                type="textarea"
                label="About You"
                style={inputText}
                required
                maxLength="150"
              />
              {!isBusiness && (
                <div style={{ marginTop: "-0.5rem", marginBottom: "2rem" }}>
                  <h5 style={labelText}>Select Your Age</h5>
                  <div style={ageContainer}>
                    <p
                      onClick={() => this.setState({ age: "6-9" })}
                      style={{
                        padding: "0.25rem",
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        fontFamily: "Oswald",
                        color: "#f8f8ff",
                        opacity: age === "" || age === "6-9" ? 1 : 0.5,
                      }}
                    >
                      6-9
                    </p>
                    <p
                      onClick={() => this.setState({ age: "10-12" })}
                      style={{
                        padding: "0.25rem",
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        fontFamily: "Oswald",
                        color: "#f8f8ff",
                        opacity: age === "" || age === "10-12" ? 1 : 0.5,
                      }}
                    >
                      10-12
                    </p>
                    <p
                      onClick={() => this.setState({ age: "13-17" })}
                      style={{
                        padding: "0.25rem",
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        fontFamily: "Oswald",
                        color: "#f8f8ff",
                        opacity: age === "" || age === "13-17" ? 1 : 0.5,
                      }}
                    >
                      13-17
                    </p>
                    <p
                      onClick={() => this.setState({ age: "18+" })}
                      style={{
                        padding: "0.25rem",
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        fontFamily: "Oswald",
                        color: "#f8f8ff",
                        opacity: age === "" || age === "18+" ? 1 : 0.5,
                      }}
                    >
                      18+
                    </p>
                  </div>
                </div>
              )}
              <div>
                <MDBInput
                  value={instagram}
                  onChange={(e) => this.setState({ instagram: e.target.value })}
                  type="text"
                  label="Instagram Handle"
                  style={inputText}
                  required
                  maxLength="80"
                />
                <div style={infoIcon}>
                  <MDBIcon
                    style={{ color: "#f8f8ff" }}
                    onClick={() =>
                      this.setState({ instagramInfo: !instagramInfo })
                    }
                    icon="question-circle"
                  />
                </div>
                {instagramInfo ? (
                  <div>
                    <p style={infoText}>
                      Your Instagram is used so Brands can check you out and get
                      a sense of who you are.
                    </p>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              <div>
                <MDBInput
                  value={city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                  type="text"
                  label="City*"
                  style={inputText}
                  required
                  maxLength="80"
                />
                <div style={infoIcon}>
                  <MDBIcon
                    style={{ color: "#f8f8ff" }}
                    onClick={() => this.setState({ cityInfo: !cityInfo })}
                    icon="question-circle"
                  />
                </div>
                {cityInfo ? (
                  <div>
                    <p style={infoText}>
                      Your location is used to discover local Discounts and
                      Athletes.
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <MDBSelect
                  label={country}
                  required
                  value={country}
                  getTextContent={this.handleSelectChangeCountry}
                >
                  <MDBSelectInput />
                  <MDBSelectOptions search>
                    <MDBSelectOption value="United Kingdom">
                      United Kingdom
                    </MDBSelectOption>
                    <MDBSelectOption value="United States of America">
                      United States of America
                    </MDBSelectOption>
                    <MDBSelectOption value="Australia">
                      Australia
                    </MDBSelectOption>
                    <MDBSelectOption value="France">France</MDBSelectOption>
                    <MDBSelectOption value="New Zealand">
                      New Zealand
                    </MDBSelectOption>
                    <MDBSelectOption value="South Africa">
                      South Africa
                    </MDBSelectOption>
                    <MDBSelectOption value="Spain">Spain</MDBSelectOption>
                    <MDBSelectOption value="Afganistan">
                      Afghanistan
                    </MDBSelectOption>
                    <MDBSelectOption value="Albania">Albania</MDBSelectOption>
                    <MDBSelectOption value="Algeria">Algeria</MDBSelectOption>
                    <MDBSelectOption value="American Samoa">
                      American Samoa
                    </MDBSelectOption>
                    <MDBSelectOption value="Andorra">Andorra</MDBSelectOption>
                    <MDBSelectOption value="Angola">Angola</MDBSelectOption>
                    <MDBSelectOption value="Anguilla">Anguilla</MDBSelectOption>
                    <MDBSelectOption value="Antigua & Barbuda">
                      Antigua & Barbuda
                    </MDBSelectOption>
                    <MDBSelectOption value="Argentina">
                      Argentina
                    </MDBSelectOption>
                    <MDBSelectOption value="Armenia">Armenia</MDBSelectOption>
                    <MDBSelectOption value="Aruba">Aruba</MDBSelectOption>
                    <MDBSelectOption value="Austria">Austria</MDBSelectOption>
                    <MDBSelectOption value="Azerbaijan">
                      Azerbaijan
                    </MDBSelectOption>
                    <MDBSelectOption value="Bahamas">Bahamas</MDBSelectOption>
                    <MDBSelectOption value="Bahrain">Bahrain</MDBSelectOption>
                    <MDBSelectOption value="Bangladesh">
                      Bangladesh
                    </MDBSelectOption>
                    <MDBSelectOption value="Barbados">Barbados</MDBSelectOption>
                    <MDBSelectOption value="Belarus">Belarus</MDBSelectOption>
                    <MDBSelectOption value="Belgium">Belgium</MDBSelectOption>
                    <MDBSelectOption value="Belize">Belize</MDBSelectOption>
                    <MDBSelectOption value="Benin">Benin</MDBSelectOption>
                    <MDBSelectOption value="Bermuda">Bermuda</MDBSelectOption>
                    <MDBSelectOption value="Bhutan">Bhutan</MDBSelectOption>
                    <MDBSelectOption value="Bolivia">Bolivia</MDBSelectOption>
                    <MDBSelectOption value="Bonaire">Bonaire</MDBSelectOption>
                    <MDBSelectOption value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </MDBSelectOption>
                    <MDBSelectOption value="Botswana">Botswana</MDBSelectOption>
                    <MDBSelectOption value="Brazil">Brazil</MDBSelectOption>
                    <MDBSelectOption value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </MDBSelectOption>
                    <MDBSelectOption value="Brunei">Brunei</MDBSelectOption>
                    <MDBSelectOption value="Bulgaria">Bulgaria</MDBSelectOption>
                    <MDBSelectOption value="Burkina Faso">
                      Burkina Faso
                    </MDBSelectOption>
                    <MDBSelectOption value="Burundi">Burundi</MDBSelectOption>
                    <MDBSelectOption value="Cambodia">Cambodia</MDBSelectOption>
                    <MDBSelectOption value="Cameroon">Cameroon</MDBSelectOption>
                    <MDBSelectOption value="Canada">Canada</MDBSelectOption>
                    <MDBSelectOption value="Canary Islands">
                      Canary Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Cape Verde">
                      Cape Verde
                    </MDBSelectOption>
                    <MDBSelectOption value="Cayman Islands">
                      Cayman Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Central African Republic">
                      Central African Republic
                    </MDBSelectOption>
                    <MDBSelectOption value="Chad">Chad</MDBSelectOption>
                    <MDBSelectOption value="Channel Islands">
                      Channel Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Chile">Chile</MDBSelectOption>
                    <MDBSelectOption value="China">China</MDBSelectOption>
                    <MDBSelectOption value="Christmas Island">
                      Christmas Island
                    </MDBSelectOption>
                    <MDBSelectOption value="Cocos Island">
                      Cocos Island
                    </MDBSelectOption>
                    <MDBSelectOption value="Colombia">Colombia</MDBSelectOption>
                    <MDBSelectOption value="Comoros">Comoros</MDBSelectOption>
                    <MDBSelectOption value="Congo">Congo</MDBSelectOption>
                    <MDBSelectOption value="Cook Islands">
                      Cook Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Costa Rica">
                      Costa Rica
                    </MDBSelectOption>
                    <MDBSelectOption value="Cote DIvoire">
                      Cote DIvoire
                    </MDBSelectOption>
                    <MDBSelectOption value="Croatia">Croatia</MDBSelectOption>
                    <MDBSelectOption value="Cuba">Cuba</MDBSelectOption>
                    <MDBSelectOption value="Curaco">Curacao</MDBSelectOption>
                    <MDBSelectOption value="Cyprus">Cyprus</MDBSelectOption>
                    <MDBSelectOption value="Czech Republic">
                      Czech Republic
                    </MDBSelectOption>
                    <MDBSelectOption value="Denmark">Denmark</MDBSelectOption>
                    <MDBSelectOption value="Djibouti">Djibouti</MDBSelectOption>
                    <MDBSelectOption value="Dominica">Dominica</MDBSelectOption>
                    <MDBSelectOption value="Dominican Republic">
                      Dominican Republic
                    </MDBSelectOption>
                    <MDBSelectOption value="East Timor">
                      East Timor
                    </MDBSelectOption>
                    <MDBSelectOption value="Ecuador">Ecuador</MDBSelectOption>
                    <MDBSelectOption value="Egypt">Egypt</MDBSelectOption>
                    <MDBSelectOption value="El Salvador">
                      El Salvador
                    </MDBSelectOption>
                    <MDBSelectOption value="Equatorial Guinea">
                      Equatorial Guinea
                    </MDBSelectOption>
                    <MDBSelectOption value="Eritrea">Eritrea</MDBSelectOption>
                    <MDBSelectOption value="Estonia">Estonia</MDBSelectOption>
                    <MDBSelectOption value="Ethiopia">Ethiopia</MDBSelectOption>
                    <MDBSelectOption value="Falkland Islands">
                      Falkland Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Faroe Islands">
                      Faroe Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Fiji">Fiji</MDBSelectOption>
                    <MDBSelectOption value="Finland">Finland</MDBSelectOption>
                    <MDBSelectOption value="French Guiana">
                      French Guiana
                    </MDBSelectOption>
                    <MDBSelectOption value="French Polynesia">
                      French Polynesia
                    </MDBSelectOption>
                    <MDBSelectOption value="French Southern Ter">
                      French Southern Ter
                    </MDBSelectOption>
                    <MDBSelectOption value="Gabon">Gabon</MDBSelectOption>
                    <MDBSelectOption value="Gambia">Gambia</MDBSelectOption>
                    <MDBSelectOption value="Georgia">Georgia</MDBSelectOption>
                    <MDBSelectOption value="Germany">Germany</MDBSelectOption>
                    <MDBSelectOption value="Ghana">Ghana</MDBSelectOption>
                    <MDBSelectOption value="Gibraltar">
                      Gibraltar
                    </MDBSelectOption>
                    <MDBSelectOption value="Greece">Greece</MDBSelectOption>
                    <MDBSelectOption value="Greenland">
                      Greenland
                    </MDBSelectOption>
                    <MDBSelectOption value="Grenada">Grenada</MDBSelectOption>
                    <MDBSelectOption value="Guadeloupe">
                      Guadeloupe
                    </MDBSelectOption>
                    <MDBSelectOption value="Guam">Guam</MDBSelectOption>
                    <MDBSelectOption value="Guatemala">
                      Guatemala
                    </MDBSelectOption>
                    <MDBSelectOption value="Guinea">Guinea</MDBSelectOption>
                    <MDBSelectOption value="Guyana">Guyana</MDBSelectOption>
                    <MDBSelectOption value="Haiti">Haiti</MDBSelectOption>
                    <MDBSelectOption value="Hawaii">Hawaii</MDBSelectOption>
                    <MDBSelectOption value="Honduras">Honduras</MDBSelectOption>
                    <MDBSelectOption value="Hong Kong">
                      Hong Kong
                    </MDBSelectOption>
                    <MDBSelectOption value="Hungary">Hungary</MDBSelectOption>
                    <MDBSelectOption value="Iceland">Iceland</MDBSelectOption>
                    <MDBSelectOption value="Indonesia">
                      Indonesia
                    </MDBSelectOption>
                    <MDBSelectOption value="India">India</MDBSelectOption>
                    <MDBSelectOption value="Iran">Iran</MDBSelectOption>
                    <MDBSelectOption value="Iraq">Iraq</MDBSelectOption>
                    <MDBSelectOption value="Ireland">Ireland</MDBSelectOption>
                    <MDBSelectOption value="Isle of Man">
                      Isle of Man
                    </MDBSelectOption>
                    <MDBSelectOption value="Israel">Israel</MDBSelectOption>
                    <MDBSelectOption value="Italy">Italy</MDBSelectOption>
                    <MDBSelectOption value="Jamaica">Jamaica</MDBSelectOption>
                    <MDBSelectOption value="Japan">Japan</MDBSelectOption>
                    <MDBSelectOption value="Jordan">Jordan</MDBSelectOption>
                    <MDBSelectOption value="Kazakhstan">
                      Kazakhstan
                    </MDBSelectOption>
                    <MDBSelectOption value="Kenya">Kenya</MDBSelectOption>
                    <MDBSelectOption value="Kiribati">Kiribati</MDBSelectOption>
                    <MDBSelectOption value="Korea North">
                      Korea North
                    </MDBSelectOption>
                    <MDBSelectOption value="Korea Sout">
                      Korea South
                    </MDBSelectOption>
                    <MDBSelectOption value="Kuwait">Kuwait</MDBSelectOption>
                    <MDBSelectOption value="Kyrgyzstan">
                      Kyrgyzstan
                    </MDBSelectOption>
                    <MDBSelectOption value="Laos">Laos</MDBSelectOption>
                    <MDBSelectOption value="Latvia">Latvia</MDBSelectOption>
                    <MDBSelectOption value="Lebanon">Lebanon</MDBSelectOption>
                    <MDBSelectOption value="Lesotho">Lesotho</MDBSelectOption>
                    <MDBSelectOption value="Liberia">Liberia</MDBSelectOption>
                    <MDBSelectOption value="Libya">Libya</MDBSelectOption>
                    <MDBSelectOption value="Liechtenstein">
                      Liechtenstein
                    </MDBSelectOption>
                    <MDBSelectOption value="Lithuania">
                      Lithuania
                    </MDBSelectOption>
                    <MDBSelectOption value="Luxembourg">
                      Luxembourg
                    </MDBSelectOption>
                    <MDBSelectOption value="Macau">Macau</MDBSelectOption>
                    <MDBSelectOption value="Macedonia">
                      Macedonia
                    </MDBSelectOption>
                    <MDBSelectOption value="Madagascar">
                      Madagascar
                    </MDBSelectOption>
                    <MDBSelectOption value="Malaysia">Malaysia</MDBSelectOption>
                    <MDBSelectOption value="Malawi">Malawi</MDBSelectOption>
                    <MDBSelectOption value="Maldives">Maldives</MDBSelectOption>
                    <MDBSelectOption value="Mali">Mali</MDBSelectOption>
                    <MDBSelectOption value="Malta">Malta</MDBSelectOption>
                    <MDBSelectOption value="Marshall Islands">
                      Marshall Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Martinique">
                      Martinique
                    </MDBSelectOption>
                    <MDBSelectOption value="Mauritania">
                      Mauritania
                    </MDBSelectOption>
                    <MDBSelectOption value="Mauritius">
                      Mauritius
                    </MDBSelectOption>
                    <MDBSelectOption value="Mayotte">Mayotte</MDBSelectOption>
                    <MDBSelectOption value="Mexico">Mexico</MDBSelectOption>
                    <MDBSelectOption value="Midway Islands">
                      Midway Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Moldova">Moldova</MDBSelectOption>
                    <MDBSelectOption value="Monaco">Monaco</MDBSelectOption>
                    <MDBSelectOption value="Mongolia">Mongolia</MDBSelectOption>
                    <MDBSelectOption value="Montserrat">
                      Montserrat
                    </MDBSelectOption>
                    <MDBSelectOption value="Morocco">Morocco</MDBSelectOption>
                    <MDBSelectOption value="Mozambique">
                      Mozambique
                    </MDBSelectOption>
                    <MDBSelectOption value="Myanmar">Myanmar</MDBSelectOption>
                    <MDBSelectOption value="Nambia">Nambia</MDBSelectOption>
                    <MDBSelectOption value="Nauru">Nauru</MDBSelectOption>
                    <MDBSelectOption value="Nepal">Nepal</MDBSelectOption>
                    <MDBSelectOption value="Netherland Antilles">
                      Netherland Antilles
                    </MDBSelectOption>
                    <MDBSelectOption value="Netherlands">
                      Netherlands (Holland, Europe)
                    </MDBSelectOption>
                    <MDBSelectOption value="Nevis">Nevis</MDBSelectOption>
                    <MDBSelectOption value="New Caledonia">
                      New Caledonia
                    </MDBSelectOption>
                    <MDBSelectOption value="Nicaragua">
                      Nicaragua
                    </MDBSelectOption>
                    <MDBSelectOption value="Niger">Niger</MDBSelectOption>
                    <MDBSelectOption value="Nigeria">Nigeria</MDBSelectOption>
                    <MDBSelectOption value="Niue">Niue</MDBSelectOption>
                    <MDBSelectOption value="Norfolk Island">
                      Norfolk Island
                    </MDBSelectOption>
                    <MDBSelectOption value="Norway">Norway</MDBSelectOption>
                    <MDBSelectOption value="Oman">Oman</MDBSelectOption>
                    <MDBSelectOption value="Pakistan">Pakistan</MDBSelectOption>
                    <MDBSelectOption value="Palau Island">
                      Palau Island
                    </MDBSelectOption>
                    <MDBSelectOption value="Palestine">
                      Palestine
                    </MDBSelectOption>
                    <MDBSelectOption value="Panama">Panama</MDBSelectOption>
                    <MDBSelectOption value="Papua New Guinea">
                      Papua New Guinea
                    </MDBSelectOption>
                    <MDBSelectOption value="Paraguay">Paraguay</MDBSelectOption>
                    <MDBSelectOption value="Peru">Peru</MDBSelectOption>
                    <MDBSelectOption value="Phillipines">
                      Philippines
                    </MDBSelectOption>
                    <MDBSelectOption value="Pitcairn Island">
                      Pitcairn Island
                    </MDBSelectOption>
                    <MDBSelectOption value="Poland">Poland</MDBSelectOption>
                    <MDBSelectOption value="Portugal">Portugal</MDBSelectOption>
                    <MDBSelectOption value="Puerto Rico">
                      Puerto Rico
                    </MDBSelectOption>
                    <MDBSelectOption value="Qatar">Qatar</MDBSelectOption>
                    <MDBSelectOption value="Republic of Montenegro">
                      Republic of Montenegro
                    </MDBSelectOption>
                    <MDBSelectOption value="Republic of Serbia">
                      Republic of Serbia
                    </MDBSelectOption>
                    <MDBSelectOption value="Reunion">Reunion</MDBSelectOption>
                    <MDBSelectOption value="Romania">Romania</MDBSelectOption>
                    <MDBSelectOption value="Russia">Russia</MDBSelectOption>
                    <MDBSelectOption value="Rwanda">Rwanda</MDBSelectOption>
                    <MDBSelectOption value="St Barthelemy">
                      St Barthelemy
                    </MDBSelectOption>
                    <MDBSelectOption value="St Eustatius">
                      St Eustatius
                    </MDBSelectOption>
                    <MDBSelectOption value="St Helena">
                      St Helena
                    </MDBSelectOption>
                    <MDBSelectOption value="St Kitts-Nevis">
                      St Kitts-Nevis
                    </MDBSelectOption>
                    <MDBSelectOption value="St Lucia">St Lucia</MDBSelectOption>
                    <MDBSelectOption value="St Maarten">
                      St Maarten
                    </MDBSelectOption>
                    <MDBSelectOption value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </MDBSelectOption>
                    <MDBSelectOption value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </MDBSelectOption>
                    <MDBSelectOption value="Saipan">Saipan</MDBSelectOption>
                    <MDBSelectOption value="Samoa">Samoa</MDBSelectOption>
                    <MDBSelectOption value="Samoa American">
                      Samoa American
                    </MDBSelectOption>
                    <MDBSelectOption value="San Marino">
                      San Marino
                    </MDBSelectOption>
                    <MDBSelectOption value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </MDBSelectOption>
                    <MDBSelectOption value="Saudi Arabia">
                      Saudi Arabia
                    </MDBSelectOption>
                    <MDBSelectOption value="Senegal">Senegal</MDBSelectOption>
                    <MDBSelectOption value="Seychelles">
                      Seychelles
                    </MDBSelectOption>
                    <MDBSelectOption value="Sierra Leone">
                      Sierra Leone
                    </MDBSelectOption>
                    <MDBSelectOption value="Singapore">
                      Singapore
                    </MDBSelectOption>
                    <MDBSelectOption value="Slovakia">Slovakia</MDBSelectOption>
                    <MDBSelectOption value="Slovenia">Slovenia</MDBSelectOption>
                    <MDBSelectOption value="Solomon Islands">
                      Solomon Islands
                    </MDBSelectOption>
                    <MDBSelectOption value="Somalia">Somalia</MDBSelectOption>
                    <MDBSelectOption value="Sri Lanka">
                      Sri Lanka
                    </MDBSelectOption>
                    <MDBSelectOption value="Sudan">Sudan</MDBSelectOption>
                    <MDBSelectOption value="Suriname">Suriname</MDBSelectOption>
                    <MDBSelectOption value="Swaziland">
                      Swaziland
                    </MDBSelectOption>
                    <MDBSelectOption value="Sweden">Sweden</MDBSelectOption>
                    <MDBSelectOption value="Switzerland">
                      Switzerland
                    </MDBSelectOption>
                    <MDBSelectOption value="Syria">Syria</MDBSelectOption>
                    <MDBSelectOption value="Tahiti">Tahiti</MDBSelectOption>
                    <MDBSelectOption value="Taiwan">Taiwan</MDBSelectOption>
                    <MDBSelectOption value="Tajikistan">
                      Tajikistan
                    </MDBSelectOption>
                    <MDBSelectOption value="Tanzania">Tanzania</MDBSelectOption>
                    <MDBSelectOption value="Thailand">Thailand</MDBSelectOption>
                    <MDBSelectOption value="Togo">Togo</MDBSelectOption>
                    <MDBSelectOption value="Tokelau">Tokelau</MDBSelectOption>
                    <MDBSelectOption value="Tonga">Tonga</MDBSelectOption>
                    <MDBSelectOption value="Trinidad & Tobago">
                      Trinidad & Tobago
                    </MDBSelectOption>
                    <MDBSelectOption value="Tunisia">Tunisia</MDBSelectOption>
                    <MDBSelectOption value="Turkey">Turkey</MDBSelectOption>
                    <MDBSelectOption value="Turkmenistan">
                      Turkmenistan
                    </MDBSelectOption>
                    <MDBSelectOption value="Turks & Caicos Is">
                      Turks & Caicos Is
                    </MDBSelectOption>
                    <MDBSelectOption value="Tuvalu">Tuvalu</MDBSelectOption>
                    <MDBSelectOption value="Uganda">Uganda</MDBSelectOption>
                    <MDBSelectOption value="Ukraine">Ukraine</MDBSelectOption>
                    <MDBSelectOption value="United Arab Erimates">
                      United Arab Emirates
                    </MDBSelectOption>
                    <MDBSelectOption value="Uraguay">Uruguay</MDBSelectOption>
                    <MDBSelectOption value="Uzbekistan">
                      Uzbekistan
                    </MDBSelectOption>
                    <MDBSelectOption value="Vanuatu">Vanuatu</MDBSelectOption>
                    <MDBSelectOption value="Vatican City State">
                      Vatican City State
                    </MDBSelectOption>
                    <MDBSelectOption value="Venezuela">
                      Venezuela
                    </MDBSelectOption>
                    <MDBSelectOption value="Vietnam">Vietnam</MDBSelectOption>
                    <MDBSelectOption value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </MDBSelectOption>
                    <MDBSelectOption value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </MDBSelectOption>
                    <MDBSelectOption value="Wake Island">
                      Wake Island
                    </MDBSelectOption>
                    <MDBSelectOption value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </MDBSelectOption>
                    <MDBSelectOption value="Yemen">Yemen</MDBSelectOption>
                    <MDBSelectOption value="Zaire">Zaire</MDBSelectOption>
                    <MDBSelectOption value="Zambia">Zambia</MDBSelectOption>
                    <MDBSelectOption value="Zimbabwe">Zimbabwe</MDBSelectOption>
                  </MDBSelectOptions>
                </MDBSelect>
              </div>
              <div style={{ marginTop: "-0.5rem", marginBottom: "1rem" }}>
                <MDBInput
                  label="Are you a Business?"
                  type="checkbox"
                  id="checkbox"
                  checked={isBusiness}
                  value={isBusiness}
                  onChange={(e) => this.setState({ isBusiness: !isBusiness })}
                />
              </div>
              <div>
                <h5 style={labelText}>Select Your Sports</h5>
                <MDBRow className="mt-3" style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Surf" ||
                          category[1] === "Surf" ||
                          category[2] === "Surf" ||
                          category[3] === "Surf" ||
                          category[4] === "Surf" ||
                          category[5] === "Surf" ||
                          category[6] === "Surf" ||
                          category[7] === "Surf" ||
                          category[8] === "Surf" ||
                          category[9] === "Surf"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addSurf) {
                          this.setState({ category: [...category, "Surf"] });
                          this.setState({ addSurf: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Surf") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addSurf: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Surf</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/surf.jpg"
                        alt="Surf"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Skate" ||
                          category[1] === "Skate" ||
                          category[2] === "Skate" ||
                          category[3] === "Skate" ||
                          category[4] === "Skate" ||
                          category[5] === "Skate" ||
                          category[6] === "Skate" ||
                          category[7] === "Skate" ||
                          category[8] === "Skate" ||
                          category[9] === "Skate"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addSkate) {
                          this.setState({ category: [...category, "Skate"] });
                          this.setState({ addSkate: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Skate") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addSkate: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Skate</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/skate.jpeg"
                        alt="Skate"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Snowboard" ||
                          category[1] === "Snowboard" ||
                          category[2] === "Snowboard" ||
                          category[3] === "Snowboard" ||
                          category[4] === "Snowboard" ||
                          category[5] === "Snowboard" ||
                          category[6] === "Snowboard" ||
                          category[7] === "Snowboard" ||
                          category[8] === "Snowboard" ||
                          category[9] === "Snowboard"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addSnowboard) {
                          this.setState({
                            category: [...category, "Snowboard"],
                          });
                          this.setState({ addSnowboard: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Snowboard") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addSnowboard: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Snowboard</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/snowboard.jpg"
                        alt="Snowboard"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-3" style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Ski" ||
                          category[1] === "Ski" ||
                          category[2] === "Ski" ||
                          category[3] === "Ski" ||
                          category[4] === "Ski" ||
                          category[5] === "Ski" ||
                          category[6] === "Ski" ||
                          category[7] === "Ski" ||
                          category[8] === "Ski" ||
                          category[9] === "Ski"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addSki) {
                          this.setState({ category: [...category, "Ski"] });
                          this.setState({ addSki: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Ski") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addSki: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Ski</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/ski.jpg"
                        alt="Ski"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "BMX" ||
                          category[1] === "BMX" ||
                          category[2] === "BMX" ||
                          category[3] === "BMX" ||
                          category[4] === "BMX" ||
                          category[5] === "BMX" ||
                          category[6] === "BMX" ||
                          category[7] === "BMX" ||
                          category[8] === "BMX" ||
                          category[9] === "BMX"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addBMX) {
                          this.setState({ category: [...category, "BMX"] });
                          this.setState({ addBMX: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "BMX") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addBMX: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>BMX</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/bmx.jpg"
                        alt="BMX"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "MTB" ||
                          category[1] === "MTB" ||
                          category[2] === "MTB" ||
                          category[3] === "MTB" ||
                          category[4] === "MTB" ||
                          category[5] === "MTB" ||
                          category[6] === "MTB" ||
                          category[7] === "MTB" ||
                          category[8] === "MTB" ||
                          category[9] === "MTB"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addMTB) {
                          this.setState({ category: [...category, "MTB"] });
                          this.setState({ addMTB: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "MTB") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addMTB: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>MTB</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/mtb.jpeg"
                        alt="MTB"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3" style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Scooter" ||
                          category[1] === "Scooter" ||
                          category[2] === "Scooter" ||
                          category[3] === "Scooter" ||
                          category[4] === "Scooter" ||
                          category[5] === "Scooter" ||
                          category[6] === "Scooter" ||
                          category[7] === "Scooter" ||
                          category[8] === "Scooter" ||
                          category[9] === "Scooter"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addScooter) {
                          this.setState({
                            category: [...category, "Scooter"],
                          });
                          this.setState({ addScooter: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Scooter") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addScooter: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Scooter</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/scooter.jpeg"
                        alt="Scooter"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Climbing" ||
                          category[1] === "Climbing" ||
                          category[2] === "Climbing" ||
                          category[3] === "Climbing" ||
                          category[4] === "Climbing" ||
                          category[5] === "Climbing" ||
                          category[6] === "Climbing" ||
                          category[7] === "Climbing" ||
                          category[8] === "Climbing" ||
                          category[9] === "Climbing"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addClimbing) {
                          this.setState({
                            category: [...category, "Climbing"],
                          });
                          this.setState({ addClimbing: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Climbing") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addClimbing: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Climbing</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/climbing.jpeg"
                        alt="Climbing"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className="col-4">
                    <div
                      style={{
                        border: "1px solid #202020",
                        borderRadius: "0.25rem",
                        textAlign: "center",
                        padding: "0.25rem",
                        opacity:
                          category.length === 0 ||
                          category[0] === "Other" ||
                          category[1] === "Other" ||
                          category[2] === "Other" ||
                          category[3] === "Other" ||
                          category[4] === "Other" ||
                          category[5] === "Other" ||
                          category[6] === "Other" ||
                          category[7] === "Other" ||
                          category[8] === "Other" ||
                          category[9] === "Other"
                            ? 1
                            : 0.5,
                      }}
                      onClick={() => {
                        if (category.length === 0 || addOther) {
                          this.setState({ category: [...category, "Other"] });
                          this.setState({ addOther: false });
                        } else {
                          for (var i = 0; i < category.length; i++) {
                            if (category[i] === "Other") {
                              category.splice(i, 1);
                              i--;
                              this.setState({ category: category });
                              this.setState({ addOther: true });
                            }
                          }
                        }
                      }}
                    >
                      <h5 style={categoryTitle}>Other</h5>
                      <img
                        style={categoryImg}
                        src="/Sport_Icons/Other.jpg"
                        alt="Other"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3" style={categoryRow}>
                  {isBusiness ? (
                    <MDBCol lg="3" md="3" sm="4" className="col-4">
                      <div
                        style={{
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          textAlign: "center",
                          padding: "0.25rem",
                          opacity:
                            category.length === 0 ||
                            category[0] === "Apparel" ||
                            category[1] === "Apparel" ||
                            category[2] === "Apparel" ||
                            category[3] === "Apparel" ||
                            category[4] === "Apparel" ||
                            category[5] === "Apparel" ||
                            category[6] === "Apparel" ||
                            category[7] === "Apparel" ||
                            category[8] === "Apparel" ||
                            category[9] === "Apparel"
                              ? 1
                              : 0.5,
                        }}
                        onClick={() => {
                          if (category.length === 0 || addApparel) {
                            this.setState({
                              category: [...category, "Apparel"],
                            });
                            this.setState({ addApparel: false });
                          } else {
                            for (var i = 0; i < category.length; i++) {
                              if (category[i] === "Apparel") {
                                category.splice(i, 1);
                                i--;
                                this.setState({ category: category });
                                this.setState({ addApparel: true });
                              }
                            }
                          }
                        }}
                      >
                        <h5 style={categoryTitle}>Apparel</h5>
                        <img
                          style={categoryImg}
                          src="/Sport_Icons/apparelCategory.jpg"
                          alt="Apparel"
                        />
                      </div>
                    </MDBCol>
                  ) : (
                    <></>
                  )}
                </MDBRow>
                {!addOther ? (
                  <p
                    style={{
                      color: "#f8f8ff",
                      fontFamily: "Oswald",
                      textAlign: "center",
                    }}
                  >
                    As demand for specific Sports increase, they will be added
                    as a category.
                  </p>
                ) : (
                  <></>
                )}
              </div>
              {!isBusiness ? (
                <div>
                  <div style={{ marginTop: "2rem" }}>
                    <h5 style={labelText}>Select Your Gender</h5>
                    <div style={genderContainer}>
                      <p
                        onClick={() => this.setState({ gender: "Male" })}
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity: gender === "" || gender === "Male" ? 1 : 0.5,
                        }}
                      >
                        Male
                      </p>
                      <p
                        onClick={() => this.setState({ gender: "Female" })}
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            gender === "" || gender === "Female" ? 1 : 0.5,
                        }}
                      >
                        Female
                      </p>
                      <p
                        onClick={() => this.setState({ gender: "Other" })}
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            gender === "" || gender === "Other" ? 1 : 0.5,
                        }}
                      >
                        Other
                      </p>
                      <p
                        onClick={() =>
                          this.setState({ gender: "Prefer Not To Say" })
                        }
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            gender === "" || gender === "Prefer Not To Say"
                              ? 1
                              : 0.5,
                        }}
                      >
                        Prefer Not to Say
                      </p>
                    </div>
                  </div>
                  <MDBInput
                    value={sponsors}
                    onChange={(e) =>
                      this.setState({ sponsors: e.target.value })
                    }
                    type="text"
                    label="Sponsors"
                    style={inputText}
                    maxLength="80"
                  />
                  <div style={{ marginBottom: "1rem" }}>
                    <h5 style={labelText}>Select Your Group</h5>
                    <div style={groupContainer}>
                      <p
                        onClick={() =>
                          this.setState({ athleteOrPhotographer: "Athlete" })
                        }
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            athleteOrPhotographer === "" ||
                            athleteOrPhotographer === "Athlete"
                              ? 1
                              : 0.5,
                        }}
                      >
                        Athlete
                      </p>
                      <p
                        onClick={() =>
                          this.setState({
                            athleteOrPhotographer: "Photographer/Videographer",
                          })
                        }
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            athleteOrPhotographer === "" ||
                            athleteOrPhotographer ===
                              "Photographer/Videographer"
                              ? 1
                              : 0.5,
                        }}
                      >
                        Photographer/Videographer
                      </p>
                      <p
                        onClick={() =>
                          this.setState({
                            athleteOrPhotographer: "Spectator",
                          })
                        }
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            athleteOrPhotographer === "" ||
                            athleteOrPhotographer === "Spectator"
                              ? 1
                              : 0.5,
                        }}
                      >
                        Spectator
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{ marginTop: "2rem" }}>
                    <p style={websiteText}>
                      Please have your Website in the format https://
                    </p>
                    <MDBInput
                      value={website}
                      onChange={(e) =>
                        this.setState({ website: e.target.value })
                      }
                      type="text"
                      placeholder="https://www.thesponsorhub.com"
                      label="Website link"
                      style={inputText}
                      maxLength="120"
                    />
                  </div>
                  <div style={{ marginTop: "-0.5rem" }}>
                    <MDBInput
                      label="Is your Business a Cafe or Campsite?"
                      type="checkbox"
                      id="checkbox1"
                      value={businessIsBricks}
                      onChange={(e) =>
                        this.setState({ businessIsBricks: !businessIsBricks })
                      }
                    />
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <h5 style={labelText}>Select Your Business Region</h5>
                    <div style={businessRegionContainer}>
                      <p
                        onClick={() =>
                          this.setState({ businessRegion: "National" })
                        }
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            businessRegion === "" ||
                            businessRegion === "National"
                              ? 1
                              : 0.5,
                        }}
                      >
                        National
                      </p>
                      <p
                        onClick={() =>
                          this.setState({ businessRegion: "International" })
                        }
                        style={{
                          padding: "0.25rem",
                          border: "1px solid #202020",
                          borderRadius: "0.25rem",
                          fontFamily: "Oswald",
                          color: "#f8f8ff",
                          opacity:
                            businessRegion === "" ||
                            businessRegion === "International"
                              ? 1
                              : 0.5,
                        }}
                      >
                        International
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={{ marginTop: "1rem" }}>
                      <MDBFileInput
                        btnColor="black"
                        btnTitle="Upload Logo"
                        value={file}
                        type="file"
                        getValue={this.fileChangeHandler}
                      />
                    </div>
                    {fileError !== "" ? (
                      <div style={{ textAlign: "center" }}>
                        <p style={fileErrorText}>{fileError}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Mutation mutation={UPLOAD_FILE_STREAM}>
                      {(businessLogoUpload, { data, loading, error }) => {
                        return (
                          <div
                            style={{ marginTop: "10px" }}
                            className="text-center"
                          >
                            {loading && (
                              <div>
                                <p style={loadingText}>
                                  Your Logo is being uploaded to the cloud ...
                                </p>
                                <Loader size={8} color="white" />
                              </div>
                            )}
                            {error && (
                              <p style={errorText}>
                                {error.message ===
                                "Network error: NetworkError when attempting to fetch resource."
                                  ? "Connection Timed Out: Please Try Again"
                                  : error.message.includes("jwt expired")
                                  ? "Token Expired. Please Login again"
                                  : error.message.replace(
                                      "GraphQL error: ",
                                      ""
                                    )}
                              </p>
                            )}
                            {file !== "" && !disableUpload ? (
                              <MDBBtn
                                color="white"
                                style={uploadButton}
                                rounded
                                onClick={() => {
                                  const { file } = this.state;
                                  const mimetype = file.type;
                                  var filename = file.name;
                                  filename = filename.replace(
                                    /[,\s]+|[,\s]+/g,
                                    ""
                                  );
                                  if (file.size > 50000000) {
                                    this.setState({
                                      fileError:
                                        "File too big, please upload a file of size less than 50Mb",
                                    });
                                  } else if (
                                    (mimetype === "image/jpeg" ||
                                      mimetype === "image/png") &&
                                    !loading &&
                                    !data
                                  ) {
                                    if (mimetype === "image/png") {
                                      var tmpFilename = filename.substr(
                                        0,
                                        filename.lastIndexOf(".")
                                      );
                                      filename = tmpFilename + ".jpg";
                                    }
                                    this.setState({ businessLogo: filename });
                                    this.setState({ fileError: "" });
                                    businessLogoUpload({
                                      variables: { file: file },
                                    });
                                    this.setState({
                                      disableUpload: !disableUpload,
                                    });
                                  } else {
                                    this.setState({
                                      fileError:
                                        "Image type not recognised, please upload file of type png or jpg",
                                    });
                                  }
                                }}
                              >
                                {!loading
                                  ? "Upload New Logo"
                                  : "Uploading New Logo"}
                              </MDBBtn>
                            ) : (
                              <></>
                            )}
                            {data && (
                              <div
                                style={{ marginTop: "10px" }}
                                className="text-center"
                              >
                                <h5 style={uploadedText}>
                                  Logo Uploaded{" "}
                                  <span role="img" aria-labelledby="shaka">
                                    🤙
                                  </span>
                                </h5>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Mutation>
                  </div>
                </div>
              )}
              <div className="text-center pt-2">
                {error && (
                  <p style={errorText}>
                    {error.message.includes("jwt expired")
                      ? "Token Expired. Please Login again"
                      : error.message.replace("GraphQL error: ", "")}
                  </p>
                )}
                {validationError !== "" && (
                  <p style={errorText}>{validationError}</p>
                )}
                <div className="flex justify-content-center">
                  <MDBBtn
                    style={updateProfileButton}
                    color="white"
                    rounded
                    type="submit"
                  >
                    {!loading ? "Update Profile" : "Updating Profile"}
                  </MDBBtn>
                </div>
              </div>
              <div className="text-center pt-4">
                <div className="flex justify-content-center">
                  <h4
                    onClick={() =>
                      this.setState({ deleteAccount: !deleteAccount })
                    }
                    style={labelText}
                  >
                    Do you want to Delete your account?
                  </h4>
                </div>
              </div>
              {deleteAccount ? (
                <div className="text-center pt-2">
                  <div className="flex justify-content-center">
                    <Mutation
                      mutation={DELETE_ACCOUNT_MUTATION}
                      onCompleted={() => {
                        localStorage.removeItem(AUTH_TOKEN);
                        window.location.href = `/`;
                      }}
                    >
                      {(deleteAccountMutation, { loading, data }) => (
                        <MDBBtn
                          style={deleteProfileButton}
                          onClick={() => {
                            if (!loading && !data) {
                              deleteAccountMutation();
                            }
                          }}
                          color="red"
                          rounded
                        >
                          {!loading ? "Delete Account" : "Deleting Account"}
                        </MDBBtn>
                      )}
                    </Mutation>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </form>
          )}
        </Mutation>
      </div>
    );
  }
}

class Settings extends Component {
  state = {
    id: this.props.user.id,
    marketingOptIn: this.props.user.marketingOptIn,
    websiteCookies: this.props.user.websiteCookies,
    appCookies: this.props.user.appCookies,
    age: this.props.user.age,
    informationOptIn: this.props.user.informationOptIn,
  };

  render() {
    const { id, marketingOptIn, websiteCookies, appCookies, age } = this.state;
    var { informationOptIn } = this.state;
    if (informationOptIn === null) informationOptIn = true;
    var PrivacyPolicy = "privacy_policy_" + age;
    return (
      <div>
        <div>
          <Mutation mutation={UPDATE_USER_SETTINGS_MUTATION}>
            {(userSettingsUpdate, { loading }) => (
              <div>
                <p style={settingsText}>
                  Want to opt in to our Information Emails / Notifications which
                  provide insights and tips to improve your experience with the
                  platform.
                </p>
                <div style={{ marginTop: 10 }}>
                  <MDBInput
                    label="Opt Into Information Emails / Notifications?"
                    type="checkbox"
                    id="checkboxSettings"
                    checked={informationOptIn}
                    value={informationOptIn}
                    onChange={(e) => {
                      if (!loading) {
                        this.setState({ informationOptIn: !informationOptIn });
                        userSettingsUpdate({
                          variables: {
                            id,
                            informationOptIn: !informationOptIn,
                          },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </Mutation>
        </div>
        <div>
          <Mutation mutation={UPDATE_USER_SETTINGS_MUTATION}>
            {(userSettingsUpdate, { loading }) => (
              <div>
                <p style={settingsText}>
                  Want to opt in to our marketing to recieve Discounts and Edits
                  through Email?
                </p>
                {age === "6-9" || age === "10-12" ? (
                  <div style={{ marginTop: 5 }}>
                    <p style={settingsInfoText}>
                      As you are younger than 13, you will need Parental Consent
                      to opt into our marketing.
                    </p>
                    <p style={settingsInfoText}>
                      Please check with a Parent or Guardian before opting in.
                    </p>
                    <p style={settingsInfoText}>
                      More information regarding our marketing can be found in
                      our{" "}
                      <a
                        style={{ padding: 0, display: "inline-flex" }}
                        href="/terms_and_conditions"
                      >
                        <p style={settingsInfoText}>Terms and Conditions</p>
                      </a>{" "}
                      and{" "}
                      <a
                        style={{ padding: 0, display: "inline-flex" }}
                        href={PrivacyPolicy}
                      >
                        <p style={settingsInfoText}>Privacy Policy</p>
                      </a>
                      .
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ marginTop: 10 }}>
                  <MDBInput
                    label="Opt Into Marketing?"
                    type="checkbox"
                    id="checkboxSettings1"
                    checked={marketingOptIn}
                    value={marketingOptIn}
                    onChange={(e) => {
                      if (!loading) {
                        this.setState({ marketingOptIn: !marketingOptIn });
                        userSettingsUpdate({
                          variables: { id, marketingOptIn: !marketingOptIn },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </Mutation>
        </div>
        {age !== "6-9" && age !== "10-12" ? (
          <>
            <div>
              <Mutation mutation={UPDATE_USER_SETTINGS_MUTATION}>
                {(userSettingsUpdate, { loading }) => (
                  <div style={{ marginTop: 5 }}>
                    <p style={settingsText}>
                      Cookies help us customize and improve your experience.
                      Allow Cookies to be used. For further details, see our{" "}
                      <a
                        style={{ padding: 0, display: "inline-flex" }}
                        href="cookie_policy"
                      >
                        <p style={labelText}>Cookie Policy</p>
                      </a>
                      .
                    </p>
                    <MDBInput
                      label="Opt Into App Cookies?"
                      type="checkbox"
                      id="checkboxSettings2"
                      checked={appCookies}
                      value={appCookies}
                      onChange={(e) => {
                        if (!loading) {
                          this.setState({ appCookies: !appCookies });
                          userSettingsUpdate({
                            variables: { id, appCookies: !appCookies },
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </Mutation>
            </div>
            <div>
              <Mutation mutation={UPDATE_USER_SETTINGS_MUTATION}>
                {(userSettingsUpdate, { loading }) => (
                  <div>
                    <MDBInput
                      label="Opt Into Website Cookies?"
                      type="checkbox"
                      id="checkboxSettings3"
                      checked={websiteCookies}
                      value={websiteCookies}
                      onChange={(e) => {
                        if (!loading) {
                          this.setState({ websiteCookies: !websiteCookies });
                          userSettingsUpdate({
                            variables: { id, websiteCookies: !websiteCookies },
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </Mutation>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default EditProfile;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const infoIcon = {
  marginTop: "-55px",
  zIndex: "10",
  float: "right",
  position: "relative",
};

const infoText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  textAlign: "justify",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
};

const ageContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const categoryRow = {
  display: "flex",
  justifyContent: "space-evenly",
};

const categoryTitle = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "center",
  fontSize: "1rem",
};

const genderContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const groupContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const websiteText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  marginTop: "15px",
  textAlign: "center",
};

const businessRegionContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const fileErrorText = {
  color: "red",
  fontFamily: "Oswald",
  marginBottom: "0.5rem",
};

const errorText = {
  color: "red",
  fontFamily: "Oswald",
};

const uploadButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};

const updateProfileButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};

const uploadedText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const categoryImg = {
  borderRadius: "0.25rem",
  objectFit: "cover",
  width: "100%",
  height: "80px",
};

const deleteProfileButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
  color: "#000",
};

const settingsText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
  marginBottom: 0,
};

const settingsInfoText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  textAlign: "justify",
  marginTop: 5,
};

const loadingText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  marginBottom: "0.5rem",
};
