import React from "react";
import { MDBCol } from "mdbreact";
import './../../styles/Legal.css'

const CommunityGuidelines = () => {
  return (
    <div className="px-3 pt-4" style={{backgroundColor: '#fff', textAlign: 'justify'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Community Guidelines</h1>
          <h3 className="legal-subheading">Be cool and share the Stoke.</h3>
          <p className="legal-text">The Sponsor Hub is committed to be a safe, supportive and inclusive platform for all our members to enjoy.</p><br/>
          <p className="legal-text">These are the core values of the extreme sports community, along with being environmentally aware, custodians of the environment we play in and supportive of these local communities and pioneering businesses who keep the essence of our sports alive.</p><br/>
          <p className="legal-text">To help us maintain these values we have a few simple rules.</p><br/>
          <h3 className="legal-subheading">Be cool.</h3>
          <p className="legal-text">Upload sick Edits, showcase your skills and share your passion.</p><br/>
          <p className="legal-text">Tag your photographer / videographer so we can credit them.</p><br/>
          <p className="legal-text">Make use of the great discounts offered by our collaborating brands.</p><br/>
          <p className="legal-text">Enter competitions, build crews and find spots to rip.</p><br/>
          <p className="legal-text">Most importantly, have fun, enjoy the platform and share the Stoke!</p><br/>
          <h3 className="legal-subheading">Don’t be salty.</h3>
          <p className="legal-text">Don’t post obscene, hateful, or objectionable content.</p><br/>
          <p className="legal-text">Don’t post edits with explicit audio.</p><br/>
          <p className="legal-text">Don’t post copyrighted content without permission.</p><br/>
          <p className="legal-text">For further information see our <a className="legal-link" href="/terms_and_conditions"><p className="legal-text">Terms and Conditions</p></a>.</p><br/>
          <p className="legal-text">Athletes, don’t contact brands directly, if they are interested we will tell you.</p><br/>
          <p className="legal-text">Brands, don’t contact athletes directly, if you’re interested tell us and we will sound them out.</p><br/>
          <p className="legal-text">Have respect for our shared space and all the shredders in our community.</p><br/>
          <h3 className="legal-subheading">If you see something off, tell us.</h3>
          <p className="legal-text">If something seems off, get in <a className="legal-link" href="/#contact"><p className="legal-text">Contact</p></a>.</p><br/>
          <p className="legal-text">Our Trust & Safety team reviews every report received, and we will take action when we see someone violating our guidelines.</p><br/>
          <p className="legal-text">Sometimes that just means giving someone a warning; sometimes it means revoking certain privileges or accounts entirely.</p><br/>
          <p className="legal-text">The best way to avoid anything like that happening to you?</p><br/>
          <p className="legal-text">Don’t be salty, be cool and share the stoke.</p><br/>
          <p className="legal-text">As our community grows and changes, so will the way we evaluate and enforce these guidelines. It’s up to all of us to ensure that The Sponsor Hub remains a safe and fun space.</p><br/>
          <h4 className="legal-subheading" style={{marginBottom: '-0.25rem'}}>Thank you for being a part of The Sponsor Hub community.</h4>
          <h3 className="legal-subheading">Stay safe and keep shredding!</h3>
        </div>
      </MDBCol>
    </div>
  );
};

export default CommunityGuidelines;