import React from "react";
import { MDBCol } from "mdbreact";
import './../../styles/Legal.css'

const FilmingTips = () => {
  return (
    <div className="px-3 py-4" style={{backgroundColor: '#fff', textAlign: 'justify'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Tips on Shooting Content</h1>
          <p className="legal-text">Below are our tips for producing awesome, attention-grabbing content.</p><br/>
          <h3 className="legal-subheading">Landscape, not Portrait</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Shoot in Landscape (horizontal), not portrait (vertical).</p><br/>
            </li>
            <li>
                <p className="legal-text">Our eyes are aligned on the horizontal plane, we see in ‘Landscape’. Films and TV are horizontal.</p><br/>
            </li>
            <li>
                <p className="legal-text">Landscape produces a much more professional shot, simple as that!</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Get Close to the action</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">We need to see the athlete, not a speck on the horizon. Perspective longshots are ok first up, then the focus must be on the athlete.</p><br/>
            </li>
            <li>
                <p className="legal-text">Don’t use zoom, it pixelates the subject. Get in the mix, get close.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Experiment</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Keep your audience interested, shoot different and interesting angles.</p><br/>
            </li>
            <li>
                <p className="legal-text">Think about the framing, where is the athlete in the shot?</p><br/>
            </li>
            <li>
                <p className="legal-text">Where do they start, where are they going, where will they end?</p><br/>
            </li>
            <li>
                <p className="legal-text">Play with slow motion.</p><br/>
            </li>
            <li>
                <p className="legal-text">Experiment.</p><br/>
            </li>
            <li>
                <p className="legal-text">Angles, framing and slow motion all add great production values.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Keep rolling / shooting</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Keep the camera rolling / shooting, you don’t want to miss that magic moment because you cut early.</p><br/>
            </li>
            <li>
                <p className="legal-text">It’s better to have too much footage than not enough, that’s what post-production is for.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Tripod</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">For steady shots, invest in a tripod.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">POV / Lighting</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Find that perfect track / spot for your shots. It is where the action, framing, backdrop and lighting come together.</p><br/>
            </li>
            <li>
                <p className="legal-text">Think carefully about how the light works and how it will affect your edit, it will pay-off in the long run.</p><br/>
            </li>
            <li>
                <p className="legal-text">Being in the right place at the right time can be luck, but the more you practice, the luckier you get.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Post Production</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Spend time with the editing process, you’ve got the footage, keep the awesome, cut the boring.</p><br/>
            </li>
            <li>
                <p className="legal-text">Don’t overplay your hand, don’t continually switch from one angle to another then back, allow time between angles for your audience to adjust and adapt to the new view.</p><br/>
            </li>
            <li>
                <p className="legal-text">You know what you like to watch, you know how you could make other edits you’ve watched better.</p><br/>
            </li>
            <li>
                <p className="legal-text">Make the edit flow, tell a story.</p><br/>
            </li>
            <li>
                <p className="legal-text">Grab and keep the audience's attention.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Sound</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Personal choice. Natural sound plays well, if music is used ensure you have the copyright.</p><br/>
            </li>
            <li>
                <p className="legal-text">Shouting and hollering cool, explicit lyrics not cool.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Make friends, build crews, have fun</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Extreme sports and their communities are awesome.</p><br/>
            </li>
            <li>
                <p className="legal-text">They are friendly and encouraging, talk to people, ask questions, get involved.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Summary</h3>
          <p className="legal-text">Go for it, pre-plan, think about the shot, get close, experiment and invest time in the editing, but most of all enjoy yourself and share the stoke.</p><br/>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Landscape.</p><br/>
            </li>
            <li>
                <p className="legal-text">Get close.</p><br/>
            </li>
            <li>
                <p className="legal-text">Experiment.</p><br/>
            </li>
            <li>
                <p className="legal-text">Keep rolling.</p><br/>
            </li>
            <li>
                <p className="legal-text">Think ahead.</p><br/>
            </li>
            <li>
                <p className="legal-text">Edit.</p><br/>
            </li>
            <li>
                <p className="legal-text">Smile, enjoy yourself, meet cool people, make friends and most of all...</p><br/>
            </li>
            <li>
                <p className="legal-text">Have fun!</p><br/>
            </li>
          </ul>
        </div>
      </MDBCol>
    </div>
  );
};

export default FilmingTips;