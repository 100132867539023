import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import Discount from './../Discounts/Discount'
import ErrorPage from './../Errors/ErrorMessage'
import Loading from './../Loading'
import InfiniteScroll from "react-infinite-scroll-component";
import { MDBRow, MDBBtn } from 'mdbreact'

const PAGE_SIZE = 6;
 
const USER_DISCOUNTS_QUERY = gql`
  query userDiscountsQuery($userId: ID!, $skip: Int, $last: Int){
    userDiscounts(id: $userId, skip: $skip, last: $last, orderBy: createdAt_ASC){
      discounts {
        id
        title
        amount
        description
        code 
        filename1
        filename2
        filename3
        productDescription1
        productDescription2
        productDescription3
        postedBy {
          id
          name
          website
          businessLogo
          city
          country
        }
        inStore
      }
    }
  }
`

class ProfileDiscounts extends Component {
    state = {
      profileUser: this.props.profileUser,
      currentUserId: this.props.currentUserId,
      discounts: [],
      skip: 0,
      loadMore: false,
      firstLoad: true,
      hasMore: true,
    }

    fetchMoreData = () => {
        setTimeout(() => {
          this._executeFetchMore()
        }, 1000);
      };
    
      _executeFetchMore = async () => {
        var { profileUser, skip } = this.state
    
        const result = await this.props.client.query({
          query: USER_DISCOUNTS_QUERY,
          variables: { userId: profileUser.id , skip, last: PAGE_SIZE },
        })
        this.setState({ discounts: [...this.state.discounts, ...result.data.userDiscounts.discounts] })
        if(result.data.userDiscounts.discounts.length === 0){
          this.setState({hasMore: false})
        } else {
          this.setState({skip: this.state.skip + PAGE_SIZE})
          if(this.state.firstLoad){
            this._executeFetchMore()
          }
          this.setState({hasMore: true, firstLoad: false})
        }
      }

    render() {
      const { profileUser, currentUserId} = this.state
      return (
          <Query query={USER_DISCOUNTS_QUERY} variables={{userId: profileUser.id, skip: 0, last: PAGE_SIZE }}>
            {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorPage error={error.message} />

            return(
                <div className="pt-3" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
                    {data.userDiscounts.discounts.length === 0 ?
                      <div className="py-2 px-3" style={editsText}>
                        <h5>{profileUser.name} currently has no Discounts.</h5>
                        {profileUser.id === currentUserId ?
                            <MDBBtn style={uploadButton} color="white" rounded outline href="/business/upload_discount">Upload a Discount</MDBBtn>
                        :
                            <></>
                        }
                      </div>
                    :
                    <InfiniteScroll
                      dataLength={this.state.discounts.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.hasMore}
                      loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Discounts...</h5></div>}
                    >
                      {data && this.state.firstLoad ?
                        <MDBRow style={{justifyContent: 'space-evenly'}}>
                            {data.userDiscounts.discounts.map(discount => <Discount key={discount.id} discount={discount} />)}
                        </MDBRow>
                      :
                        <MDBRow style={{justifyContent: 'space-evenly'}}>
                            {this.state.discounts.map(discount => <Discount key={discount.id} discount={discount} />)}
                        </MDBRow>
                      }
                      {!this.state.hasMore ?
                        <div className="pb-2" style={editsText} key={0}><h5>No More Discounts!</h5></div>
                      :
                        <></>
                      }
                    </InfiniteScroll>
                    }
                </div>
            )
        }}
      </Query>
    );
  }
}

export default withApollo(ProfileDiscounts)

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const uploadButton = {
  minWidth: 150,
  paddingTop: '0.15rem',
  paddingBottom: '0.25rem',
  fontSize: 15,
  fontFamily: 'Oswald'
}