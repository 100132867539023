import React, { Component } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBCol } from 'mdbreact'
import "./../../styles/Forms.css"
import './../../styles/Background.css'
import Loading from './../Loading'
import ErrorPage from './../Errors/ErrorMessage'
import UploadDiscountForm from './UploadDiscountForm'

const GET_USER_LOGO_QUERY = gql`
query{
  user{
    id
    businessLogo
    name
    country
    city
    category
    businessRegion
    newUser
    businessIsBricks
    email
    discounts{
      id
    }
  }
}
`

class UploadDiscount extends Component {
  render() {
    return (
      <Query query={GET_USER_LOGO_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />
          
          const user = data.user
          
          return (
            <div style={{backgroundColor: '#000', minHeight: '100vh'}} className="d-flex py-5 justify-content-center align-items-center">
              <MDBCol sm="12" md="7" lg="5" xl="4" className="px-3 justify-content-center col-12">
                <UploadDiscountForm user={user} />
              </MDBCol>
            </div>
          )
        }}
      </Query >
    )
  }
}

export default UploadDiscount
