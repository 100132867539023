import React, { Component } from "react";
import { MDBCol, MDBBtn } from "mdbreact";
import "./../../styles/Background.css";

class Partners extends Component {
  render() {
    return (
      <section id="partners">
        <div style={{ backgroundColor: "#000" }}>
          <div className="pt-5 pb-3 px-3 text-center">
            <h2 className="mt-3" style={title}>
              Partners
            </h2>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <MDBCol
              sm="12"
              md="12"
              lg="6"
              xl="6"
              className="px-3 justify-content-center col-12"
            >
              <div style={{ textAlign: "center" }}>
                <p style={labelText}>
                  The Sponsor Hub is proud to Partner with nQ Legal for all our
                  Legal requirements.
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.nqlegal.co.uk/"
                >
                  <img
                    className="my-4"
                    width={200}
                    src="/Partners/nqLegalLogoWhite.png"
                    alt="ngLegal"
                  />
                </a>
                <div>
                  <p style={labelTextInline}>
                    Commercial lawyers specialising in providing legal advice
                    and consultancy services to businesses and individuals
                    involved in entertainment, sport (sponsorship & branding),
                    media, retail / leisure and the creative industries.
                  </p>
                </div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.nqlegal.co.uk/"
                >
                  <MDBBtn
                    style={showMoreButton}
                    color="white"
                    outline
                    rounded
                    type="submit"
                  >
                    Read More
                  </MDBBtn>
                </a>
              </div>
            </MDBCol>
          </div>
        </div>
      </section>
    );
  }
}

export default Partners;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
  marginBottom: 0,
};

const labelTextInline = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
  display: "inline",
  padding: 0,
};

const showMoreButton = {
  fontFamily: "Oswald",
  fontSize: "16px",
  width: 200,
  paddingTop: "0.15rem",
  paddingBottom: "0.15rem",
  marginTop: 10,
};
