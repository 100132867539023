import React, { Component } from "react";
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBCol} from "mdbreact";
import PrivacyPolicy18 from './PrivacyPolicy18';
import PrivacyPolicy13 from './PrivacyPolicy13';
import PrivacyPolicy10 from './PrivacyPolicy10';
import PrivacyPolicy6 from './PrivacyPolicy6';

class PrivacyPolicyAll extends Component {
  state = {
    activeItemClassicTabs2: "1",
  }

  toggleClassicTabs2 = tab => () => {
    if (this.state.activeItemClassicTabs2 !== tab) {
    this.setState({
      activeItemClassicTabs2: tab
    });
    }
  }

  render() {
    return (
      <div className="pt-5 px-3" style={{backgroundColor: '#fff'}}>
        <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
          <div className={window.innerWidth >= 576 ? "pt-4" : ""}>
            <MDBNav style={{justifyContent: 'space-evenly'}} color="transparent">
              <MDBNavItem>
                <MDBNavLink link to="#" active={this.state.activeItemClassicTabs2 === "1"} onClick={this.toggleClassicTabs2("1")}>
                  <h5 style={this.state.activeItemClassicTabs2 !== "1" ? tabTitle : tabTitleSelected}>{window.innerWidth >= 576 ? "Privacy Policy 18+" : "18+"}</h5>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink link to="#" active={this.state.activeItemClassicTabs2 === "2"} onClick={this.toggleClassicTabs2("2")}>
                  <h5 style={this.state.activeItemClassicTabs2 !== "2" ? tabTitle : tabTitleSelected}>{window.innerWidth >= 576 ? "Privacy Policy 13-17" : "13-17"}</h5>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink link to="#" active={this.state.activeItemClassicTabs2 === "3"} onClick={this.toggleClassicTabs2("3")}>
                  <h5 style={this.state.activeItemClassicTabs2 !== "3" ? tabTitle : tabTitleSelected}>{window.innerWidth >= 576 ? "Privacy Policy 10-12" : "10-12"}</h5>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink link to="#" active={this.state.activeItemClassicTabs2 === "4"} onClick={this.toggleClassicTabs2("4")}>
                  <h5 style={this.state.activeItemClassicTabs2 !== "4" ? tabTitle : tabTitleSelected}>{window.innerWidth >= 576 ? "Privacy Policy 6-9" : "6-9"}</h5>
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent
              activeItem={this.state.activeItemClassicTabs2}
            >
              <MDBTabPane tabId="1">
                  <div style={{marginTop: '-5rem'}}>
                      <PrivacyPolicy18 />
                  </div>
              </MDBTabPane>
              <MDBTabPane tabId="2">
                  <div style={{marginTop: '-5rem'}}>
                      <PrivacyPolicy13 />
                  </div>
              </MDBTabPane>
              <MDBTabPane tabId="3">
                  <div style={{marginTop: '-5rem'}}>
                      <PrivacyPolicy10 />
                  </div>
              </MDBTabPane>
              <MDBTabPane tabId="4">
                  <div style={{marginTop: '-5rem'}}>
                      <PrivacyPolicy6 />
                  </div>
              </MDBTabPane>
            </MDBTabContent>
          </div>
        </MDBCol>
      </div>
    );
  }
}

export default PrivacyPolicyAll;

const tabTitle = {
  color: '#121212', 
  fontFamily: 'Bebas Neue'
}

const tabTitleSelected = {
  color: '#121212', 
  fontFamily: 'Bebas Neue', 
  borderBottom: '3px solid #121212', 
  paddingBottom: 5
}
