import React from 'react'
import ReactDOM from 'react-dom'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import './styles/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { setContext } from 'apollo-link-context'
import { AUTH_TOKEN } from './constants'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import ApolloLinkTimeout from 'apollo-link-timeout';

const timeoutLink = new ApolloLinkTimeout(7500000); // 2 hour timeout

const uploadLink = createUploadLink({
	uri: '/graphql',
  headers: {
    "keep-alive": "true",
    "Access-Control-Allow-Credentials": "true"
  }
})

const timeoutHttpLink = timeoutLink.concat(uploadLink);

const apolloCache = new InMemoryCache()

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(timeoutHttpLink),
  cache: apolloCache
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister();
