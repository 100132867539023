import React from "react";
import './../../styles/Legal.css'
import { MDBCol } from "mdbreact";

const EnvironmentalAndSustainabilityPolicy = () => {
  return (
    <div className="px-3 pt-4" style={{backgroundColor: '#fff', textAlign: 'justify'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Environmental And Sustainability Policy</h1>
          <p className="legal-text">The Sponsor Hub Ltd. is committed to minimising the impact its activities have on the environment.</p><br /><br />
          <p className="legal-text">Our strategy to achieve this are:</p>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Minimise waste by evaluating operations and ensuring they are as efficient as possible.</p>
            </li>
            <li>
                <p className="legal-text">Using DigitalOcean as a hosting provider, who uses 100% renewable energy to power their services.</p>
            </li>
            <li>
                <p className="legal-text">Source and promote a product range that will minimise the impact on the environment.</p>
            </li>
            <li>
                <p className="legal-text">Meet or exceed all the environmental legislation that relates to the Company.</p>
            </li>
            <li>
                <p className="legal-text">Use a program to offset the greenhouse gas emissions generated by our activities.</p>
            </li>
          </ul><br />
          <p className="legal-text">Our program of choice is The Seagrass Project.</p><br />
          <p className="legal-text">Project Seagrass is a global facing environmental charity devoted to the conservation of seagrass ecosystems through research, community and action.</p>
          <p className="legal-text">Why The Seagrass Project?</p>
          <h4 className="legal-subheading">Carbon Sequestration</h4>
          <p className="legal-text">Seagrasses occupy 0.1% of the seafloor, yet are responsible for 11% of the organic carbon buried in the ocean. Seagrass meadows capture carbon 35 times more efficiently than rainforests.</p>
          <h4 className="legal-subheading">Fisheries</h4>
          <p className="legal-text">Seagrass meadows form the basis of the world’s primary fishing grounds, supplying 20% of the world’s fisheries.</p>
          <h4 className="legal-subheading">Poverty Alleviation</h4>
          <p className="legal-text">Seagrass meadows support communities and livelihoods. They provide vital nutrition for close to 3 billion people, and 50% of animal protein to 400 million people in the third world.</p>
          <h4 className="legal-subheading">Biodiversity</h4>
          <p className="legal-text">Seagrass meadows provide food and habitat for 1000’s of species such as shellfish, seahorses, manatees, and sea turtles. Over 30 times more animals live within seagrass compared to adjacent sandy habitats.</p>

          <p className="legal-text">More information regarding The Seagrass Project can be found on their website: <a target="_blank" rel="noopener noreferrer" className="legal-link" href="https://www.projectseagrass.org/"><p className="legal-text">https://www.projectseagrass.org/</p></a>.</p>
        </div>
      </MDBCol>
    </div>
  );
};

export default EnvironmentalAndSustainabilityPolicy;