import React from "react";
import "./../../styles/Legal.css";
import { MDBCol } from "mdbreact";

const DataRetentionAndErasurePolicy = () => {
  return (
    <div
      className="px-3 pt-4"
      style={{ backgroundColor: "#fff", textAlign: "justify" }}
    >
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Data Retention & Erasure Policy</h1>
          <h3 className="legal-subheading">1 About this policy</h3>
          <p className="legal-text">
            1.1 The corporate information, records and data of The Sponsor Hub
            Ltd is important to how we conduct business and manage our website
            and app users.
          </p>
          <br />
          <p className="legal-text">
            1.2 There are legal and regulatory requirements for us to retain
            certain data, usually for a specified amount of time. We also retain
            data to help our business operate and to have information available
            when we need it. However, we do not need to retain all data
            indefinitely, and retaining data can expose us to risk as well as be
            a cost to our business.
          </p>
          <br />
          <p className="legal-text">
            1.3 This Data Retention Policy explains our requirements to retain
            data and to dispose of data and provides guidance on appropriate
            data handling and disposal.
          </p>
          <br />
          <p className="legal-text">
            1.4 Failure to comply with this policy can expose us to fines and
            penalties, adverse publicity, difficulties in providing evidence
            when we need it and in running our business.
          </p>
          <br />
          <p className="legal-text">
            1.5 We may amend this policy at any time.
          </p>
          <br />
          <h3 className="legal-subheading">2 Scope of policy</h3>
          <p className="legal-text">
            2.1 This policy covers all data that we hold or have control over.
            This includes electronic data such as emails, electronic documents,
            audio and video recordings, photos and imagery, CCTV recordings, any
            data provided by you to us or obtained from you by us by means of
            our mobile application and/ or via our website. It also includes
            (where appropriate) physical data such as hard copy documents,
            contracts, notebooks, letters and invoices. It applies to both
            personal data and non-personal data. In this policy we refer to this
            information and these records collectively as "data".
          </p>
          <br />
          <p className="legal-text">
            2.2 This policy covers data that is held by third parties on our
            behalf, for example cloud or web storage providers or offsite
            records storage.
          </p>
          <br />
          <p className="legal-text">
            2.3 This policy explains the differences between our formal or
            official records, disposable information, confidential information
            belonging to others, personal data and non-personal data. It also
            gives guidance on how we classify our data.
          </p>
          <br />
          <p className="legal-text">
            2.4 This policy applies to data collected, stored, retained in
            connection with our business.
          </p>
          <br />
          <h3 className="legal-subheading">3 Guiding principles</h3>
          <p className="legal-text">
            3.1 Through this policy, and our data retention practices, we aim to
            meet the following commitments:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                We comply with legal and regulatory requirements to retain data.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                We comply with our data protection obligations, in particular to
                keep personal data no longer than is necessary for the purposes
                for which it is processed (storage limitation principle).
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                We handle, store and dispose of data responsibly and securely.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                We create and retain data where we need this to operate our
                business effectively, but we do not create or retain data
                without good business reason.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                We allocate appropriate resources to data retention and deal
                with it responsibly.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                We monitor and audit compliance with this policy and update this
                policy when required.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">4 Roles and responsibilities</h3>
          <p className="legal-text">
            4.1 <p className="legal-text-bolder">Responsibility of directors</p>
            . We aim to comply with the laws, rules, and regulations that govern
            our organisation and with recognised compliance good practices. All
            directors must comply with this policy, the Record Retention
            Schedule, any communications suspending data disposal and any
            specific instructions from the Data Protection Compliance Manager. A
            consultant’s failure to comply with this policy may result in
            disciplinary sanctions, including suspension or termination. It is
            therefore the responsibility of everyone to understand and comply
            with this policy.
          </p>
          <br />
          <p className="legal-text">
            4.2{" "}
            <p className="legal-text-bolder">
              Data Protection Compliance Manager
            </p>
            . The Data Protection Compliance Manager (‘DPCM’) is responsible for
            identifying the data that we must or should retain, and determining,
            the proper period of retention. It also arranges for the proper
            storage and retrieval of data. Additionally, the DPCM is responsible
            for the destruction of records whose retention period has expired.
          </p>
          <br />
          <p className="legal-text">
            4.3 We have designated Louie Cherrington as the DPCM. He is
            responsible for:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                Administering the data management programme;
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Helping the business to implement the data management programme
                and related best practices;
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Planning, developing, and prescribing data disposal policies,
                systems, standards, and procedures; and
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Providing guidance, training, monitoring and updating in
                relation to this policy.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">
            5 Types of data and data classifications
          </h3>
          <p className="legal-text">
            5.1 <p className="legal-text-bolder">Formal or official records</p>.
            Certain data is more important to us and is therefore listed in the
            Record Retention Schedule. This may be because we have a legal
            requirement to retain it, or because we may need it as evidence of
            our transactions, or because it is important to the running of our
            business. Please see paragraph 6.1 below for more information on
            retention periods for this type of data.
          </p>
          <br />
          <p className="legal-text">
            5.2 <p className="legal-text-bolder">Disposable information</p>.
            Disposable information consists of data that may be discarded or
            deleted at the discretion of the user once it has served its
            temporary useful purpose and/or data that may be safely destroyed
            because it is not a formal or official record as defined by this
            policy and the Record Retention Schedule. Examples may include:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                Duplicates of originals that have not been annotated.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Preliminary drafts of letters, memoranda, reports, worksheets,
                and informal notes that do not represent significant steps or
                decisions in the preparation of an official record.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Books, periodicals, manuals, training binders, and other printed
                materials obtained from sources outside of The Sponsor Hub Ltd
                and retained primarily for reference purposes.
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">Spam and junk mail.</p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            Please see paragraph 6.2 below for more information on how to
            determine retention periods for this type of data.
          </p>
          <br />
          <p className="legal-text">
            5.3 <p className="legal-text-bolder">Personal data</p>. Both formal
            or official records and disposable information may contain personal
            data; that is, data that identifies living individuals. Data
            protection laws require us to retain personal data for no longer
            than is necessary for the purposes for which it is processed
            (principle of storage limitation). See paragraph 6.3 below for more
            information on this.
          </p>
          <br />
          <p className="legal-text">
            5.4{" "}
            <p className="legal-text-bolder">
              Confidential information belonging to others
            </p>
            . Any confidential information that a director may have obtained
            from a source outside of The Sponsor Hub Ltd , must not, so long as
            such information remains confidential, be disclosed to or used by
            us. Unsolicited confidential information submitted to us should be
            refused, returned to the sender where possible, and deleted, if
            received via the internet.
          </p>
          <br />
          <h3 className="legal-subheading">Retention periods</h3>
          <p className="legal-text">
            6.1 <p className="legal-text-bolder">Formal or official records</p>.
            Any data that is part of any of the categories listed in the Record
            Retention Schedule contained in the Annex to this policy, must be
            retained for the amount of time indicated in the Record Retention
            Schedule. A record must not be retained beyond the period indicated
            in the Record Retention Schedule, unless a valid business reason (or
            notice to preserve documents for contemplated litigation or other
            special situation) calls for its continued retention.
          </p>
          <br />
          <p className="legal-text">
            6.2 <p className="legal-text-bolder">Disposable information</p>. The
            Record Retention Schedule will not set out retention periods for
            disposable information. This type of data should only be retained as
            long as it is needed for business purposes. Once it no longer has
            any business purpose or value it should be securely disposed of.
          </p>
          <br />
          <p className="legal-text">
            6.3 <p className="legal-text-bolder">Personal data</p>. As explained
            above, data protection laws require us to retain personal data for
            no longer than is necessary for the purposes for which it is
            processed (principle of storage limitation). Where data is listed in
            the Record Retention Schedule, we have taken into account the
            principle of storage limitation and balanced this against our
            requirements to retain the data. Where data is disposable
            information, you must take into account the principle of storage
            limitation when deciding whether to retain this data. More
            information can be found in in our{" "}
            <a className="legal-link" href="privacy_policy">
              <p className="legal-text">Privacy Notice </p>
            </a>
            .
          </p>
          <br />
          <p className="legal-text">
            6.4{" "}
            <p className="legal-text-bolder">
              What to do if data is not listed in the Record Retention Schedule
            </p>
            . If data is not listed in the Record Retention Schedule, it is
            likely that it should be classed as disposable information. However,
            if you consider that there is an omission in the Record Retention
            Schedule, or if you are unsure, please contact the DPCM.
          </p>
          <br />
          <h3 className="legal-subheading">
            7 Storage, back-up and disposal of data
          </h3>
          <p className="legal-text">
            7.1 <p className="legal-text-bolder">Storage</p>. Our data must be
            stored in a safe, secure, and accessible manner. Any documents and
            financial files that are essential to our business operations during
            an emergency must be duplicated and/or backed up at least once per
            week and maintained off site.
          </p>
          <br />
          <p className="legal-text">
            7.2 <p className="legal-text-bolder">Destruction</p>. Our DPCM is
            responsible for the continuing process of identifying the data that
            has met its required retention period and supervising its
            destruction. The destruction of confidential, financial, hard copy
            data must be conducted by shredding if possible. Non-confidential
            data may be destroyed by recycling. All electronic data will be
            deleted.
          </p>
          <br />
          <p className="legal-text">
            7.3 The destruction of data must stop immediately upon notification
            that preservation of documents for contemplated litigation is
            required (sometimes referred to as a litigation hold). This is
            because we may be involved in a legal claim or an official
            investigation (see next paragraph). Destruction may begin again once
            such requirement for preservation is lifted.
          </p>
          <br />
          <h3 className="legal-subheading">8 Special circumstances</h3>
          <p className="legal-text">
            8.1{" "}
            <p className="legal-text-bolder">
              Preservation of documents for contemplated litigation and other
              special situations
            </p>
            . We require all officers and employees of the company to comply
            fully with our Record Retention Schedule and procedures as provided
            in this policy. The following general exception to any stated
            destruction schedule is to be noted : If you believe, that certain
            records are relevant to current litigation or contemplated
            litigation (that is, a dispute that could result in litigation),
            government investigation, audit, or other event, you must preserve
            and not delete, dispose, destroy, or change those records, including
            emails and other electronic documents, until the DPCM determines
            those records are no longer needed. Preserving documents includes
            suspending any requirements in the Record Retention Schedule and
            preserving the integrity of the electronic files or other format in
            which the records are kept.
          </p>
          <br />
          <p className="legal-text">
            8.2 If you believe this exception may apply, or have any questions
            regarding whether it may apply, please contact the DPCM.
          </p>
          <br />
          <p className="legal-text">
            8.3 In addition, you may be asked to suspend any routine data
            disposal procedures in connection with certain other types of
            events, such as our merger with another organisation or the
            replacement of our information technology systems.
          </p>
          <br />
          <h3 className="legal-subheading">
            9 Where to go for advice and questions
          </h3>
          <p className="legal-text">
            9.1 <p className="legal-text-bolder">Questions about the policy</p>.
            Any questions about this policy should be referred to the DPCM at{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>
            , who is in charge of administering, enforcing, and updating this
            policy.
          </p>
          <br />
          <h3 className="legal-subheading">10 Breach reporting and audit</h3>
          <p className="legal-text">
            10.1 <p className="legal-text-bolder">Reporting policy breaches</p>.
            The Sponsor Hub Ltds is committed to enforcing this policy as it
            applies to all forms of data. If you feel that there may have been a
            breach of this policy, you should report the incident immediately to
            the DPCM to make us aware of the possible breach of this policy as
            soon as possible so that we may take appropriate corrective action
          </p>
          <br />
          <p className="legal-text">
            10.2 <p className="legal-text-bolder">Audits</p>. Our DPCM will
            periodically review this policy and its procedures (including where
            appropriate by taking outside legal or GDPR compliance advice) to
            ensure we are in compliance with relevant new or amended laws,
            regulations or guidance. Additionally, we will regularly monitor
            compliance with this policy, including by carrying out audits.
          </p>
          <br />
          <h3 className="legal-subheading">11 Other relevant policies</h3>
          <p className="legal-text">
            This policy supplements and should be read in conjunction with our
            other policies and procedures in force from time to time, including
            our{" "}
            <a className="legal-link" href="/privacy_policy">
              <p className="legal-text">Privacy Policy</p>
            </a>
            , our{" "}
            <a className="legal-link" href="/terms_and_conditions">
              <p className="legal-text">Terms and Conditions</p>
            </a>
            , our{" "}
            <a className="legal-link" href="cookies_policy">
              <p className="legal-text">Cookie Policy</p>
            </a>
            , our{" "}
            <a className="legal-link" href="/community_guidelines">
              <p className="legal-text">Community Guidelines</p>
            </a>{" "}
            and any other IT and security related policies that The Sponsor Hub
            Ltd may have in place from time to time
          </p>
          <br />
          <h3 className="legal-subheading">Definitions</h3>
          <p className="legal-text">
            <p className="legal-text-bolder">Data</p>: all data that we hold or
            have control over and therefore to which this policy applies. This
            includes physical data such as hard copy documents, contracts,
            notebooks, letters and invoices. It also includes electronic data
            such as emails, electronic documents, audio and video recordings and
            CCTV recordings and any other data electronically communicated to us
            digitally or obtained by us digitally. It applies to both personal
            data and non-personal data. In this policy we refer to this
            information and these records collectively as "data".
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Data Retention Policy</p>: this
            policy, which explains our requirements to retain data and to
            dispose of data and provides guidance on appropriate data handling
            and disposal.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Disposable information</p>:
            disposable information consists of data that may be discarded or
            deleted at the discretion of the user once it has served its
            temporary useful purpose and/or data that may be safely destroyed
            because it is not a formal or official record as defined by this
            policy and the Record Retention Schedule.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Formal or official record</p>:
            certain data is more important to us and is therefore listed in the
            Record Retention Schedule. This may be because we have a legal
            requirement to retain it, or because we may need it as evidence of
            our transactions, or because it is important to the running of our
            business. We refer to this as formal or official records or data.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Non-personal data</p>: data which
            does not identify living individuals, either because it is not about
            living individuals (for example financial records) or because it has
            been fully anonymised.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Personal data</p>: any information
            identifying a living individual or information relating to a living
            individual that we can identify (directly or indirectly) from that
            data alone or in combination with other identifiers we possess or
            can reasonably access. This includes special categories of personal
            data such as health data and pseudonymised personal data but
            excludes anonymous data or data that has had the identity of an
            individual permanently removed. Personal data can be factual (for
            example, a name, email address, location or date of birth) or an
            opinion about that person's actions or behaviour.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">
              Data Protection Compliance Manager
            </p>
            : the DPCM is responsible for administering the data management
            programme, helping the business implement it and related best
            practices, planning, developing, and prescribing data disposal
            policies, systems, standards, and procedures and providing guidance,
            training, monitoring and updating in relation to this policy. Our
            DPCM is Louie Cherrington.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Record Retention Schedule</p>: the
            schedule attached at Annex A which sets out retention periods for
            our formal or official records.
          </p>
          <br />
          <p className="legal-text">
            <p className="legal-text-bolder">Storage limitation principle</p>:
            data protection laws require us to retain personal data for no
            longer than is necessary for the purposes for which it is processed.
            This is referred to in the GDPR as the principle of storage
            limitation.
          </p>
          <br />
          <h3 className="legal-subheading">
            Annex A Record retention and erasure schedule
          </h3>
          <p className="legal-text">
            The Sponsor Hub Ltd establishes retention or destruction schedules
            or procedures for specific categories of data. This is done to
            ensure legal compliance (for example with our data protection
            obligations) and accomplish other objectives, such as protecting
            intellectual property and controlling costs.
          </p>
          <br />
          <p className="legal-text">
            The Sponsor Hub Ltd and its directors (and employees) shall comply
            with the retention periods listed in the record retention schedule
            below.
          </p>
          <br />
          <table>
            <tr>
              <th>
                <p className="legal-text-bolder">Type of data</p>
              </th>
              <th>
                <p className="legal-text-bolder">Minimum retention period</p>
              </th>
              <th>
                <p className="legal-text-bolder">
                  Reasons for retaining data longer (if applicable)
                </p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Personal Data - belonging to visitors/ users of our app and
                  website
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  3 years post termination of your online account
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">These include:</p>
                <br />
                <ul>
                  <li>
                    <p className="legal-text">For legal reasons</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">Where you ask us to</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">For business reasons</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      Where we have asked your permisiion and you have given it.
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Personal Data – belonging to officers and employees of the
                  company
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  3 years post termination of your online account
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">These include:</p>
                <br />
                <ul>
                  <li>
                    <p className="legal-text">For legal reasons</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">Where you ask us to</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">For business reasons</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      Where we have asked your permisiion and you have given it.
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Data related to specific client services/ projects/ work for
                  clients
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  3 years post termination of your online account
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">These include:</p>
                <br />
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">For legal reasons</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">Where you ask us to</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">For business reasons</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      Where we have asked your permisiion and you have given it.
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
          </table>
          <h3 className="legal-subheading">How to contact us</h3>
          <p className="legal-text">
            Please contact us if you have any questions about this cookie policy
            or the information we hold about you.
          </p>
          <br />
          <p className="legal-text">
            If you wish to contact us please send an email to{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>{" "}
            or write to Walk Mill, Kirkwhelpington, Newcastle Upon Tyne,
            Northumberland, England, NE19 2SB or call +44 (0)7966 593 208.
          </p>
          <br />
          <h3 className="legal-subheading">Do you need extra help?</h3>
          <p className="legal-text">
            If you would like this notice in another format (for example large
            print) please contact us (see above).
          </p>
          <br />
          <h3 className="legal-subheading">Changes to this policy</h3>
          <p className="legal-text">This policy was published on 12/3/22.</p>
          <br />
          <p className="legal-text">
            We may change this policy from time to time, when we do we will
            inform you via email, informing you of changes to this policy. Any
            such changes will become binding on you on your first use of our
            website after the changes have been made. You are therefore advised
            to also check this policy from time to time.
          </p>
          <br />
          <h3 className="legal-subheading">Thanks</h3>
          <h3 className="legal-subheading">The Sponsor Hub</h3>
          <p className="legal-text">
            This document was produced by our Legal Services Partner{" "}
            <a
              style={{ display: "inline", padding: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nqlegal.co.uk/"
            >
              <img
                height={20}
                style={{ marginTop: -2 }}
                src="/Partners/nqLegalLogo.png"
                alt="ngLegal"
              />
            </a>
            .
          </p>
        </div>
      </MDBCol>
    </div>
  );
};

export default DataRetentionAndErasurePolicy;
