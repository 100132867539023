import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";

class ErrorMessage extends Component {

  state = {
      error: this.props.error
  }

  render() {
    var {error} = this.state
    error.replace('GraphQL error: ', '')
    return (
      <div style={mainContainer} className="d-flex align-items-center justify-content-center mb-3">
        <div>
          <MDBCol xs="12" sm="10" md="8" lg="4" className="px-3 offset-lg-4 offset-md-2 offset-sm-1">
            <img src="TSH_logos/TSH_ES_Black.png" alt="TSH Logo"/>
          </MDBCol>
          <MDBCol xs="12" sm="12" md="10" lg="8" className="px-3 offset-lg-2 offset-md-1">
            <div className="pb-3" style={errorText}>
              <h4 className="mb-3">We've encountered an issue:</h4>
              <h5 className="mb-3">{error}</h5>
              <h4>Please get in Contact, so we can fix this issue.</h4>
            </div>
            <MDBRow className="justify-content-center pt-2">
              <MDBCol style={{ flexGrow: '1' }} className="text-center">
                <a href="mailto:info@thesponsorhub.com" className="px-1">
                  <MDBIcon icon="envelope" style={icons} />
                </a>
              </MDBCol>
              <MDBCol style={{ flexGrow: '1' }} className="text-center">
                <a href="https://www.instagram.com/thesponsorhub/?hl=en" className="px-1">
                  <MDBIcon fab icon="instagram" style={icons} />
                </a>
              </MDBCol>
              <MDBCol style={{ flexGrow: '1' }} className="text-center">
                <a href="https://www.facebook.com/TheSponsorHub/" className="px-1">
                  <MDBIcon fab icon="facebook-f" style={icons} />
                </a>
              </MDBCol>
              <MDBCol style={{ flexGrow: '1' }} className="text-center">
                <a href="https://twitter.com/thesponsorhub" className="px-1">
                  <MDBIcon fab icon="twitter" style={icons} />
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </div>
      </div>
    );
  }
}

export default ErrorMessage;

const mainContainer = {
  backgroundColor: '#000', 
  minHeight: '100vh', 
  display: 'inline-grid'
}

const errorText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const icons = {
  color: '#f8f8ff', 
  fontSize: '2rem'
}
