import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBRow, MDBIcon } from "mdbreact";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import "./../../styles/Background.css";
import "./../../styles/Video.css";
import Loading from "../Loading";

const USER_QUERY = gql`
  query {
    user {
      id
      isBusiness
      name
    }
  }
`;

const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

const FOLLOW_MUTATION = gql`
  mutation FollowMutation($followId: ID!) {
    follow(followId: $followId) {
      id
      user
      follow {
        id
      }
    }
  }
`;

const DELETE_FOLLOW_MUTATION = gql`
  mutation DeleteFollowMutation($deleteId: ID!) {
    deleteFollow(id: $deleteId) {
      id
    }
  }
`;

const FOLLOW_VIDEOS_QUERY = gql`
  query FollowsQuery($currentUserId: ID!) {
    follows(id: $currentUserId) {
      follows {
        id
        user
        follow {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;
const WEBSITE_TRACKER_MUTATION = gql`
  mutation WebsiteTrackerMutation(
    $business: String!
    $user: String!
    $discountId: String
  ) {
    websiteTracker(business: $business, user: $user, discountId: $discountId) {
      id
      business
      user
    }
  }
`;

class SponsoredSection extends Component {
  state = {
    isLoggedIn: this.props.isLoggedIn,
    showMore: false,
  };
  render() {
    var { isLoggedIn, showMore } = this.state;
    return (
      <Query query={USER_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) isLoggedIn = false;

          var currentUserId;
          if (data !== undefined && data.user !== null) {
            currentUserId = data.user.id;
          } else {
            isLoggedIn = false;
          }

          return (
            <section id="sponsored">
              <div className="pt-5" style={{ backgroundColor: "#000" }}>
                <div className="mx-2">
                  <h2 className="text-center pt-3 text-uppercase" style={title}>
                    Athletes sponsored through the platform
                  </h2>
                </div>
                <MDBRow className="justify-content-center mx-3">
                  <SponsoredVideo
                    videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckzwg3tdv1fwa07415f07i43b/OcReLed1GAyGPW3o_720.mp4"
                    sponsoredName="ANITA AGGARWAL"
                    sponsoredCountry="United Kingdom"
                    sponsoredCity="Derby"
                    sponsoredInfo="Ambassador role at ProBalm. ProBalm is the active skin restorer that athletes love, and has been the choice of both professional and amateur extreme sports fanatics since 2011."
                    sponsoredId="ckzwg3tdv1fwa07415f07i43b"
                    sponsorWebsite="https://probalm.co.uk/"
                    currentUserId={currentUserId}
                    isLoggedIn={isLoggedIn}
                    sponsorId="cl0mbg6fz3wc80741luoewutq"
                  />
                  <SponsoredVideo
                    videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cky4fdz3g4xs207412zj0ehs0/pdTPJ3FBBTLe6v3l_720.mp4"
                    sponsoredName="JUAN LATORRAGA"
                    sponsoredCountry="Canada"
                    sponsoredCity="Winnipeg"
                    sponsoredInfo="Ambassador role at Snow Bunny / Shellz Apparel. Snow Bunny / Shellz Apparel are an American snowsports protection brand, who produce quality, impact protective gear."
                    sponsoredId="cky4fdz3g4xs207412zj0ehs0"
                    sponsorWebsite="https://sbshellz.com/"
                    currentUserId={currentUserId}
                    isLoggedIn={isLoggedIn}
                    sponsorId="ckculowml00gp0841d746k6ce"
                  />
                  <SponsoredVideo
                    videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckihflesh378g0741p5d90qip/sZHkjLyBKtys5efr_720.mp4"
                    sponsoredName="YEMAYA CHATEL"
                    sponsoredCountry="Costa Rica"
                    sponsoredCity="Dominical"
                    sponsoredInfo="Sponsored by Leiki Boardshorts through our online Surf Competition. Leiki Boardshorts are an American boardshorts brand, who produce quality, comfortable and quick drying boarshorts."
                    sponsoredId="ckihflesh378g0741p5d90qip"
                    sponsorWebsite="https://leikiboardshort.co/"
                    currentUserId={currentUserId}
                    isLoggedIn={isLoggedIn}
                    sponsorId="ckft4r8ax1ekq0741oet23yc8"
                  />
                  <SponsoredVideo
                    videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cko38haz18dzi0741ybb1qk4d/PeaSDgPdbqo6KUtr_720.mp4"
                    sponsoredName="XUEYING XING"
                    sponsoredCountry="Costa Rica"
                    sponsoredCity="Tamarindo"
                    sponsoredInfo="Sponsored by Leiki Boardshorts through our online Surf Competition. Leiki Boardshorts are an American boardshorts brand, who produce quality, comfortable and quick drying boarshorts."
                    sponsoredId="cko38haz18dzi0741ybb1qk4d"
                    sponsorWebsite="https://leikiboardshort.co/"
                    currentUserId={currentUserId}
                    isLoggedIn={isLoggedIn}
                    sponsorId="ckft4r8ax1ekq0741oet23yc8"
                  />
                </MDBRow>
                <div
                  style={{ textAlign: "-webkit-center" }}
                  className="justify-content-center mx-3"
                >
                  {isLoggedIn && !showMore ? (
                    <MDBCol xl="3" lg="4" md="6" sm="12" className="col-12">
                      <div className="text-center pt-2">
                        <div className="flex justify-content-center">
                          <MDBBtn
                            onClick={() => this.setState({ showMore: true })}
                            style={showMoreButton}
                            color="white"
                            outline
                            rounded
                            type="submit"
                          >
                            Show More
                          </MDBBtn>
                        </div>
                      </div>
                    </MDBCol>
                  ) : (
                    <></>
                  )}
                </div>
                <MDBRow className="justify-content-center mx-3">
                  {isLoggedIn && showMore ? (
                    <>
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckmcdme9z6gmy0741ourel0nc/wbGPxVKEWXHmgmKF_720.mp4"
                        sponsoredName="NYIMAS BUNGA CINTA"
                        sponsoredCountry="Indonesia"
                        sponsoredCity="Jakarta"
                        sponsoredInfo="Sponsored by Neverknowhy® and NKWHY Extreme Sport® through our online Skate Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY."
                        sponsoredId="ckmcdme9z6gmy0741ourel0nc"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckf32r07g0rc007417hnxboot/I0WW6Xn1WqLE5XtC_720.mp4"
                        sponsoredName="JAKOB NIXON"
                        sponsoredCountry="United Kingdom"
                        sponsoredCity="Derry"
                        sponsoredInfo="Sponsored by Mothership through the Platform. Mothership is an independent collective, inspired by the cultures that formed us and the lifestyles we live.
                      Mothership is a UK based independent lifestyle brand which takes its primary influence from the skate culture, street art and underground music scenes that we grew up alongside."
                        sponsoredId="ckf32r07g0rc007417hnxboot"
                        sponsorWebsite="https://somethinghaslanded.com/"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckg1xrak71j1z0741ul6pip41"
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cki2q8k5r2s110741qhajaob5/30GSvY62ThqPUKKc_720.mp4"
                        sponsoredName="EZRA CLARK"
                        sponsoredCountry="United States of America"
                        sponsoredCity="Oahu"
                        sponsoredInfo="Sponsored by Leiki Boardshorts through our online Surf Competition. Leiki Boardshorts are an American boardshorts brand, who produce quality, comfortable and quick drying boarshorts."
                        sponsoredId="cki2q8k5r2s110741qhajaob5"
                        sponsorWebsite="https://leikiboardshort.co/"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckft4r8ax1ekq0741oet23yc8"
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckeim4uxw0d4l0741e1z2300r/VAno9NThqqErMA5c_720.mp4"
                        sponsoredName="JOSHUA EVANS"
                        sponsoredCountry="United Kingdom"
                        sponsoredCity="Aberystwyth"
                        sponsoredInfo="Sponsored by EatSalt through our online Surf Competition. EatSalt are a UK surfwear and gear brand, who produce quality clothing, surfboards and accessories with unique designs inspired by Wales and its waves."
                        sponsoredId="ckeim4uxw0d4l0741e1z2300r"
                        sponsorWebsite="https://www.eatsalt.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckcm6wl5u02x6074116fr1ljo"
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckoo2u0kb8tf307417pq9ia4n/6KzJ5HDcdqrfoYlZ_720.mp4"
                        sponsoredName="RHIANNON EVANS"
                        sponsoredCountry="United Kingdom"
                        sponsoredCity="Thurso"
                        sponsoredInfo="Sponsored by EatSalt through our online Surf Competition. EatSalt are a UK surfwear and gear brand, who produce quality clothing, surfboards and accessories with unique designs inspired by Wales and its waves."
                        sponsoredId="ckoo2u0kb8tf307417pq9ia4n"
                        sponsorWebsite="https://www.eatsalt.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckcm6wl5u02x6074116fr1ljo"
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cko04sigm8a9o0741d14q8lvr/TZloZCqUfuI7x4Eg_720.mp4"
                        sponsoredName="BLAKE JONES"
                        sponsoredCountry="United Kingdom"
                        sponsoredCity="Porthcawl"
                        sponsoredInfo="Sponsored by EatSalt through our online Surf Competition. EatSalt are a UK surfwear and gear brand, who produce quality clothing, surfboards and accessories with unique designs inspired by Wales and its waves."
                        sponsoredId="cko04sigm8a9o0741d14q8lvr"
                        sponsorWebsite="https://www.eatsalt.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckcm6wl5u02x6074116fr1ljo"
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cknrj0lr684rl0741pdef3ay0/5WKKNzBiQVPIPgsP_720.mp4"
                        sponsoredName="EVA MOORCRAFT_HOLLAND"
                        sponsoredCountry="United Kingdom"
                        sponsoredCity="Devon"
                        sponsoredInfo="Sponsored by EatSalt through our online Surf Competition. EatSalt are a UK surfwear and gear brand, who produce quality clothing, surfboards and accessories with unique designs inspired by Wales and its waves."
                        sponsoredId="cknrj0lr684rl0741pdef3ay0"
                        sponsorWebsite="https://www.eatsalt.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckcm6wl5u02x6074116fr1ljo"
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cknj0yoba7x4y0741nttph9ge/OQg75QBCuFkQZLDf_720.mp4"
                        sponsoredName="KIAN ASHARA"
                        sponsoredCountry="Costa Rica"
                        sponsoredCity="Dominical"
                        sponsoredInfo="Sponsored by Neverknowhy® and NKWHY Extreme Sport® through our online Surf Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY."
                        sponsoredId="cknj0yoba7x4y0741nttph9ge"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/cko8stkqh8hq70741dfj7bor0/kxnR0b06VOQfCvJm_720.mp4"
                        sponsoredName="KIMBERLY JACHIMEK"
                        sponsoredCountry="United States of America"
                        sponsoredCity="Oceanside"
                        sponsoredInfo="Sponsored by Neverknowhy® and NKWHY Extreme Sport® through our online Surf Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY."
                        sponsoredId="cko8stkqh8hq70741dfj7bor0"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckej351700dqe0741gspcij4t/4CSWCn6T50JBKPff_720.mp4"
                        sponsoredName="ZACH LIA LORENZ"
                        sponsoredCountry="Australia"
                        sponsoredCity="Sydney"
                        sponsoredInfo="Sponsored by Neverknowhy® and NKWHY Extreme Sport® through our online Surf Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY."
                        sponsoredId="ckej351700dqe0741gspcij4t"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckoleg2id8rl30741o5vs22gw/0HsUe2YoBf0BXkwG_720.mp4"
                        sponsoredName="ADRIANA HIDALGO FERNANDEZ"
                        sponsoredCountry="Spain"
                        sponsoredCity="Corralejo"
                        sponsoredInfo="Sponsored by Neverknowhy®, NKWHY Extreme Sport® and Leiki Boardshorts through our online Surf Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY. Leiki Boardshorts are an American boardshorts brand, who produce quality, comfortable and quick drying boarshorts."
                        sponsoredId="ckoleg2id8rl30741o5vs22gw"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckg4iuoed1kn30741pdye6x3c/ilMsnb5lWM8OtqHt_720.mp4"
                        sponsoredName="JASPER SMITH"
                        sponsoredCountry="Australia"
                        sponsoredCity="Yeppoon"
                        sponsoredInfo="Sponsored by Neverknowhy® and NKWHY Extreme Sport® through our online Skate Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY."
                        sponsoredId="ckg4iuoed1kn30741pdye6x3c"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckmchw5136h8707413z7zhb6o/fIP6TWliZcWgbsHl_720.mp4"
                        sponsoredName="KAROLENE LIMA"
                        sponsoredCountry="Brazil"
                        sponsoredCity="Fortaleza-CE"
                        sponsoredInfo="Sponsored by Neverknowhy® and NKWHY Extreme Sport® through our online Skate Competition. Neverknowhy® is an Australian men's and women's apparel brand for lovers of surf, skate and fitness. Their vision is to Never give up and always embrace your warrior instinct. SURF it, SKATE it, EXTREME it and ACTIVATE your WHY."
                        sponsoredId="ckmchw5136h8707413z7zhb6o"
                        sponsorWebsite="https://neverknowhy.com"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId=""
                      />
                      <SponsoredVideo
                        videoSrc="https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/ckflnvm0u181u0741gx011rm0/bDPwMjuuGvuH5Mzy_480.mp4"
                        sponsoredName="JAIDEN SQUIBB"
                        sponsoredCountry="United Kingdom"
                        sponsoredCity="Weymouth"
                        sponsoredInfo="Sponsored by Daisy's Cold Water Surf Cream through the Platform. Daisy’s Cold Water Surf Cream is a natural and organic skin cream. This cream protects your skin 
                      from the elements, is an effective alternative to petroleum-based products, and acts as a lubricant wherever needed. Cold water surfers regularly use and enjoy all the benefits of this cream."
                        sponsoredId="ckflnvm0u181u0741gx011rm0"
                        sponsorWebsite="https://daisyscoldwatersurfcream.com/"
                        currentUserId={currentUserId}
                        isLoggedIn={isLoggedIn}
                        sponsorId="ckckaex3x028v0741k7qq60jz"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </MDBRow>
              </div>
            </section>
          );
        }}
      </Query>
    );
  }
}

class SponsoredVideo extends Component {
  state = {
    videoSrc: this.props.videoSrc,
    user: this.props.sponsoredName,
    sponsoredCountry: this.props.sponsoredCountry,
    sponsoredCity: this.props.sponsoredCity,
    sponsoredInfo: this.props.sponsoredInfo,
    userId: this.props.sponsoredId,
    website: this.props.sponsorWebsite,
    currentUserId: this.props.currentUserId,
    isLoggedIn: this.props.isLoggedIn,
    followed: false,
    businessId: this.props.sponsorId,
  };
  render() {
    const {
      videoSrc,
      user,
      sponsoredCountry,
      sponsoredCity,
      sponsoredInfo,
      userId,
      website,
      currentUserId,
      isLoggedIn,
      businessId,
    } = this.state;
    var { followed } = this.state;
    return (
      <MDBCol
        xs="12"
        sm="12"
        md="5"
        lg="4"
        className="mb-3 mx-4 mt-3"
        style={{
          background: "#000",
          paddingLeft: 5,
          paddingRight: 5,
          borderRadius: "0.25rem",
        }}
      >
        <div>
          <div>
            <video id="sponsoredVideo" controls preload="auto">
              <source src={videoSrc} />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <h5 style={nameText}>{user}</h5>
            {isLoggedIn ? (
              <p style={locationText}>
                {sponsoredCity}, {sponsoredCountry}
              </p>
            ) : (
              <></>
            )}
            <p style={infoText}>{sponsoredInfo}</p>
            <div>
              {isLoggedIn ? (
                <div style={buttonContainer}>
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <a
                        style={{ padding: "0" }}
                        onClick={() => {
                          if (!loading && !data) {
                            profileTrackerMutation();
                          }
                        }}
                        href={`/profile/${userId}`}
                      >
                        <MDBIcon
                          style={{ marginTop: "5px" }}
                          size="2x"
                          far
                          icon="user-circle"
                          className="white-text"
                        />
                      </a>
                    )}
                  </Mutation>
                  {userId === currentUserId ? (
                    <></>
                  ) : (
                    <div>
                      <Query
                        query={FOLLOW_VIDEOS_QUERY}
                        variables={{ currentUserId }}
                      >
                        {({ loading, error, data, refetch }) => {
                          if (loading) return <div></div>;
                          if (error)
                            return (
                              <MDBBtn
                                id="btnSM"
                                outline
                                rounded
                                size="sm"
                                color="white"
                              >
                                Follow
                              </MDBBtn>
                            );
                          const numberOfFollows = data.follows.follows.length;
                          const followId = userId;
                          var i;
                          var deleteId;
                          followed = false;
                          for (i = 0; i < numberOfFollows; i++) {
                            if (data.follows.follows[i].follow === null) {
                              refetch();
                            } else {
                              if (
                                data.follows.follows[i].follow.id === followId
                              ) {
                                followed = true;
                                deleteId = data.follows.follows[i].id;
                              }
                            }
                          }
                          return (
                            <div>
                              {followed === false ? (
                                <Mutation
                                  mutation={FOLLOW_MUTATION}
                                  variables={{ followId }}
                                  refetchQueries="FOLLOW_VIDEOS_QUERY"
                                >
                                  {(followMutation, { loading }) => (
                                    <MDBBtn
                                      id="btnSM"
                                      onClick={() => {
                                        if (!loading) {
                                          followMutation();
                                          refetch();
                                        }
                                      }}
                                      outline
                                      rounded
                                      size="sm"
                                      color="white"
                                    >
                                      Follow
                                    </MDBBtn>
                                  )}
                                </Mutation>
                              ) : (
                                <Mutation
                                  mutation={DELETE_FOLLOW_MUTATION}
                                  variables={{ deleteId }}
                                  refetchQueries="FOLLOW_VIDEOS_QUERY"
                                >
                                  {(deleteFollowMutation, { loading }) => (
                                    <MDBBtn
                                      id="btnSM"
                                      onClick={() => {
                                        if (!loading) {
                                          deleteFollowMutation();
                                          refetch();
                                        }
                                      }}
                                      rounded
                                      size="sm"
                                      color="white"
                                    >
                                      Following
                                    </MDBBtn>
                                  )}
                                </Mutation>
                              )}
                            </div>
                          );
                        }}
                      </Query>
                    </div>
                  )}
                  <Mutation
                    mutation={WEBSITE_TRACKER_MUTATION}
                    variables={{
                      business: businessId,
                      user: currentUserId,
                      discountId: "Sponsored",
                    }}
                  >
                    {(websiteTrackerMutation, { loading }) => (
                      <MDBBtn
                        id="btnSM"
                        outline
                        rounded
                        color="white"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={website}
                        onClick={() => {
                          if (!loading) {
                            websiteTrackerMutation();
                          }
                        }}
                        size="sm"
                      >
                        Website
                      </MDBBtn>
                    )}
                  </Mutation>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{ borderBottom: "1px solid #f8f8ff", marginTop: 10 }}
          ></div>
        </div>
      </MDBCol>
    );
  }
}

export default SponsoredSection;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
};

const nameText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "left",
  fontSize: 18,
  marginBottom: "5px",
};

const locationText = {
  fontFamily: "Roboto",
  color: "#f8f8ff",
  marginBottom: "5px",
};

const infoText = {
  fontFamily: "Roboto",
  color: "#f8f8ff",
  textAlign: "justify",
};

const buttonContainer = {
  justifyContent: "space-around",
  display: "flex",
  marginTop: "-10px",
  alignItems: "center",
};

const showMoreButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};
