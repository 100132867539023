import React, { Component } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga";
import { Query, withApollo } from "react-apollo";
import gql from "graphql-tag";

import LoggedIn from "./components/Navigation/LoggedIn";
import LoggedOut from "./components/Navigation/LoggedOut";
import Home from "./components/Home";
import EditProfile from "./components/User/EditProfile";
import UploadDiscount from "./components/Business/UploadDiscount";
import EditDiscount from "./components/Business/EditDiscount";
import UploadVideo from "./components/User/UploadVideo";
import Search from "./components/Search/Search";
import Login from "./components/Login";
import About from "./components/Information/About";
import Testimonials from "./components/Information/Testimonials";
import Features from "./components/Information/Features";
import Contact from "./components/Information/Contact";
import Brands from "./components/Information/Brands";
import FAQ from "./components/Information/FAQ";
import Sponsored from "./components/Information/Sponsored";
import NoMatch from "./components/Errors/404";
import SurfDiscounts from "./components/Discounts/SurfDiscountsPage";
import SnowboardDiscounts from "./components/Discounts/SnowboardDiscountsPage";
import SkiDiscounts from "./components/Discounts/SkiDiscountsPage";
import ScooterDiscounts from "./components/Discounts/ScooterDiscountsPage";
import BMXDiscounts from "./components/Discounts/BMXDiscountsPage";
import MTBDiscounts from "./components/Discounts/MTBDiscountsPage";
import SkateDiscounts from "./components/Discounts/SkateDiscountsPage";
import ClimbingDiscounts from "./components/Discounts/ClimbingDiscountsPage";
import ApparelDiscounts from "./components/Discounts/ApparelDiscountsPage";
import NewDiscounts from "./components/Discounts/NewDiscountsPage";
import SurfVideos from "./components/Videos/SurfVideosPage";
import SkateVideos from "./components/Videos/SkateVideosPage";
import SnowboardVideos from "./components/Videos/SnowboardVideosPage";
import SkiVideos from "./components/Videos/SkiVideosPage";
import ScooterVideos from "./components/Videos/ScooterVideosPage";
import BMXVideos from "./components/Videos/BMXVideosPage";
import MTBVideos from "./components/Videos/MTBVideosPage";
import ClimbingVideos from "./components/Videos/ClimbingVideosPage";
import TermsAndConditions from "./components/Legal/TermsAndConditions";
import Blog from "./components/Information/Blog";
import AdvancedSearch from "./components/Business/AdvancedSearch";
import LocalAthletesVideos from "./components/Videos/LocalAthletesVideosPage";
import LocalDiscounts from "./components/Discounts/LocalDiscountsPage";
import HighestStoke from "./components/Videos/HighestStokePage";
import Profile from "./components/Profile/Profile";
import EditVideo from "./components/User/EditVideo";
import ChangePassword from "./components/User/ChangePassword";
import ResetPassword from "./components/ResetPassword";
import Competition from "./components/Videos/CompetitionsPage";
// import CompetitionGuidelines from './components/Competition/CompetitionGuidelines'
// import CompetitionGuidelinesSkate from './components/Competition/CompetitionGuidelinesSkate'
import CrewVideos from "./components/Videos/CrewVideosPage";
import VideoPage from "./components/Videos/VideoPage";
import DiscountPage from "./components/Discounts/DiscountPage";
// import Analytics from './components/Analytics/Analytics'
import CommunityGuidelines from "./components/Legal/CommunityGuidelines";
import CookiePolicy from "./components/Legal/CookiePolicy";
import DataRetentionAndErasurePolicy from "./components/Legal/DataRetentionAndErasurePolicy";
import PrivacyPolicy18 from "./components/Legal/PrivacyPolicy18";
import PrivacyPolicy13 from "./components/Legal/PrivacyPolicy13";
import PrivacyPolicy10 from "./components/Legal/PrivacyPolicy10";
import PrivacyPolicy6 from "./components/Legal/PrivacyPolicy6";
import PrivacyPolicyAll from "./components/Legal/PrivacyPolicyAll";
import LandingPageEdit from "./components/Information/LandingPageEdit";
import CreateAccount from "./components/CreateAccount";
import Map from "./components/Map/Map";
import Tutorial from "./components/Tutorial/Tutorial";
import MapEdits from "./components/Map/MapEdits";
import SponsorshipTips from "./components/Information/SponsorshipTips";
import FilmingTips from "./components/Information/FilmingTips";
import PostingTips from "./components/Information/PostingTips";
import TrustAndSafetyProcedure from "./components/Legal/TrustAndSafetyProcedure";
import EnvironmentalAndSustainabilityPolicy from "./components/Legal/EnvironmentalAndSustainabilityPolicy";
import SendEmails from "./components/Emails/SendEmails";
import Charity from "./components/Information/Charity";
import Statistics from "./components/TSH/Statistics";
import InitialSetup from "./components/InitialSetup";
import ProfileSetup from "./components/ProfileSetup";
import Loading from "./components/Loading";
import Partners from "./components/Information/Partners";

const USER_QUERY = gql`
  query {
    user {
      id
      isBusiness
      websiteCookies
      initialProfileSetup
      profileSetup
      lastSession
    }
  }
`;

class App extends Component {
  render() {
    return (
      <Query query={USER_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error || data.user == null)
            return (
              <Router>
                <LoggedOut />
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <div>
                        <LandingPageEdit />
                        <CreateAccount />
                        <Features />
                        <About />
                        <Sponsored isLoggedIn={false} />
                        <Charity />
                        <FAQ />
                        <Testimonials />
                        <Partners />
                        <Contact isLoggedIn={false} />
                      </div>
                    )}
                  />
                  <Route exact path="/login" component={Login} />
                  <Route
                    exact
                    path="/terms_and_conditions"
                    component={TermsAndConditions}
                  />
                  <Route
                    exact
                    path="/privacy_policy"
                    component={PrivacyPolicyAll}
                  />
                  <Route
                    exact
                    path="/reset_password"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/community_guidelines"
                    component={CommunityGuidelines}
                  />
                  <Route
                    exact
                    path="/cookies_policy"
                    component={CookiePolicy}
                  />
                  <Route
                    exact
                    path="/environmental_and_sustainability_policy"
                    component={EnvironmentalAndSustainabilityPolicy}
                  />
                  <Route component={Login} />
                </Switch>
              </Router>
            );

          const userId = data.user.id;
          const isBusiness = data.user.isBusiness;
          const websiteCookies = data.user.websiteCookies;
          const initialProfileSetup = data.user.initialProfileSetup;
          const profileSetup = data.user.profileSetup;

          if (websiteCookies) {
            ReactGA.initialize("UA-79256299-1");
            ReactGA.pageview(window.location.pathname + window.location.search);
          }

          if (!initialProfileSetup) {
            return (
              <Router>
                <LoggedIn />
                <Switch>
                  <Route exact path="/" component={InitialSetup} />
                  <Route
                    exact
                    path="/terms_and_conditions"
                    component={TermsAndConditions}
                  />
                  <Route
                    exact
                    path="/privacy_policy"
                    component={PrivacyPolicyAll}
                  />
                  <Route
                    exact
                    path="/privacy_policy_18+"
                    component={PrivacyPolicy18}
                  />
                  <Route
                    exact
                    path="/privacy_policy_13-17"
                    component={PrivacyPolicy13}
                  />
                  <Route
                    exact
                    path="/privacy_policy_10-12"
                    component={PrivacyPolicy10}
                  />
                  <Route
                    exact
                    path="/privacy_policy_6-9"
                    component={PrivacyPolicy6}
                  />
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </Router>
            );
          }

          if (!profileSetup) {
            return (
              <Router>
                <LoggedIn />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route
                    exact
                    path={`/profile/${userId}`}
                    render={() => (
                      <ProfileSetup userId={userId} isBusiness={isBusiness} />
                    )}
                  />
                  <Route exact path="/tutorial" component={Tutorial} />
                  <Route
                    exact
                    path="/information"
                    render={(props) => (
                      <div>
                        <Features />
                        <About />
                        <Sponsored isLoggedIn={true} />
                        <Brands />
                        <Charity />
                        <FAQ />
                        <Testimonials />
                        <Partners />
                        <Contact />
                      </div>
                    )}
                  />
                  <Route
                    exact
                    path="/terms_and_conditions"
                    component={TermsAndConditions}
                  />
                  <Route
                    exact
                    path="/privacy_policy"
                    component={PrivacyPolicyAll}
                  />
                  <Route
                    exact
                    path="/community_guidelines"
                    component={CommunityGuidelines}
                  />
                  <Route
                    exact
                    path="/cookies_policy"
                    component={CookiePolicy}
                  />
                  <Route
                    exact
                    path="/data_retention_and_erasure_policy"
                    component={DataRetentionAndErasurePolicy}
                  />
                  <Route
                    exact
                    path="/privacy_policy_18+"
                    component={PrivacyPolicy18}
                  />
                  <Route
                    exact
                    path="/privacy_policy_13-17"
                    component={PrivacyPolicy13}
                  />
                  <Route
                    exact
                    path="/privacy_policy_10-12"
                    component={PrivacyPolicy10}
                  />
                  <Route
                    exact
                    path="/privacy_policy_6-9"
                    component={PrivacyPolicy6}
                  />
                  <Route exact path="/blog" component={Blog} />
                  <Route
                    exact
                    path="/sponsorship_tips"
                    component={SponsorshipTips}
                  />
                  <Route exact path="/posting_tips" component={PostingTips} />
                  <Route exact path="/filming_tips" component={FilmingTips} />
                  <Route
                    exact
                    path="/trust_and_safety_procedure"
                    component={TrustAndSafetyProcedure}
                  />
                  <Route
                    exact
                    path="/environmental_and_sustainability_policy"
                    component={EnvironmentalAndSustainabilityPolicy}
                  />
                  <Route
                    render={() => <Redirect to={`/profile/${userId}`} />}
                  />
                </Switch>
              </Router>
            );
          }

          return (
            <div>
              <Router>
                <LoggedIn />
                {!initialProfileSetup ? (
                  <Switch>
                    <Route exact path="/" component={InitialSetup} />
                  </Switch>
                ) : userId === "ckck7xbsv025f0741j230h9w7" ? (
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/edit_profile" component={EditProfile} />
                    <Route exact path="/search" component={Search} />
                    <Route
                      exact
                      path="/information"
                      render={(props) => (
                        <div>
                          <Features />
                          <About />
                          <Sponsored isLoggedIn={true} />
                          <Brands />
                          <Charity />
                          <FAQ />
                          <Testimonials />
                          <Partners />
                          <Contact />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/surf_discounts"
                      component={SurfDiscounts}
                    />
                    <Route
                      exact
                      path="/snowboard_discounts"
                      component={SnowboardDiscounts}
                    />
                    <Route
                      exact
                      path="/ski_discounts"
                      component={SkiDiscounts}
                    />
                    <Route
                      exact
                      path="/skate_discounts"
                      component={SkateDiscounts}
                    />
                    <Route
                      exact
                      path="/climbing_discounts"
                      component={ClimbingDiscounts}
                    />
                    <Route
                      exact
                      path="/scooter_discounts"
                      component={ScooterDiscounts}
                    />
                    <Route
                      exact
                      path="/bmx_discounts"
                      component={BMXDiscounts}
                    />
                    <Route
                      exact
                      path="/mtb_discounts"
                      component={MTBDiscounts}
                    />
                    <Route
                      exact
                      path="/apparel_discounts"
                      component={ApparelDiscounts}
                    />
                    <Route
                      exact
                      path="/new_discounts"
                      component={NewDiscounts}
                    />
                    <Route exact path="/surf_edits" component={SurfVideos} />
                    <Route exact path="/skate_edits" component={SkateVideos} />
                    <Route
                      exact
                      path="/snowboard_edits"
                      component={SnowboardVideos}
                    />
                    <Route exact path="/ski_edits" component={SkiVideos} />
                    <Route
                      exact
                      path="/scooter_edits"
                      component={ScooterVideos}
                    />
                    <Route exact path="/bmx_edits" component={BMXVideos} />
                    <Route exact path="/mtb_edits" component={MTBVideos} />
                    <Route
                      exact
                      path="/climbing_edits"
                      component={ClimbingVideos}
                    />
                    <Route
                      exact
                      path="/highest_stoke_edits"
                      component={HighestStoke}
                    />
                    <Route
                      exact
                      path="/business/upload_discount"
                      component={UploadDiscount}
                    />
                    <Route
                      exact
                      path="/business/edit_discount/:id"
                      component={EditDiscount}
                    />
                    <Route
                      exact
                      path="/business/search"
                      component={AdvancedSearch}
                    />
                    <Route exact path="/profile/:id" component={Profile} />
                    <Route exact path="/upload_edit" component={UploadVideo} />
                    <Route exact path="/edit/:id" component={EditVideo} />
                    <Route
                      exact
                      path="/local_athletes"
                      component={LocalAthletesVideos}
                    />
                    <Route
                      exact
                      path="/change_password"
                      component={ChangePassword}
                    />
                    <Route
                      exact
                      path="/competition/:sportCategory/:category"
                      component={Competition}
                    />
                    {/* <Route exact path="/competition/guidelines_surf" component={CompetitionGuidelines} /> */}
                    {/* <Route exact path="/competition/guidelines_skate" component={CompetitionGuidelinesSkate} /> */}
                    <Route
                      exact
                      path="/terms_and_conditions"
                      component={TermsAndConditions}
                    />
                    <Route
                      exact
                      path="/privacy_policy"
                      component={PrivacyPolicyAll}
                    />
                    <Route
                      exact
                      path="/reset_password"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/community_guidelines"
                      component={CommunityGuidelines}
                    />
                    <Route
                      exact
                      path="/cookies_policy"
                      component={CookiePolicy}
                    />
                    <Route
                      exact
                      path="/data_retention_and_erasure_policy"
                      component={DataRetentionAndErasurePolicy}
                    />
                    <Route
                      exact
                      path="/privacy_policy_18+"
                      component={PrivacyPolicy18}
                    />
                    <Route
                      exact
                      path="/privacy_policy_13-17"
                      component={PrivacyPolicy13}
                    />
                    <Route
                      exact
                      path="/privacy_policy_10-12"
                      component={PrivacyPolicy10}
                    />
                    <Route
                      exact
                      path="/privacy_policy_6-9"
                      component={PrivacyPolicy6}
                    />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/crew_edits" component={CrewVideos} />
                    <Route exact path="/edits/:id" component={VideoPage} />
                    <Route
                      exact
                      path="/discounts/:id"
                      component={DiscountPage}
                    />
                    {/* <Route exact path="/analytics" component={Analytics} /> */}
                    <Route exact path="/tutorial" component={Tutorial} />
                    <Route exact path="/map" component={Map} />
                    <Route exact path="/map_edits/:id" component={MapEdits} />
                    <Route
                      exact
                      path="/sponsorship_tips"
                      component={SponsorshipTips}
                    />
                    <Route exact path="/posting_tips" component={PostingTips} />
                    <Route exact path="/filming_tips" component={FilmingTips} />
                    <Route
                      exact
                      path="/trust_and_safety_procedure"
                      component={TrustAndSafetyProcedure}
                    />
                    <Route
                      exact
                      path="/environmental_and_sustainability_policy"
                      component={EnvironmentalAndSustainabilityPolicy}
                    />
                    <Route exact path="/send_emails" component={SendEmails} />
                    <Route exact path="/statistics" component={Statistics} />
                    <Route component={NoMatch} />
                  </Switch>
                ) : isBusiness ? (
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/edit_profile" component={EditProfile} />
                    <Route exact path="/search" component={Search} />
                    <Route
                      exact
                      path="/information"
                      render={(props) => (
                        <div>
                          <Features />
                          <About />
                          <Sponsored isLoggedIn={true} />
                          <Brands />
                          <Charity />
                          <FAQ />
                          <Testimonials />
                          <Partners />
                          <Contact />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/surf_discounts"
                      component={SurfDiscounts}
                    />
                    <Route
                      exact
                      path="/snowboard_discounts"
                      component={SnowboardDiscounts}
                    />
                    <Route
                      exact
                      path="/ski_discounts"
                      component={SkiDiscounts}
                    />
                    <Route
                      exact
                      path="/skate_discounts"
                      component={SkateDiscounts}
                    />
                    <Route
                      exact
                      path="/climbing_discounts"
                      component={ClimbingDiscounts}
                    />
                    <Route
                      exact
                      path="/scooter_discounts"
                      component={ScooterDiscounts}
                    />
                    <Route
                      exact
                      path="/bmx_discounts"
                      component={BMXDiscounts}
                    />
                    <Route
                      exact
                      path="/mtb_discounts"
                      component={MTBDiscounts}
                    />
                    <Route
                      exact
                      path="/apparel_discounts"
                      component={ApparelDiscounts}
                    />
                    <Route
                      exact
                      path="/new_discounts"
                      component={NewDiscounts}
                    />
                    <Route exact path="/surf_edits" component={SurfVideos} />
                    <Route exact path="/skate_edits" component={SkateVideos} />
                    <Route
                      exact
                      path="/snowboard_edits"
                      component={SnowboardVideos}
                    />
                    <Route exact path="/ski_edits" component={SkiVideos} />
                    <Route
                      exact
                      path="/scooter_edits"
                      component={ScooterVideos}
                    />
                    <Route exact path="/bmx_edits" component={BMXVideos} />
                    <Route exact path="/mtb_edits" component={MTBVideos} />
                    <Route
                      exact
                      path="/climbing_edits"
                      component={ClimbingVideos}
                    />
                    <Route
                      exact
                      path="/highest_stoke_edits"
                      component={HighestStoke}
                    />
                    <Route
                      exact
                      path="/business/upload_discount"
                      component={UploadDiscount}
                    />
                    <Route
                      exact
                      path="/business/edit_discount/:id"
                      component={EditDiscount}
                    />
                    <Route
                      exact
                      path="/business/search"
                      component={AdvancedSearch}
                    />
                    <Route exact path="/profile/:id" component={Profile} />
                    <Route exact path="/upload_edit" component={UploadVideo} />
                    <Route exact path="/edit/:id" component={EditVideo} />
                    <Route
                      exact
                      path="/local_athletes"
                      component={LocalAthletesVideos}
                    />
                    <Route
                      exact
                      path="/change_password"
                      component={ChangePassword}
                    />
                    <Route
                      exact
                      path="/competition/:sportCategory/:category"
                      component={Competition}
                    />
                    {/* <Route exact path="/competition/guidelines_surf" component={CompetitionGuidelines} /> */}
                    {/* <Route exact path="/competition/guidelines_skate" component={CompetitionGuidelinesSkate} /> */}
                    <Route
                      exact
                      path="/terms_and_conditions"
                      component={TermsAndConditions}
                    />
                    <Route
                      exact
                      path="/privacy_policy"
                      component={PrivacyPolicyAll}
                    />
                    <Route
                      exact
                      path="/reset_password"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/community_guidelines"
                      component={CommunityGuidelines}
                    />
                    <Route
                      exact
                      path="/cookies_policy"
                      component={CookiePolicy}
                    />
                    <Route
                      exact
                      path="/data_retention_and_erasure_policy"
                      component={DataRetentionAndErasurePolicy}
                    />
                    <Route
                      exact
                      path="/privacy_policy_18+"
                      component={PrivacyPolicy18}
                    />
                    <Route
                      exact
                      path="/privacy_policy_13-17"
                      component={PrivacyPolicy13}
                    />
                    <Route
                      exact
                      path="/privacy_policy_10-12"
                      component={PrivacyPolicy10}
                    />
                    <Route
                      exact
                      path="/privacy_policy_6-9"
                      component={PrivacyPolicy6}
                    />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/crew_edits" component={CrewVideos} />
                    <Route exact path="/edits/:id" component={VideoPage} />
                    <Route
                      exact
                      path="/discounts/:id"
                      component={DiscountPage}
                    />
                    {/* <Route exact path="/analytics" component={Analytics} /> */}
                    <Route exact path="/tutorial" component={Tutorial} />
                    <Route exact path="/map" component={Map} />
                    <Route exact path="/map_edits/:id" component={MapEdits} />
                    <Route
                      exact
                      path="/sponsorship_tips"
                      component={SponsorshipTips}
                    />
                    <Route exact path="/posting_tips" component={PostingTips} />
                    <Route exact path="/filming_tips" component={FilmingTips} />
                    <Route
                      exact
                      path="/trust_and_safety_procedure"
                      component={TrustAndSafetyProcedure}
                    />
                    <Route
                      exact
                      path="/environmental_and_sustainability_policy"
                      component={EnvironmentalAndSustainabilityPolicy}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/edit_profile" component={EditProfile} />
                    <Route exact path="/upload_edit" component={UploadVideo} />
                    <Route exact path="/search" component={Search} />
                    <Route
                      exact
                      path="/information"
                      render={(props) => (
                        <div>
                          <Features />
                          <About />
                          <Sponsored isLoggedIn={true} />
                          <Brands />
                          <Charity />
                          <FAQ />
                          <Testimonials />
                          <Partners />
                          <Contact />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/surf_discounts"
                      component={SurfDiscounts}
                    />
                    <Route
                      exact
                      path="/snowboard_discounts"
                      component={SnowboardDiscounts}
                    />
                    <Route
                      exact
                      path="/ski_discounts"
                      component={SkiDiscounts}
                    />
                    <Route
                      exact
                      path="/skate_discounts"
                      component={SkateDiscounts}
                    />
                    <Route
                      exact
                      path="/climbing_discounts"
                      component={ClimbingDiscounts}
                    />
                    <Route
                      exact
                      path="/scooter_discounts"
                      component={ScooterDiscounts}
                    />
                    <Route
                      exact
                      path="/bmx_discounts"
                      component={BMXDiscounts}
                    />
                    <Route
                      exact
                      path="/mtb_discounts"
                      component={MTBDiscounts}
                    />
                    <Route
                      exact
                      path="/apparel_discounts"
                      component={ApparelDiscounts}
                    />
                    <Route
                      exact
                      path="/new_discounts"
                      component={NewDiscounts}
                    />
                    <Route exact path="/surf_edits" component={SurfVideos} />
                    <Route exact path="/skate_edits" component={SkateVideos} />
                    <Route
                      exact
                      path="/snowboard_edits"
                      component={SnowboardVideos}
                    />
                    <Route exact path="/ski_edits" component={SkiVideos} />
                    <Route
                      exact
                      path="/scooter_edits"
                      component={ScooterVideos}
                    />
                    <Route exact path="/bmx_edits" component={BMXVideos} />
                    <Route exact path="/mtb_edits" component={MTBVideos} />
                    <Route
                      exact
                      path="/climbing_edits"
                      component={ClimbingVideos}
                    />
                    <Route
                      exact
                      path="/highest_stoke_edits"
                      component={HighestStoke}
                    />
                    <Route
                      exact
                      path="/local_discounts"
                      component={LocalDiscounts}
                    />
                    <Route exact path="/profile/:id" component={Profile} />
                    <Route exact path="/edit/:id" component={EditVideo} />
                    <Route
                      exact
                      path="/local_athletes"
                      component={LocalAthletesVideos}
                    />
                    <Route
                      exact
                      path="/change_password"
                      component={ChangePassword}
                    />
                    <Route
                      exact
                      path="/competition/:sportCategory/:category"
                      component={Competition}
                    />
                    {/* <Route exact path="/competition/guidelines_surf" component={CompetitionGuidelines} /> */}
                    {/* <Route exact path="/competition/guidelines_skate" component={CompetitionGuidelinesSkate} /> */}
                    <Route
                      exact
                      path="/terms_and_conditions"
                      component={TermsAndConditions}
                    />
                    <Route
                      exact
                      path="/privacy_policy"
                      component={PrivacyPolicyAll}
                    />
                    <Route
                      exact
                      path="/reset_password"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/community_guidelines"
                      component={CommunityGuidelines}
                    />
                    <Route
                      exact
                      path="/cookies_policy"
                      component={CookiePolicy}
                    />
                    <Route
                      exact
                      path="/data_retention_and_erasure_policy"
                      component={DataRetentionAndErasurePolicy}
                    />
                    <Route
                      exact
                      path="/privacy_policy_18+"
                      component={PrivacyPolicy18}
                    />
                    <Route
                      exact
                      path="/privacy_policy_13-17"
                      component={PrivacyPolicy13}
                    />
                    <Route
                      exact
                      path="/privacy_policy_10-12"
                      component={PrivacyPolicy10}
                    />
                    <Route
                      exact
                      path="/privacy_policy_6-9"
                      component={PrivacyPolicy6}
                    />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/crew_edits" component={CrewVideos} />
                    <Route exact path="/edits/:id" component={VideoPage} />
                    <Route
                      exact
                      path="/discounts/:id"
                      component={DiscountPage}
                    />
                    <Route exact path="/tutorial" component={Tutorial} />
                    <Route exact path="/map" component={Map} />
                    <Route exact path="/map_edits/:id" component={MapEdits} />
                    <Route exact path="/posting_tips" component={PostingTips} />
                    <Route exact path="/filming_tips" component={FilmingTips} />
                    <Route
                      exact
                      path="/sponsorship_tips"
                      component={SponsorshipTips}
                    />
                    <Route
                      exact
                      path="/trust_and_safety_procedure"
                      component={TrustAndSafetyProcedure}
                    />
                    <Route
                      exact
                      path="/environmental_and_sustainability_policy"
                      component={EnvironmentalAndSustainabilityPolicy}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                )}
              </Router>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(App);
