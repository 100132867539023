import React from "react";
import { withRouter } from "react-router";
import {
  MDBSideNavLink,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBSideNavItem,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon,
} from "mdbreact";
import "./../../styles/Navbar.css";
import { AUTH_TOKEN } from "./../../constants";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import "./../../styles/Background.css";
import { HashLink } from "react-router-hash-link";

const USER_QUERY = gql`
  query {
    user {
      id
      isBusiness
      category
      businessLogo
      profileImage
    }
  }
`;

class LoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStateA: false,
      search: "",
      profileImageResize: true,
      businessLogoResize: true,
    };
  }

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA,
    });
  };

  render() {
    return (
      <Query query={USER_QUERY}>
        {({ loading, error, data }) => {
          if (loading || error)
            return (
              <MDBNavbar color="#000" double expand="md" fixed="top" scrolling>
                <MDBNavbarNav left>
                  <MDBNavItem style={dropOutContainer}>
                    <MDBIcon
                      onClick={this.handleToggleClickA}
                      icon="bars"
                      color="white"
                      size="2x"
                    />
                  </MDBNavItem>
                  <MDBNavItem style={linkContainer}>
                    <strong style={title}>The Sponsor Hub</strong>
                  </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                  {window.innerWidth > 576 ? (
                    <>
                      <MDBNavItem style={linkContainer}>
                        <a id="navIcon" style={linkContainer} href="/map">
                          <MDBIcon
                            fas
                            icon="map-marker-alt"
                            size="lg"
                            className="white-text"
                          />
                        </a>
                      </MDBNavItem>
                      <MDBNavItem style={linkContainer}>
                        <a
                          id="navIcon"
                          style={linkContainer}
                          href="/upload_edit"
                        >
                          <img
                            style={imgStyle}
                            src="/Navigation/camcorder.png"
                            alt="Upload Edit"
                          />
                        </a>
                      </MDBNavItem>
                      <MDBNavItem style={linkContainer}>
                        <a
                          id="navIcon"
                          style={linkContainer}
                          href="/crew_edits"
                        >
                          <MDBIcon
                            fas
                            icon="users"
                            size="lg"
                            className="white-text"
                          />
                        </a>
                      </MDBNavItem>
                      <MDBNavItem style={linkContainer}>
                        <a id="navIcon" style={linkContainer} href="/search">
                          <MDBIcon
                            fas
                            icon="search"
                            size="lg"
                            className="white-text"
                          />
                        </a>
                      </MDBNavItem>
                      <MDBNavItem style={linkContainer}>
                        <a id="navIcon" style={linkContainer} href="/">
                          <MDBIcon
                            fas
                            icon="user"
                            size="lg"
                            className="white-text"
                          />
                        </a>
                      </MDBNavItem>
                    </>
                  ) : (
                    <>
                      <MDBNavItem style={linkContainer}>
                        <a id="navIcon" style={linkContainer} href="/search">
                          <MDBIcon
                            fas
                            icon="search"
                            size="lg"
                            className="white-text"
                          />
                        </a>
                      </MDBNavItem>
                      <MDBNavItem style={linkContainer}>
                        <a id="navIcon" style={linkContainer} href="/">
                          <MDBIcon
                            fas
                            icon="user"
                            size="lg"
                            className="white-text"
                          />
                        </a>
                      </MDBNavItem>
                    </>
                  )}
                </MDBNavbarNav>
              </MDBNavbar>
            );
          const id = data.user.id;
          const isBusiness = data.user.isBusiness;
          const age = data.user.age;
          const profileImage = data.user.profileImage;
          const businessLogo = data.user.businessLogo;
          return (
            <div>
              <div className="black-skin">
                <MDBSideNav
                  triggerOpening={this.state.toggleStateA}
                  mask="strong"
                  hidden
                  href="/"
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={TSHImage}
                      src="/TSH_logos/TSH_WHITE_LOGO.jpg"
                      alt="The Sponsor Hub Logo"
                    />
                  </div>
                  <div style={sidebarIcons}>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.thesponsorhub"
                    >
                      <MDBIcon
                        fab
                        icon="google-play"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.apple.com/gm/app/the-sponsor-hub/id1576680095"
                    >
                      <MDBIcon
                        fab
                        icon="app-store"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/thesponsorhub/?hl=en"
                    >
                      <MDBIcon
                        fab
                        icon="instagram"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/TheSponsorHub/"
                    >
                      <MDBIcon
                        fab
                        icon="facebook-f"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/thesponsorhub"
                    >
                      <MDBIcon
                        fab
                        icon="twitter"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                  </div>
                  <MDBSideNavNav>
                    {isBusiness ? (
                      <MDBSideNavLink
                        style={{ marginTop: "-1rem" }}
                        onClick={this.handleToggleClickA}
                        to="/business/upload_discount"
                      >
                        <div style={links}>
                          <MDBIcon
                            icon="percentage"
                            style={{ fontSize: "1rem" }}
                            className="mr-2"
                          />
                          <p style={sidebarText}>Upload Discount</p>
                        </div>
                      </MDBSideNavLink>
                    ) : (
                      <></>
                    )}
                    <MDBSideNavLink
                      style={
                        isBusiness
                          ? { marginTop: "0.25rem" }
                          : { marginTop: "-1rem" }
                      }
                      onClick={this.handleToggleClickA}
                      to="/upload_edit"
                    >
                      <div style={links}>
                        <MDBIcon
                          icon="video"
                          style={{ fontSize: "1rem" }}
                          className="mr-2"
                        />
                        <p style={sidebarText}>Upload Edit</p>
                      </div>
                    </MDBSideNavLink>
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to={`/profile/${id}`}
                    >
                      <div style={links}>
                        <MDBIcon
                          icon="user-alt"
                          style={{ fontSize: "1rem" }}
                          className="mr-2"
                        />
                        <p style={sidebarText}>Your Profile</p>
                      </div>
                    </MDBSideNavLink>
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to="/map"
                    >
                      <div style={links}>
                        <MDBIcon
                          style={{ fontSize: "1.0rem" }}
                          fas
                          icon="map-marker-alt"
                          className="mr-2"
                        />
                        <p style={sidebarText}>TSH Map</p>
                      </div>
                    </MDBSideNavLink>
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to="/crew_edits"
                    >
                      <div style={links}>
                        <MDBIcon
                          style={{ fontSize: "1.0rem" }}
                          fas
                          icon="users"
                          className="mr-2"
                        />
                        <p style={sidebarText}>Crew Edits</p>
                      </div>
                    </MDBSideNavLink>
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to="/local_athletes"
                    >
                      <div style={links}>
                        <MDBIcon
                          icon="user"
                          style={{ fontSize: "1rem" }}
                          className="mr-2"
                        />
                        <p style={sidebarText}>Local Athletes Edits</p>
                      </div>
                    </MDBSideNavLink>
                    {!isBusiness ? (
                      <MDBSideNavLink
                        style={{ marginTop: "0.25rem" }}
                        onClick={this.handleToggleClickA}
                        to="/local_discounts"
                      >
                        <div style={links}>
                          <MDBIcon
                            icon="percentage"
                            style={{ fontSize: "1rem" }}
                            className="mr-2"
                          />
                          <p style={sidebarText}>Local Discounts</p>
                        </div>
                      </MDBSideNavLink>
                    ) : (
                      <></>
                    )}
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to="/business/search"
                    >
                      <div style={links}>
                        <MDBIcon
                          icon="search"
                          style={{ fontSize: "1rem" }}
                          className="mr-2"
                        />
                        <p style={sidebarText}>Search For Athletes</p>
                      </div>
                    </MDBSideNavLink>
                    <MDBSideNavCat
                      style={sidebarLinkContainer}
                      name="Edits"
                      id="videos-cat"
                      icon="camera-retro"
                    >
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/surf_edits"
                      >
                        <p style={sidebarSmallText}>Surf</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/skate_edits"
                      >
                        <p style={sidebarSmallText}>Skate</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/snowboard_edits"
                      >
                        <p style={sidebarSmallText}>Snowboard</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/ski_edits"
                      >
                        <p style={sidebarSmallText}>Ski</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/bmx_edits"
                      >
                        <p style={sidebarSmallText}>BMX</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/mtb_edits"
                      >
                        <p style={sidebarSmallText}>MTB</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/scooter_edits"
                      >
                        <p style={sidebarSmallText}>Scooter</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/climbing_edits"
                      >
                        <p style={sidebarSmallText}>Climbing</p>
                      </MDBSideNavItem>
                    </MDBSideNavCat>
                    <MDBSideNavCat
                      style={sidebarLinkContainer}
                      name="Discounts"
                      id="discount-cat"
                      icon="percentage"
                    >
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/new_discounts"
                      >
                        <p style={sidebarSmallText}>New</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/surf_discounts"
                      >
                        <p style={sidebarSmallText}>Surf</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/skate_discounts"
                      >
                        <p style={sidebarSmallText}>Skate</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/snowboard_discounts"
                      >
                        <p style={sidebarSmallText}>Snowboard</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/ski_discounts"
                      >
                        <p style={sidebarSmallText}>Ski</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/bmx_discounts"
                      >
                        <p style={sidebarSmallText}>BMX</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/mtb_discounts"
                      >
                        <p style={sidebarSmallText}>MTB</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/scooter_discounts"
                      >
                        <p style={sidebarSmallText}>Scooter</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/climbing_discounts"
                      >
                        <p style={sidebarSmallText}>Climbing</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem
                        onClick={this.handleToggleClickA}
                        href="/apparel_discounts"
                      >
                        <p style={sidebarSmallText}>Apparel</p>
                      </MDBSideNavItem>
                    </MDBSideNavCat>
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to="/highest_stoke_edits"
                    >
                      <div style={links}>
                        <MDBIcon
                          icon="camera-retro"
                          style={{ fontSize: "1rem" }}
                          className="mr-2"
                        />
                        <p style={sidebarText}>Highest Stoke</p>
                      </div>
                    </MDBSideNavLink>
                    {/* <MDBSideNavCat style={sidebarLinkContainer} name="Surf Competition" id="competition-cat">
                      <MDBSideNavItem href="/competition/guidelines_surf"><p style={sidebarSmallText}>Details</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Surf/15andundermale"><p style={sidebarSmallText}>15 and under Male</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Surf/15andunderfemale"><p style={sidebarSmallText}>15 and under Female</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Surf/16+male"><p style={sidebarSmallText}>16+ Male</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Surf/16+female"><p style={sidebarSmallText}>16+ Female</p></MDBSideNavItem>
                    </MDBSideNavCat>
                    <MDBSideNavCat style={sidebarLinkContainer} name="Skate Competition" id="competition-cat">
                      <MDBSideNavItem href="/competition/guidelines_skate"><p style={sidebarSmallText}>Guidelines</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Skate/15andundermale"><p style={sidebarSmallText}>15 and under Male</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Skate/15andunderfemale"><p style={sidebarSmallText}>15 and under Female</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Skate/16+male"><p style={sidebarSmallText}>16+ Male</p></MDBSideNavItem>
                      <MDBSideNavItem href="/competition/Skate/16+female"><p style={sidebarSmallText}>16+ Female</p></MDBSideNavItem>
                    </MDBSideNavCat> */}
                    <MDBSideNavCat
                      style={sidebarLinkContainer}
                      name="Information"
                      icon="info-circle"
                      id="information-cat"
                    >
                      <li style={{ marginLeft: "47px", marginTop: "10px" }}>
                        <HashLink
                          className="Ripple-parent"
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#about-us"
                        >
                          <p style={sidebarSmallText}>About Us</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#brands"
                        >
                          <p style={sidebarSmallText}>Brands</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#sponsored"
                        >
                          <p style={sidebarSmallText}>Sponsored Athletes</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#testimonials"
                        >
                          <p style={sidebarSmallText}>Testimonials</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#features"
                        >
                          <p style={sidebarSmallText}>Features</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#supported-charities"
                        >
                          <p style={sidebarSmallText}>Supported Charities</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#partners"
                        >
                          <p style={sidebarSmallText}>Partners</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#faq"
                        >
                          <p style={sidebarSmallText}>FAQ</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <a href="/blog" style={{ padding: 0 }}>
                          <p style={sidebarSmallText}>Blog</p>
                        </a>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <HashLink
                          style={{ padding: 0 }}
                          onClick={this.handleToggleClickA}
                          to="/information#contact"
                        >
                          <p style={sidebarSmallText}>Contact Us</p>
                        </HashLink>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <a href="/community_guidelines" style={{ padding: 0 }}>
                          <p style={sidebarSmallText}>Community Guidelines</p>
                        </a>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <a href="/sponsorship_tips" style={{ padding: 0 }}>
                          <p style={sidebarSmallText}>Sponsorship Tips</p>
                        </a>
                      </li>
                      <li style={{ marginLeft: "47px" }}>
                        <a href="/posting_tips" style={{ padding: 0 }}>
                          <p style={sidebarSmallText}>Posting Tips</p>
                        </a>
                      </li>
                      <li
                        style={{ marginLeft: "47px", marginBottom: "-10px`" }}
                      >
                        <a href="/filming_tips" style={{ padding: 0 }}>
                          <p style={sidebarSmallText}>Filming Tips</p>
                        </a>
                      </li>
                    </MDBSideNavCat>
                    <MDBSideNavCat
                      style={sidebarLinkContainer}
                      name="Legal"
                      id="legal-cat"
                    >
                      <MDBSideNavItem href="/terms_and_conditions">
                        <p style={sidebarSmallText}>Terms And Conditions</p>
                      </MDBSideNavItem>
                      {age === "6-9" ? (
                        <MDBSideNavItem href="/privacy_policy_6-9">
                          <p style={sidebarSmallText}>Privacy Policy</p>
                        </MDBSideNavItem>
                      ) : age === "10-12" ? (
                        <MDBSideNavItem href="/privacy_policy_10-12">
                          <p style={sidebarSmallText}>Privacy Policy</p>
                        </MDBSideNavItem>
                      ) : age === "13-17" ? (
                        <MDBSideNavItem href="/privacy_policy_13-17">
                          <p style={sidebarSmallText}>Privacy Policy</p>
                        </MDBSideNavItem>
                      ) : age === "18+" ? (
                        <MDBSideNavItem href="/privacy_policy_18+">
                          <p style={sidebarSmallText}>Privacy Policy</p>
                        </MDBSideNavItem>
                      ) : (
                        <MDBSideNavItem href="/privacy_policy">
                          <p style={sidebarSmallText}>Privacy Policy</p>
                        </MDBSideNavItem>
                      )}
                      <MDBSideNavItem href="/cookies_policy">
                        <p style={sidebarSmallText}>Cookies Policy</p>
                      </MDBSideNavItem>
                      <MDBSideNavItem href="/trust_and_safety_procedure">
                        <p style={sidebarSmallText}>
                          Trust and Safety Procedure
                        </p>
                      </MDBSideNavItem>
                      <MDBSideNavItem href="/data_retention_and_erasure_policy">
                        <p style={sidebarSmallText}>
                          Data Retention and Erasure Policy
                        </p>
                      </MDBSideNavItem>
                      <MDBSideNavItem href="/environmental_and_sustainability_policy">
                        <p style={sidebarSmallText}>
                          Environmental And Sustainability Policy
                        </p>
                      </MDBSideNavItem>
                    </MDBSideNavCat>
                    <MDBSideNavLink
                      style={{ marginTop: "0.25rem" }}
                      onClick={this.handleToggleClickA}
                      to={`/tutorial`}
                    >
                      <p style={sidebarText}>Tutorial</p>
                    </MDBSideNavLink>
                    <MDBNavItem>
                      <div
                        style={logoutButton}
                        onClick={() => {
                          localStorage.removeItem(AUTH_TOKEN);
                          window.location.href = "/";
                        }}
                      >
                        <p style={logoutText}>logout</p>
                      </div>
                    </MDBNavItem>
                  </MDBSideNavNav>
                </MDBSideNav>
                <MDBNavbar
                  color="#000"
                  double
                  expand="md"
                  fixed="top"
                  scrolling
                >
                  <MDBNavbarNav left>
                    <MDBNavItem style={dropOutContainer}>
                      <MDBIcon
                        onClick={this.handleToggleClickA}
                        icon="bars"
                        color="white"
                        size="2x"
                      />
                    </MDBNavItem>
                    <MDBNavItem style={linkContainer}>
                      <Link style={titleLink} to="/">
                        <strong style={title}>The Sponsor Hub</strong>
                      </Link>
                    </MDBNavItem>
                  </MDBNavbarNav>
                  <MDBNavbarNav right>
                    {window.innerWidth > 576 ? (
                      <>
                        <MDBNavItem style={linkContainer}>
                          <a id="navIcon" style={linkContainer} href="/map">
                            <MDBIcon
                              fas
                              icon="map-marker-alt"
                              size="lg"
                              className="white-text"
                            />
                          </a>
                        </MDBNavItem>
                        {isBusiness ? (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainer}
                              href="/business/upload_discount"
                            >
                              <MDBIcon
                                fas
                                icon="percentage"
                                size="lg"
                                className="white-text"
                              />
                            </a>
                          </MDBNavItem>
                        ) : (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainer}
                              href="/new_discounts"
                            >
                              <MDBIcon
                                fas
                                icon="percentage"
                                size="lg"
                                className="white-text"
                              />
                            </a>
                          </MDBNavItem>
                        )}
                        <MDBNavItem style={linkContainer}>
                          <a
                            id="navIcon"
                            style={linkContainer}
                            href="/upload_edit"
                          >
                            <img
                              style={imgStyle}
                              src="/Navigation/camcorder.png"
                              alt="Upload Edit"
                            />
                          </a>
                        </MDBNavItem>
                        <MDBNavItem style={linkContainer}>
                          <a
                            id="navIcon"
                            style={linkContainer}
                            href="/crew_edits"
                          >
                            <MDBIcon
                              fas
                              icon="users"
                              size="lg"
                              className="white-text"
                            />
                          </a>
                        </MDBNavItem>
                        <MDBNavItem style={linkContainer}>
                          <a id="navIcon" style={linkContainer} href="/search">
                            <MDBIcon
                              fas
                              icon="search"
                              size="lg"
                              className="white-text"
                            />
                          </a>
                        </MDBNavItem>
                        {businessLogo !== "" &&
                        businessLogo !== null &&
                        isBusiness ? (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainer}
                              href={`/profile/${id}`}
                            >
                              <img
                                style={userImg}
                                src={
                                  this.state.businessLogoResize
                                    ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${id}_50x50.jpeg`
                                    : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${businessLogo}`
                                }
                                onError={() =>
                                  this.setState({ businessLogoResize: false })
                                }
                                alt="Business Logo"
                              ></img>
                            </a>
                          </MDBNavItem>
                        ) : profileImage !== "" &&
                          profileImage !== null &&
                          !isBusiness ? (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainer}
                              href={`/profile/${id}`}
                            >
                              <img
                                style={userImg}
                                src={
                                  this.state.profileImageResize
                                    ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${id}/${id}_50x50.jpeg`
                                    : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${id}/${profileImage}`
                                }
                                onError={() =>
                                  this.setState({ profileImageResize: false })
                                }
                                alt="Profile"
                              ></img>
                            </a>
                          </MDBNavItem>
                        ) : (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainer}
                              href={`/profile/${id}`}
                            >
                              <MDBIcon
                                fas
                                icon="user"
                                size="lg"
                                className="white-text"
                              />
                            </a>
                          </MDBNavItem>
                        )}
                      </>
                    ) : (
                      <>
                        <MDBNavItem style={linkContainer}>
                          <a id="navIcon" style={linkContainer} href="/search">
                            <MDBIcon
                              fas
                              icon="search"
                              size="lg"
                              className="white-text"
                            />
                          </a>
                        </MDBNavItem>
                        {businessLogo !== "" &&
                        businessLogo !== null &&
                        isBusiness ? (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainerCollapse}
                              href={`/profile/${id}`}
                            >
                              <img
                                style={userImg}
                                src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${businessLogo}`}
                                alt="Business Logo"
                              ></img>
                            </a>
                          </MDBNavItem>
                        ) : profileImage !== "" &&
                          profileImage !== null &&
                          !isBusiness ? (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainerCollapse}
                              href={`/profile/${id}`}
                            >
                              <img
                                style={userImg}
                                src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${id}/${profileImage}`}
                                alt="Profile"
                              ></img>
                            </a>
                          </MDBNavItem>
                        ) : (
                          <MDBNavItem style={linkContainer}>
                            <a
                              id="navIcon"
                              style={linkContainerCollapse}
                              href={`/profile/${id}`}
                            >
                              <MDBIcon
                                fas
                                icon="user"
                                size="lg"
                                className="white-text"
                              />
                            </a>
                          </MDBNavItem>
                        )}
                      </>
                    )}
                  </MDBNavbarNav>
                </MDBNavbar>
              </div>
              <div></div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(LoggedIn);

const title = {
  verticalAlign: "middle",
  fontSize: "24px",
  textTransform: "uppercase",
  fontFamily: "Bebas Neue",
  color: "#f8f8ff",
  marginTop: "0.22rem",
};

const dropOutContainer = {
  display: "flex",
  alignItems: "center",
  marginRight: "1rem",
};

const linkContainer = {
  display: "flex",
  alignItems: "center",
};

const linkContainerCollapse = {
  display: "flex",
  alignItems: "center",
  paddingRight: 0,
};

const titleLink = {
  padding: 0,
  display: "flex",
  alignItems: "center",
};

const imgStyle = {
  height: "1.333em",
  objectFit: "cover",
};

const TSHImage = {
  width: "50%",
  height: "auto",
  objectFit: "cover",
};

const sidebarIcons = {
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "-0.5rem",
};

const sidebarText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  display: "inline",
  fontSize: "1rem",
  marginBottom: 0,
};

const sidebarLinkContainer = {
  fontWeight: "400",
  fontSize: "1rem",
  color: "#f8f8ff",
  fontFamily: "Oswald",
  marginTop: "0.25rem",
};

const sidebarSmallText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  fontSize: "0.9rem",
};

const logoutButton = {
  marginTop: "1.25rem",
  textAlign: "center",
  justifyContent: "center",
  display: "flex",
};

const logoutText = {
  border: "1px solid #f8f8ff",
  borderRadius: "1rem",
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textTransform: "uppercase",
  paddingTop: "0.05rem",
  paddingBottom: "0.15rem",
  width: "50%",
  fontSize: "15px",
};

const userImg = {
  height: 30,
  width: 30,
  objectFit: "cover",
  borderRadius: "0.4rem",
  border: "1px solid #f8f8ff",
};

const links = {
  display: "flex",
  alignItems: "center",
};
