import React from "react";
import "./../../styles/Legal.css";
import { MDBCol } from "mdbreact";

const PrivacyPolicy13 = () => {
  return (
    <div
      className="px-3 pt-4"
      style={{ backgroundColor: "#fff", textAlign: "justify" }}
    >
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h2 className="legal-title">Privacy Policy for 13 - 17 year olds</h2>
          <h3 className="legal-subheading">Who we are</h3>
          <p className="legal-text">
            Welcome to The Sponsor Hub. We are an extreme sports digital
            marketing and social media platform where athletes can publish their
            skills and talents to the world, meet like-minded sportspersons, and
            hook up with extreme sports brands and other sponsors.
          </p>
          <br />
          <p className="legal-text">
            <a className="legal-link" href="https://thesponsorhub.com/">
              <p className="legal-text">https://thesponsorhub.com/</p>
            </a>{" "}
            (<p className="legal-text-bolder">the/our website</p>) is provided
            by The Sponsor Hub Limited (
            <p className="legal-text-bolder">The Sponsor Hub, we, us, our</p>).
            We are a private limited company registered in England & Wales under
            company number 11467568, registered office at Walk Mill,
            Kirkwhelpington, Newcastle upon Tyne, Northumberland, England, NE19
            2SB.
          </p>
          <br />
          <h3 className="legal-subheading">
            Your information and your privacy
          </h3>
          <p className="legal-text">
            The website will collect some of the information you input into the
            website and send some other information about you to us. We call
            that information ‘
            <p className="legal-text-bolder">your information</p>’. We are
            responsible for deciding how and why your information is used.
          </p>
          <br />
          <p className="legal-text">
            We take protecting your information and privacy very seriously.
            There are strong laws in the UK designed to protect your information
            and we will always comply with those. Those law are called the UK
            GDPR. You can find more information about them{" "}
            <a className="legal-link" href="privacy_policy_18+">
              <p className="legal-text">here</p>
            </a>
            .
          </p>
          <br />
          <p className="legal-text">
            Please read this privacy policy carefully as it contains important
            information about why we collect and use your information and how we
            protect it.
          </p>
          <br />
          <p className="legal-text">
            It also explains your rights in relation to your information. And
            how you can contact us if you have any questions or worries.
          </p>
          <br />
          <p className="legal-text">
            This policy is written for children aged 13 – 18 years old. If you
            are not aged between 13 and 17, we have other information that you
            might find more helpful. We recommend you read the information most
            suitable for you:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                if you are aged{" "}
                <a className="legal-link" href="privacy+policy_6-9">
                  <p className="legal-text">6 – 9 years</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                if you are aged{" "}
                <a className="legal-link" href="privacy_policy_10-12">
                  <p className="legal-text">10 – 12 years</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                if you are a{" "}
                <a className="legal-link" href="privacy_policy_18+">
                  <p className="legal-text">parent, guardian or adult</p>
                </a>
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            The information aimed at older people gives increasing levels of
            detail, and information for younger children gives less detail. If
            you want more information, you can check out the adult privacy
            policy{" "}
            <a className="legal-link" href="privacy_policy_18+">
              <p className="legal-text">here</p>
            </a>
            .
          </p>
          <br />
          <p className="legal-text">
            You should read through this policy with a parent or guardian to
            help you to understand it.{" "}
          </p>
          <br />
          <p className="legal-text">
            For some uses of your information we may ask your permission. If you
            are aged 13 or over, you can give your permission yourself although
            we advise that you speak to your parent or guardian prior to giving
            your permission.
          </p>
          <br />
          <p className="legal-text">
            If you are aged under 13, we need the consent of your parent or
            guardian.
          </p>
          <br />
          <p className="legal-text">
            If you are aged under 18, we recommend that you speak to an adult
            that you trust if you have any difficulties reaching an informed
            decision regarding any use of your information or our treatment of
            your information. If you are not sure about anything, particularly
            about giving consent, you should also speak to your parent or
            guardian.
          </p>
          <br />
          <p className="legal-text">
            The website can be used by people all over the world.{" "}
          </p>
          <br />
          <h3 className="legal-subheading">What this policy applies to</h3>
          <p className="legal-text">
            This privacy policy covers your use of the website.
          </p>
          <br />
          <p className="legal-text">
            Other websites or services will collect and use your information in
            different ways and you should visit their websites or notices to
            find out more. So for example if you see a brand that you like on
            our app, that brand will have its own privacy policy and if you are
            going to visit the brand’s website you should check out it’s privacy
            policy.
          </p>
          <br />
          <h3 className="legal-subheading">
            What happens when you upload content?
          </h3>
          <p className="legal-text">
            When you upload footage, edits and content about yourself and your
            sporting activity, other website and app users will be able to see
            and to share the uploads. Also, we may repost and share your uploads
            on our app and also our website{" "}
            <a className="legal-link" href="https://thesponsorhub.com">
              <p className="legal-text">https://thesponsorhub.com/</p>
            </a>
            . And we may share your information with third parties.
          </p>
          <br />
          <p className="legal-text">
            When you upload your content you give us your consent to all of
            that.
          </p>
          <br />
          <p className="legal-text">
            If you are under 13 you must speak to your parent or guardian before
            uploading any videos, edits or other content on the website.
          </p>
          <br />
          <h3 className="legal-subheading">Information we collect about you</h3>
          <p className="legal-text">
            The information we collect about you depends on the particular
            activities that are carried out by you through the website. It will
            include:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                your contact information, like your name, address, email
                address, telephone number
              </p>
            </li>
            <li>
              <p className="legal-text">
                identity information like date of birth, gender, your Sponsor
                Hub account log in details, social media handles
              </p>
            </li>
            <li>
              <p className="legal-text">
                your location data, like country and city
              </p>
            </li>
            <li>
              <p className="legal-text">
                details we can see from how you use the website, such as things
                we can tell you like or dislike, and when you use the website
              </p>
            </li>
            <li>
              <p className="legal-text">
                facts about your device, like the numbers used to identify your
                device on the internet and what operating system your device
                uses
              </p>
            </li>
          </ul>
          <p className="legal-text">
            Sometimes, you will be able to choose if you want to provide some of
            the information, but in other cases we need the information to
            provide services or functions on the app. If you choose not to
            provide information that we tell you is ‘required’, it may delay or
            prevent us from providing services to you. For example, if you don’t
            provide information required to register an account with us, you
            wont be able to use the website.
          </p>
          <br />
          <p className="legal-text">
            For a more detailed picture of the information we may collect from
            you please click{" "}
            <a className="legal-link" href="privacy_policy_18+">
              <p className="legal-text">here</p>
            </a>
            .
          </p>
          <br />
          <h3 className="legal-subheading">
            How your information is collected
          </h3>
          <p className="legal-text">
            We will collect information from you directly when you enter
            information on the app or send us information, for example when you
            sign up to an account, when you log in, when you post content, when
            send us feedback, when you enter our competitions, sign up for
            marketing emails, use mobile devices to access content on our app or
            website, and when you otherwise contact us via email or social
            media.
          </p>
          <br />
          <p className="legal-text">
            We also collect information automatically when you access our app
            using cookies and other similar technologies (please see our{" "}
            <a className="legal-link" href="cookies_policy">
              <p className="legal-text">Cookie Policy</p>
            </a>{" "}
            for further information) when you browse our website and through
            your activity on the website.
          </p>
          <br />
          <h3 className="legal-subheading">
            How and why we use your information
          </h3>
          <p className="legal-text">
            We can only use your information if we have a valid reason to do so.
            The valid reasons we rely on are:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">to comply with the law</p>
            </li>
            <li>
              <p className="legal-text">
                we have agreed to provide you with a service
              </p>
            </li>
            <li>
              <p className="legal-text">
                if we have a good reason to use your information, and this is
                not overridden by your own rights and interests (this is also
                known as our ‘legitimate interests’). In this case, we will
                carefully consider our needs and yours to make sure we have got
                the balance right. You can ask for details of this by contacting
                us (see ‘<p className="legal-text-bolder">How to contact us</p>
                ’).
              </p>
            </li>
            <li>
              <p className="legal-text">
                because your parent or guardian has given consent.
              </p>
            </li>
          </ul>
          <p className="legal-text">
            The table below explains what we use your information for and why.
          </p>
          <br />
          <table>
            <tr>
              <th>
                <p className="legal-text-bolder">
                  What we use your information for
                </p>
              </th>
              <th>
                <p className="legal-text-bolder">Why we can do so</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Create and manage your account with us
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  We have a good reason, which is to deliver the best service to
                  you
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">Providing the website to you</p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      to make or perform an agreement with you
                    </p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      we have a good reason, which is to deliver the best
                      service to you{" "}
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To check who is using the website or to prevent criminals
                  misusing your account or information
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <br />
                <ul>
                  <li>
                    <p className="legal-text">to comply with the law</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      we have a good reason, which is to minimise the risk of
                      crime
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">To make or defend legal claims</p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <br />
                <ul>
                  <li>
                    <p className="legal-text">to comply with the law</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      we have a good reason, which is to protect people and
                      their rights
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To send important messages to you, for example about changes
                  to our terms or policies, changes to the website or service,
                  or other important notices
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <br />
                <ul>
                  <li>
                    <p className="legal-text">to comply with the law</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      we have a good reason, which is to provide the best
                      service to you
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To protect computers, devices and the information they hold
                </p>
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p className="legal-text">To comply with the law</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      We also use your information to provide more protections
                      than required by law where we have a good reason to
                      prevent criminals causing harm to you, us or other people
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To run and improve the website and our services
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  We have a good reason, which is to deliver the best service to
                  you
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To help us understand what types of people like to use our
                  website and how we can deliver it in a lower cost way
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  We have a good reason, which is to deliver the best service to
                  you
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To make sure the information we have about you is correct and
                  up-to-date
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <ul>
                  <li>
                    <p className="legal-text">
                      to make or perform an agreement with you
                    </p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">to comply with the law</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      in other cases, because we have a good reason, eg making
                      sure that we can keep in touch with you
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Marketing our services to website users.
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  For our legitimate interests, ie to promote our business to
                  website users
                </p>
                <br />
                <p className="legal-text">
                  See ‘<p className="legal-text-bolder">Marketing</p>’ below for
                  further information.
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Other things we need to do to comply with law, for example to
                  record your parent’s or guardian’s consent to our use of your
                  information where relevant
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">To comply with the law</p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To share your information with others in connection with major
                  changes to our business, such as if it was sold or moved to
                  other owners
                </p>
                <br />
                <p className="legal-text">
                  In such cases, information will be changed by us so that you
                  cannot be identified before we share it wherever possible and
                  only shared where necessary
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <ul>
                  <li>
                    <p className="legal-text">to comply with the law</p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      we have a good reason, which is to protect, get or grow
                      the value in our business and assets
                    </p>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
          </table>
          <h3 className="legal-subheading">Marketing</h3>
          <p className="legal-text">
            We will use your information to send you updates about our website,
            including exclusive discounts, offers, competitions or new features
            available.
          </p>
          <br />
          <p className="legal-text">
            We make sure that any information or marketing that we send to under
            18s is always age appropriate.
          </p>
          <br />
          <p className="legal-text">
            We have a legitimate interest in using your information for
            marketing purposes (see above ‘
            <p className="legal-text-bolder">
              How and why we use your information
            </p>
            ’). This means we do not necessarily need your consent to send you
            marketing information in certain circumstances, however it is our
            practice to ask for your consent to send your marketing and we will
            do so clearly.{" "}
          </p>
          <br />
          <p className="legal-text">
            If you are under 13 we will need your parent or guardian’s consent
            to us sending you marketing information.
          </p>
          <br />
          <p className="legal-text">
            You have the right to opt out of receiving marketing communications
            at any time by:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                contacting us at{" "}
                <a className="legal-link" href="mailto:info@thesponsorhub.com">
                  <p className="legal-text">info@thesponsorhub.com</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                using the ‘unsubscribe’ link in emails; or
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                updating your marketing preferences in your account
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            We will always treat your information with the utmost respect and we
            will never sell it to other organisations for marketing purposes.
          </p>
          <br />
          <p className="legal-text">
            For more information on your right to object at any time to your
            information being used for marketing purposes, see ‘
            <p className="legal-text-bolder">Your rights</p>’ below.
          </p>
          <br />
          <h3 className="legal-subheading">
            Who we share your information with
          </h3>
          <p className="legal-text">
            We will usually share your information with:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                other companies we work with to help us run our business, and
                provide the app and the website, and store the information you
                provide to us and help us understand how the website is used.
              </p>
            </li>
            <li>
              <p className="legal-text">
                brands who are on our website and who may see your photos,
                videos and edits.
              </p>
            </li>
          </ul>
          <p className="legal-text">
            We or the businesses mentioned above also need to share your
            information with:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                people who advise us or the companies mentioned above (such as
                lawyers)
              </p>
            </li>
            <li>
              <p className="legal-text">
                others in connection with major changes to our business, such as
                if it was sold or moved to other owners.
              </p>
            </li>
          </ul>
          <p className="legal-text">
            Please note that if a brand likes your uploads and wants to offer
            you goods, services, or even sponsorship, we may contact you
            directly (by email, text or telephone) and ask you for your express
            permission to share you contact details with the brand. You should
            always speak to your parent or guardian for advice when you contact
            you regarding a brand.
          </p>
          <br />
          <p className="legal-text">
            We will only allow the other companies and people mentioned above to
            handle your information if we know they will take care of it. For
            example, this means they should not look at your information unless
            there is a good reason to and will try hard to stop anyone from
            stealing it. We will take steps to make sure this happens.
          </p>
          <br />
          <p className="legal-text">
            We may also share your information with social media such as Twitter
            Facebook and Instagram. For more information on how social media
            organisations use your information please read their privacy
            policies.
          </p>
          <br />
          <p className="legal-text">
            Also, other website and app users may view your videos, content,
            edits and your name when you upload and post content. All users
            within the Sponsor Hub community may see and share each other's
            uploads, except ‘Your Crew’ feature edits which can only been and
            shared with the members of ‘Your Crew’ Group and us.{" "}
          </p>
          <br />
          <p className="legal-text">
            We and the companies and people we share your information with also
            share your information with others if the law says we must (for
            example the police).
          </p>
          <br />
          <p className="legal-text">
            More detailed information on who we share your information with is
            available{" "}
            <a className="legal-link" href="privacy_policy_18+">
              <p className="legal-text">here</p>
            </a>
            .
          </p>
          <br />
          <p className="legal-text">
            If you would like more information about who we share your
            information with and why, you can also contact us (see ‘
            <p className="legal-text-bolder">How to contact us</p>’).
          </p>
          <br />
          <h3 className="legal-subheading">
            How long your information will be kept
          </h3>
          <p className="legal-text">
            We will only keep your information as long as we need to. How long
            we need your information depends on what we are using it for, as set
            out in this privacy policy. For example, we may need to use it to
            continue providing our website and services to you as long as you
            are an website user and have an account with us. If we no longer
            need your data, we will delete it or make it anonymous by removing
            all details that identify you.{" "}
          </p>
          <br />
          <h3 className="legal-subheading">
            Transferring your information out of the UK
          </h3>
          <p className="legal-text">
            Countries outside the UK have different laws to protect information,
            some of which provide lower levels of protection than the law in the
            UK.
          </p>
          <br />
          <p className="legal-text">
            We sometimes need to transfer your information to countries outside
            the UK. The law says that we can only do that if certain protections
            are in place.
          </p>
          <br />
          <p className="legal-text">
            For example, we will transfer your information from the UK to:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                companies that help us provide the app which are located outside
                the UK, and
              </p>
            </li>
            <li>
              <p className="legal-text">brands who are based outside the UK</p>
            </li>
          </ul>
          <p className="legal-text">
            We will only transfer your information if the UK has decided there
            are good enough protections in place for your information — either
            because:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                the UK has decided laws in the country your information is
                transferred to are strong enough, (known as an ‘adequacy’
                finding), or
              </p>
            </li>
            <li>
              <p className="legal-text">
                in the case of all other countries your information is
                transferred to, which do not have adequacy findings, because we
                have put agreements in place to ensure your information is kept
                safe as required by UK law
              </p>
            </li>
          </ul>
          <p className="legal-text">
            The rules about transfers of information outside the UK are
            complicated. More detailed information on the transfer of your
            information outside the UK is available{" "}
            <a className="legal-link" href="privacy_policy_18+">
              <p className="legal-text">here</p>
            </a>
            .
          </p>
          <br />
          <p className="legal-text">
            We will let you know about any changes to where we send your
            information—see the section on ‘
            <p className="legal-text-bolder">Changes to privacy information</p>
            ’.
          </p>
          <br />
          <p className="legal-text">
            You understand that by posting your content on our app, brands
            worldwide may see it, including your name. Should you decide to
            engage directly with a brand on our website which is based outside
            of the UK, for products or services including sponsorship, the data
            protection laws in the country in which that brand is based shall
            apply to their handling of your information. You should therefore
            read and be comfortable with their policy on data protection.
          </p>
          <br />
          <h3 className="legal-subheading">Your rights</h3>
          <p className="legal-text">
            You generally have the following rights, which you can usually
            exercise free of charge:
          </p>
          <br />
          <table>
            <tr>
              <td>
                <p className="legal-text">
                  Access to a copy of your information
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to be provided with a copy of your information
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Correction (also known as rectification)
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to require us to correct any mistakes in your
                  information
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Erasure (also known as the right to be forgotten)
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to require us to delete your information — in
                  certain situations
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">Restriction of use</p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to require us to restrict use of your information in
                  certain circumstances, eg if you contest the accuracy of the
                  data
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">Data portability</p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to receive your information that you provided to us,
                  in a structured, commonly used and machine-readable format
                  and/or transmit that data to a third party — in certain
                  situations
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">To object to use</p>
                <br />
              </td>
              <td>
                <p className="legal-text">The right to object:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      at any time to your information being used for direct
                      marketing (including profiling)
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      in certain other situations to our continued use of your
                      information, eg where we use your information for our
                      legitimate interests unless there are compelling
                      legitimate grounds for the processing to continue or the
                      processing is required for the establishment, exercise or
                      defence of legal claims
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Not to be subject to decisions without human involvement
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right not to be subject to a decision based solely on
                  automated processing (including profiling) that produces legal
                  effects concerning you or similarly significantly affects you
                </p>
                <br />
                <p className="legal-text">
                  We do not make any such decisions based on data collected by
                  the website
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">The right to withdraw consents</p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  If you have provided us with a consent to use your
                  information, you have a right to withdraw that consent easily
                  at any time by contacting us (see '
                  <p className="legal-text-bolder">Contact Us</p>' below) or by
                  any other method described in this privacy policy.
                </p>
                <br />
                <p className="legal-text">
                  Withdrawing a consent will not affect the lawfulness of our
                  use of your information in reliance on that consent before it
                  was withdrawn
                </p>
                <br />
              </td>
            </tr>
          </table>
          <p className="legal-text">
            For further information on each of those rights please click{" "}
            <a className="legal-link" href="privacy_policy_18+">
              <p className="legal-text">here</p>
            </a>
            . You may also contact us (see{" "}
            <p className="legal-text-bolder">How to contact us</p> below) and
            send us any questions you have.
          </p>
          <br />
          <p className="legal-text">
            If you would like to exercise any of those rights please contact us
            by email, call or write to us — see below: ‘
            <p className="legal-text-bolder">How to contact us</p>’. When
            contacting us please:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                provide your full name, date of birth, address, and email
                address; and
              </p>
            </li>
            <li>
              <p className="legal-text">
                say which right(s) you want to exercise and the information to
                which your request relates.
              </p>
            </li>
          </ul>
          <h3 className="legal-subheading">Keeping your information secure</h3>
          <p className="legal-text">
            We have appropriate security measures to prevent your information
            from being accidentally lost, or used or accessed unlawfully. We
            limit access to your information to those who have a genuine
            business need to access it.
          </p>
          <br />
          <p className="legal-text">
            We also have procedures in place to deal with any suspected data
            security breach. We will notify you and any applicable regulator of
            a suspected data security breach where we are legally required to do
            so.
          </p>
          <br />
          <p className="legal-text">
            If you want detailed guidance from Get Safe Online on how to protect
            your information and other information and your computers and
            devices against fraud, identity theft, viruses and many other online
            problems, please visit{" "}
            <a className="legal-link" href="www.getsafeonline.org">
              <p className="legal-text">www.getsafeonline.org</p>
            </a>
            . Get Safe Online is supported by HM Government and leading
            businesses.
          </p>
          <br />
          <h3 className="legal-subheading">
            Special Note for Brands using our website
          </h3>
          <p className="legal-text">
            We will collect data from you as a business entity once you register
            with us. Please see types of data we collect above (
            <p className="legal-text-bolder">
              information we collect about you
            </p>
            ). For you, this will in the main be contact and identity
            information, in order that we may register you to the website as one
            of our trusted third parties giving you access to the talent on our
            website and in order that we may pass on the discounts on your
            products and services that you may offer our talent. For further
            information about how and why we collect your information please see
            above (
            <p className="legal-text-bolder">
              how and why we use your information
            </p>
            ).{" "}
          </p>
          <br />
          <p className="legal-text">
            Should you see an athlete on our website that you would be
            interested in providing goods and/or services to or other commercial
            or financial support such as for example sponsorship, you should
            contact us directly using one the methods set out below (
            <p className="legal-text-bolder">How to contact us</p>). We will in
            turn contact that athlete on your behalf and if they wishe to engage
            with you, then with their consent, we will pass on their contact
            details to you.
          </p>
          <br />
          <p className="legal-text">
            You must comply with the provisions of this privacy policy when
            engaging with athletes accessed via The Sponsor Hub and also with
            the terms of any contract between you and us dealing with your
            access to website users/ athletes via our website In particular, you
            agree to comply with the data sharing agreement entered into between
            you and us. You must also provide the terms of any data protection
            policy that you must adhere to under the data protection laws of
            your country to athletes at the outset.
          </p>
          <br />
          <p className="legal-text">
            The privacy and the rights of our website users (our athletes) is
            sacrosanct. You must respect that.
          </p>
          <br />
          <h3 className="legal-subheading">How to complain</h3>
          <p className="legal-text">
            Please contact us if you have any queries or concerns about our use
            of your information (see below ‘
            <p className="legal-text-bolder">How to contact us</p>’). We hope we
            will be able to resolve any issues you may have.
          </p>
          <br />
          <p className="legal-text">
            You also have the right to lodge a complaint with the Information
            Commissioner.
          </p>
          <br />
          <p className="legal-text">
            The Information Commissioner can be contacted using the details at{" "}
            <a
              className="legal-link"
              href="https://ico.org.uk/make-a-complaint"
            >
              <p className="legal-text">https://ico.org.uk/make-a-complaint</p>
            </a>{" "}
            or telephone: 0303 123 1113.
          </p>
          <br />
          <h3 className="legal-subheading">Changes to this privacy policy</h3>
          <p className="legal-text">
            We may change this privacy policy from time to time. When we make
            significant changes we will take steps to inform you, for example
            via the website or by other means, such as email.
          </p>
          <br />
          <h3 className="legal-subheading">How to contact us</h3>
          <p className="legal-text">
            You can contact us by post, email or telephone if you have any
            questions about this privacy policy or the information we hold about
            you, to exercise a right under data protection law or to make a
            complaint.
          </p>
          <br />
          <p className="legal-text">Our contact details are shown below:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                By email at{" "}
                <a className="legal-link" href="mailto:info@thesponsorhub.com">
                  <p className="legal-text">info@thesponsorhub.com</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                By writing to us at Walk Mill, Kirkwhelpington, Newcastle Upon
                Tyne, Northumberland NE19 2SB
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">By calling us on +44 (0)7966 593 208</p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">Do you need extra help?</h3>
          <p className="legal-text">
            If you would like this notice in another format (for example large
            print) please contact us (see above).
          </p>
          <br />
          <h3 className="legal-subheading">Changes to our Privacy Policy</h3>
          <p className="legal-text">This policy was published on 12/3/22.</p>
          <br />
          <h3 className="legal-subheading">Thanks</h3>
          <h3 className="legal-subheading">The Sponsor Hub</h3>
          <p className="legal-text">
            This document was produced by our Legal Services Partner{" "}
            <a
              style={{ display: "inline", padding: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nqlegal.co.uk/"
            >
              <img
                height={20}
                style={{ marginTop: -2 }}
                src="/Partners/nqLegalLogo.png"
                alt="ngLegal"
              />
            </a>
            .
          </p>
        </div>
      </MDBCol>
    </div>
  );
};

export default PrivacyPolicy13;
