import React, { Component } from "react";
import { withApollo, Query } from "react-apollo";
import gql from "graphql-tag";
import Loading from "./Loading";
import HomeFeed from "./HomeComponent";
import ErrorPage from "./Errors/ErrorMessage";

const USER_CATEGORY_QUERY = gql`
  query {
    user {
      id
      category
      isBusiness
      newUser
      country
      city
      newUser
      lastSession
    }
  }
`;

const UPDATE_LAST_SESSION_MUTATION = gql`
  mutation UpdateLastSessionMutation {
    updateLastSession {
      id
    }
  }
`;

class Home extends Component {
  updateLastSession = async () => {
    this.props.client.mutate({
      mutation: UPDATE_LAST_SESSION_MUTATION,
    });
  };

  render() {
    return (
      <Query query={USER_CATEGORY_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorPage error={error.message} />;

          const id = data.user.id;
          const category = data.user.category;
          const country = data.user.country;
          const city = data.user.city;
          const newUser = data.user.newUser;

          if (newUser || newUser == null) window.location.href = "/tutorial";

          if ((Date.now() - new Date(data.user.lastSession)) / 86400000 >= 1) {
            this.updateLastSession();
          }

          return (
            <div
              className="pt-5"
              style={{ backgroundColor: "#000", minHeight: "100vh" }}
            >
              <HomeFeed
                id={id}
                category={category}
                country={country}
                city={city}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(Home);
