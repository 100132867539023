import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import Loading from "../Loading";
import { MDBIcon, MDBRow, MDBCol } from "mdbreact";
import ErrorPage from "../Errors/ErrorMessage";

const USER_CATEGORY_QUERY = gql`
  query {
    user {
      id
    }
  }
`;

const USER_ISBUSINESS_QUERY = gql`
  {
    userIsBusiness {
      users {
        id
        name
        category
        website
        businessLogo
      }
    }
  }
`;
const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

function BrandsSection({ navigation }) {
  const [viewMoreSurf, setViewMoreSurf] = React.useState(false);
  const [viewMoreSkate, setViewMoreSkate] = React.useState(false);
  const [viewMoreSnowboard, setViewMoreSnowboard] = React.useState(false);
  const [viewMoreSki, setViewMoreSki] = React.useState(false);
  const [viewMoreBMX, setViewMoreBMX] = React.useState(false);
  const [viewMoreMTB, setViewMoreMTB] = React.useState(false);
  const [viewMoreScooter, setViewMoreScooter] = React.useState(false);
  const [viewMoreClimbing, setViewMoreClimbing] = React.useState(false);
  const [viewMoreOther, setViewMoreOther] = React.useState(false);
  const [viewMoreApparel, setViewMoreApparel] = React.useState(false);

  return (
    <Query query={USER_CATEGORY_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <ErrorPage error={error.message} />;

        const currentUserId = data.user.id;

        return (
          <Query query={USER_ISBUSINESS_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return <ErrorPage error={error.message} />;

              const users = data.userIsBusiness.users;
              var surfBrands = [];
              var skateBrands = [];
              var snowboardBrands = [];
              var skiBrands = [];
              var scooterBrands = [];
              var BMXBrands = [];
              var MTBBrands = [];
              var climbingBrands = [];
              var otherBrands = [];
              var apparelBrands = [];
              for (var i = 0; i < users.length; i++) {
                if (
                  users[i].category[0] === "Apparel" ||
                  users[i].category[1] === "Apparel" ||
                  users[i].category[2] === "Apparel" ||
                  users[i].category[3] === "Apparel" ||
                  users[i].category[4] === "Apparel" ||
                  users[i].category[5] === "Apparel" ||
                  users[i].category[6] === "Apparel" ||
                  users[i].category[7] === "Apparel" ||
                  users[i].category[8] === "Apparel" ||
                  users[i].category[9] === "Apparel"
                ) {
                  apparelBrands.push(users[i]);
                } else if (users[i].category[0] === "Surf") {
                  surfBrands.push(users[i]);
                } else if (users[i].category[0] === "Skate") {
                  skateBrands.push(users[i]);
                } else if (users[i].category[0] === "Snowboard") {
                  snowboardBrands.push(users[i]);
                } else if (users[i].category[0] === "Ski") {
                  skiBrands.push(users[i]);
                } else if (users[i].category[0] === "Scooter") {
                  scooterBrands.push(users[i]);
                } else if (users[i].category[0] === "BMX") {
                  BMXBrands.push(users[i]);
                } else if (users[i].category[0] === "MTB") {
                  MTBBrands.push(users[i]);
                } else if (users[i].category[0] === "Climbing") {
                  climbingBrands.push(users[i]);
                } else if (users[i].category[0] === "Other") {
                  otherBrands.push(users[i]);
                }
              }
              return (
                <section id="brands" style={{ backgroundColor: "#000" }}>
                  <div
                    className="pt-5  px-3"
                    style={{ backgroundColor: "#000", minHeight: "100vh" }}
                  >
                    <h2 className="pb-3 mt-3" style={title}>
                      BRANDS WE WORK WITH
                    </h2>
                    <div className="d-flex justify-content-center">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreSurf(!viewMoreSurf)}
                          style={subTitle}
                        >
                          Surf
                        </h4>
                        {viewMoreSurf || surfBrands.length < 12 ? (
                          <MDBRow>
                            {surfBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {surfBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() => setViewMoreSurf(!viewMoreSurf)}
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreSkate(!viewMoreSkate)}
                          style={subTitle}
                        >
                          Skate
                        </h4>
                        {viewMoreSkate || skateBrands.length < 12 ? (
                          <MDBRow>
                            {skateBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {skateBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() => setViewMoreSkate(!viewMoreSkate)}
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() =>
                            setViewMoreSnowboard(!viewMoreSnowboard)
                          }
                          style={subTitle}
                        >
                          Snowboard
                        </h4>
                        {viewMoreSnowboard || snowboardBrands.length < 12 ? (
                          <MDBRow>
                            {snowboardBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {snowboardBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() =>
                                  setViewMoreSnowboard(!viewMoreSnowboard)
                                }
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreSki(!viewMoreSki)}
                          style={subTitle}
                        >
                          Ski
                        </h4>
                        {viewMoreSki || skiBrands.length < 12 ? (
                          <MDBRow>
                            {skiBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {skiBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() => setViewMoreSki(!viewMoreSki)}
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreBMX(!viewMoreBMX)}
                          style={subTitle}
                        >
                          BMX
                        </h4>
                        {viewMoreBMX || BMXBrands.length < 12 ? (
                          <MDBRow>
                            {BMXBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {BMXBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() => setViewMoreBMX(!viewMoreBMX)}
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreMTB(!viewMoreMTB)}
                          style={subTitle}
                        >
                          MTB
                        </h4>
                        {viewMoreMTB || MTBBrands.length < 12 ? (
                          <div>
                            {MTBBrands.length === 0 ? (
                              <MDBCol
                                xl="3"
                                lg="4"
                                md="6"
                                sm="12"
                                className="col-12"
                              >
                                <p style={businessNameText}>
                                  Brands Coming Soon
                                </p>
                              </MDBCol>
                            ) : (
                              <MDBRow>
                                {MTBBrands.map((user) => (
                                  <BrandsTile
                                    key={user.id}
                                    user={user}
                                    navigation={navigation}
                                    currentUserId={currentUserId}
                                  />
                                ))}
                              </MDBRow>
                            )}
                          </div>
                        ) : (
                          <MDBRow>
                            {MTBBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() => setViewMoreMTB(!viewMoreMTB)}
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreScooter(!viewMoreScooter)}
                          style={subTitle}
                        >
                          Scooter
                        </h4>
                        {viewMoreScooter || scooterBrands.length < 12 ? (
                          <MDBRow>
                            {scooterBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {scooterBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() =>
                                  setViewMoreScooter(!viewMoreScooter)
                                }
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreClimbing(!viewMoreClimbing)}
                          style={subTitle}
                        >
                          Climbing
                        </h4>
                        {viewMoreClimbing || climbingBrands.length < 12 ? (
                          <MDBRow>
                            {climbingBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {climbingBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() =>
                                  setViewMoreClimbing(!viewMoreClimbing)
                                }
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreOther(!viewMoreOther)}
                          style={subTitle}
                        >
                          Other
                        </h4>
                        {viewMoreOther || otherBrands.length < 12 ? (
                          <MDBRow>
                            {otherBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {otherBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() => setViewMoreOther(!viewMoreOther)}
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <MDBCol
                        xl="10"
                        lg="12"
                        md="10"
                        sm="12"
                        className="px-3 col-12"
                      >
                        <h4
                          onClick={() => setViewMoreApparel(!viewMoreApparel)}
                          style={subTitle}
                        >
                          Apparel
                        </h4>
                        {viewMoreApparel || apparelBrands.length < 12 ? (
                          <MDBRow>
                            {apparelBrands.map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                          </MDBRow>
                        ) : (
                          <MDBRow>
                            {apparelBrands.slice(0, 11).map((user) => (
                              <BrandsTile
                                key={user.id}
                                user={user}
                                navigation={navigation}
                                currentUserId={currentUserId}
                              />
                            ))}
                            <MDBCol
                              xl="3"
                              lg="4"
                              md="6"
                              sm="12"
                              className="col-12"
                            >
                              <p
                                onClick={() =>
                                  setViewMoreApparel(!viewMoreApparel)
                                }
                                style={businessNameText}
                              >
                                View All
                              </p>
                            </MDBCol>
                          </MDBRow>
                        )}
                      </MDBCol>
                    </div>
                  </div>
                </section>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
}

class BrandsTile extends Component {
  state = {
    businessLogoResize: true,
  };
  render() {
    const { businessLogoResize } = this.state;
    return (
      <MDBCol xl="4" lg="4" md="6" sm="12" className="col-12 pb-3">
        <div
          style={{ display: "inline-flex", alignItems: "center" }}
          className="pr-3"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: "0" }}
            href={this.props.user.website}
          >
            <p style={businessNameText}>{this.props.user.name}</p>
          </a>
          <div className="ml-3">
            {this.props.user.businessLogo !== "" &&
            this.props.user.businessLogo !== null ? (
              <Mutation
                mutation={USER_PROFILE_MUTATION}
                variables={{
                  currentUserId: this.props.currentUserId,
                  userId: this.props.user.id,
                }}
              >
                {(profileTrackerMutation, { loading, data }) => (
                  <div style={{ textAlign: "left" }}>
                    <a
                      style={{ padding: "0" }}
                      onClick={() => {
                        if (!loading && !data) {
                          profileTrackerMutation();
                        }
                      }}
                      href={`/profile/${this.props.user.id}`}
                    >
                      <img
                        style={userImg}
                        src={
                          businessLogoResize
                            ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${this.props.user.id}_50x50.jpeg`
                            : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${this.props.user.businessLogo}`
                        }
                        onError={() =>
                          this.setState({ businessLogoResize: false })
                        }
                        alt="Business Logo"
                      ></img>
                    </a>
                  </div>
                )}
              </Mutation>
            ) : (
              <Mutation
                mutation={USER_PROFILE_MUTATION}
                variables={{
                  currentUserId: this.props.currentUserId,
                  userId: this.props.user.id,
                }}
              >
                {(profileTrackerMutation, { loading, data }) => (
                  <MDBIcon
                    style={{ color: "#f8f8ff" }}
                    size="1x"
                    far
                    icon="user-circle"
                    onClick={() => {
                      if (!loading && !data) {
                        window.location.href = `/profile/${this.props.user.id}`;
                        profileTrackerMutation();
                      }
                    }}
                  />
                )}
              </Mutation>
            )}
          </div>
        </div>
      </MDBCol>
    );
  }
}

export default BrandsSection;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
  marginBottom: "1.5rem",
};

const subTitle = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontWeight: "bolder",
  textTransform: "uppercase",
  marginBottom: "1.5rem",
};

const businessNameText = {
  fontFamily: "Roboto",
  color: "#f8f8ff",
  marginBottom: 0,
};

const userImg = {
  height: 30,
  width: "auto",
  objectFit: "cover",
  borderRadius: "0.4rem",
};
