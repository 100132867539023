import React, { Component } from 'react'
import Video from '../Videos/Video'
import { Query, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBRow, MDBBtn } from 'mdbreact'
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorPage from './../Errors/ErrorMessage'

const PAGE_SIZE = 6 
  
const SPOT_EDITS_QUERY = gql`
query SpotsEditsQuery($spotId: ID!, $skip: Int, $last: Int){
  spotEdits(spotId: $spotId, skip: $skip, last: $last){
    videos {
      id
      filename
      description
      filmerInstagram
      mimetype
      spotLocation
      crewOnly
      postedBy {
        id
        name
        category
        country
        city
        instagram
        email
        businessLogo
        profileImage
        isBusiness
      }
      votes {
        id
        user {
          id
        }
      }
      videoViews{
        id
      }
    }
  }
 }
` 
 
class MapEdits extends Component {
    state = {
      videos: [],
      skip: 0,
      loadMore: false,
      firstLoad: true,
      hasMore: true,
      spotId: this.props.match.params.id
    };
  
    fetchMoreData = () => {
      setTimeout(() => {
        this._executeFetchMore()
      }, 1000);
    };
  
    _executeFetchMore = async () => {
      var { spotId, skip } = this.state
  
      const result = await this.props.client.query({
        query: SPOT_EDITS_QUERY,
        variables: { spotId, skip, last: PAGE_SIZE },
      })
      this.setState({ videos: [...this.state.videos, ...result.data.spotEdits.videos] })
      if(result.data.spotEdits.videos.length === 0){
        this.setState({hasMore: false})
      } else {
        this.setState({skip: this.state.skip + PAGE_SIZE})
        if(this.state.firstLoad){
          this._executeFetchMore()
        }
        this.setState({hasMore: true, firstLoad: false})
      }
    }
  
    render() {
      const {spotId} = this.state
      return (
        <div style={{backgroundColor: "#000"}}>
        <h2 className="pt-5 mt-3" style={title}>SPOT EDITS</h2>
          <Query query={SPOT_EDITS_QUERY} variables={{spotId, skip: 0, last: PAGE_SIZE}}>
            {({ data, loading, error }) => {
              if(loading) return <div style={{minHeight: '100vh', backgroundColor: 'transparent'}}></div>
              if(error) return <ErrorPage error={error.message} />
    
              return (
                <div className="pt-3" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
                  {data.spotEdits.videos.length === 0 ?
                    <div className="py-2 px-3" style={editsText}>
                      <h5>There are currently no Spot Edits.</h5>
                      <MDBBtn style={uploadButton} color="white" rounded outline href="/upload_edit">Upload an Edit</MDBBtn>
                    </div>
                  :
                  <InfiniteScroll
                    dataLength={this.state.videos.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    style={{overflow: 'hidden'}}
                    loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Edits...</h5></div>}
                  >
                    {data && this.state.firstLoad ?
                      <MDBRow className="justify-content-center">
                          {data.spotEdits.videos.map(video => <Video key={video.id} video={video} />)}
                      </MDBRow>
                    :
                      <MDBRow className="justify-content-center">
                          {this.state.videos.map(video => <Video key={video.id} video={video} />)}
                      </MDBRow>
                    }
                    {!this.state.hasMore ?
                      <div className="pb-2" style={editsText} key={0}><h5>No More Edits!</h5></div>
                    :
                      <></>
                    }
                  </InfiniteScroll>
                  }
                </div>
              )
            }}
          </Query>
        </div>
      );
    }
  }
    
export default withApollo(MapEdits)

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const uploadButton = {
  minWidth: 150,
  paddingTop: '0.15rem',
  paddingBottom: '0.25rem',
  fontSize: 15,
  fontFamily: 'Oswald'
}

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald'
}

