import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import "./../../styles/Forms.css";
import "./../../styles/Profile.css";
import Loading from "./../Loading";
import ErrorPage from "./../Errors/ErrorMessage";
import {
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import ProfileDetail from "./ProfileDetails";
import ProfileUploads from "./ProfileUploads";
import ProfileDiscounts from "./ProfileDiscounts";
import ProfileStokes from "./ProfileStokes";

const GET_PROFILE_DETAILS = gql`
  query findUserQuery($filter: String) {
    findUser(filter: $filter) {
      users {
        id
        name
        email
        gender
        instagram
        country
        city
        category
        sponsors
        athleteOrPhotographer
        isBusiness
        website
        businessLogo
        profileImage
        paidCurrentMonth
        monthPaid
        about
      }
    }
  }
`;

const USER_ID_QUERY = gql`
  query {
    user {
      id
      name
      isBusiness
      newUser
    }
  }
`;

const WEBSITE_TRACKER_MUTATION = gql`
  mutation WebsiteTrackerMutation(
    $business: String!
    $user: String!
    $discountId: String
  ) {
    websiteTracker(business: $business, user: $user, discountId: $discountId) {
      id
      business
      user
    }
  }
`;

class Profile extends Component {
  state = {
    profileId: this.props.match.params.id,
    activeItemTabs: "1",
  };

  toggleTabs = (tab) => () => {
    if (this.state.activeItemTabs !== tab) {
      this.setState({
        activeItemTabs: tab,
      });
    }
  };

  render() {
    const { profileId } = this.state;
    return (
      <Query
        query={GET_PROFILE_DETAILS}
        variables={{ filter: profileId }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorPage error={error.message} />;
          if (data.findUser.users.length === 0)
            return <ErrorPage error={"No user found"} />;

          const user = data.findUser.users[0];

          return (
            <div className="pt-4">
              <div
                className="pt-2"
                style={{ backgroundColor: "#000", minHeight: "100vh" }}
              >
                <Query query={USER_ID_QUERY}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loading />;
                    if (error) return <ErrorPage error={error.message} />;

                    const currentUser = data.user;

                    return (
                      <div>
                        <div className="classic-tabs">
                          <MDBNav
                            classicTabs
                            color="transparent"
                            className="mt-3 px-3"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <MDBNavItem className="mx-4">
                              <MDBNavLink
                                style={{ padding: 0 }}
                                link
                                to="#"
                                active={this.state.activeItemTabs === "1"}
                                onClick={this.toggleTabs("1")}
                              >
                                <h5 id="tabTitle" style={title}>
                                  Details
                                </h5>
                              </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem className="mx-4">
                              <MDBNavLink
                                style={{ padding: 0 }}
                                link
                                to="#"
                                active={this.state.activeItemTabs === "2"}
                                onClick={this.toggleTabs("2")}
                              >
                                <h5 id="tabTitle" style={title}>
                                  Uploads
                                </h5>
                              </MDBNavLink>
                            </MDBNavItem>
                            {user.id === currentUser.id ? (
                              <MDBNavItem className="mx-4">
                                <MDBNavLink
                                  style={{ padding: 0 }}
                                  link
                                  to="#"
                                  active={this.state.activeItemTabs === "3"}
                                  onClick={this.toggleTabs("3")}
                                >
                                  <h5 id="tabTitle" style={title}>
                                    Stokes
                                  </h5>
                                </MDBNavLink>
                              </MDBNavItem>
                            ) : (
                              <></>
                            )}
                            {user.isBusiness ? (
                              <>
                                <MDBNavItem className="mx-4">
                                  <MDBNavLink
                                    style={{ padding: 0 }}
                                    link
                                    to="#"
                                    active={this.state.activeItemTabs === "4"}
                                    onClick={this.toggleTabs("4")}
                                  >
                                    <h5 id="tabTitle" style={title}>
                                      Discounts
                                    </h5>
                                  </MDBNavLink>
                                </MDBNavItem>
                                {user.id !== currentUser.id ? (
                                  <MDBNavItem
                                    className="mx-4"
                                    onClick={this.toggleTabs("5")}
                                  >
                                    <Mutation
                                      mutation={WEBSITE_TRACKER_MUTATION}
                                      variables={{
                                        business: user.id,
                                        user: currentUser.id,
                                        discountId: "Profile",
                                      }}
                                    >
                                      {(
                                        websiteTrackerMutation,
                                        { loading }
                                      ) => (
                                        <MDBNavLink
                                          style={{ padding: 0 }}
                                          link
                                          to="#"
                                          active={
                                            this.state.activeItemTabs === "5"
                                          }
                                          onClick={() => {
                                            if (!loading) {
                                              websiteTrackerMutation();
                                            }
                                          }}
                                        >
                                          <h5 id="tabTitle" style={title}>
                                            Website
                                          </h5>
                                        </MDBNavLink>
                                      )}
                                    </Mutation>
                                  </MDBNavItem>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </MDBNav>
                          <MDBTabContent
                            style={{ paddingTop: 0 }}
                            activeItem={this.state.activeItemTabs}
                          >
                            <MDBTabPane tabId="1">
                              <ProfileDetail
                                profileUser={user}
                                currentUser={currentUser}
                              />
                            </MDBTabPane>
                            <MDBTabPane tabId="2">
                              <ProfileUploads
                                profileUser={user}
                                currentUserId={currentUser.id}
                              />
                            </MDBTabPane>
                            {user.id === currentUser.id ? (
                              <MDBTabPane tabId="3">
                                <ProfileStokes profileUser={user} />
                              </MDBTabPane>
                            ) : (
                              <></>
                            )}
                            {user.isBusiness ? (
                              <>
                                <MDBTabPane tabId="4">
                                  <ProfileDiscounts
                                    profileUser={user}
                                    currentUserId={currentUser.id}
                                  />
                                </MDBTabPane>
                                {user.id !== currentUser.id ? (
                                  <MDBTabPane
                                    style={{ paddingTop: 0 }}
                                    tabId="5"
                                  >
                                    <div className="pt-3">
                                      <iframe
                                        style={{
                                          width: "100%",
                                          height: "100vh",
                                          border: "none",
                                        }}
                                        src={user.website}
                                        title={user.name}
                                      ></iframe>
                                    </div>
                                  </MDBTabPane>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </MDBTabContent>
                        </div>
                      </div>
                    );
                  }}
                </Query>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Profile;

const title = {
  color: "#f8f8ff",
  textAlign: "center",
  fontFamily: "Oswald",
};
