import React, { Component } from 'react'
import LocalAthletesVideos from './LocalAthletesVideos';
import VideosNational from './VideosNational'
import VideosInternational from './VideosInternational'
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";

class SkiVideosPage extends Component {
  state = {
    activeItemTabs: "2"
  };

  toggleTabs = tab => () => {
    if (this.state.activeItemTabs !== tab) {
      this.setState({
        activeItemTabs: tab
      });
    }
  };

  render() {
    return (
      <div style={{backgroundColor: "#000"}}>
        <h2 className="pt-5 mt-3" style={title}>SKI EDITS</h2>
        <div className="classic-tabs">
          <MDBNav classicTabs color="transparent" className="mt-3 justify-content-center">
            <MDBNavItem className="mx-4">
              <MDBNavLink link to="#" active={ this.state.activeItemTabs === "1"} onClick={this.toggleTabs("1")}>
                <h3 id="tabTitle" style={title}>LOCAL</h3>
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="mx-4">
              <MDBNavLink link to="#" active={ this.state.activeItemTabs === "2"} onClick={this.toggleTabs("2")}>
                <h3 id="tabTitle" style={title}>NATIONAL</h3>
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="mx-4">
              <MDBNavLink link to="#" active={ this.state.activeItemTabs === "3"} onClick={this.toggleTabs("3")}>
                <h3 id="tabTitle" style={title}>INTERNATIONAL</h3>
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent style={{paddingTop: 0}} activeItem={this.state.activeItemTabs}>
            <MDBTabPane tabId="1">
              <LocalAthletesVideos category={"Ski"} />
            </MDBTabPane>
            <MDBTabPane tabId="2">
              <VideosNational category={"Ski"} />
            </MDBTabPane>
            <MDBTabPane tabId="3">
              <VideosInternational category={"Ski"} />
            </MDBTabPane>
          </MDBTabContent>
        </div>
      </div>
    );
  }
}

export default SkiVideosPage

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald'
}
