import React, { Component } from 'react'
import {  Query } from 'react-apollo'
import { MDBCol } from 'mdbreact'
import gql from 'graphql-tag'
import "./../../styles/Forms.css"
import Loading from '../Loading';
import ErrorPage from './../Errors/ErrorMessage'
import VideoUploadForm from './UploadVideoForm'

const GET_USER_DETAILS = gql`
  query{
    user{
      id
      name
      instagram
      gender
      country
      city
      category
      newUser
      isBusiness
      email
      videos{
        id
      }
    }
  }
`

const SPOT_DETAILS_QUERY = gql`
  query SpotsQuery($userId: ID!, $category: [String]!, $skip: Int, $last: Int){
    spots(userId: $userId, category: $category, skip: $skip, last: $last){
      spots{
        id
        title
        isBusiness
      }
    }
  }
` 

class UploadVideo extends Component {
  render() {
    return (
      <Query query={GET_USER_DETAILS}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />
          
          const user = data.user

          return (
            <Query query={SPOT_DETAILS_QUERY} variables={{userId: data.user.id, category: data.user.category}}>
              {({ loading, error, data, refetch }) => {
                if (loading) return <Loading />
                if (error) return <ErrorPage error={error.message} />
                var spots = data.spots.spots
                var spotsData = [] 
  

                for(var i = 0; i < spots.length; i++){
                  if(spots[i].isBusiness === false){
                    spotsData.push({label: spots[i].id, value: spots[i].title})
                  }
                }

                return(
                  <div style={{backgroundColor: '#000', minHeight: '100vh'}} className="d-flex py-5 justify-content-center align-items-center">
                    <MDBCol sm="12" md="7" lg="5" xl="4" className="px-3 justify-content-center col-12">
                      <VideoUploadForm user={user}  spotsData={spotsData}/>
                    </MDBCol>
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query >
    )
  }
}

export default UploadVideo
