import React, { Component } from 'react'
import Discount from './Discount'
import { Query, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBRow } from 'mdbreact'
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorPage from './../Errors/ErrorMessage'

const PAGE_SIZE = 6

const USER_DETAILS_QUERY = gql`
  {
    user {
      id
      country
      city
      category
    }
  }
`

const DISCOUNT_INTERNATIONAL_FEED_QUERY = gql`
  query DiscountFeedQuery($category: [String], $skip: Int, $last: Int){
    discountFeed(category: $category, businessRegion: "International", skip: $skip, last: $last) {
      discounts {
        id
        amount
        description
        code 
        filename1
        filename2
        filename3
        productDescription1
        productDescription2
        productDescription3
        postedBy {
          id
          name
          website
          businessLogo
          city
          country
        }
        inStore
      }
    }
  }
`

var userCategories

class DiscountsInternational extends Component {
  state = {
    discounts: [],
    skip: 0,
    loadMore: false,
    firstLoad: true,
    hasMore: true,
    category: this.props.category,
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this._executeFetchMore()
    }, 1000);
  };

  _executeFetchMore = async () => {
    var { category, skip } = this.state

    if(category === "Users"){
      category = userCategories
    }

    const result = await this.props.client.query({
      query: DISCOUNT_INTERNATIONAL_FEED_QUERY,
      variables: { category, skip, last: PAGE_SIZE },
    })
    this.setState({ discounts: [...this.state.discounts, ...result.data.discountFeed.discounts] })
    if(result.data.discountFeed.discounts.length === 0){
      this.setState({hasMore: false})
    } else {
      this.setState({skip: this.state.skip + PAGE_SIZE})
      if(this.state.firstLoad){
        this._executeFetchMore()
      }
      this.setState({hasMore: true, firstLoad: false})
    }
  }

  render() {
    var {category} = this.state
    return (
      <Query query={USER_DETAILS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <div style={{minHeight: '100vh', backgroundColor: 'transparent'}}></div>
          if (error) return <ErrorPage error={error.message} />

          if(category === "Users"){
            category = data.user.category
            category.push("Apparel")
            userCategories = category
          }

          return(
            <Query query={DISCOUNT_INTERNATIONAL_FEED_QUERY} variables={{category, skip: 0, last: PAGE_SIZE}}>
              {({ data, loading, error }) => {
                if(loading) return <div style={{minHeight: '100vh', backgroundColor: 'transparent'}}></div>
                if(error) return <ErrorPage error={error.message} />

                return (
                  <div className="pt-3" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
                    {data.discountFeed.discounts.length === 0 ?
                      <div className="py-2 px-3" style={editsText}>
                        <h5>There are currently no {category} Discounts Internationally.</h5>
                      </div>
                    :
                    <InfiniteScroll
                      dataLength={this.state.discounts.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.hasMore}
                      loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Discounts...</h5></div>}
                    >
                      {data && this.state.firstLoad ?
                        <MDBRow style={{justifyContent: 'space-evenly'}}>
                          {data.discountFeed.discounts.map(discount => <Discount key={discount.id} discount={discount} />)}
                        </MDBRow>
                      :
                        <MDBRow style={{justifyContent: 'space-evenly'}}>
                          {this.state.discounts.map(discount => <Discount key={discount.id} discount={discount} />)}
                        </MDBRow>
                      }
                      {!this.state.hasMore ?
                        <div className="pb-2" style={editsText} key={0}><h5>No More Discounts!</h5></div>
                      :
                        <></>
                      }
                    </InfiniteScroll>
                    }
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    );
  }
}

export default withApollo(DiscountsInternational)

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}
