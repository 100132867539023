import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { AUTH_TOKEN } from "../constants";
import gql from "graphql-tag";
import { MDBCol, MDBRow, MDBBtn, MDBInput } from "mdbreact";
import "./../styles/Inputs.css";

const INITIAL_SETUP_MUTATION = gql`
  mutation InitialSetupMutation(
    $isBusiness: Boolean!
    $athleteOrPhotographer: String!
    $age: String!
    $parentEmail: String!
    $category: [String!]!
    $marketingOptIn: Boolean!
  ) {
    initialSetup(
      isBusiness: $isBusiness
      athleteOrPhotographer: $athleteOrPhotographer
      age: $age
      parentEmail: $parentEmail
      category: $category
      marketingOptIn: $marketingOptIn
    ) {
      id
    }
  }
`;

const AGE_VERIFICATION_MUTATION = gql`
  mutation AgeVerificationMutation(
    $parentEmail: String!
    $verification: Int!
    $age: String!
  ) {
    ageVerification(
      parentEmail: $parentEmail
      verification: $verification
      age: $age
    )
  }
`;

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccountMutation {
    deleteAccount {
      id
    }
  }
`;

class InitialSetup extends Component {
  state = {
    isBusiness: false,
    athleteOrPhotographer: "",
    age: "",
    parentEmail: "",
    sendVerification: true,
    ageVerification: "",
    verification: Math.floor(Math.random() * 999999),
    category: [],
    addSurf: true,
    addSkate: true,
    addSnowboard: true,
    addSki: true,
    addBMX: true,
    addMTB: true,
    addScooter: true,
    addClimbing: true,
    addApparel: true,
    addOther: true,
    marketingOptIn: false,
    validationError: "",
  };

  render() {
    const {
      isBusiness,
      athleteOrPhotographer,
      age,
      parentEmail,
      sendVerification,
      verification,
      ageVerification,
      category,
      addSurf,
      addSkate,
      addSnowboard,
      addSki,
      addBMX,
      addMTB,
      addScooter,
      addClimbing,
      addApparel,
      addOther,
      marketingOptIn,
      validationError,
    } = this.state;
    return (
      <div
        style={{ minHeight: "100vh", backgroundColor: "#000" }}
        className="d-flex justify-content-center align-items-center pt-5 pb-3"
      >
        <MDBCol
          sm="10"
          md="8"
          lg="6"
          xl="6"
          className="px-3 justify-content-center col-12 pt-4"
        >
          <MDBCol
            sm="8"
            md="8"
            lg="10"
            className="col-8 offset-2 offset-xs-2 offset-sm-2 offset-md-2 offset-lg-1"
          ></MDBCol>
          <h2 style={title}>Personalise your experience to get started</h2>
          <Mutation
            mutation={INITIAL_SETUP_MUTATION}
            onCompleted={() => (window.location.href = "/")}
          >
            {(initialSetup, { error, loading, data }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.setState({ validationError: "" });
                  if (!loading && !data) {
                    if (!isBusiness && athleteOrPhotographer.length === 0) {
                      this.setState({
                        validationError: "Please select your Group",
                      });
                    } else if (!isBusiness && age.length === 0) {
                      this.setState({
                        validationError: "Please select your Age",
                      });
                    } else if (category.length === 0) {
                      if (isBusiness) {
                        this.setState({
                          validationError:
                            "Please select the sports you participate in",
                        });
                      } else {
                        this.setState({
                          validationError:
                            "Please select the sports you are interested in",
                        });
                      }
                    } else if (age === "6-9" || age === "10-12") {
                      if (parentEmail.length === 0) {
                        this.setState({
                          validationError:
                            "Please enter your Parent/Guardian's Email",
                        });
                      } else if (ageVerification !== verification.toString()) {
                        if (sendVerification) {
                          this.setState({
                            validationError: "Send Verification Email",
                          });
                        } else {
                          this.setState({
                            validationError: "Verification Code Incorrect",
                          });
                        }
                      } else {
                        initialSetup({
                          variables: {
                            isBusiness,
                            athleteOrPhotographer,
                            age,
                            parentEmail,
                            category,
                            marketingOptIn,
                          },
                        });
                      }
                    } else {
                      initialSetup({
                        variables: {
                          isBusiness,
                          athleteOrPhotographer,
                          age,
                          parentEmail,
                          category,
                          marketingOptIn,
                        },
                      });
                    }
                  }
                }}
              >
                <div className="py-3">
                  <div>
                    <MDBInput
                      label="Are you a Business?"
                      type="checkbox"
                      id="checkbox"
                      value={isBusiness}
                      checked={isBusiness}
                      onChange={(e) =>
                        this.setState({ isBusiness: !isBusiness })
                      }
                    />
                  </div>
                  {!isBusiness && (
                    <div>
                      <div className="pt-3">
                        <h5 style={labelText}>Select Your Group</h5>
                        <div style={groupContainer}>
                          <p
                            onClick={() =>
                              this.setState({
                                athleteOrPhotographer: "Athlete",
                              })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                athleteOrPhotographer === "" ||
                                athleteOrPhotographer === "Athlete"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            Athlete
                          </p>
                          <p
                            onClick={() =>
                              this.setState({
                                athleteOrPhotographer:
                                  "Photographer/Videographer",
                              })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                athleteOrPhotographer === "" ||
                                athleteOrPhotographer ===
                                  "Photographer/Videographer"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            Photographer/Videographer
                          </p>
                          <p
                            onClick={() =>
                              this.setState({
                                athleteOrPhotographer: "Spectator",
                              })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                athleteOrPhotographer === "" ||
                                athleteOrPhotographer === "Spectator"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            Spectator
                          </p>
                          <p
                            onClick={() =>
                              this.setState({
                                athleteOrPhotographer: "Club",
                              })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                athleteOrPhotographer === "" ||
                                athleteOrPhotographer === "Club"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            Club
                          </p>
                        </div>
                      </div>
                      <div className="pt-4">
                        <h5 style={labelText}>Select Your Age</h5>
                        <div style={ageContainer}>
                          <p
                            onClick={() => this.setState({ age: "Under 6" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                age === "" || age === "Under 6" ? 1 : 0.5,
                            }}
                          >
                            Under 6
                          </p>
                          <p
                            onClick={() => this.setState({ age: "6-9" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity: age === "" || age === "6-9" ? 1 : 0.5,
                            }}
                          >
                            6-9
                          </p>
                          <p
                            onClick={() => this.setState({ age: "10-12" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity: age === "" || age === "10-12" ? 1 : 0.5,
                            }}
                          >
                            10-12
                          </p>
                          <p
                            onClick={() => this.setState({ age: "13-17" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity: age === "" || age === "13-17" ? 1 : 0.5,
                            }}
                          >
                            13-17
                          </p>
                          <p
                            onClick={() => this.setState({ age: "18+" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity: age === "" || age === "18+" ? 1 : 0.5,
                            }}
                          >
                            18+
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    {age === "6-9" || age === "10-12" ? (
                      <div style={{ marginTop: "2rem" }}>
                        <h5 style={parentTitle}>
                          Parent Verification Required to Get Started
                        </h5>
                        <p style={infoText}>
                          As you are under 13, we require Parental Consent for
                          you to access and use the platform.
                        </p>
                        <p style={infoText}>
                          For more information, check out our Terms and
                          Conditions and Privacy Policy below.
                        </p>
                        <p style={infoText}>
                          We will send a verification email to your
                          Parent/Guardian for consent.
                        </p>
                        <MDBInput
                          value={parentEmail}
                          onChange={(e) =>
                            this.setState({ parentEmail: e.target.value })
                          }
                          type="email"
                          label="Parent/Guardian Email"
                          style={inputText}
                          maxLength="80"
                        />
                        {sendVerification ? (
                          <div style={{ textAlign: "center" }}>
                            <Mutation
                              mutation={AGE_VERIFICATION_MUTATION}
                              variables={{ parentEmail, verification, age }}
                              onCompleted={() =>
                                this.setState({ sendVerification: false })
                              }
                            >
                              {(
                                ageVerificationMutation,
                                { error, loading, data }
                              ) => (
                                <MDBBtn
                                  style={emailButton}
                                  color="white"
                                  outline
                                  rounded
                                  onClick={() => {
                                    if (
                                      parentEmail !== "" &&
                                      !loading &&
                                      !data
                                    ) {
                                      ageVerificationMutation();
                                    }
                                  }}
                                >
                                  {!loading ? "Send" : "Sending"}
                                </MDBBtn>
                              )}
                            </Mutation>
                          </div>
                        ) : (
                          <MDBInput
                            value={ageVerification}
                            onChange={(e) =>
                              this.setState({
                                ageVerification: e.target.value,
                              })
                            }
                            type="text"
                            label="Verification Code"
                            style={inputText}
                            maxLength="80"
                          />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <div>
                      <h4 className="pb-3 mt-4" style={title}>
                        {isBusiness
                          ? "What Sports do you operate in"
                          : "What Sports are you interested in"}
                      </h4>
                      <MDBRow className="mt-3" style={categoryRow}>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Surf" ||
                                category[1] === "Surf" ||
                                category[2] === "Surf" ||
                                category[3] === "Surf" ||
                                category[4] === "Surf" ||
                                category[5] === "Surf" ||
                                category[6] === "Surf" ||
                                category[7] === "Surf" ||
                                category[8] === "Surf" ||
                                category[9] === "Surf"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addSurf) {
                                this.setState({
                                  category: [...category, "Surf"],
                                });
                                this.setState({ addSurf: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Surf") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addSurf: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Surf</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/surf.jpg"
                              alt="Surf"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Skate" ||
                                category[1] === "Skate" ||
                                category[2] === "Skate" ||
                                category[3] === "Skate" ||
                                category[4] === "Skate" ||
                                category[5] === "Skate" ||
                                category[6] === "Skate" ||
                                category[7] === "Skate" ||
                                category[8] === "Skate" ||
                                category[9] === "Skate"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addSkate) {
                                this.setState({
                                  category: [...category, "Skate"],
                                });
                                this.setState({ addSkate: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Skate") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addSkate: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Skate</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/skate.jpeg"
                              alt="Skate"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Snowboard" ||
                                category[1] === "Snowboard" ||
                                category[2] === "Snowboard" ||
                                category[3] === "Snowboard" ||
                                category[4] === "Snowboard" ||
                                category[5] === "Snowboard" ||
                                category[6] === "Snowboard" ||
                                category[7] === "Snowboard" ||
                                category[8] === "Snowboard" ||
                                category[9] === "Snowboard"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addSnowboard) {
                                this.setState({
                                  category: [...category, "Snowboard"],
                                });
                                this.setState({ addSnowboard: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Snowboard") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addSnowboard: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Snowboard</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/snowboard.jpg"
                              alt="Snowboard"
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-3" style={categoryRow}>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Ski" ||
                                category[1] === "Ski" ||
                                category[2] === "Ski" ||
                                category[3] === "Ski" ||
                                category[4] === "Ski" ||
                                category[5] === "Ski" ||
                                category[6] === "Ski" ||
                                category[7] === "Ski" ||
                                category[8] === "Ski" ||
                                category[9] === "Ski"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addSki) {
                                this.setState({
                                  category: [...category, "Ski"],
                                });
                                this.setState({ addSki: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Ski") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addSki: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Ski</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/ski.jpg"
                              alt="Ski"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "BMX" ||
                                category[1] === "BMX" ||
                                category[2] === "BMX" ||
                                category[3] === "BMX" ||
                                category[4] === "BMX" ||
                                category[5] === "BMX" ||
                                category[6] === "BMX" ||
                                category[7] === "BMX" ||
                                category[8] === "BMX" ||
                                category[9] === "BMX"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addBMX) {
                                this.setState({
                                  category: [...category, "BMX"],
                                });
                                this.setState({ addBMX: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "BMX") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addBMX: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>BMX</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/bmx.jpg"
                              alt="BMX"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "MTB" ||
                                category[1] === "MTB" ||
                                category[2] === "MTB" ||
                                category[3] === "MTB" ||
                                category[4] === "MTB" ||
                                category[5] === "MTB" ||
                                category[6] === "MTB" ||
                                category[7] === "MTB" ||
                                category[8] === "MTB" ||
                                category[9] === "MTB"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addMTB) {
                                this.setState({
                                  category: [...category, "MTB"],
                                });
                                this.setState({ addMTB: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "MTB") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addMTB: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>MTB</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/mtb.jpeg"
                              alt="MTB"
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="my-3" style={categoryRow}>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Scooter" ||
                                category[1] === "Scooter" ||
                                category[2] === "Scooter" ||
                                category[3] === "Scooter" ||
                                category[4] === "Scooter" ||
                                category[5] === "Scooter" ||
                                category[6] === "Scooter" ||
                                category[7] === "Scooter" ||
                                category[8] === "Scooter" ||
                                category[9] === "Scooter"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addScooter) {
                                this.setState({
                                  category: [...category, "Scooter"],
                                });
                                this.setState({ addScooter: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Scooter") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addScooter: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Scooter</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/scooter.jpeg"
                              alt="Scooter"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Climbing" ||
                                category[1] === "Climbing" ||
                                category[2] === "Climbing" ||
                                category[3] === "Climbing" ||
                                category[4] === "Climbing" ||
                                category[5] === "Climbing" ||
                                category[6] === "Climbing" ||
                                category[7] === "Climbing" ||
                                category[8] === "Climbing" ||
                                category[9] === "Climbing"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addClimbing) {
                                this.setState({
                                  category: [...category, "Climbing"],
                                });
                                this.setState({ addClimbing: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Climbing") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addClimbing: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Climbing</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/climbing.jpeg"
                              alt="Climbing"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol lg="3" md="3" sm="4" className="col-4">
                          <div
                            style={{
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              textAlign: "center",
                              padding: "0.25rem",
                              opacity:
                                category.length === 0 ||
                                category[0] === "Other" ||
                                category[1] === "Other" ||
                                category[2] === "Other" ||
                                category[3] === "Other" ||
                                category[4] === "Other" ||
                                category[5] === "Other" ||
                                category[6] === "Other" ||
                                category[7] === "Other" ||
                                category[8] === "Other" ||
                                category[9] === "Other"
                                  ? 1
                                  : 0.5,
                            }}
                            onClick={() => {
                              if (category.length === 0 || addOther) {
                                this.setState({
                                  category: [...category, "Other"],
                                });
                                this.setState({ addOther: false });
                              } else {
                                for (var i = 0; i < category.length; i++) {
                                  if (category[i] === "Other") {
                                    category.splice(i, 1);
                                    i--;
                                    this.setState({ category: category });
                                    this.setState({ addOther: true });
                                  }
                                }
                              }
                            }}
                          >
                            <h5 style={categoryTitle}>Other</h5>
                            <img
                              style={categoryImg}
                              src="/Sport_Icons/Other.jpg"
                              alt="Other"
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-3" style={categoryRow}>
                        {isBusiness ? (
                          <MDBCol lg="3" md="3" sm="4" className="col-4">
                            <div
                              style={{
                                border: "1px solid #202020",
                                borderRadius: "0.25rem",
                                textAlign: "center",
                                padding: "0.25rem",
                                opacity:
                                  category.length === 0 ||
                                  category[0] === "Apparel" ||
                                  category[1] === "Apparel" ||
                                  category[2] === "Apparel" ||
                                  category[3] === "Apparel" ||
                                  category[4] === "Apparel" ||
                                  category[5] === "Apparel" ||
                                  category[6] === "Apparel" ||
                                  category[7] === "Apparel" ||
                                  category[8] === "Apparel" ||
                                  category[9] === "Apparel"
                                    ? 1
                                    : 0.5,
                              }}
                              onClick={() => {
                                if (category.length === 0 || addApparel) {
                                  this.setState({
                                    category: [...category, "Apparel"],
                                  });
                                  this.setState({ addApparel: false });
                                } else {
                                  for (var i = 0; i < category.length; i++) {
                                    if (category[i] === "Apparel") {
                                      category.splice(i, 1);
                                      i--;
                                      this.setState({ category: category });
                                      this.setState({ addApparel: true });
                                    }
                                  }
                                }
                              }}
                            >
                              <h5 style={categoryTitle}>Apparel</h5>
                              <img
                                style={categoryImg}
                                src="/Sport_Icons/apparelCategory.jpg"
                                alt="Apparel"
                              />
                            </div>
                          </MDBCol>
                        ) : (
                          <></>
                        )}
                      </MDBRow>
                      {!addOther && (
                        <p
                          style={{
                            color: "#f8f8ff",
                            fontFamily: "Oswald",
                            textAlign: "center",
                            marginTop: "1.5rem",
                          }}
                        >
                          As demand for specific Sports increase, they will be
                          added as a category.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  {age !== "6-9" && age !== "10-12" && isBusiness ? (
                    <h5 style={parentTitle}>
                      Want to opt in to our Marketing to recieve Athletes to
                      watch and Edits through Email?
                    </h5>
                  ) : (
                    <h5 style={parentTitle}>
                      Want to opt in to our Marketing to recieve Discounts and
                      Edits through Email?
                    </h5>
                  )}
                  {age === "6-9" || age === "10-12" ? (
                    <div style={{ marginTop: 5, marginBottom: 5 }}>
                      <p style={infoText}>
                        As you are younger than 13, you will need Parental
                        Consent to opt into our marketing.
                      </p>
                      <p style={infoText}>
                        Please check with a Parent or Guardian before opting in.
                      </p>
                      <p style={infoText}>
                        More information regarding our marketing can be found
                        below in our{" "}
                        <a style={legalLink} href="/terms_and_conditions">
                          {" "}
                          Terms and Conditions
                        </a>{" "}
                        and
                        <a style={legalLink} href="/privacy_policy">
                          {" "}
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <MDBInput
                    label="Opt Into Marketing?"
                    type="checkbox"
                    id="checkbox2"
                    value={marketingOptIn}
                    onChange={(e) =>
                      this.setState({ marketingOptIn: !marketingOptIn })
                    }
                  />
                </div>
                {validationError.length !== 0 && (
                  <div className="flex justify-content-center pb-3 pt-3">
                    <h5 style={inputError}>{validationError}</h5>
                  </div>
                )}
                {error && (
                  <div className="flex justify-content-center pb-3 pt-3">
                    <h5 style={inputError}>
                      {error.message.replace("GraphQL error: ", "")}
                    </h5>
                  </div>
                )}
                {age === "Under 6" && (
                  <div className="flex justify-content-center pb-3 pt-3">
                    <h5 style={inputError}>
                      Users under the age of 6 aren't allowed to use the
                      platform for their safety.
                    </h5>
                  </div>
                )}
                {age !== "Under 6" ? (
                  <div className="text-center">
                    <MDBBtn
                      style={submitButton}
                      color="white"
                      rounded
                      type="submit"
                    >
                      {!loading && age !== "Under 6"
                        ? "Get Started"
                        : "Getting you Setup"}
                    </MDBBtn>
                  </div>
                ) : (
                  <Mutation
                    mutation={DELETE_ACCOUNT_MUTATION}
                    onCompleted={() => {
                      localStorage.removeItem(AUTH_TOKEN);
                      window.location.href = `/`;
                    }}
                  >
                    {(deleteAccount, { loading: deleteLoading }) => (
                      <div className="text-center">
                        <MDBBtn
                          style={submitButton}
                          color="white"
                          rounded
                          onClick={() => deleteAccount()}
                        >
                          {!deleteLoading
                            ? "Delete Account"
                            : "Deleting Account"}
                        </MDBBtn>
                      </div>
                    )}
                  </Mutation>
                )}
              </form>
            )}
          </Mutation>
        </MDBCol>
      </div>
    );
  }
}

export default InitialSetup;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
};

const inputError = {
  color: "red",
  fontFamily: "Oswald",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
};

const groupContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const ageContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const parentTitle = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
  textAlign: "justify",
};

const emailButton = {
  fontFamily: "Oswald",
  fontSize: "15px",
  width: "50%",
  paddingTop: "0.1rem",
  paddingBottom: "0.2rem",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const infoText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  textAlign: "justify",
};

const categoryRow = {
  display: "flex",
  justifyContent: "space-evenly",
};

const categoryTitle = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "center",
};

const categoryImg = {
  borderRadius: "0.25rem",
  objectFit: "cover",
  width: "100%",
  height: "100px",
};

const legalLink = {
  display: "inline",
  color: "#f8f8ff",
  fontFamily: "Oswald",
  padding: "0",
  fontStyle: "italic",
};

const submitButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};
