import React from 'react'
import { MDBBtn, MDBCol, MDBLink } from 'mdbreact'

const NoMatch = ({ location }) => (
  <div style={mainContainer} className="pt-2 d-flex align-items-center justify-content-center">
    <div>
      <MDBCol xs="12" sm="10" md="8" lg="4" className="px-3 offset-lg-4 offset-md-2 offset-sm-1">
        <img src="TSH_logos/TSH_ES_Black.png" alt="TSH Logo"/>
      </MDBCol>
      <MDBCol xs="12" sm="12" md="10" lg="8" className="px-3 offset-lg-2 offset-md-1">
        <h2 style={wrongPage}>The page you are looking for <code><h2 style={pageError}>{location.pathname}</h2></code> doesn't exist</h2>
      </MDBCol>
      <MDBCol xs="12" sm="12" md="12" lg="12" className="px-3">
        <MDBLink to="/" style={homeLink}><MDBBtn rounded color="white" style={homeButton}>Take Me Home</MDBBtn></MDBLink>
      </MDBCol>
    </div>
  </div>
)

export default NoMatch

const mainContainer = {
  backgroundColor: '#000', 
  minHeight: '100vh'
}

const wrongPage = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff', 
  textAlign: 'center',
}

const pageError = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff', 
  display: 'inline'
}

const homeLink = {
  textAlign: 'center',
}

const homeButton = {
  fontFamily: 'Oswald',
  fontSize: '18px', 
  paddingTop: '0.25rem',
  paddingBottom: '0.3rem'
}



