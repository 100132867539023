import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import gql from 'graphql-tag'
import { withRouter } from "react-router-dom";
import { MDBCol, MDBInput, MDBBtn, MDBRow } from 'mdbreact';
import "./../../styles/Forms.css"
import './../../styles/Background.css'
import Loading from './../Loading'
import ErrorPage from './../Errors/ErrorMessage'
import NoMatch from '../Errors/404';

const UPDATE_DISCOUNT_MUTATION = gql`
  mutation UpdateMutation($id: ID!, $amount: String!, $description: String!, $code: String!, $surf: Boolean, $skate: Boolean, $snowboard: Boolean, $ski: Boolean, $bmx: Boolean, $mtb: Boolean, $scooter: Boolean, $climbing: Boolean, $apparel: Boolean, $other: Boolean, $productDescription1: String!, $productDescription2: String!, $productDescription3: String!, $inStore: Boolean, $under18Viewable: Boolean) {
    postDiscountUpdate(id: $id, amount: $amount, description: $description, code: $code, surf: $surf, skate: $skate, snowboard: $snowboard, ski: $ski, bmx: $bmx, mtb: $mtb, scooter: $scooter, climbing: $climbing, apparel: $apparel, other: $other, productDescription1: $productDescription1, productDescription2: $productDescription2, productDescription3: $productDescription3, inStore: $inStore, under18Viewable: $under18Viewable) {
      id
      amount
      description
      code
      productDescription1
      productDescription2
      productDescription3
      inStore
      under18Viewable
    }
  }
` 

const DISCOUNT_QUERY = gql`
  query GetDiscountDetails($id: ID!) {
    discount(id: $id) {
      id
      amount
      description
      surf
      skate
      snowboard
      ski
      bmx
      mtb
      scooter
      climbing
      apparel
      other
      code 
      filename1
      filename2
      filename3
      productDescription1
      productDescription2
      productDescription3
      postedBy {
        id
        name
        website
        businessLogo
        city
        country
      }
      inStore
      }
  }
`

const GET_USER_DETAILS = gql`
  query{
    user{
      id
    }
  }
`

class EditDiscount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
    }
  }
  render() {

    const { id } = this.state

    return (
      <Query query={DISCOUNT_QUERY} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />

          const discount = data.discount

          return (
            <Query query={GET_USER_DETAILS}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return <ErrorPage error={error.message} />

              const user = data.user
          
              return (
                <>
                  {user.id === discount.postedBy.id ?
                    <div style={{backgroundColor: '#000', minHeight: '100vh'}} className="d-flex py-5 justify-content-center align-items-center">
                      <MDBCol sm="12" md="7" lg="5" xl="4" className="px-3 justify-content-center col-12">
                        <EditDiscountForm discount={discount} />
                      </MDBCol>
                    </div>
                  :
                    <NoMatch location={{pathname: 'Edit Discount'}}  />
                  }
                </>
              )
            }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

class EditDiscountForm extends Component {

  state = {
    id: this.props.discount.id,
    amount: this.props.discount.amount,
    description: this.props.discount.description,
    code: this.props.discount.code,
    surf: this.props.discount.surf,
    skate: this.props.discount.skate,
    snowboard: this.props.discount.snowboard,
    ski: this.props.discount.ski,
    bmx: this.props.discount.bmx,
    mtb: this.props.discount.mtb,
    scooter: this.props.discount.scooter,
    climbing: this.props.discount.climbing,
    apparel: this.props.discount.apparel,
    other: this.props.discount.other,
    noCategory: false,
    productDescription1: this.props.discount.productDescription1,
    productDescription2: this.props.discount.productDescription2,
    productDescription3: this.props.discount.productDescription3,
    userId: this.props.discount.postedBy.id,
    inStore: this.props.discount.inStore,
    under18Viewable: this.props.discount.under18Viewable,
    filename1: this.props.discount.filename1,
    fileError: '',
  }

  render() {
    const { id, amount, description, code, surf, skate, snowboard, ski, bmx, mtb, scooter, climbing, apparel, other, noCategory, productDescription1, productDescription2, productDescription3, userId, inStore, under18Viewable, fileError } = this.state
    var isVideo = false
    if(this.props.discount.filename1.substr(this.props.discount.filename1.lastIndexOf("."), this.props.discount.filename1.length) === ".mp4" || this.props.discount.filename1.substr(this.props.discount.filename1.lastIndexOf("."), this.props.discount.filename1.length) === ".MP4"){
      isVideo = true
    }

    return (
      <div>
        <h3 style={mainTitle}>EDIT DISCOUNT</h3>
        <Mutation mutation={UPDATE_DISCOUNT_MUTATION} onCompleted={() => window.location.href =`/profile/${userId}`}>
          {(postDiscountUpdate, { error, loading, data }) => (
            <form onSubmit={e => {
              e.preventDefault();
              if(!isVideo){
                if(amount === '') this.setState({fileError: 'Please add an Amount'})
                if(description === '') this.setState({fileError: 'Please add a Description'})
                if(code === '') this.setState({fileError: 'Please add a Code'})
                if(noCategory) this.setState({fileError: 'Please add a Category'})
                if(productDescription1 === '' || productDescription2 === '' || productDescription3 === '') this.setState({fileError: 'Please add a Product Description'})
                if(amount !== '' && description !== '' && code !== '' && !noCategory && productDescription1 !== '' && productDescription2 !== '' && productDescription3 !== '' && !loading && !data){
                  postDiscountUpdate({ variables: { id, amount, description, code, surf, skate, snowboard, ski, bmx, mtb, scooter, climbing, apparel, other, productDescription1, productDescription2, productDescription3, inStore, under18Viewable } })
                }
              }else{
                if(amount === '') this.setState({fileError: 'Please add an Amount'})
                if(description === '') this.setState({fileError: 'Please add a Description'})
                if(code === '') this.setState({fileError: 'Please add a Code'})
                if(noCategory) this.setState({fileError: 'Please add a Category'})
                if(productDescription1 === '') this.setState({fileError: 'Please add a Product Description'})
                if(amount !== '' && description !== '' && code !== '' && !noCategory && productDescription1 !== '' && !loading && !data){
                  postDiscountUpdate({ variables: { id, amount, description, code, surf, skate, snowboard, ski, bmx, mtb, scooter, climbing, apparel, other, productDescription1, productDescription2, productDescription3, inStore, under18Viewable } })
                }
              }
            }}>
              <MDBInput
                label="Amount*"
                value={amount}
                onChange={e => this.setState({ amount: e.target.value })}
                type="text"
                style={inputText}
                required
              />
              <MDBInput
                label="Description*"
                type="textarea"
                value={description}
                onChange={e => this.setState({ description: e.target.value })}
                style={inputText}
                required
              />
              <MDBInput
                label="Code*"
                value={code}
                onChange={e => this.setState({ code: e.target.value })}
                type="text"
                style={inputText}
                required
              />
              <div style={{marginTop: "1rem", marginBottom: '1rem'}}>
                <MDBInput
                  label="Is this Discount only In-Store?"
                  type='checkbox'
                  id='checkbox1'
                  value={inStore}
                  onChange={e => this.setState({ inStore: !inStore })}
                />
              </div>
              <div>
                <h5 style={labelText}>Select Sports*</h5>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: surf || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!surf){
                        this.setState({surf: true, noCategory: false})
                      }else{
                        this.setState({surf: false})
                        if(!skate && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Surf</h5>
                      <img style={categoryImg} src="/Sport_Icons/surf.jpg" alt="Surf" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: skate || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!skate){
                        this.setState({skate: true, noCategory: false})
                      }else{
                        this.setState({skate: false})
                        if(!surf && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Skate</h5>
                      <img style={categoryImg} src="/Sport_Icons/skate.jpeg" alt="Skate" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: snowboard || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!snowboard){
                        this.setState({snowboard: true, noCategory: false})
                      }else{
                        this.setState({snowboard: false})
                        if(!surf && !skate && !ski && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Snowboard</h5>
                      <img style={categoryImg} src="/Sport_Icons/snowboard.jpg" alt="Snowboard" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: ski || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!ski){
                        this.setState({ski: true, noCategory: false})
                      }else{
                        this.setState({ski: false})
                        if(!surf && !skate && !snowboard && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Ski</h5>
                      <img style={categoryImg} src="/Sport_Icons/ski.jpg" alt="Ski" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: bmx || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!bmx){
                        this.setState({bmx: true, noCategory: false})
                      }else{
                        this.setState({bmx: false})
                        if(!surf && !skate && !snowboard && !ski && !mtb && !scooter && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>BMX</h5>
                      <img style={categoryImg} src="/Sport_Icons/bmx.jpg" alt="BMX" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: mtb || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!mtb){
                        this.setState({mtb: true, noCategory: false})
                      }else{
                        this.setState({mtb: false})
                        if(!surf && !skate && !snowboard && !ski && !bmx && !scooter && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>MTB</h5>
                      <img style={categoryImg} src="/Sport_Icons/mtb.jpeg" alt="MTB" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: scooter || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!scooter){
                        this.setState({scooter: true, noCategory: false})
                      }else{
                        this.setState({scooter: false})
                        if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !climbing && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Scooter</h5>
                      <img style={categoryImg} src="/Sport_Icons/scooter.jpeg" alt="Scooter" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: climbing || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!climbing){
                        this.setState({climbing: true, noCategory: false})
                      }else{
                        this.setState({climbing: false})
                        if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !scooter && !apparel && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Climbing</h5>
                      <img style={categoryImg} src="/Sport_Icons/climbing.jpeg" alt="Climbing" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: other || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!other){
                        this.setState({other: true, noCategory: false})
                      }else{
                        this.setState({other: false})
                        if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !apparel){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Other</h5>
                      <img style={categoryImg} src="/Sport_Icons/Other.jpg" alt="Other" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="4" className='col-4'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: apparel || noCategory ? 1 : 0.5}} onClick={() => {
                      if(!apparel){
                        this.setState({apparel: true, noCategory: false})
                      }else{
                        this.setState({apparel: false})
                        if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !other){
                          this.setState({noCategory: true})
                        }
                      }
                    }}>
                      <h5 style={categoryTitle}>Apparel</h5>
                      <img style={categoryImg} src="/Sport_Icons/apparelCategory.jpg" alt="Apparel" />
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
              <div>
                <MDBInput
                  label="Description of 1st Product*"
                  value={productDescription1}
                  onChange={e => this.setState({ productDescription1: e.target.value })}
                  type="text"
                  required
                  style={inputText}
                  maxLength="120"
                />
                {!isVideo ?
                  <div>
                    <MDBInput
                      label="Description of 2nd Product*"
                      value={productDescription2}
                      onChange={e => this.setState({ productDescription2: e.target.value })}
                      type="text"
                      required
                      style={inputText}
                      maxLength="120"
                    />
                    <MDBInput
                      label="Description of 3rd Product*"
                      value={productDescription3}
                      onChange={e => this.setState({ productDescription3: e.target.value })}
                      type="text"
                      required
                      style={inputText}
                      maxLength="120"
                    />
                  </div>
                :
                  <></>
                }
              </div>
              {fileError !== '' ?
                <div className='py-2' style={{ textAlign: 'center' }}>
                  <p style={fileErrorText}>{fileError}</p>
                </div>
              :
                <></> 
              }
              {error && 
                <div className='py-2' style={{textAlign: 'center'}}>
                  <p style={errorText}>{error.message.includes('jwt expired') ?
                                                                'Token Expired. Please Login again' : error.message.replace('GraphQL error: ', '')}</p>
                </div>
              }
              <div className="text-center">
                <MDBBtn style={updateButton} rounded color="white" type="submit">{!loading ? "Update" : "Updating"}</MDBBtn>
              </div>
            </form>
          )}
        </Mutation>
      </div>
    )
  }
}

export default withRouter(EditDiscount)

const mainTitle = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  marginBottom: '1.5rem'
}

const inputText = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

const categoryRow = {
  display: 'flex', 
  justifyContent: 'space-evenly'
}

const categoryTitle = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald', 
  textAlign: 'center',
  fontSize: '1rem'
}

const categoryImg = {
  borderRadius: '0.25rem', 
  objectFit: 'cover', 
  width: '100%', 
  height: "80px"
}

const labelText = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff',
  fontSize: '1rem'
}

const fileErrorText = {
  color: 'red',
  fontFamily: 'Oswald',
  marginBottom: '0.5rem'
}

const errorText = {
  color: 'red',
  fontFamily: 'Oswald'
}

const updateButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: '50%',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem'
}
