import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

const AboutSection = () => {
  return (
    <section id="about-us" style={{backgroundColor: '#000'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5">
          <h2 className="h2-responsive text-uppercase text-center py-3 px-3" style={title}>
            We are born out of frustration
          </h2>
          <MDBRow style={{display: 'flex', alignItems: 'center'}}>
            {window.innerWidth > 1200 ?
              <>
                <MDBRow className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                  <MDBCol lg="3" class="justify-content-center">
                    <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/PolzeathMist.jpg" alt="Polzeath"/> 
                  </MDBCol>
                  <MDBCol lg="9">
                    <p style={aboutText} className="font-weight-bolder">
                      Originally based in the beautiful Tamar Valley, on the Devon and Cornwall border, spitting distance from the rugged north Cornish coast and its legendary surf. Wanting to share clips,  learn new tricks, connect with like minded surfers and find new kit, hours were spent searching the web, visiting different sites, to no avail. All that was wanted was a single hub.
                    </p>
                    <p style={aboutText} className="font-weight-bolder">
                      Unable to find that single site, we decided to build one.
                    </p>
                    <p style={aboutText} className="font-weight-bolder">
                      A single site to showcase skills, connect with like minded athletes, find established and exciting new brands at fantastic discounts and possibly secure sponsorship / ambassador roles. Allowing 'The Dream' to become a reality.
                    </p>
                    <p style={aboutText} className="font-weight-bolder">
                      As the site grew it became obvious that it was not just limited to surfers, new members were also keen, talented skaters and snowboarders. In response The Sponsor Hub expanded to accommodate these sports and will continue to do so as the demand dictates.
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                  <MDBCol lg="9">
                    <p style={aboutText} className="font-weight-bolder">
                      Initially successful in hooking up a number of athletes with our collaborating brands, as the site evolved it became obvious we couldn’t guarantee sponsorship for all our members. We could however provide all our members with access to exclusive discounts, whilst staying true to our original ethos of seeking sponsorship / brand ambassadorship for our members.
                    </p>
                    <p style={aboutText} className="font-weight-bolder">
                     This is <p style={aboutTextBolder}>The Sponsor Hub</p>.
                   </p>
                   <p style={aboutText} className="font-weight-bolder">
                     A safe, supportive and inclusive platform, creating a community with the extreme sports values at its heart; environmentally aware, custodians of the environment we play in, supportive of local communities and the pioneering businesses who keep the essence of our sports alive.
                   </p> 
                  </MDBCol>
                  <MDBCol lg="3" class="justify-content-center">
                    <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/skate1.jpg" alt="The Cougarr"/> 
                  </MDBCol>
                </MDBRow>
              </>
            :
            <>
              <MDBRow className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                <MDBCol md="4" sm="12" class="justify-content-center">
                  <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/PolzeathMist.jpg" alt="Polzeath"/> 
                </MDBCol>
                <MDBCol md="8" sm="12">
                  <p style={aboutText} className="font-weight-bolder">
                    Originally based in the beautiful Tamar Valley, on the Devon and Cornwall border, spitting distance from the rugged north Cornish coast and its legendary surf. Wanting to share clips,  learn new tricks, connect with like minded surfers and find new kit, hours were spent searching the web, visiting different sites, to no avail. All that was wanted was a single hub.
                  </p>
                  <p style={aboutText} className="font-weight-bolder">
                    Unable to find that single site, we decided to build one.
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                {window.innerWidth < 576 ?
                  <MDBCol md="4" sm="12" class="justify-content-center">
                    <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/skate.jpg" alt="Rachelle"/> 
                  </MDBCol>
                :
                  <></>
                }
                <MDBCol md="8" sm="12">
                  <p style={aboutText} className="font-weight-bolder">
                    A single site to showcase skills, connect with like minded athletes, find established and exciting new brands at fantastic discounts and possibly secure sponsorship / ambassador roles. Allowing 'The Dream' to become a reality.
                  </p>
                  <p style={aboutText} className="font-weight-bolder">
                    As the site grew it became obvious that it was not just limited to surfers, new members were also keen, talented skaters and snowboarders. In response The Sponsor Hub expanded to accommodate these sports and will continue to do so as the demand dictates.
                  </p>
                </MDBCol>
                {window.innerWidth > 576 ?
                  <MDBCol md="4" sm="12" class="justify-content-center">
                    <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/skate.jpg" alt="Rachelle"/> 
                  </MDBCol>
                :
                  <></>
                }
              </MDBRow>
              <MDBRow className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                <MDBCol md="4" sm="12" class="justify-content-center">
                  <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/luke_testimonial.jpg" alt="Luke"/> 
                </MDBCol>
                <MDBCol md="8" sm="12">
                  <p style={aboutText} className="font-weight-bolder">
                    Initially successful in hooking up a number of athletes with our collaborating brands, as the site evolved it became obvious we couldn’t guarantee sponsorship for all our members. We could however provide all our members with access to exclusive discounts, whilst staying true to our original ethos of seeking sponsorship / brand ambassadorship for our members.
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                {window.innerWidth < 576 ?
                  <MDBCol md="4" sm="12" class="justify-content-center">
                    <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/mtb.jpg" alt="Michael"/> 
                  </MDBCol> 
                :
                  <></>
                }
                <MDBCol md="8" sm="12">
                  <p style={aboutText} className="font-weight-bolder">
                    This is <p style={aboutTextBolder}>The Sponsor Hub</p>.
                  </p>
                  <p style={aboutText} className="font-weight-bolder">
                    A safe, supportive and inclusive platform, creating a community with the extreme sports values at its heart; environmentally aware, custodians of the environment we play in, supportive of local communities and the pioneering businesses who keep the essence of our sports alive.
                  </p>
                </MDBCol>
                {window.innerWidth > 576 ?
                  <MDBCol md="4" sm="12" class="justify-content-center">
                    <img style={{borderRadius: 5, marginBottom: '1rem'}} src="/About/mtb.jpg" alt="Michael"/> 
                  </MDBCol> 
                :
                  <></>
                }
              </MDBRow>
            </>
            }
          </MDBRow>
        </div>
      </MDBCol>
    </section>
  );
}

export default AboutSection;

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff'
}

const aboutText = {
  textAlign: 'justify', 
  color: '#f8f8ff', 
  fontFamily: 'Roboto',
}

const aboutTextBolder = {
  textAlign: 'justify', 
  color: '#f8f8ff', 
  fontFamily: 'Oswald',
  display: 'inline'
}
