import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import Video from './../Videos/Video'
import ErrorPage from './../Errors/ErrorMessage'
import Loading from './../Loading'
import InfiniteScroll from "react-infinite-scroll-component";
import { MDBRow } from 'mdbreact'

const PAGE_SIZE = 6;
 
const USER_VOTES_QUERY = gql`
  query userVotesQuery($id: ID!, $skip: Int, $last: Int){
    userVotes(id: $id, skip: $skip, last: $last, orderBy: createdAt_ASC){
      votes{
          id
        video{
          id
          filename
          description
          filmerInstagram
          mimetype
          crewOnly
          postedBy {
            id
            name
            category
            country
            city
            instagram
            email
            businessLogo
            profileImage
            isBusiness
          }
          votes {
            id
            user {
              id
            }
          }
          videoViews{
            id
          }
        }
      }
    }
  }
`


class ProfileStokes extends Component {
    state = {
      profileUser: this.props.profileUser,
      videos: [],
      skip: 0,
      loadMore: false,
      firstLoad: true,
      hasMore: true,
    }

    fetchMoreData = () => {
        setTimeout(() => {
          this._executeFetchMore()
        }, 1000);
      };
    
      _executeFetchMore = async () => {
        var { profileUser, skip } = this.state
    
        const result = await this.props.client.query({
          query: USER_VOTES_QUERY,
          variables: { id: profileUser.id , skip, last: PAGE_SIZE },
        })
        this.setState({ videos: [...this.state.videos, ...result.data.userVotes.votes] })
        if(result.data.userVotes.votes.length === 0){
          this.setState({hasMore: false})
        } else {
          this.setState({skip: this.state.skip + PAGE_SIZE})
          if(this.state.firstLoad){
            this._executeFetchMore()
          }
          this.setState({hasMore: true, firstLoad: false})
        }
      }

    render() {
      const { profileUser} = this.state
      return (
          <Query query={USER_VOTES_QUERY} variables={{id: profileUser.id, skip: 0, last: PAGE_SIZE }}>
            {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorPage error={error.message} />

            return(
                <div className="pt-3" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
                    {data.userVotes.votes.length === 0 ?
                      <div className="py-2 px-3" style={editsText}>
                        <h5>You currently have not Stoked any posts.</h5>
                        <h5>Go Stoke some Edits!</h5>
                      </div>
                    :
                    <InfiniteScroll
                      dataLength={this.state.videos.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.hasMore}
                      loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Stoked Edits...</h5></div>}
                    >
                      {data && this.state.firstLoad ?
                        <MDBRow className="justify-content-center">
                            {data.userVotes.votes.map(video => <Video key={video.video.id} video={video.video} />)}
                        </MDBRow>
                      :
                        <MDBRow className="justify-content-center">
                            {this.state.videos.map(video => <Video key={video.video.id} video={video.video} />)}
                        </MDBRow>
                      }
                      {!this.state.hasMore ?
                        <div className="pb-2" style={editsText} key={0}><h5>No More Stoked Edits!</h5></div>
                      :
                        <></>
                      }
                    </InfiniteScroll>
                    }
                </div>
            )
        }}
      </Query>
    );
  }
}

export default withApollo(ProfileStokes)

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}