import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Video from './Video'
import { MDBRow, MDBIcon } from 'mdbreact';
import Loading from './../Loading'
import ErrorPage from './../Errors/ErrorMessage'

const PAGE_SIZE = 6

const USER_CATEGORY_QUERY = gql`
    query{
        user{
            id
            category
            isBusiness
            newUser
        }
    }
`

const CREW_VIDEOS_QUERY = gql`
query CrewsQuery($id: ID!, $skip: Int, $last: Int){
  crewsVideos(id: $id, skip: $skip, last: $last){
    videos {
      id
      filename
      description
      filmerInstagram
      mimetype
      crewOnly
      postedBy {
        id
        name
        category
        country
        city
        instagram
        email
        businessLogo
        profileImage
        isBusiness
      }
      votes {
        id
        user {
          id
        }
      }
      videoViews{
        id
      }
    }
  }
}
`
var id

class CrewFeed extends Component {
  state = {
    videos: [],
    skip: 0,
    loadMore: false,
    firstLoad: true,
    hasMore: true,
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this._executeFetchMore()
    }, 1000);
  };

  _executeFetchMore = async () => {
    var { skip } = this.state

    const result = await this.props.client.query({
      query: CREW_VIDEOS_QUERY,
      variables: { id, skip, last: PAGE_SIZE },
    })
    this.setState({ videos: [...this.state.videos, ...result.data.crewsVideos.videos] })
    if(result.data.crewsVideos.videos.length === 0){
      this.setState({hasMore: false})
    } else {
      this.setState({skip: this.state.skip + PAGE_SIZE})
      if(this.state.firstLoad){
        this._executeFetchMore()
      }
      this.setState({hasMore: true, firstLoad: false})
    }
  }

  render() {
    return (
      <div className="pt-5 mt-3" style={{backgroundColor: '#000', minHeight: '100vh'}}>
        <h2 style={title}>CREW EDITS</h2>
        <Query query={USER_CATEGORY_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorPage error={error.message} />

            id = data.user.id

            return (
              <Query query={CREW_VIDEOS_QUERY} variables={{id, skip: 0, last: PAGE_SIZE}}>
              {({ data, loading, error }) => {
                  if(loading) return <div style={{minHeight: '100vh', backgroundColor: 'transparent'}}></div>
                  if(error) return <ErrorPage error={error.message} />

                  return (
                  <div className="pt-1" style={{minHeight: '100vh', backgroundColor: 'transparent'}}>
                      {data.crewsVideos.videos.length === 0 ?
                      <div className="py-3" style={crewsText}>
                          <h5>There are currently no Edits from your Crew.</h5>
                          <h5 style={{display: 'inline-flex', alignItems: 'center'}}>Click  <MDBIcon className="mx-2" style={{color: '#f8f8ff', fontSize: '1.2rem' }} fas icon="user-plus" /> to add Users to your Crew.</h5>
                          <h5>Please note, The Sponsor Hub can still see these Uploads for moderation purposes.</h5>
                      </div>
                      :
                      <InfiniteScroll
                      dataLength={this.state.videos.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.hasMore}
                      loader={<div className="pb-2" style={editsText} key={0}><h5>Loading More Crew Edits...</h5></div>}
                      >
                      {data && this.state.firstLoad ?
                        <MDBRow className="justify-content-center">
                            {data.crewsVideos.videos.map(video => <Video key={video.id} video={video} />)}
                        </MDBRow>
                      :
                        <MDBRow className="justify-content-center">
                            {this.state.videos.map(video => <Video key={video.id} video={video} />)}
                        </MDBRow>
                      }
                      {!this.state.hasMore ?
                          <div className="pb-2" style={editsText} key={0}><h5>No More Crew Edits!</h5></div>
                      :
                          <></>
                      }
                      </InfiniteScroll>
                      }
                  </div>
                  )
              }}
              </Query>
            )
          }}
        </Query>
      </div>
    );
  }
}

export default withApollo(CrewFeed);

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald'
}

const editsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}

const crewsText = {
  textAlign: 'center', 
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

