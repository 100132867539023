import React, { Component } from 'react'
import LocalAthletesVideos from './LocalAthletesVideos'

class LocalAthletesVideosPage extends Component {

  render() {
    return (
      <div style={{backgroundColor: "#000"}}>
        <h2 className="pt-5 mt-3" style={title}>Local Athletes</h2>
        <LocalAthletesVideos category={"All"} />
      </div>
    );
  }
}

export default LocalAthletesVideosPage

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald',
  textTransform: 'uppercase'
}

