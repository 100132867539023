import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBCol, MDBInput, MDBBtn, MDBRow, MDBSelect } from 'mdbreact';
import "./../../styles/Forms.css"
import Loading from '../Loading';
import ErrorPage from '../Errors/ErrorMessage'
import NoMatch from '../Errors/404';

const POST_UPDATE_VIDEO_MUTATION = gql`
  mutation PostMutation($id: ID!, $filename: String!, $description: String!, $category: String!, $userName: String!, $userInstagram: String!, $userGender: String!, $userCountry: String!, $userCity: String!, $filmerInstagram: String, $surfboard: String, $fins: String, $leash: String, $trackpad: String, $wetsuit: String, $booties: String, $surfGloves: String, $hood: String, $skateDeck: String, $trucks: String, $wheels: String, $griptape: String, $tshirt: String, $hoodie: String, $snowboard: String, 
    $snowboardBoots: String, $bindings: String, $jacket: String, $snowsportsHelmet: String, $goggles: String, $gloves: String, $snowsportsBottoms: String, $skis: String, $skiBoots: String, $poles: String, $bike: String, $shoes: String, $scooterDeck: String, $scooterWheels: String, $helmet: String, $pads: String, $bottoms: String, $surfWax: String, $skateWax: String, $beanie: String, $harness: String, $rope: String, $sling: String, $belay: String, $lockingCarabiner: String, $quickdraws: String, $cams: String, $nuts: String, $chalkBag: String, $chalk: String, $backpack: String, $competition: String, $crewOnly: Boolean, $spotLocation: String, $under18Viewable: Boolean, $spotId: String) {
    postVideoUpdate(id: $id, filename: $filename, description: $description, category: $category, userName: $userName, userInstagram: $userInstagram, userGender: $userGender, userCountry: $userCountry, userCity: $userCity, filmerInstagram: $filmerInstagram, surfboard: $surfboard, fins: $fins, leash: $leash, trackpad: $trackpad, wetsuit: $wetsuit, booties: $booties, surfGloves: $surfGloves, hood: $hood, skateDeck: $skateDeck, trucks: $trucks, wheels: $wheels, griptape: $griptape, tshirt: $tshirt, hoodie: $hoodie, snowboard: $snowboard, 
      snowboardBoots: $snowboardBoots, bindings: $bindings, jacket: $jacket, snowsportsHelmet: $snowsportsHelmet, goggles: $goggles, gloves: $gloves, snowsportsBottoms: $snowsportsBottoms, skis: $skis, skiBoots: $skiBoots, poles: $poles, bike: $bike, shoes: $shoes, scooterDeck: $scooterDeck, scooterWheels: $scooterWheels, helmet: $helmet, pads: $pads, bottoms: $bottoms, surfWax: $surfWax, skateWax: $skateWax, beanie: $beanie, harness: $harness, rope: $rope, sling: $sling, belay: $belay, lockingCarabiner: $lockingCarabiner, quickdraws: $quickdraws, cams: $cams, nuts: $nuts, chalkBag: $chalkBag, chalk: $chalk, backpack: $backpack, competition: $competition, crewOnly: $crewOnly, spotLocation: $spotLocation, under18Viewable: $under18Viewable, spotId: $spotId) {
      id
      filename
      description
      category
      userName
      userInstagram
      userGender
      userCountry
      userCity
      filmerInstagram
    }
  }
`

const SPOT_DETAILS_QUERY = gql`
query SpotsQuery($userId: ID!, $category: [String]!, $skip: Int, $last: Int){
  spots(userId: $userId, category: $category, skip: $skip, last: $last){
    spots{
      id
      title
      isBusiness
    }
  }
 }
` 

const VIDEO_QUERY = gql`
  query GetVideoDetails($id: ID!){
    video(id: $id){
      id
      filename
      description
      category
      filmerInstagram
      userId
      userName
      userInstagram
      userGender
      userCountry
      userCity
      surfboard
      fins
      leash
      trackpad
      wetsuit
      booties
      surfGloves
      hood
      skateDeck
      trucks
      wheels
      griptape
      tshirt
      hoodie
      snowboard
      snowboardBoots
      bindings
      jacket
      snowsportsHelmet
      goggles
      gloves
      snowsportsBottoms
      skis
      skiBoots
      poles
      bike
      shoes
      scooterDeck
      scooterWheels
      helmet
      pads
      bottoms
      surfWax
      skateWax
      beanie 
      competition
      crewOnly
      spotLocation
      spotId
      under18Viewable
      harness
      rope
      sling
      belay
      lockingCarabiner
      quickdraws
      cams
      nuts
      chalkBag
      chalk
      backpack
    }
  }
`

const GET_USER_DETAILS = gql`
  query{
    user{
      id
      category
      isBusiness
    }
  }
`

class EditVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
    }
  }

  render() {
    const { id } = this.state
    return (
      <Query query={VIDEO_QUERY} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />

          const video = data.video
          
          return(
          <Query query={GET_USER_DETAILS}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return <ErrorPage error={error.message} />

              const user = data.user
              const userIsBusiness = data.user.isBusiness
          
              return (
                <Query query={SPOT_DETAILS_QUERY} variables={{userId: user.id, category: user.category}}>
                  {({ loading, error, data, refetch }) => {
                    if (loading) return <Loading />
                    if (error) return <ErrorPage error={error.message} />

                    var spots = data.spots.spots
                    var spotsData = []

                    for(var i = 0; i < spots.length; i++){
                      if(spots[i].isBusiness === false){
                        spotsData.push({label: spots[i].id, value: spots[i].title})
                      }
                    }

                    return(
                      <>
                        {user.id === video.userId ?
                          <div style={{backgroundColor: '#000', minHeight: '100vh'}} className="d-flex py-5 justify-content-center align-items-center">
                            <MDBCol sm="12" md="7" lg="5" xl="4" className="px-3 justify-content-center col-12">
                              <EditVideoUploadForm video={video} user={user} spotsData={spotsData} userIsBusiness={userIsBusiness} />
                            </MDBCol>
                          </div> 
                        :
                          <NoMatch location={{pathname: "Edit Video"}} />
                        }
                      </>
                    )
                  }}
                </Query>
              )
            }}
          </Query>
          )
        }}
      </Query>
    )
  }
}

class EditVideoUploadForm extends Component {

  state = {
    id: this.props.video.id,
    filename: this.props.video.filename,
    description: this.props.video.description,
    category: this.props.video.category,
    addSurf: true,
    addSkate: true,
    addSnowboard: true,
    addSki: true,
    addBMX: true,
    addMTB: true,
    addScooter: true,
    addClimbing: true,
    addOther: true,
    addApparel: true,
    filmerInstagram: this.props.video.filmerInstagram,
    userId: this.props.video.userId,
    userName: this.props.video.userName, 
    userInstagram: this.props.video.userInstagram, 
    userGender: this.props.video.userGender, 
    userCountry: this.props.video.userCountry, 
    userCity: this.props.video.userCity,
    surfboard: this.props.video.surfboard,
    fins: this.props.video.fins,
    leash: this.props.video.leash,
    trackpad: this.props.video.trackpad,
    wetsuit: this.props.video.wetsuit,
    booties: this.props.video.booties,
    surfGloves: this.props.video.surfGloves,
    hood: this.props.video.hood,
    skateDeck: this.props.video.skateDeck,
    trucks: this.props.video.trucks,
    wheels: this.props.video.wheels,
    griptape: this.props.video.griptape,
    tshirt: this.props.video.tshirt,
    hoodie: this.props.video.hoodie,
    snowboard: this.props.video.snowboard,
    snowboardBoots: this.props.video.snowboardBoots,
    bindings: this.props.video.bindings,
    jacket: this.props.video.jacket,
    snowsportsHelmet: this.props.video.snowsportsHelmet,
    goggles: this.props.video.goggles,
    gloves: this.props.video.gloves,
    snowsportsBottoms: this.props.video.snowsportsBottoms,
    skis: this.props.video.skis,
    skiBoots: this.props.video.skiBoots,
    poles: this.props.video.poles,
    bike: this.props.video.bike,
    shoes: this.props.video.shoes,
    scooterDeck: this.props.video.scooterDeck,
    scooterWheels: this.props.video.scooterWheels,
    helmet: this.props.video.helmet,
    pads: this.props.video.pads,
    bottoms: this.props.video.bottoms,
    surfWax: this.props.video.surfWax,
    skateWax: this.props.video.skateWax,
    beanie: this.props.video.beanie,
    competition: this.props.video.competition,
    crewOnly: this.props.video.crewOnly,
    spotLocation: this.props.video.spotLocation,
    spotsData: this.props.spotsData,
    spotId: this.props.video.spotId,
    under18Viewable: this.props.video.under18Viewable,
    harness: this.props.video.harness,
    rope: this.props.video.rope,
    sling: this.props.video.sling,
    belay: this.props.video.belay,
    lockingCarabiner: this.props.video.lockingCarabiner,
    quickdraws: this.props.video.quickdraws,
    cams: this.props.video.cams,
    nuts: this.props.video.nuts,
    chalkBag: this.props.video.chalkBag,
    chalk: this.props.video.chalk,
    backpack: this.props.video.backpack,
    fileError: '',
    userIsBusiness: this.props.userIsBusiness
  }

  handleSelectChangeSpot = spotLocation => {
    for(var i = 0; i < this.state.spotsData.length; i++){
      if(spotLocation === this.state.spotsData[i].value){
        this.setState({spotId: this.state.spotsData[i].label})
      }
    }
    this.setState({ spotLocation: spotLocation })
  }

  render() {
    const { id, filename, description, category, addSurf, addSkate, addSnowboard, addSki, addBMX, addMTB, addScooter, addClimbing, addOther, addApparel, userId, userName, userInstagram, userGender, userCountry, userCity,
      surfboard, fins, leash, trackpad, wetsuit, booties, surfGloves, hood, skateDeck, trucks, wheels, griptape, tshirt, hoodie, snowboard, 
      snowboardBoots, bindings, jacket, snowsportsHelmet, goggles, gloves, snowsportsBottoms, skis, skiBoots, poles, bike, shoes, scooterDeck, 
      scooterWheels, helmet, pads, bottoms, surfWax, skateWax, beanie, competition, crewOnly, spotsData, spotId, under18Viewable, harness, rope, sling, belay, lockingCarabiner, quickdraws, cams, nuts,chalkBag, chalk, backpack, fileError, userIsBusiness } = this.state
      var filmerInstagram = this.state.filmerInstagram
      var spotLocation = this.state.spotLocation
      filmerInstagram = filmerInstagram.replace('@', '')
      filmerInstagram = '@' + filmerInstagram
    return (
      <div className='mt-3'>
        <h3 style={title}>EDIT UPLOAD</h3>
        <Mutation mutation={POST_UPDATE_VIDEO_MUTATION} onCompleted={() => window.location.href = `/profile/${userId}`}>
          {(postVideoUpdate, { error, loading, data }) => (
            <form onSubmit={e => {
              e.preventDefault();
              filmerInstagram = filmerInstagram.replace('@', '')
              if(category === ""){
                this.setState({fileError: 'Please select a Sport'})
              }else{
                if(!loading && !data){
                  postVideoUpdate({ variables: { id, filename, description, category, userName, userInstagram, userGender, userCountry, userCity, filmerInstagram, surfboard, fins, leash, trackpad, wetsuit, booties, surfGloves, hood, skateDeck, trucks, wheels, griptape, tshirt, hoodie, snowboard, snowboardBoots, bindings, jacket, snowsportsHelmet, goggles, gloves, snowsportsBottoms, skis, skiBoots, poles, bike, shoes, scooterDeck, scooterWheels, helmet, pads, bottoms, surfWax, skateWax, beanie, competition, crewOnly, spotLocation, under18Viewable, spotId } })
                }
              }
            }}>
              <MDBInput
                label="Description*"
                type="textarea"
                value={description}
                onChange={e => this.setState({ description: e.target.value })}
                required
                style={inputText}
                maxLength="150"
              />
              <MDBInput
                value={filmerInstagram}
                onChange={e => this.setState({ filmerInstagram: e.target.value })}
                type="text"
                label="Your Filmer's Instagram Handle"
                style={inputText}
                maxLength="80"
              />
              <div>
                <h5 style={labelText}>Select Sport*</h5>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Surf" ? 1 : 0.5}} onClick={() => {
                      if(addSurf){
                        this.setState({category: "Surf", addSurf: false})
                      }else{ 
                        this.setState({category: "", addSurf: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Surf</h5>
                      <img style={categoryImg} src="/Sport_Icons/surf.jpg" alt="Surf" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                  <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Skate" ? 1 : 0.5}} onClick={() => {
                      if(addSkate){
                        this.setState({category: "Skate", addSkate: false})
                      }else{ 
                        this.setState({category: "", addSkate: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Skate</h5>
                      <img style={categoryImg} src="/Sport_Icons/skate.jpeg" alt="Skate" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                  <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Snowboard" ? 1 : 0.5}} onClick={() => {
                      if(addSnowboard){
                        this.setState({category: "Snowboard", addSnowboard: false})
                      }else{ 
                        this.setState({category: "", addSnowboard: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Snowboard</h5>
                      <img style={categoryImg} src="/Sport_Icons/snowboard.jpg" alt="Snowboard" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Ski" ? 1 : 0.5}} onClick={() => {
                      if(addSki){
                        this.setState({category: "Ski", addSki: false})
                      }else{ 
                        this.setState({category: "", addSki: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Ski</h5>
                      <img style={categoryImg} src="/Sport_Icons/ski.jpg" alt="Ski" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                  <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "BMX" ? 1 : 0.5}} onClick={() => {
                      if(addBMX){
                        this.setState({category: "BMX", addBMX: false})
                      }else{ 
                        this.setState({category: "", addBMX: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>BMX</h5>
                      <img style={categoryImg} src="/Sport_Icons/bmx.jpg" alt="BMX" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                  <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "MTB" ? 1 : 0.5}} onClick={() => {
                      if(addMTB){
                        this.setState({category: "MTB", addMTB: false})
                      }else{ 
                        this.setState({category: "", addMTB: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>MTB</h5>
                      <img style={categoryImg} src="/Sport_Icons/mtb.jpeg" alt="MTB" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mt-3' style={categoryRow}>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Scooter" ? 1 : 0.5}} onClick={() => {
                      if(addScooter){
                        this.setState({category: "Scooter", addScooter: false})
                      }else{ 
                        this.setState({category: "", addScooter: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Scooter</h5>
                      <img style={categoryImg} src="/Sport_Icons/scooter.jpeg" alt="Scooter" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Climbing" ? 1 : 0.5}} onClick={() => {
                      if(addClimbing){
                        this.setState({category: "Climbing", addClimbing: false})
                      }else{ 
                        this.setState({category: "", addClimbing: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Climbing</h5>
                      <img style={categoryImg} src="/Sport_Icons/climbing.jpeg" alt="Climbing" />
                    </div>
                  </MDBCol>
                  <MDBCol lg="3" md="3" sm="6" className='col-6'>
                    <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Other" ? 1 : 0.5}} onClick={() => {
                      if(addOther){
                        this.setState({category: "Other", addOther: false})
                      }else{ 
                        this.setState({category: "", addOther: true})                                   
                      }
                    }}>
                      <h5 style={categoryTitle}>Other</h5>
                      <img style={categoryImg} src="/Sport_Icons/Other.jpg" alt="Other" />
                    </div>
                  </MDBCol>
                </MDBRow>
                {userIsBusiness ?
                  <MDBRow className='mt-3' style={categoryRow}>
                    <MDBCol lg="3" md="3" sm="6" className='col-6'>
                      <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: category === "" || category === "Apparel" ? 1 : 0.5}} onClick={() => {
                        if(addApparel){
                          this.setState({category: "Apparel", addApparel: false})
                        }else{ 
                          this.setState({category: "", addApparel: true})                                   
                        }
                      }}>
                        <h5 style={categoryTitle}>Apparel</h5>
                        <img style={categoryImg} src="/Sport_Icons/apparelCategory.jpg" alt="Apparel" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                :
                  <></>
                }
              </div> 
              <div style={{marginTop: "1.5rem", marginBottom: '1rem'}}>
                <MDBInput
                  label="Show Edit only to your Crew?"
                  type='checkbox'
                  id='checkbox1'
                  checked={crewOnly}
                  value={crewOnly}
                  onChange={e => this.setState({ crewOnly: !crewOnly })}
                />
              </div>
              {spotsData !== undefined ?
                <div>
                  <h5 style={labelText}>Add Spots on the Map to link Edits to</h5>
                  <MDBSelect 
                    options={spotsData}
                    label="Select Spot" 
                    value={spotLocation} 
                    getTextContent={this.handleSelectChangeSpot}
                  />
                </div>
              :
                <></>
              }
             {/* {category === "Surf" ?
                  <div>
                    <MDBSelect label="Competition" required value={competition} getValue={this.handleSelectChangeCompetition}>
                      <MDBSelectInput />
                      <MDBSelectOptions>
                        <MDBSelectOption value="15andundermale">15 and under Male</MDBSelectOption>
                        <MDBSelectOption value="15andunderfemale">15 and under Female</MDBSelectOption>
                        <MDBSelectOption value="16+male">16+ Male</MDBSelectOption>
                        <MDBSelectOption value="16+female">16+ Female</MDBSelectOption>
                      </MDBSelectOptions>
                    </MDBSelect>
                  </div>
                :
                  <></>
                }
                {competition !== '' ?
                  <div>
                    <h3 style={title}>Competition Guidelines</h3>
                    <p style={competitionTextMain}>This competition is open to surfers only.</p>
                    <ul>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>The edit must be 30-45 seconds long and can contain background audio but NO MUSIC or can be sequential images from the same wave.</p></li>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>The competition will run until the 16th of May with the winners announced on Instagram, on the 31st of May.</p></li>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>There are a variety of prizes on offer from Neverknowhy®, Leiki Boardshorts and EatSalt.</p></li>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>For Neverknowhy®, the top 3 contestants within each category of the competition will receive Neverknowhy Apparel wear, with the 1st place contestants being sponsored by Neverknowhy® and NKWHY® Extreme Sport for a minimum of 6 months. (Details to be announced)</p></li>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>For Leiki Boardshorts, the top 3 contestants within each category of the competition will receive Apparel wear, with the 1st place contestants being sponsored by Leiki Boardshorts for the Summer. (Details to be announced)</p></li>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>EatSalt are providing prizes for the UK contestants within the 16+ categories:</p></li>
                      <ul>
                        <li style={{color: '#f8f8ff'}}><p style={competitionText}>1st place — Eatsalt bundle + 3 months sponsorship package</p></li>
                        <li style={{color: '#f8f8ff'}}><p style={competitionText}>2nd place — Eatsalt beanie, sweater, surf wax & stickers</p></li>
                        <li style={{color: '#f8f8ff'}}><p style={competitionText}>3rd place — Eatsalt cap, surf wax & stickers</p></li>
                      </ul>
                      <li style={{color: '#f8f8ff'}}><p style={competitionText}>As well as smaller prizes for UK contestants within the 15 and under categories:</p></li>
                      <ul>
                        <li style={{color: '#f8f8ff'}}><p style={competitionText}>1st place — surf wax, stickers and £5 voucher</p></li>
                        <li style={{color: '#f8f8ff'}}><p style={competitionText}>2nd place — stickers and £5 voucher</p></li>
                        <li style={{color: '#f8f8ff'}}><p style={competitionText}>3rd place — stickers</p></li>
                      </ul>
                    </ul>
                  </div>
                :
                  <></> 
                }  */}
              {category === "Surf" ?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={surfboard}
                    onChange={e => this.setState({ surfboard: e.target.value})}
                    type="text"
                    label="Surfboard"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={fins}
                    onChange={e => this.setState({ fins: e.target.value})}
                    type="text"
                    label="Fins"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={leash}
                    onChange={e => this.setState({ leash: e.target.value})}
                    type="text"
                    label="Leash"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={trackpad}
                    onChange={e => this.setState({ trackpad: e.target.value})}
                    type="text"
                    label="Trackpad"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={wetsuit}
                    onChange={e => this.setState({ wetsuit: e.target.value})}
                    type="text"
                    label="Wetsuit"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={booties}
                    onChange={e => this.setState({ booties: e.target.value})}
                    type="text"
                    label="Booties"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={surfGloves}
                    onChange={e => this.setState({ surfGloves: e.target.value})}
                    type="text"
                    label="Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hood}
                    onChange={e => this.setState({ hood: e.target.value})}
                    type="text"
                    label="Hood"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={surfWax}
                    onChange={e => this.setState({ surfWax: e.target.value})}
                    type="text"
                    label="Wax"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Snowboard" ?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={snowboard}
                    onChange={e => this.setState({ snowboard: e.target.value})}
                    type="text"
                    label="Snowboard"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bindings}
                    onChange={e => this.setState({ bindings: e.target.value})}
                    type="text"
                    label="Bindings"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowboardBoots}
                    onChange={e => this.setState({ snowboardBoots: e.target.value})}
                    type="text"
                    label="Boots"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={jacket}
                    onChange={e => this.setState({ jacket: e.target.value})}
                    type="text"
                    label="Jacket"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsBottoms}
                    onChange={e => this.setState({ snowsportsBottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsHelmet}
                    onChange={e => this.setState({ snowsportsHelmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={goggles}
                    onChange={e => this.setState({ goggles: e.target.value})}
                    type="text"
                    label="Goggles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={gloves}
                    onChange={e => this.setState({ gloves: e.target.value})}
                    type="text"
                    label="Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Ski" ?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={skis}
                    onChange={e => this.setState({ skis: e.target.value})}
                    type="text"
                    label="Skis"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skiBoots}
                    onChange={e => this.setState({ skiBoots: e.target.value})}
                    type="text"
                    label="Boots"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={poles}
                    onChange={e => this.setState({ poles: e.target.value})}
                    type="text"
                    label="Poles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={jacket}
                    onChange={e => this.setState({ jacket: e.target.value})}
                    type="text"
                    label="Jacket"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsBottoms}
                    onChange={e => this.setState({ snowsportsBottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsHelmet}
                    onChange={e => this.setState({ snowsportsHelmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={goggles}
                    onChange={e => this.setState({ goggles: e.target.value})}
                    type="text"
                    label="Goggles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={gloves}
                    onChange={e => this.setState({ gloves: e.target.value})}
                    type="text"
                    label="Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Skate"?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={skateDeck}
                    onChange={e => this.setState({ skateDeck: e.target.value})}
                    type="text"
                    label="Deck"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={trucks}
                    onChange={e => this.setState({ trucks: e.target.value})}
                    type="text"
                    label="Trucks"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={wheels}
                    onChange={e => this.setState({ wheels: e.target.value})}
                    type="text"
                    label="Wheels"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={griptape}
                    onChange={e => this.setState({ griptape: e.target.value})}
                    type="text"
                    label="Griptape"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={pads}
                    onChange={e => this.setState({ pads: e.target.value})}
                    type="text"
                    label="Pads"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skateWax}
                    onChange={e => this.setState({ skateWax: e.target.value})}
                    type="text"
                    label="Wax"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Scooter"?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={scooterDeck}
                    onChange={e => this.setState({ scooterDeck: e.target.value})}
                    type="text"
                    label="Scooter"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={scooterWheels}
                    onChange={e => this.setState({ scooterWheels: e.target.value})}
                    type="text"
                    label="Wheels"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={griptape}
                    onChange={e => this.setState({ griptape: e.target.value})}
                    type="text"
                    label="Griptape"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={pads}
                    onChange={e => this.setState({ pads: e.target.value})}
                    type="text"
                    label="Pads"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "BMX"?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={bike}
                    onChange={e => this.setState({ bike: e.target.value})}
                    type="text"
                    label="Bike"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={pads}
                    onChange={e => this.setState({ pads: e.target.value})}
                    type="text"
                    label="Pads"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "MTB"?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={bike}
                    onChange={e => this.setState({ bike: e.target.value})}
                    type="text"
                    label="Bike"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={pads}
                    onChange={e => this.setState({ pads: e.target.value})}
                    type="text"
                    label="Pads"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Climbing"?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={harness}
                    onChange={e => this.setState({ harness: e.target.value})}
                    type="text"
                    label="Harness"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={rope}
                    onChange={e => this.setState({ rope: e.target.value})}
                    type="text"
                    label="Rope"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={sling}
                    onChange={e => this.setState({ sling: e.target.value})}
                    type="text"
                    label="Sling"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={belay}
                    onChange={e => this.setState({ belay: e.target.value})}
                    type="text"
                    label="Belay"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={lockingCarabiner}
                    onChange={e => this.setState({ lockingCarabiner: e.target.value})}
                    type="text"
                    label="Locking Carabiner"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={quickdraws}
                    onChange={e => this.setState({ quickdraws: e.target.value})}
                    type="text"
                    label="Quickdraws"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={cams}
                    onChange={e => this.setState({ cams: e.target.value})}
                    type="text"
                    label="Cams"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={nuts}
                    onChange={e => this.setState({ nuts: e.target.value})}
                    type="text"
                    label="Nuts"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={chalkBag}
                    onChange={e => this.setState({ chalkBag: e.target.value})}
                    type="text"
                    label="Chalk Bag"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={chalk}
                    onChange={e => this.setState({ chalk: e.target.value})}
                    type="text"
                    label="Chalk"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={gloves}
                    onChange={e => this.setState({ gloves: e.target.value})}
                    type="text"
                    label="Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={backpack}
                    onChange={e => this.setState({ backpack: e.target.value})}
                    type="text"
                    label="Back Pack"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Other" ?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={surfboard}
                    onChange={e => this.setState({ surfboard: e.target.value})}
                    type="text"
                    label="Surfboard"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={fins}
                    onChange={e => this.setState({ fins: e.target.value})}
                    type="text"
                    label="Fins"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={leash}
                    onChange={e => this.setState({ leash: e.target.value})}
                    type="text"
                    label="Leash"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={trackpad}
                    onChange={e => this.setState({ trackpad: e.target.value})}
                    type="text"
                    label="Trackpad"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={wetsuit}
                    onChange={e => this.setState({ wetsuit: e.target.value})}
                    type="text"
                    label="Wetsuit"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={booties}
                    onChange={e => this.setState({ booties: e.target.value})}
                    type="text"
                    label="Surf Booties"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={surfGloves}
                    onChange={e => this.setState({ surfGloves: e.target.value})}
                    type="text"
                    label="Surf Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hood}
                    onChange={e => this.setState({ hood: e.target.value})}
                    type="text"
                    label="Surf Hood"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={surfWax}
                    onChange={e => this.setState({ surfWax: e.target.value})}
                    type="text"
                    label="Surf Wax"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowboard}
                    onChange={e => this.setState({ snowboard: e.target.value})}
                    type="text"
                    label="Snowboard"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bindings}
                    onChange={e => this.setState({ bindings: e.target.value})}
                    type="text"
                    label="Bindings"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowboardBoots}
                    onChange={e => this.setState({ snowboardBoots: e.target.value})}
                    type="text"
                    label="Snowboard Boots"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={jacket}
                    onChange={e => this.setState({ jacket: e.target.value})}
                    type="text"
                    label="Jacket"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsBottoms}
                    onChange={e => this.setState({ snowsportsBottoms: e.target.value})}
                    type="text"
                    label="Snowsports Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsHelmet}
                    onChange={e => this.setState({ snowsportsHelmet: e.target.value})}
                    type="text"
                    label="Snowsports Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={goggles}
                    onChange={e => this.setState({ goggles: e.target.value})}
                    type="text"
                    label="Snowsports Goggles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={gloves}
                    onChange={e => this.setState({ gloves: e.target.value})}
                    type="text"
                    label="Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skis}
                    onChange={e => this.setState({ skis: e.target.value})}
                    type="text"
                    label="Skis"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skiBoots}
                    onChange={e => this.setState({ skiBoots: e.target.value})}
                    type="text"
                    label="Ski Boots"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={poles}
                    onChange={e => this.setState({ poles: e.target.value})}
                    type="text"
                    label="Ski Poles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={scooterDeck}
                    onChange={e => this.setState({ scooterDeck: e.target.value})}
                    type="text"
                    label="Scooter"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={scooterWheels}
                    onChange={e => this.setState({ scooterWheels: e.target.value})}
                    type="text"
                    label="Scooter Wheels"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={griptape}
                    onChange={e => this.setState({ griptape: e.target.value})}
                    type="text"
                    label="Griptape"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={pads}
                    onChange={e => this.setState({ pads: e.target.value})}
                    type="text"
                    label="Pads"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skateDeck}
                    onChange={e => this.setState({ skateDeck: e.target.value})}
                    type="text"
                    label="Skate Deck"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={trucks}
                    onChange={e => this.setState({ trucks: e.target.value})}
                    type="text"
                    label="Trucks"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={wheels}
                    onChange={e => this.setState({ wheels: e.target.value})}
                    type="text"
                    label="Skateboard Wheels"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skateWax}
                    onChange={e => this.setState({ skateWax: e.target.value})}
                    type="text"
                    label="Wax"
                    style={inputText}
                    maxLength="80"
                  />
                    <MDBInput
                    value={bike}
                    onChange={e => this.setState({ bike: e.target.value})}
                    type="text"
                    label="Bike"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={harness}
                    onChange={e => this.setState({ harness: e.target.value})}
                    type="text"
                    label="Harness"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={rope}
                    onChange={e => this.setState({ rope: e.target.value})}
                    type="text"
                    label="Rope"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={sling}
                    onChange={e => this.setState({ sling: e.target.value})}
                    type="text"
                    label="Sling"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={belay}
                    onChange={e => this.setState({ belay: e.target.value})}
                    type="text"
                    label="Belay"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={lockingCarabiner}
                    onChange={e => this.setState({ lockingCarabiner: e.target.value})}
                    type="text"
                    label="Locking Carabiner"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={quickdraws}
                    onChange={e => this.setState({ quickdraws: e.target.value})}
                    type="text"
                    label="Quickdraws"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={cams}
                    onChange={e => this.setState({ cams: e.target.value})}
                    type="text"
                    label="Cams"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={nuts}
                    onChange={e => this.setState({ nuts: e.target.value})}
                    type="text"
                    label="Nuts"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={chalkBag}
                    onChange={e => this.setState({ chalkBag: e.target.value})}
                    type="text"
                    label="Chalk Bag"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={chalk}
                    onChange={e => this.setState({ chalk: e.target.value})}
                    type="text"
                    label="Chalk"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={backpack}
                    onChange={e => this.setState({ backpack: e.target.value})}
                    type="text"
                    label="Back Pack"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              : category === "Apparel" ?
                <div>
                  <h5 style={title}>Add brands to the gear you ride - You don't have to fill them all in</h5>
                  <MDBInput
                    value={surfboard}
                    onChange={e => this.setState({ surfboard: e.target.value})}
                    type="text"
                    label="Surfboard"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={fins}
                    onChange={e => this.setState({ fins: e.target.value})}
                    type="text"
                    label="Fins"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={leash}
                    onChange={e => this.setState({ leash: e.target.value})}
                    type="text"
                    label="Leash"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={trackpad}
                    onChange={e => this.setState({ trackpad: e.target.value})}
                    type="text"
                    label="Trackpad"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={wetsuit}
                    onChange={e => this.setState({ wetsuit: e.target.value})}
                    type="text"
                    label="Wetsuit"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={booties}
                    onChange={e => this.setState({ booties: e.target.value})}
                    type="text"
                    label="Surf Booties"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={surfGloves}
                    onChange={e => this.setState({ surfGloves: e.target.value})}
                    type="text"
                    label="Surf Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hood}
                    onChange={e => this.setState({ hood: e.target.value})}
                    type="text"
                    label="Surf Hood"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={surfWax}
                    onChange={e => this.setState({ surfWax: e.target.value})}
                    type="text"
                    label="Surf Wax"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowboard}
                    onChange={e => this.setState({ snowboard: e.target.value})}
                    type="text"
                    label="Snowboard"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bindings}
                    onChange={e => this.setState({ bindings: e.target.value})}
                    type="text"
                    label="Bindings"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowboardBoots}
                    onChange={e => this.setState({ snowboardBoots: e.target.value})}
                    type="text"
                    label="Snowboard Boots"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={jacket}
                    onChange={e => this.setState({ jacket: e.target.value})}
                    type="text"
                    label="Jacket"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsBottoms}
                    onChange={e => this.setState({ snowsportsBottoms: e.target.value})}
                    type="text"
                    label="Snowsports Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={snowsportsHelmet}
                    onChange={e => this.setState({ snowsportsHelmet: e.target.value})}
                    type="text"
                    label="Snowsports Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={goggles}
                    onChange={e => this.setState({ goggles: e.target.value})}
                    type="text"
                    label="Snowsports Goggles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={gloves}
                    onChange={e => this.setState({ gloves: e.target.value})}
                    type="text"
                    label="Gloves"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={beanie}
                    onChange={e => this.setState({ beanie: e.target.value})}
                    type="text"
                    label="Beanie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skis}
                    onChange={e => this.setState({ skis: e.target.value})}
                    type="text"
                    label="Skis"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skiBoots}
                    onChange={e => this.setState({ skiBoots: e.target.value})}
                    type="text"
                    label="Ski Boots"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={poles}
                    onChange={e => this.setState({ poles: e.target.value})}
                    type="text"
                    label="Ski Poles"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={scooterDeck}
                    onChange={e => this.setState({ scooterDeck: e.target.value})}
                    type="text"
                    label="Scooter"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={scooterWheels}
                    onChange={e => this.setState({ scooterWheels: e.target.value})}
                    type="text"
                    label="Scooter Wheels"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={griptape}
                    onChange={e => this.setState({ griptape: e.target.value})}
                    type="text"
                    label="Griptape"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={shoes}
                    onChange={e => this.setState({ shoes: e.target.value})}
                    type="text"
                    label="Shoes"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={helmet}
                    onChange={e => this.setState({ helmet: e.target.value})}
                    type="text"
                    label="Helmet"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={pads}
                    onChange={e => this.setState({ pads: e.target.value})}
                    type="text"
                    label="Pads"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={tshirt}
                    onChange={e => this.setState({ tshirt: e.target.value})}
                    type="text"
                    label="T-Shirt"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={hoodie}
                    onChange={e => this.setState({ hoodie: e.target.value})}
                    type="text"
                    label="Hoodie"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bottoms}
                    onChange={e => this.setState({ bottoms: e.target.value})}
                    type="text"
                    label="Bottoms"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skateDeck}
                    onChange={e => this.setState({ skateDeck: e.target.value})}
                    type="text"
                    label="Skate Deck"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={trucks}
                    onChange={e => this.setState({ trucks: e.target.value})}
                    type="text"
                    label="Trucks"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={wheels}
                    onChange={e => this.setState({ wheels: e.target.value})}
                    type="text"
                    label="Skateboard Wheels"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={skateWax}
                    onChange={e => this.setState({ skateWax: e.target.value})}
                    type="text"
                    label="Skate Wax"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={bike}
                    onChange={e => this.setState({ bike: e.target.value})}
                    type="text"
                    label="Bike"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={harness}
                    onChange={e => this.setState({ harness: e.target.value})}
                    type="text"
                    label="Harness"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={rope}
                    onChange={e => this.setState({ rope: e.target.value})}
                    type="text"
                    label="Rope"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={sling}
                    onChange={e => this.setState({ sling: e.target.value})}
                    type="text"
                    label="Sling"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={belay}
                    onChange={e => this.setState({ belay: e.target.value})}
                    type="text"
                    label="Belay"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={lockingCarabiner}
                    onChange={e => this.setState({ lockingCarabiner: e.target.value})}
                    type="text"
                    label="Locking Carabiner"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={quickdraws}
                    onChange={e => this.setState({ quickdraws: e.target.value})}
                    type="text"
                    label="Quickdraws"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={cams}
                    onChange={e => this.setState({ cams: e.target.value})}
                    type="text"
                    label="Cams"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={nuts}
                    onChange={e => this.setState({ nuts: e.target.value})}
                    type="text"
                    label="Nuts"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={chalkBag}
                    onChange={e => this.setState({ chalkBag: e.target.value})}
                    type="text"
                    label="Chalk Bag"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={chalk}
                    onChange={e => this.setState({ chalk: e.target.value})}
                    type="text"
                    label="Chalk"
                    style={inputText}
                    maxLength="80"
                  />
                  <MDBInput
                    value={backpack}
                    onChange={e => this.setState({ backpack: e.target.value})}
                    type="text"
                    label="Back Pack"
                    style={inputText}
                    maxLength="80"
                  />
                </div>
              :
                <></>
              }
              <MDBInput
                label="Is this Edit suitable for under 18's?"
                type='checkbox'
                id='checkbox1'
                value={under18Viewable}
                onChange={e => this.setState({ under18Viewable: !under18Viewable })}
              />
              <div className='pt-2' style={{textAlign: 'center'}}>
                {error && <p style={errorText}>{error.message.includes('jwt expired') ?
                                                                'Token Expired. Please Login again' : error.message.replace('GraphQL error: ', '')}</p>}
                {fileError !== '' ?
                  <p style={fileErrorText}>{fileError}</p>
                :
                  <></> 
                }
              </div>
              <div className="pt-2 text-center">
                <MDBBtn style={updateButton} rounded color="white" type="submit">{!loading ? "Update" : "Updating"}</MDBBtn>
              </div>
            </form>
          )}
        </Mutation>
      </div>
    )
  }
}

export default EditVideo

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  marginBottom: '1.5rem'
}

const inputText = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

const categoryRow = {
  display: 'flex', 
  justifyContent: 'space-evenly'
}

const categoryTitle = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald', 
  textAlign: 'center',
  fontSize: '1rem'
}

const categoryImg = {
  borderRadius: '0.25rem', 
  objectFit: 'cover', 
  width: '100%', 
  height: "80px"
}

const labelText = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff',
  fontSize: '1rem'
}

// const competitionTextMain = {
//   color: '#f8f8ff',
//   fontFamily: 'Roboto',
//   fontWeight: '300',
//   marginBottom: 0 ,
//   fontSize: '14px',
//   marginTop: '15px',
//   textAlign: 'center'
// }

// const competitionText = {
//   color: '#f8f8ff',
//   fontFamily: 'Roboto',
//   fontWeight: '300',
//   marginBottom: 0 ,
//   fontSize: '14px',
//   marginTop: '15px',
//   textAlign: 'justify'
// }

const errorText = {
  color: 'red',
  fontFamily: 'Oswald'
}

const updateButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: '50%',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem'
}

const fileErrorText = {
  color: 'red',
  fontFamily: 'Oswald',
  marginBottom: '0.5rem'
}
