import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Video from "../Videos/Video";
import { MDBRow, MDBInput, MDBCol } from "mdbreact";
import Discount from "../Discounts/Discount";
import User from "../User/User";
import ErrorPage from "../Errors/ErrorMessage";

const DISCOUNT_SEARCH_QUERY = gql`
  query DiscountSearchQuery($filter: String!, $skip: Int, $first: Int) {
    discountSearch(filter: $filter, skip: $skip, first: $first) {
      discounts {
        id
        amount
        description
        code
        filename1
        filename2
        filename3
        productDescription1
        productDescription2
        productDescription3
        postedBy {
          id
          name
          website
          businessLogo
          city
          country
        }
        inStore
      }
    }
  }
`;

const VIDEO_SEARCH_QUERY = gql`
  query VideoSearchQuery($filter: String!, $skip: Int, $first: Int) {
    videoSearch(filter: $filter, skip: $skip, first: $first) {
      videos {
        id
        filename
        description
        filmerInstagram
        mimetype
        crewOnly
        postedBy {
          id
          name
          category
          country
          city
          instagram
          email
          businessLogo
          profileImage
          isBusiness
        }
        votes {
          id
          user {
            id
          }
        }
        videoViews {
          id
        }
      }
    }
  }
`;

const USERS_SEARCH_QUERY = gql`
  query UserSearchQuery($filter: String!, $skip: Int, $first: Int) {
    userSearch(filter: $filter, skip: $skip, first: $first) {
      users {
        id
        name
        email
        gender
        instagram
        country
        city
        category
        sponsors
        athleteOrPhotographer
        isBusiness
        website
        businessLogo
        profileImage
      }
    }
  }
`;

class SearchFeed extends Component {
  state = {
    filter: "",
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        loadMore: true,
      });
    }, 1000);
  };

  handleInput = (e) => {
    this.setState({
      filter: e.target.value,
    });
  };

  render() {
    var VIDEO_SIZE = 6;
    var DISCOUNT_SIZE = 6;
    var USER_SIZE = 6;
    if (window.innerWidth > 1900) {
      VIDEO_SIZE = 8;
      DISCOUNT_SIZE = 8;
      USER_SIZE = 8;
    }
    var { filter } = this.state;
    return (
      <div
        className={window.innerWidth > 576 ? "pt-5 mt-4" : "pt-5 mt-1"}
        style={{ backgroundColor: "#000", minHeight: "100vh" }}
      >
        <div style={{ width: "-moz-available" }}>
          <div className="px-3" style={{ textAlign: "center" }}>
            <h2 style={title}>Search for Users, Discounts and Edits</h2>
          </div>
          <MDBCol
            xl="4"
            lg="6"
            md="8"
            sm="12"
            className="offset-xl-4 offset-lg-3 offset-md-2 col-12 px-3"
          >
            <MDBInput
              id="searchInput"
              hint="Search"
              type="text"
              placeholder="Search"
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.keyCode === 13) {
                  this.setState({ filter: event.target.value });
                }
              }}
              style={inputText}
              className="white-text font-weight-bolder"
              containerClass="mt-0"
              onBlur={(e) => this.setState({ filter: e.target.value })}
            />
          </MDBCol>
        </div>
        <Query
          query={DISCOUNT_SEARCH_QUERY}
          variables={{ filter, skip: 0, first: DISCOUNT_SIZE }}
        >
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div
                  style={{ minHeight: "100vh", backgroundColor: "transparent" }}
                ></div>
              );
            if (error) return <ErrorPage error={error.message} />;

            const discountsToRender = data.discountSearch.discounts;

            return (
              <Query
                query={USERS_SEARCH_QUERY}
                variables={{ filter, skip: 0, first: USER_SIZE }}
              >
                {({ data, loading, error }) => {
                  if (loading)
                    return (
                      <div
                        style={{
                          minHeight: "100vh",
                          backgroundColor: "transparent",
                        }}
                      ></div>
                    );
                  if (error) return <ErrorPage error={error.message} />;

                  const usersToRender = data.userSearch.users;

                  return (
                    <Query
                      query={VIDEO_SEARCH_QUERY}
                      variables={{ filter, skip: 0, first: VIDEO_SIZE }}
                    >
                      {({ data, loading, error }) => {
                        if (loading)
                          return (
                            <div
                              style={{
                                minHeight: "100vh",
                                backgroundColor: "transparent",
                              }}
                            ></div>
                          );
                        if (error) return <ErrorPage error={error.message} />;

                        const videosToRender = data.videoSearch.videos;

                        return (
                          <div
                            className="pt-1"
                            style={{
                              minHeight: "100vh",
                              backgroundColor: "transparent",
                            }}
                          >
                            {discountsToRender.length === 0 &&
                            usersToRender.length === 0 &&
                            videosToRender.length === 0 ? (
                              <div className="pb-2 text-center" key={0}>
                                <h5 style={labelText}>There Are No Matches</h5>
                              </div>
                            ) : (
                              <div>
                                <div className="mb-3">
                                  <h3 style={subTitle}>Users</h3>
                                </div>
                                {usersToRender.length === 0 ? (
                                  <div className="pb-2 text-center" key={0}>
                                    <h5 style={labelText}>
                                      There Are No Users that Match!
                                    </h5>
                                  </div>
                                ) : (
                                  <MDBRow
                                    style={{ justifyContent: "space-evenly" }}
                                  >
                                    {usersToRender.map((user) => (
                                      <User key={user.id} user={user} />
                                    ))}
                                  </MDBRow>
                                )}
                                <div className="mt-4 mb-3">
                                  <h3 style={subTitle}>Discounts</h3>
                                </div>
                                {discountsToRender.length === 0 ? (
                                  <div className="pb-2 text-center" key={0}>
                                    <h5 style={labelText}>
                                      There Are No Discounts that Match!
                                    </h5>
                                  </div>
                                ) : (
                                  <MDBRow
                                    style={{ justifyContent: "space-evenly" }}
                                  >
                                    {discountsToRender.map((discount) => (
                                      <Discount
                                        key={discount.id}
                                        discount={discount}
                                      />
                                    ))}
                                  </MDBRow>
                                )}
                                <div className="mt-4 mb-3">
                                  <h3 style={subTitle}>Edits</h3>
                                </div>
                                {videosToRender.length === 0 ? (
                                  <div className="pb-2" key={0}>
                                    <h5 style={labelText}>
                                      There Are No Edits that Match!
                                    </h5>
                                  </div>
                                ) : (
                                  <MDBRow
                                    className="mb-3"
                                    style={{ justifyContent: "space-evenly" }}
                                  >
                                    {videosToRender.map((video) => (
                                      <Video key={video.id} video={video} />
                                    ))}
                                  </MDBRow>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default SearchFeed;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const subTitle = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontWeight: "bolder",
  textTransform: "uppercase",
  marginLeft: "2rem",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
  textTransform: "uppercase",
};
