import React, { Component } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import NoMatch from '../Errors/404';
import Loading from '../Loading';
import ErrorPage from '../Errors/ErrorMessage';
import { MDBCol, MDBRow } from 'mdbreact';

const USER_ID_QUERY = gql`
  query{
    user{
      id
    }
  }
`

const STATISTICS_QUERY = gql`
  query{
    statistics{
        newUsersTotal
        newUsersTotalGrowth
        newUsersAthlete
        newUsersAthleteGrowth
        newUsersBusiness
        newUsersBusinessGrowth
        deletedUsersTotal
        deletedUsersTotalGrowth
        deletedUsersAthlete
        deletedUsersAthleteGrowth
        deletedUsersBusiness
        deletedUsersBusinessGrowth
        engagedUsers
        engagedUsersGrowth
        engagedUsersTotal
        engagedUsersTotalGrowth
        engagedAthletes
        engagedAthletesGrowth
        engagedBusinesses
        engagedBusinessesGrowth
        engagedNewUsers,
        engagedNewUsersGrowth,
        engagedOldUsers,
        engagedOldUsersGrowth,
        postedEdits
        postedEditsGrowth
        newEditsTotal
        newEditsTotalGrowth
        newEditsSurf
        newEditsSurfGrowth
        newEditsSkate
        newEditsSkateGrowth
        newEditsSnowboard
        newEditsSnowboardGrowth
        newEditsSki
        newEditsSkiGrowth
        newEditsBMX
        newEditsBMXGrowth
        newEditsMTB
        newEditsMTBGrowth
        newEditsClimbing
        newEditsClimbingGrowth
        newEditsScooter
        newEditsScooterGrowth
        postedDiscounts
        postedDiscountsGrowth
        newDiscountsTotal
        newDiscountsTotalGrowth
        newDiscountsSurf
        newDiscountsSurfGrowth
        newDiscountsSkate
        newDiscountsSkateGrowth
        newDiscountsSnowboard
        newDiscountsSnowboardGrowth
        newDiscountsSki
        newDiscountsSkiGrowth
        newDiscountsBMX
        newDiscountsBMXGrowth
        newDiscountsMTB
        newDiscountsMTBGrowth
        newDiscountsClimbing
        newDiscountsClimbingGrowth
        newDiscountsScooter
        newDiscountsScooterGrowth
        newDiscountsApparel
        newDiscountsApparelGrowth
    }
  }
`

class Statistics extends Component {
  render() {
    return(
      <Query query={USER_ID_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />

          const id = data.user.id

          if(id !== "ckck7xbsv025f0741j230h9w7"){
            return(
              <NoMatch location={{pathname: "Statistics"}} />
            )
          }else{
            return (
                <Query query={STATISTICS_QUERY}>
                {({ loading, error, data }) => {
                  if (loading) return <Loading />
                  if (error) return <ErrorPage error={error.message} />

                  return(
                    <div style={{backgroundColor: "#000", minHeight: '100vh'}}>
                        <h2 className="pt-5 mt-3" style={title}>STATISTICS</h2>
                        <MDBRow>
                            <MDBCol lg="4">
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>New Users this Week: {data.statistics.newUsersTotal}</h5>
                                    <p style={data.statistics.newUsersTotalGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newUsersTotalGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>New Athletes this Week: {data.statistics.newUsersAthlete}</h5>
                                    <p style={data.statistics.newUsersAthleteGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newUsersAthleteGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>New Businesses this Week: {data.statistics.newUsersBusiness}</h5>
                                    <p style={data.statistics.newUsersBusinessGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newUsersBusinessGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Deleted Users this Week: {data.statistics.deletedUsersTotal}</h5>
                                    <p style={data.statistics.deletedUsersTotalGrowth > 0 ? improvementDown : improvementUp}>{data.statistics.deletedUsersTotalGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Deleted Athletes this Week: {data.statistics.deletedUsersAthlete}</h5>
                                    <p style={data.statistics.deletedUsersAthleteGrowth > 0 ? improvementDown : improvementUp}>{data.statistics.deletedUsersAthleteGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Deleted Businesses this Week: {data.statistics.deletedUsersBusiness}</h5>
                                    <p style={data.statistics.deletedUsersBusinessGrowth > 0 ? improvementDown : improvementUp}>{data.statistics.deletedUsersBusinessGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Percentage of Total Users Engaged this Week: {data.statistics.engagedUsersTotal}%</h5>
                                    <p style={data.statistics.engagedUsersTotalGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.engagedUsersTotalGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Engaged Users this Week: {data.statistics.engagedUsers}</h5>
                                    <p style={data.statistics.engagedUsersGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.engagedUsersGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Engaged Athletes this Week: {data.statistics.engagedAthletes}</h5>
                                    <p style={data.statistics.engagedAthletesGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.engagedAthletesGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Engaged Businesses this Week: {data.statistics.engagedBusinesses}</h5>
                                    <p style={data.statistics.engagedBusinessesGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.engagedBusinessesGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Engaged New Users this Week: {data.statistics.engagedNewUsers}</h5>
                                    <p style={data.statistics.engagedNewUsersGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.engagedNewUsersGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Engaged Old Users this Week: {data.statistics.engagedOldUsers}</h5>
                                    <p style={data.statistics.engagedOldUsersGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.engagedOldUsersGrowth}%</p>
                                </div>
                            </MDBCol>
                            <MDBCol lg="4">
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Percentage of Users posting Edits this Week: {data.statistics.postedEdits}%</h5>
                                    <p style={data.statistics.postedEditsGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.postedEditsGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Number of Edits posted this Week: {data.statistics.newEditsTotal}</h5>
                                    <p style={data.statistics.newEditsTotalGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsTotalGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Surf Edits posted this Week: {data.statistics.newEditsSurf}</h5>
                                    <p style={data.statistics.newEditsSurfGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsSurfGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Skate Edits posted this Week: {data.statistics.newEditsSkate}</h5>
                                    <p style={data.statistics.newEditsSkateGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsSkateGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Snowboard Edits posted this Week: {data.statistics.newEditsSnowboard}</h5>
                                    <p style={data.statistics.newEditsSnowboardGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsSnowboardGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Ski Edits posted this Week: {data.statistics.newEditsSki}</h5>
                                    <p style={data.statistics.newEditsSkiGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsSkiGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>BMX Edits posted this Week: {data.statistics.newEditsBMX}</h5>
                                    <p style={data.statistics.newEditsBMXGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsBMXGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>MTB Edits posted this Week: {data.statistics.newEditsMTB}</h5>
                                    <p style={data.statistics.newEditsMTBGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsMTBGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Climbing Edits posted this Week: {data.statistics.newEditsClimbing}</h5>
                                    <p style={data.statistics.newEditsClimbingGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsClimbingGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Scooter Edits posted this Week: {data.statistics.newEditsScooter}</h5>
                                    <p style={data.statistics.newEditsScooterGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newEditsScooterGrowth}%</p>
                                </div>
                            </MDBCol>
                            <MDBCol lg="4">
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Percentage of Businesses posting Discounts this Week: {data.statistics.postedDiscounts}%</h5>
                                    <p style={data.statistics.postedDiscountsGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.postedDiscountsGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Number of Discounts posted this Week: {data.statistics.newDiscountsTotal}</h5>
                                    <p style={data.statistics.newDiscountsTotalGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsTotalGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Surf Discounts posted this Week: {data.statistics.newDiscountsSurf}</h5>
                                    <p style={data.statistics.newDiscountsSurfGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsSurfGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Skate Discounts posted this Week: {data.statistics.newDiscountsSkate}</h5>
                                    <p style={data.statistics.newDiscountsSkateGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsSkateGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Snowboard Discounts posted this Week: {data.statistics.newDiscountsSnowboard}</h5>
                                    <p style={data.statistics.newDiscountsSnowboardGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsSnowboardGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Ski Discounts posted this Week: {data.statistics.newDiscountsSki}</h5>
                                    <p style={data.statistics.newDiscountsSkiGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsSkiGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>BMX Discounts posted this Week: {data.statistics.newDiscountsBMX}</h5>
                                    <p style={data.statistics.newDiscountsBMXGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsBMXGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>MTB Discounts posted this Week: {data.statistics.newDiscountsMTB}</h5>
                                    <p style={data.statistics.newDiscountsMTBGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsMTBGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Climbing Discounts posted this Week: {data.statistics.newDiscountsClimbing}</h5>
                                    <p style={data.statistics.newDiscountsClimbingGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsClimbingGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Scooter Discounts posted this Week: {data.statistics.newDiscountsScooter}</h5>
                                    <p style={data.statistics.newDiscountsScooterGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsScooterGrowth}%</p>
                                </div>
                                <div className='py-3' style={{display: 'flex', alignItems: 'center'}}>
                                    <h5 className='mx-3' style={subTitle}>Apparel Discounts posted this Week: {data.statistics.newDiscountsApparel}</h5>
                                    <p style={data.statistics.newDiscountsApparelGrowth > 0 ? improvementUp : improvementDown}>{data.statistics.newDiscountsApparelGrowth}%</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                  )
                }}
                </Query>
            );
          }
        }}
      </Query>
    )
  }
}

export default Statistics

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald'
}

const subTitle = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald',
  margin: 0
}

const improvementUp = {
    color: 'green', 
    fontFamily: 'Oswald',
    margin: 0
}

const improvementDown = {
    color: 'red', 
    fontFamily: 'Oswald',
    margin: 0
}
