import React from "react";
import { MDBCol } from "mdbreact";
import './../../styles/Legal.css'

const TrustAndSafetyProcedure = () => {
  return (
    <div className="px-3 py-4" style={{backgroundColor: '#fff', textAlign: 'justify'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Trust and Safety Content Moderation Procedure</h1>
          <p className="legal-text">The Sponsor Hub is committed to provide a Safe, Supportive and Inclusive platform for all our members to enjoy, especially our Groms (Under 18s).</p><br/><br/>
          <p className="legal-text">We actively protect our members from any material likely to:</p><br/>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">1. Incite violence or hatred against particular groups and content which would be considered a criminal offence under laws relating to terrorism; child sexual abuse material; racism and xenophobia.</p><br/>
            </li>
            <li>
                <p className="legal-text">2. Impair Under 18s physical, mental or moral development.</p><br/>
            </li>
            <li>
                <p className="legal-text">3. Infringe standards around advertising.</p><br/>
            </li>
          </ul><br/>
          <p className="legal-text">Our current approach uses human moderation, carried out daily by our Trust and Safety team, to ensure all uploads meet with our <a className="legal-link" href="/community_guidelines"><p className="legal-text">Community Guidelines</p></a> and more specifically The Sponsor Hub Ltd.’s <a className="legal-link" href="/terms_and_conditions"><p className="legal-text">Terms and Conditions</p></a>.</p><br/><br/>
          <p className="legal-text">We also enable our community to peer review content on the platform, and you can report any potential harmful Edits or Discounts via the flag icon.</p><br/><br/>
          <p className="legal-text">If we see, or are alerted to, any content that may infringe our <a className="legal-link" href="/community_guidelines"><p className="legal-text">Community Guidelines</p></a> and more specifically our <a className="legal-link" href="/terms_and_conditions"><p className="legal-text">Terms and Conditions</p></a> we have an escalating review process in place.</p><br/><br/>
          <p className="legal-text">Once a post is flagged we act as soon as we can. Turnaround time is context-dependent. We prioritise reports based on type, how much review time is required, and how critical response time is.</p><br/><br/>
          <p className="legal-text">Our review system ensures that each report receives the proper care and attention that it requires, helping us provide our users with an experience that is as open and diverse as it is free from harmful or malicious activity.</p><br/>
          <h3 className="legal-subheading">Procedure</h3>
          <h4 className="legal-subheading">Tier 1</h4>
          <p className="legal-text">The Trust and Safety team review the content to determine whether there has been any violation.</p><br/><br />
          <p className="legal-text">If a breach is confirmed, actions that may be taken include:</p>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Deleting the post.</p>
            </li>
            <li>
                <p className="legal-text">Reminding the member of our guidelines.</p>
            </li>
            <li>
                <p className="legal-text">Issuing a warning.</p>
            </li>
            <li>
                <p className="legal-text">Revoking certain privileges.</p>
            </li>
            <li>
                <p className="legal-text">Removing the account entirely.</p>
            </li>
          </ul><br/>
          <p className="legal-text">If no breach is deemed to have occurred no action will be taken and the post will be unflagged.</p><br/><br/>
          <p className="legal-text">Decisions that are not clear are escalated to Tier 2.</p><br/>
          <h4 className="legal-subheading">Tier 2</h4>
          <p className="legal-text">In addition to reviewing reports escalated from Tier 1,  Tier 2 reviews several categories of violations in the first instance, including potential violations of copyright or trademark infringement.</p><br/><br/>
          <p className="legal-text">We follow up with the reporting user and we notify the account owner if any action against their account is taken.</p><br/><br/>
          <p className="legal-text">Out of consideration for our users and employees on the Trust and Safety team, we don’t comment on pending matters.</p>
          <h3 className="legal-subheading">Appeal Procedure</h3>
          <p className="legal-text">The Sponsor Hub runs an appeals process for members to question any actions taken against their account.</p><br/><br/>
          <p className="legal-text">To appeal a decision, email <a className="legal-link" href="mailto:info@thesponsorhub.com"><p className="legal-text">info@thesponsorhub.com</p></a>, with the email Subject being "Appeal". Within the email include details on which Edit/Discount you'd like to appeal as well as the reasons you believe the decision should be overturned. We will then reopen and re-review the decision.</p><br/><br/>
          <p className="legal-text">The appeal process is estimated to take up to 48 hours, but may be longer depending on the context. After completing the re-review, you will be emailed regarding our decision.</p><br/><br/>
          <p className="legal-text">Members wishing to appeal against any action taken against their account must do so within 14 days of receiving the notification and are advised to keep copies of all documents relating to the appeal.</p>
        </div>
      </MDBCol>
    </div>
  );
};

export default TrustAndSafetyProcedure;