import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import {
  MDBCol,
  MDBBtn,
  MDBFileInput,
  MDBSelectOption,
  MDBInput,
  MDBSelect,
  MDBSelectOptions,
  MDBSelectInput,
  MDBIcon,
} from "mdbreact";
import "./../styles/Inputs.css";

const PROFILE_SETUP_MUTATION = gql`
  mutation ProfileSetupMutation(
    $profileImage: Upload!
    $gender: String
    $instagram: String!
    $country: String!
    $city: String!
    $sponsors: String
    $about: String!
    $website: String
    $businessRegion: String
    $businessIsBricks: Boolean
  ) {
    profileSetup(
      profileImage: $profileImage
      gender: $gender
      instagram: $instagram
      country: $country
      city: $city
      sponsors: $sponsors
      about: $about
      website: $website
      businessRegion: $businessRegion
      businessIsBricks: $businessIsBricks
    ) {
      id
    }
  }
`;

class ProfileSetup extends Component {
  state = {
    isBusiness: this.props.isBusiness,
    file: "",
    gender: "",
    instagram: "",
    instagramInfo: false,
    country: "",
    city: "",
    cityInfo: false,
    sponsors: "",
    about: "",
    website: "",
    businessRegion: "",
    businessIsBricks: false,
    validationError: "",
  };

  fileChangeHandler = (files) => {
    this.setState({ file: files[0] });
  };

  handleSelectChangeCountry = (country) => {
    this.setState({ country: country });
  };

  render() {
    const {
      isBusiness,
      file,
      gender,
      instagram,
      instagramInfo,
      country,
      city,
      cityInfo,
      sponsors,
      about,
      website,
      businessRegion,
      businessIsBricks,
      validationError,
    } = this.state;
    return (
      <div
        style={{ minHeight: "100vh", backgroundColor: "#000" }}
        className="d-flex justify-content-center align-items-center pt-5"
      >
        <MDBCol
          sm="10"
          md="8"
          lg="6"
          xl="6"
          className="px-3 justify-content-center col-12 pt-4"
        >
          <MDBCol
            sm="8"
            md="8"
            lg="10"
            className="col-8 offset-2 offset-xs-2 offset-sm-2 offset-md-2 offset-lg-1"
          ></MDBCol>
          <h2 style={title}>Complete your Profile</h2>
          <Mutation
            mutation={PROFILE_SETUP_MUTATION}
            onCompleted={() =>
              (window.location.href = `/profile/${this.props.userId}`)
            }
          >
            {(profileSetup, { error, loading, data }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!loading && !data) {
                    this.setState({ validationError: "" });
                    if (file.length === 0) {
                      this.setState({
                        validationError: "Please add a Profile Image",
                      });
                    } else if (file.size > 15000000) {
                      this.setState({
                        validationError:
                          "File too big, please upload a file of size less than 15Mb",
                      });
                    } else if (
                      file.mimetype !== "image/jpeg" &&
                      file.mimetype === "image/jpg" &&
                      file.mimetype === "image/png"
                    ) {
                      this.setState({
                        validationError:
                          "Image type not recognised, please upload file of type png or jpg",
                      });
                    } else if (about.length === 0) {
                      this.setState({
                        validationError: "Please add About You",
                      });
                    } else if (instagram.length < 2) {
                      this.setState({
                        validationError: "Please add your Instagram Handle",
                      });
                    } else if (city.length === 0) {
                      this.setState({
                        validationError: "Please add your City",
                      });
                    } else if (country.length === 0) {
                      this.setState({
                        validationError: "Please select your Country",
                      });
                    } else {
                      if (isBusiness) {
                        if (
                          website.length === 0 &&
                          !website.toLowerCase().includes("http")
                        ) {
                          this.setState({
                            validationError:
                              "Please enter your Website Address in the form https://",
                          });
                        } else if (businessRegion.length === 0) {
                          this.setState({
                            validationError:
                              "Please select your Business Region",
                          });
                        } else {
                          profileSetup({
                            variables: {
                              profileImage: file,
                              instagram: instagram.replace("@", ""),
                              country,
                              city,
                              about,
                              website,
                              businessRegion,
                              businessIsBricks,
                            },
                          });
                        }
                      } else {
                        if (gender.length === 0) {
                          this.setState({
                            validationError: "Please select a Gender",
                          });
                        } else {
                          profileSetup({
                            variables: {
                              profileImage: file,
                              gender,
                              instagram: instagram.replace("@", ""),
                              country,
                              city,
                              sponsors,
                              about,
                            },
                          });
                        }
                      }
                    }
                  }
                }}
              >
                <div className="pt-3">
                  <h5 style={labelText}>Add a Profile Image</h5>
                  <div>
                    <MDBFileInput
                      btnColor="black"
                      btnTitle={isBusiness ? "Upload Logo" : "Upload Image"}
                      value={file}
                      type="file"
                      getValue={this.fileChangeHandler}
                    />
                  </div>
                  <div>
                    <MDBInput
                      value={city}
                      onChange={(e) => this.setState({ city: e.target.value })}
                      type="text"
                      label="City"
                      style={inputText}
                      required
                      maxLength="80"
                    />
                    <div style={infoIcon}>
                      <MDBIcon
                        style={{ color: "#f8f8ff" }}
                        onClick={() => this.setState({ cityInfo: !cityInfo })}
                        icon="question-circle"
                      />
                    </div>
                    {cityInfo ? (
                      <div>
                        <p style={infoText}>
                          Your location is used to discover local Discounts and
                          Athletes.
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <MDBSelect
                      label="Country"
                      required
                      value={country}
                      getTextContent={this.handleSelectChangeCountry}
                    >
                      <MDBSelectInput />
                      <MDBSelectOptions search>
                        <MDBSelectOption value="United Kingdom">
                          United Kingdom
                        </MDBSelectOption>
                        <MDBSelectOption value="United States of America">
                          United States of America
                        </MDBSelectOption>
                        <MDBSelectOption value="Australia">
                          Australia
                        </MDBSelectOption>
                        <MDBSelectOption value="France">France</MDBSelectOption>
                        <MDBSelectOption value="New Zealand">
                          New Zealand
                        </MDBSelectOption>
                        <MDBSelectOption value="South Africa">
                          South Africa
                        </MDBSelectOption>
                        <MDBSelectOption value="Spain">Spain</MDBSelectOption>
                        <MDBSelectOption value="Afganistan">
                          Afghanistan
                        </MDBSelectOption>
                        <MDBSelectOption value="Albania">
                          Albania
                        </MDBSelectOption>
                        <MDBSelectOption value="Algeria">
                          Algeria
                        </MDBSelectOption>
                        <MDBSelectOption value="American Samoa">
                          American Samoa
                        </MDBSelectOption>
                        <MDBSelectOption value="Andorra">
                          Andorra
                        </MDBSelectOption>
                        <MDBSelectOption value="Angola">Angola</MDBSelectOption>
                        <MDBSelectOption value="Anguilla">
                          Anguilla
                        </MDBSelectOption>
                        <MDBSelectOption value="Antigua & Barbuda">
                          Antigua & Barbuda
                        </MDBSelectOption>
                        <MDBSelectOption value="Argentina">
                          Argentina
                        </MDBSelectOption>
                        <MDBSelectOption value="Armenia">
                          Armenia
                        </MDBSelectOption>
                        <MDBSelectOption value="Aruba">Aruba</MDBSelectOption>
                        <MDBSelectOption value="Austria">
                          Austria
                        </MDBSelectOption>
                        <MDBSelectOption value="Azerbaijan">
                          Azerbaijan
                        </MDBSelectOption>
                        <MDBSelectOption value="Bahamas">
                          Bahamas
                        </MDBSelectOption>
                        <MDBSelectOption value="Bahrain">
                          Bahrain
                        </MDBSelectOption>
                        <MDBSelectOption value="Bangladesh">
                          Bangladesh
                        </MDBSelectOption>
                        <MDBSelectOption value="Barbados">
                          Barbados
                        </MDBSelectOption>
                        <MDBSelectOption value="Belarus">
                          Belarus
                        </MDBSelectOption>
                        <MDBSelectOption value="Belgium">
                          Belgium
                        </MDBSelectOption>
                        <MDBSelectOption value="Belize">Belize</MDBSelectOption>
                        <MDBSelectOption value="Benin">Benin</MDBSelectOption>
                        <MDBSelectOption value="Bermuda">
                          Bermuda
                        </MDBSelectOption>
                        <MDBSelectOption value="Bhutan">Bhutan</MDBSelectOption>
                        <MDBSelectOption value="Bolivia">
                          Bolivia
                        </MDBSelectOption>
                        <MDBSelectOption value="Bonaire">
                          Bonaire
                        </MDBSelectOption>
                        <MDBSelectOption value="Bosnia & Herzegovina">
                          Bosnia & Herzegovina
                        </MDBSelectOption>
                        <MDBSelectOption value="Botswana">
                          Botswana
                        </MDBSelectOption>
                        <MDBSelectOption value="Brazil">Brazil</MDBSelectOption>
                        <MDBSelectOption value="British Indian Ocean Ter">
                          British Indian Ocean Ter
                        </MDBSelectOption>
                        <MDBSelectOption value="Brunei">Brunei</MDBSelectOption>
                        <MDBSelectOption value="Bulgaria">
                          Bulgaria
                        </MDBSelectOption>
                        <MDBSelectOption value="Burkina Faso">
                          Burkina Faso
                        </MDBSelectOption>
                        <MDBSelectOption value="Burundi">
                          Burundi
                        </MDBSelectOption>
                        <MDBSelectOption value="Cambodia">
                          Cambodia
                        </MDBSelectOption>
                        <MDBSelectOption value="Cameroon">
                          Cameroon
                        </MDBSelectOption>
                        <MDBSelectOption value="Canada">Canada</MDBSelectOption>
                        <MDBSelectOption value="Canary Islands">
                          Canary Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Cape Verde">
                          Cape Verde
                        </MDBSelectOption>
                        <MDBSelectOption value="Cayman Islands">
                          Cayman Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Central African Republic">
                          Central African Republic
                        </MDBSelectOption>
                        <MDBSelectOption value="Chad">Chad</MDBSelectOption>
                        <MDBSelectOption value="Channel Islands">
                          Channel Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Chile">Chile</MDBSelectOption>
                        <MDBSelectOption value="China">China</MDBSelectOption>
                        <MDBSelectOption value="Christmas Island">
                          Christmas Island
                        </MDBSelectOption>
                        <MDBSelectOption value="Cocos Island">
                          Cocos Island
                        </MDBSelectOption>
                        <MDBSelectOption value="Colombia">
                          Colombia
                        </MDBSelectOption>
                        <MDBSelectOption value="Comoros">
                          Comoros
                        </MDBSelectOption>
                        <MDBSelectOption value="Congo">Congo</MDBSelectOption>
                        <MDBSelectOption value="Cook Islands">
                          Cook Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Costa Rica">
                          Costa Rica
                        </MDBSelectOption>
                        <MDBSelectOption value="Cote DIvoire">
                          Cote DIvoire
                        </MDBSelectOption>
                        <MDBSelectOption value="Croatia">
                          Croatia
                        </MDBSelectOption>
                        <MDBSelectOption value="Cuba">Cuba</MDBSelectOption>
                        <MDBSelectOption value="Curaco">
                          Curacao
                        </MDBSelectOption>
                        <MDBSelectOption value="Cyprus">Cyprus</MDBSelectOption>
                        <MDBSelectOption value="Czech Republic">
                          Czech Republic
                        </MDBSelectOption>
                        <MDBSelectOption value="Denmark">
                          Denmark
                        </MDBSelectOption>
                        <MDBSelectOption value="Djibouti">
                          Djibouti
                        </MDBSelectOption>
                        <MDBSelectOption value="Dominica">
                          Dominica
                        </MDBSelectOption>
                        <MDBSelectOption value="Dominican Republic">
                          Dominican Republic
                        </MDBSelectOption>
                        <MDBSelectOption value="East Timor">
                          East Timor
                        </MDBSelectOption>
                        <MDBSelectOption value="Ecuador">
                          Ecuador
                        </MDBSelectOption>
                        <MDBSelectOption value="Egypt">Egypt</MDBSelectOption>
                        <MDBSelectOption value="El Salvador">
                          El Salvador
                        </MDBSelectOption>
                        <MDBSelectOption value="Equatorial Guinea">
                          Equatorial Guinea
                        </MDBSelectOption>
                        <MDBSelectOption value="Eritrea">
                          Eritrea
                        </MDBSelectOption>
                        <MDBSelectOption value="Estonia">
                          Estonia
                        </MDBSelectOption>
                        <MDBSelectOption value="Ethiopia">
                          Ethiopia
                        </MDBSelectOption>
                        <MDBSelectOption value="Falkland Islands">
                          Falkland Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Faroe Islands">
                          Faroe Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Fiji">Fiji</MDBSelectOption>
                        <MDBSelectOption value="Finland">
                          Finland
                        </MDBSelectOption>
                        <MDBSelectOption value="French Guiana">
                          French Guiana
                        </MDBSelectOption>
                        <MDBSelectOption value="French Polynesia">
                          French Polynesia
                        </MDBSelectOption>
                        <MDBSelectOption value="French Southern Ter">
                          French Southern Ter
                        </MDBSelectOption>
                        <MDBSelectOption value="Gabon">Gabon</MDBSelectOption>
                        <MDBSelectOption value="Gambia">Gambia</MDBSelectOption>
                        <MDBSelectOption value="Georgia">
                          Georgia
                        </MDBSelectOption>
                        <MDBSelectOption value="Germany">
                          Germany
                        </MDBSelectOption>
                        <MDBSelectOption value="Ghana">Ghana</MDBSelectOption>
                        <MDBSelectOption value="Gibraltar">
                          Gibraltar
                        </MDBSelectOption>
                        <MDBSelectOption value="Greece">Greece</MDBSelectOption>
                        <MDBSelectOption value="Greenland">
                          Greenland
                        </MDBSelectOption>
                        <MDBSelectOption value="Grenada">
                          Grenada
                        </MDBSelectOption>
                        <MDBSelectOption value="Guadeloupe">
                          Guadeloupe
                        </MDBSelectOption>
                        <MDBSelectOption value="Guam">Guam</MDBSelectOption>
                        <MDBSelectOption value="Guatemala">
                          Guatemala
                        </MDBSelectOption>
                        <MDBSelectOption value="Guinea">Guinea</MDBSelectOption>
                        <MDBSelectOption value="Guyana">Guyana</MDBSelectOption>
                        <MDBSelectOption value="Haiti">Haiti</MDBSelectOption>
                        <MDBSelectOption value="Hawaii">Hawaii</MDBSelectOption>
                        <MDBSelectOption value="Honduras">
                          Honduras
                        </MDBSelectOption>
                        <MDBSelectOption value="Hong Kong">
                          Hong Kong
                        </MDBSelectOption>
                        <MDBSelectOption value="Hungary">
                          Hungary
                        </MDBSelectOption>
                        <MDBSelectOption value="Iceland">
                          Iceland
                        </MDBSelectOption>
                        <MDBSelectOption value="Indonesia">
                          Indonesia
                        </MDBSelectOption>
                        <MDBSelectOption value="India">India</MDBSelectOption>
                        <MDBSelectOption value="Iran">Iran</MDBSelectOption>
                        <MDBSelectOption value="Iraq">Iraq</MDBSelectOption>
                        <MDBSelectOption value="Ireland">
                          Ireland
                        </MDBSelectOption>
                        <MDBSelectOption value="Isle of Man">
                          Isle of Man
                        </MDBSelectOption>
                        <MDBSelectOption value="Israel">Israel</MDBSelectOption>
                        <MDBSelectOption value="Italy">Italy</MDBSelectOption>
                        <MDBSelectOption value="Jamaica">
                          Jamaica
                        </MDBSelectOption>
                        <MDBSelectOption value="Japan">Japan</MDBSelectOption>
                        <MDBSelectOption value="Jordan">Jordan</MDBSelectOption>
                        <MDBSelectOption value="Kazakhstan">
                          Kazakhstan
                        </MDBSelectOption>
                        <MDBSelectOption value="Kenya">Kenya</MDBSelectOption>
                        <MDBSelectOption value="Kiribati">
                          Kiribati
                        </MDBSelectOption>
                        <MDBSelectOption value="Korea North">
                          Korea North
                        </MDBSelectOption>
                        <MDBSelectOption value="Korea Sout">
                          Korea South
                        </MDBSelectOption>
                        <MDBSelectOption value="Kuwait">Kuwait</MDBSelectOption>
                        <MDBSelectOption value="Kyrgyzstan">
                          Kyrgyzstan
                        </MDBSelectOption>
                        <MDBSelectOption value="Laos">Laos</MDBSelectOption>
                        <MDBSelectOption value="Latvia">Latvia</MDBSelectOption>
                        <MDBSelectOption value="Lebanon">
                          Lebanon
                        </MDBSelectOption>
                        <MDBSelectOption value="Lesotho">
                          Lesotho
                        </MDBSelectOption>
                        <MDBSelectOption value="Liberia">
                          Liberia
                        </MDBSelectOption>
                        <MDBSelectOption value="Libya">Libya</MDBSelectOption>
                        <MDBSelectOption value="Liechtenstein">
                          Liechtenstein
                        </MDBSelectOption>
                        <MDBSelectOption value="Lithuania">
                          Lithuania
                        </MDBSelectOption>
                        <MDBSelectOption value="Luxembourg">
                          Luxembourg
                        </MDBSelectOption>
                        <MDBSelectOption value="Macau">Macau</MDBSelectOption>
                        <MDBSelectOption value="Macedonia">
                          Macedonia
                        </MDBSelectOption>
                        <MDBSelectOption value="Madagascar">
                          Madagascar
                        </MDBSelectOption>
                        <MDBSelectOption value="Malaysia">
                          Malaysia
                        </MDBSelectOption>
                        <MDBSelectOption value="Malawi">Malawi</MDBSelectOption>
                        <MDBSelectOption value="Maldives">
                          Maldives
                        </MDBSelectOption>
                        <MDBSelectOption value="Mali">Mali</MDBSelectOption>
                        <MDBSelectOption value="Malta">Malta</MDBSelectOption>
                        <MDBSelectOption value="Marshall Islands">
                          Marshall Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Martinique">
                          Martinique
                        </MDBSelectOption>
                        <MDBSelectOption value="Mauritania">
                          Mauritania
                        </MDBSelectOption>
                        <MDBSelectOption value="Mauritius">
                          Mauritius
                        </MDBSelectOption>
                        <MDBSelectOption value="Mayotte">
                          Mayotte
                        </MDBSelectOption>
                        <MDBSelectOption value="Mexico">Mexico</MDBSelectOption>
                        <MDBSelectOption value="Midway Islands">
                          Midway Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Moldova">
                          Moldova
                        </MDBSelectOption>
                        <MDBSelectOption value="Monaco">Monaco</MDBSelectOption>
                        <MDBSelectOption value="Mongolia">
                          Mongolia
                        </MDBSelectOption>
                        <MDBSelectOption value="Montserrat">
                          Montserrat
                        </MDBSelectOption>
                        <MDBSelectOption value="Morocco">
                          Morocco
                        </MDBSelectOption>
                        <MDBSelectOption value="Mozambique">
                          Mozambique
                        </MDBSelectOption>
                        <MDBSelectOption value="Myanmar">
                          Myanmar
                        </MDBSelectOption>
                        <MDBSelectOption value="Nambia">Nambia</MDBSelectOption>
                        <MDBSelectOption value="Nauru">Nauru</MDBSelectOption>
                        <MDBSelectOption value="Nepal">Nepal</MDBSelectOption>
                        <MDBSelectOption value="Netherland Antilles">
                          Netherland Antilles
                        </MDBSelectOption>
                        <MDBSelectOption value="Netherlands">
                          Netherlands (Holland, Europe)
                        </MDBSelectOption>
                        <MDBSelectOption value="Nevis">Nevis</MDBSelectOption>
                        <MDBSelectOption value="New Caledonia">
                          New Caledonia
                        </MDBSelectOption>
                        <MDBSelectOption value="Nicaragua">
                          Nicaragua
                        </MDBSelectOption>
                        <MDBSelectOption value="Niger">Niger</MDBSelectOption>
                        <MDBSelectOption value="Nigeria">
                          Nigeria
                        </MDBSelectOption>
                        <MDBSelectOption value="Niue">Niue</MDBSelectOption>
                        <MDBSelectOption value="Norfolk Island">
                          Norfolk Island
                        </MDBSelectOption>
                        <MDBSelectOption value="Norway">Norway</MDBSelectOption>
                        <MDBSelectOption value="Oman">Oman</MDBSelectOption>
                        <MDBSelectOption value="Pakistan">
                          Pakistan
                        </MDBSelectOption>
                        <MDBSelectOption value="Palau Island">
                          Palau Island
                        </MDBSelectOption>
                        <MDBSelectOption value="Palestine">
                          Palestine
                        </MDBSelectOption>
                        <MDBSelectOption value="Panama">Panama</MDBSelectOption>
                        <MDBSelectOption value="Papua New Guinea">
                          Papua New Guinea
                        </MDBSelectOption>
                        <MDBSelectOption value="Paraguay">
                          Paraguay
                        </MDBSelectOption>
                        <MDBSelectOption value="Peru">Peru</MDBSelectOption>
                        <MDBSelectOption value="Phillipines">
                          Philippines
                        </MDBSelectOption>
                        <MDBSelectOption value="Pitcairn Island">
                          Pitcairn Island
                        </MDBSelectOption>
                        <MDBSelectOption value="Poland">Poland</MDBSelectOption>
                        <MDBSelectOption value="Portugal">
                          Portugal
                        </MDBSelectOption>
                        <MDBSelectOption value="Puerto Rico">
                          Puerto Rico
                        </MDBSelectOption>
                        <MDBSelectOption value="Qatar">Qatar</MDBSelectOption>
                        <MDBSelectOption value="Republic of Montenegro">
                          Republic of Montenegro
                        </MDBSelectOption>
                        <MDBSelectOption value="Republic of Serbia">
                          Republic of Serbia
                        </MDBSelectOption>
                        <MDBSelectOption value="Reunion">
                          Reunion
                        </MDBSelectOption>
                        <MDBSelectOption value="Romania">
                          Romania
                        </MDBSelectOption>
                        <MDBSelectOption value="Russia">Russia</MDBSelectOption>
                        <MDBSelectOption value="Rwanda">Rwanda</MDBSelectOption>
                        <MDBSelectOption value="St Barthelemy">
                          St Barthelemy
                        </MDBSelectOption>
                        <MDBSelectOption value="St Eustatius">
                          St Eustatius
                        </MDBSelectOption>
                        <MDBSelectOption value="St Helena">
                          St Helena
                        </MDBSelectOption>
                        <MDBSelectOption value="St Kitts-Nevis">
                          St Kitts-Nevis
                        </MDBSelectOption>
                        <MDBSelectOption value="St Lucia">
                          St Lucia
                        </MDBSelectOption>
                        <MDBSelectOption value="St Maarten">
                          St Maarten
                        </MDBSelectOption>
                        <MDBSelectOption value="St Pierre & Miquelon">
                          St Pierre & Miquelon
                        </MDBSelectOption>
                        <MDBSelectOption value="St Vincent & Grenadines">
                          St Vincent & Grenadines
                        </MDBSelectOption>
                        <MDBSelectOption value="Saipan">Saipan</MDBSelectOption>
                        <MDBSelectOption value="Samoa">Samoa</MDBSelectOption>
                        <MDBSelectOption value="Samoa American">
                          Samoa American
                        </MDBSelectOption>
                        <MDBSelectOption value="San Marino">
                          San Marino
                        </MDBSelectOption>
                        <MDBSelectOption value="Sao Tome & Principe">
                          Sao Tome & Principe
                        </MDBSelectOption>
                        <MDBSelectOption value="Saudi Arabia">
                          Saudi Arabia
                        </MDBSelectOption>
                        <MDBSelectOption value="Senegal">
                          Senegal
                        </MDBSelectOption>
                        <MDBSelectOption value="Seychelles">
                          Seychelles
                        </MDBSelectOption>
                        <MDBSelectOption value="Sierra Leone">
                          Sierra Leone
                        </MDBSelectOption>
                        <MDBSelectOption value="Singapore">
                          Singapore
                        </MDBSelectOption>
                        <MDBSelectOption value="Slovakia">
                          Slovakia
                        </MDBSelectOption>
                        <MDBSelectOption value="Slovenia">
                          Slovenia
                        </MDBSelectOption>
                        <MDBSelectOption value="Solomon Islands">
                          Solomon Islands
                        </MDBSelectOption>
                        <MDBSelectOption value="Somalia">
                          Somalia
                        </MDBSelectOption>
                        <MDBSelectOption value="Sri Lanka">
                          Sri Lanka
                        </MDBSelectOption>
                        <MDBSelectOption value="Sudan">Sudan</MDBSelectOption>
                        <MDBSelectOption value="Suriname">
                          Suriname
                        </MDBSelectOption>
                        <MDBSelectOption value="Swaziland">
                          Swaziland
                        </MDBSelectOption>
                        <MDBSelectOption value="Sweden">Sweden</MDBSelectOption>
                        <MDBSelectOption value="Switzerland">
                          Switzerland
                        </MDBSelectOption>
                        <MDBSelectOption value="Syria">Syria</MDBSelectOption>
                        <MDBSelectOption value="Tahiti">Tahiti</MDBSelectOption>
                        <MDBSelectOption value="Taiwan">Taiwan</MDBSelectOption>
                        <MDBSelectOption value="Tajikistan">
                          Tajikistan
                        </MDBSelectOption>
                        <MDBSelectOption value="Tanzania">
                          Tanzania
                        </MDBSelectOption>
                        <MDBSelectOption value="Thailand">
                          Thailand
                        </MDBSelectOption>
                        <MDBSelectOption value="Togo">Togo</MDBSelectOption>
                        <MDBSelectOption value="Tokelau">
                          Tokelau
                        </MDBSelectOption>
                        <MDBSelectOption value="Tonga">Tonga</MDBSelectOption>
                        <MDBSelectOption value="Trinidad & Tobago">
                          Trinidad & Tobago
                        </MDBSelectOption>
                        <MDBSelectOption value="Tunisia">
                          Tunisia
                        </MDBSelectOption>
                        <MDBSelectOption value="Turkey">Turkey</MDBSelectOption>
                        <MDBSelectOption value="Turkmenistan">
                          Turkmenistan
                        </MDBSelectOption>
                        <MDBSelectOption value="Turks & Caicos Is">
                          Turks & Caicos Is
                        </MDBSelectOption>
                        <MDBSelectOption value="Tuvalu">Tuvalu</MDBSelectOption>
                        <MDBSelectOption value="Uganda">Uganda</MDBSelectOption>
                        <MDBSelectOption value="Ukraine">
                          Ukraine
                        </MDBSelectOption>
                        <MDBSelectOption value="United Arab Erimates">
                          United Arab Emirates
                        </MDBSelectOption>
                        <MDBSelectOption value="Uraguay">
                          Uruguay
                        </MDBSelectOption>
                        <MDBSelectOption value="Uzbekistan">
                          Uzbekistan
                        </MDBSelectOption>
                        <MDBSelectOption value="Vanuatu">
                          Vanuatu
                        </MDBSelectOption>
                        <MDBSelectOption value="Vatican City State">
                          Vatican City State
                        </MDBSelectOption>
                        <MDBSelectOption value="Venezuela">
                          Venezuela
                        </MDBSelectOption>
                        <MDBSelectOption value="Vietnam">
                          Vietnam
                        </MDBSelectOption>
                        <MDBSelectOption value="Virgin Islands (Brit)">
                          Virgin Islands (Brit)
                        </MDBSelectOption>
                        <MDBSelectOption value="Virgin Islands (USA)">
                          Virgin Islands (USA)
                        </MDBSelectOption>
                        <MDBSelectOption value="Wake Island">
                          Wake Island
                        </MDBSelectOption>
                        <MDBSelectOption value="Wallis & Futana Is">
                          Wallis & Futana Is
                        </MDBSelectOption>
                        <MDBSelectOption value="Yemen">Yemen</MDBSelectOption>
                        <MDBSelectOption value="Zaire">Zaire</MDBSelectOption>
                        <MDBSelectOption value="Zambia">Zambia</MDBSelectOption>
                        <MDBSelectOption value="Zimbabwe">
                          Zimbabwe
                        </MDBSelectOption>
                      </MDBSelectOptions>
                    </MDBSelect>
                  </div>
                  <div>
                    <MDBInput
                      value={about}
                      onChange={(e) => this.setState({ about: e.target.value })}
                      type="textarea"
                      label="About You"
                      style={inputText}
                      required
                      maxLength="150"
                    />
                  </div>
                  <div>
                    <MDBInput
                      value={instagram}
                      onChange={(e) =>
                        this.setState({ instagram: e.target.value })
                      }
                      type="text"
                      label="Instagram Handle"
                      style={inputText}
                      required
                      maxLength="80"
                    />
                    <div style={infoIcon}>
                      <MDBIcon
                        style={{ color: "#f8f8ff" }}
                        onClick={() =>
                          this.setState({ instagramInfo: !instagramInfo })
                        }
                        icon="question-circle"
                      />
                    </div>
                    {instagramInfo ? (
                      <div>
                        <p style={infoText}>
                          Your Instagram is used so Brands can check you out and
                          get a sense of who you are.
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {isBusiness ? (
                    <>
                      <div style={{ marginTop: "2rem" }}>
                        <p style={websiteText}>
                          Please have your Website in the format https://
                        </p>
                        <MDBInput
                          value={website}
                          onChange={(e) =>
                            this.setState({ website: e.target.value })
                          }
                          type="text"
                          placeholder="https://www.thesponsorhub.com"
                          label="Website"
                          style={inputText}
                          maxLength="120"
                        />
                      </div>
                      <div style={{ marginTop: "-0.5rem" }}>
                        <MDBInput
                          label="Is your Business a Cafe or Campsite?"
                          type="checkbox"
                          id="checkbox1"
                          value={businessIsBricks}
                          onChange={(e) =>
                            this.setState({
                              businessIsBricks: !businessIsBricks,
                            })
                          }
                        />
                      </div>
                      <div style={{ marginTop: "1rem" }}>
                        <h5 style={labelText}>Select Your Business Region</h5>
                        <div style={businessRegionContainer}>
                          <p
                            onClick={() =>
                              this.setState({ businessRegion: "National" })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                businessRegion === "" ||
                                businessRegion === "National"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            National
                          </p>
                          <p
                            onClick={() =>
                              this.setState({ businessRegion: "International" })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                businessRegion === "" ||
                                businessRegion === "International"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            International
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <h5 style={labelText}>Select Your Gender</h5>
                        <div style={genderContainer}>
                          <p
                            onClick={() => this.setState({ gender: "Male" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                gender === "" || gender === "Male" ? 1 : 0.5,
                            }}
                          >
                            Male
                          </p>
                          <p
                            onClick={() => this.setState({ gender: "Female" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                gender === "" || gender === "Female" ? 1 : 0.5,
                            }}
                          >
                            Female
                          </p>
                          <p
                            onClick={() => this.setState({ gender: "Other" })}
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                gender === "" || gender === "Other" ? 1 : 0.5,
                            }}
                          >
                            Other
                          </p>
                          <p
                            onClick={() =>
                              this.setState({ gender: "Prefer Not To Say" })
                            }
                            style={{
                              padding: "0.25rem",
                              border: "1px solid #202020",
                              borderRadius: "0.25rem",
                              fontFamily: "Oswald",
                              color: "#f8f8ff",
                              opacity:
                                gender === "" || gender === "Prefer Not To Say"
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            Prefer Not to Say
                          </p>
                        </div>
                      </div>
                      <div>
                        <MDBInput
                          value={sponsors}
                          onChange={(e) =>
                            this.setState({ sponsors: e.target.value })
                          }
                          type="text"
                          label="Sponsors"
                          style={inputText}
                          maxLength="80"
                        />
                      </div>
                    </>
                  )}
                </div>
                {validationError.length !== 0 && (
                  <div className="flex justify-content-center pb-3 pt-3">
                    <h5 style={inputError}>{validationError}</h5>
                  </div>
                )}
                {error && (
                  <div className="flex justify-content-center pb-3 pt-3">
                    <h5 style={inputError}>
                      {error.message.replace("GraphQL error: ", "")}
                    </h5>
                  </div>
                )}

                <div className="text-center">
                  <MDBBtn
                    style={submitButton}
                    color="white"
                    rounded
                    type="submit"
                  >
                    {loading ? "Completing Profile" : "Complete Profile"}
                  </MDBBtn>
                </div>
              </form>
            )}
          </Mutation>
        </MDBCol>
      </div>
    );
  }
}

export default ProfileSetup;

const title = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  textAlign: "center",
  fontWeight: "bolder",
  textTransform: "uppercase",
};

const inputError = {
  color: "red",
  fontFamily: "Oswald",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1.25rem",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const infoText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  textAlign: "justify",
};

const genderContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
  marginBottom: "-15px",
};

const submitButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};

const infoIcon = {
  marginTop: "-55px",
  zIndex: "10",
  float: "right",
  position: "relative",
};

const websiteText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  fontWeight: "300",
  marginBottom: 0,
  fontSize: "14px",
  marginTop: "15px",
  textAlign: "center",
};

const businessRegionContainer = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "15px",
};
