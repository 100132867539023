import React from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBTestimonial, MDBIcon, MDBCol } from 'mdbreact';

const Testimonials = () => {
  return (
    <section id="testimonials" style={{ background: '#000'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="d-flex justify-content-center align-items-center offset-lg-1 pt-5">
        <div className="px-3 text-center" >
          <h2 style={title} className='h2-responsive white-text pb-3 mt-3'>TESTIMONIALS</h2>
          <MDBCarousel
            activeItem={1}
            length={6}
            interval={10000}
            showIndicators={false}
            showControls={false}
            onHoverStop={true}
            className='no-flex'
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId='1'>
                <MDBCol lg="10" md="12" className='offset-lg-1'>
                  <MDBTestimonial>
                    <p style={testimonialText}>
                      <MDBIcon icon='quote-left' /> The Sponsor Hub has provided us with a fantastic platform to find and work with
                      some of the best British surfers. TSH has been an excellent
                      way for us to connect with surfers across the U.K. The surfers who came
                      on board from TSH  have been excellent brand
                      ambassadors and continue to contribute to our business in many positive
                      ways.  We appreciate all the help we have received from The
                      Sponsor Hub, without which we would not have such success with our
                      company. <MDBIcon icon='quote-right' />
                    </p>
                    <h5 className='pt-3' style={testimonialTitle}>Daisy's Cold Water Surf Cream</h5>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId='2'>
                <MDBCol lg="10" md="12" className='offset-lg-1'>
                  <MDBTestimonial>
                    <p style={testimonialText}>
                      <MDBIcon icon='quote-left' /> I would say about The Sponsor Hub is that it’s such a great
                      tool for athletes and sports people to grow and have opportunities they
                      wouldn’t normally have. For someone like me it’s helped huge amounts not only
                      in connecting with new contacts but also my confidence. It’s so great that we
                      have all this for free. Thank you guys! <MDBIcon icon='quote-right' />
                    </p>
                    <h5 className='pt-3' style={testimonialTitle}>Luke Stone</h5>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId='3'>
                <MDBCol lg="10" md="12" className='offset-lg-1'>
                  <MDBTestimonial>
                    <p style={testimonialText}>
                      <MDBIcon icon='quote-left' /> Awesome site, I like your idea too. Your Platform will definitely be an asset in the future. 
                      I already told some of them to go through you, so there might be some more registrations at your site the next days. I've just got two new riders on Board! 
                      Absolutely stoked since we have just launched the website and marketing. <MDBIcon icon='quote-right' />
                    </p>
                    <h5 className='pt-3' style={testimonialTitle}>Skimont</h5>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId='4'>
                <MDBCol lg="10" md="12" className='offset-lg-1'>
                  <MDBTestimonial>
                    <p style={testimonialText}>
                      <MDBIcon icon='quote-left' /> When I moved to Scotland I realized that what was cold for me now was a summer day up here.
                      I never imagined that I would have to protect my face from the extreme cold more than from the sun.
                      After suffering for over a year, one day surfing the web I found The Sponsor Hub and thanks to them I found the solution to my problem.
                      David from Sponsor Hub recommended me to use Daisys Cold Water Surf Cream and not only that, David was in charge of connecting me with Daisys in order to make me their team rider.
                      I’m very happy that the Sponsor Hub took time to help me and with the results of being parts of Daisys.
                      I highly recommend both of them. <MDBIcon icon='quote-right' />
                    </p>
                    <h5 className='pt-3' style={testimonialTitle}>Sebastian</h5>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId='5'>
                <MDBCol lg="10" md="12" className='offset-lg-1'>
                  <MDBTestimonial>
                    <p style={testimonialText}>
                      <MDBIcon icon='quote-left' /> Found this cool page on Instagram and learned an online skate comp was happening!  
                      The entry process and navigating the site were so easy and very informative!  
                      Totally stoked to not only enter, but win one of the categories!  
                      I’m looking forward to my future collaboration with The Sponsorhub and any mutual opportunities this may hold!  
                      Don’t just read about it here.....check it out!! <MDBIcon icon='quote-right' />
                    </p>
                    <h5 className='pt-3' style={testimonialTitle}>Bohdi</h5>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
              <MDBCarouselItem itemId='6'>
                <MDBCol lg="10" md="12" className='offset-lg-1'>
                  <MDBTestimonial>
                    <p style={testimonialText}>
                      <MDBIcon icon='quote-left' /> I found the competition through @thesponsorhub on Instagram. 
                      Super easy to sign up and enter, and an overall great platform! Stoked to take the win! I always enjoy video 
                      competitions because I can edit some of my fav clips together in an edit. So glad you guys enjoyed the clips, 
                      yew :) til next time 🤟 <MDBIcon icon='quote-right' />
                    </p>
                    <h5 className='pt-3' style={testimonialTitle}>Jasper</h5>
                  </MDBTestimonial>
                </MDBCol>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
        </div>
      </MDBCol>
    </section>
  );
};

export default Testimonials;

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff'
}

const testimonialText = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  textAlign: 'justify'
}

const testimonialTitle = {
  textAlign: 'center',
  color: '#f8f8ff',
  fontFamily: 'Oswald'
}