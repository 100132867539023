import React, { Component } from "react";
import { MDBCol } from "mdbreact";
import './../../styles/Background.css'

class Charity extends Component {
  render() {
    return (
      <section id="supported-charities">
        <div style={{backgroundColor: '#000'}} >                          
          <div className="pt-5 pb-3 px-3 text-center">
            <h2 className="mt-3" style={title}>Supported Charities</h2>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <MDBCol sm="12" md="12" lg="10" xl="10" className="px-3 justify-content-center col-12">
              <div style={{textAlign: 'center'}}>
                  <p style={labelText}>The Sponsor Hub proudly supports Project Seagrass, in order to offset/reduce our emissions operating the platform.</p>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.projectseagrass.org/">
                    <img className="my-4" width={200} src="/Charities/Proudly_Supporting_Logo_(Green).png" alt="Project Seagrass" />
                  </a>
                  <p style={labelText}>More information regarding our Environmental And Sustainability Policy can be found <a style={labelTextInline}  href="environmental_and_sustainability_policy">here</a>.</p>
              </div>
            </MDBCol>
          </div>
        </div>
      </section>
    );
  }
}

export default Charity;

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase'
}

const labelText = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff',
  fontSize: '1rem',
  marginBottom: 0
}

const labelTextInline = {
    fontFamily: 'Oswald', 
    color: '#f8f8ff',
    fontSize: '1rem',
    display: 'inline',
    padding: 0
}