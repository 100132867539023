import React, { Component } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBRow } from 'mdbreact';
import "./../../styles/Forms.css"
import Loading from '../Loading';
import ErrorPage from './../Errors/ErrorMessage'
import Discount from './Discount'
import Home from './../HomeComponent'

const USER_DETAILS_QUERY = gql`
  {
    user {
      id
      category
      city
      country
    }
  }
`

const DISCOUNT_QUERY = gql`
  query Discount($id: ID!){
    discount(id: $id) {
      id
      title
      amount
      description
      code 
      filename1
      filename2
      filename3
      productDescription1
      productDescription2
      productDescription3
      postedBy {
        id
        name
        website
        businessLogo
        city
        country
      }
      inStore
    }
  }
`

class DiscountPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      discountId: this.props.match.params.id,
    }
  }

  render() {
    const { discountId } = this.state
    return (
      <Query query={USER_DETAILS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorPage error={error.message} />

          const id = data.user.id
          const category = data.user.category
          const city = data.user.city
          const country = data.user.country

          return (
            <Query query={DISCOUNT_QUERY} variables={{ id: discountId }}>
              {({ loading, error, data }) => {
                if(loading) return <Loading />
                if (error) return <ErrorPage error={error.message} />
  
                return(
                  <div className="pt-3" style={{backgroundColor: '#000'}}>
                    <MDBRow className="justify-content-center pt-5">
                      <Discount key={data.discount.id} discount={data.discount} />
                    </MDBRow>
                    <div className='pt-3' style={{textAlign: 'center'}}>
                      <h3 style={relatedText}>Related</h3>
                    </div>
                    <Home id={id} category={category} country={country} city={city} />
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

export default DiscountPage

const relatedText = {
  color: '#f8f8ff', 
  textTransform: 'uppercase', 
  fontFamily: 'Oswald'
}
