import React from "react";
import { MDBCol } from "mdbreact";
import './../../styles/Legal.css'

const PostingTips = () => {
  return (
    <div className="px-3 py-4" style={{backgroundColor: '#fff', textAlign: 'justify'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Tips on Posting Content</h1>
          <p className="legal-text">Below are our tips for posting content.</p><br/>
          <p className="legal-text">There are no hard and fast rules for the length / type of video. Ones that capture and keep the attention are best, with brands looking for skill, commitment and passion to your sport.</p><br/>
          <h3 className="legal-subheading">Landscape, not Portrait</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Shoot in Landscape (horizontal), not portrait (vertical).</p><br/>
            </li>
            <li>
                <p className="legal-text">Our eyes are aligned on the horizontal plane, we see in ‘Landscape’. Films and TV are horizontal.</p><br/>
            </li>
            <li>
                <p className="legal-text">Landscape produces a much more professional shot, simple as that!</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Post regularly</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Post regularly, weekly if possible. The more edits, the more dedication you show to your sport and your commitment to producing content.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Bank of edits</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">A bank of edits on your profile will show your skills, your passion and your progress.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Keep the edits interesting</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Hold your audience attention, tell a story. You know what captures and keeps your attention and you know what turns you off.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">No gimmicks</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Don't use gimmicky effects, they distract from the real action.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Show your character</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Show your character, your reaction to wins and fails speaks volumes. Shots showing continual attempts until you achieve that trick shows great character.</p><br/>
            </li>
            <li>
                <p className="legal-text">Frustration, even anger, on failed attempts is OK (no swearing), but elation on nailing that trick and reaction of friends is always cool.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Sound</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Personal choice. Natural sound plays well, if music is used ensure you have the copyright.</p><br/>
            </li>
            <li>
                <p className="legal-text">Shouting and hollering cool, explicit lyrics not cool.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Make friends, build crews, have fun</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Extreme sports and their communities are awesome.</p><br/>
            </li>
            <li>
                <p className="legal-text">They are friendly and encouraging, talk to people, ask questions, get involved.</p><br/>
            </li>
          </ul><br/>
          <p className="legal-text">As you would expect, Sponsorship & Team Rider opportunities are hard to come by. Brands are pretty picky, and it's not just the number of followers, posts, or likes, it usually comes down to your passion, progress & character, and sometimes it just clicks.</p><br/>
          <p className="legal-text">The most important thing is to enjoy your sport and have fun.</p><br/>
          <h3 className="legal-subheading">Summary</h3>
          <p className="legal-text">Go for it, post interesting, eye-catching edits regularly, show your character, but most of all enjoy yourself and share the stoke.</p><br/>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Landscape.</p><br/>
            </li>
            <li>
                <p className="legal-text">Post regularly.</p><br/>
            </li>
            <li>
                <p className="legal-text">Hold your audience's attention.</p><br/>
            </li>
            <li>
                <p className="legal-text">Don't use gimmicky effects.</p><br/>
            </li>
            <li>
                <p className="legal-text">Show your character.</p><br/>
            </li>
            <li>
                <p className="legal-text">Smile, enjoy yourself, meet cool people, make friends and most of all...</p><br/>
            </li>
            <li>
                <p className="legal-text">Have fun!</p><br/>
            </li>
          </ul>
        </div>
      </MDBCol>
    </div>
  );
};

export default PostingTips;