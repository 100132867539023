import React from "react";
import "./../../styles/Legal.css";
import { MDBCol } from "mdbreact";

const PrivacyPolicy18 = () => {
  return (
    <div
      className="px-3 pt-4"
      style={{ backgroundColor: "#fff", textAlign: "justify" }}
    >
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h2 className="legal-title">Privacy Policy 18+</h2>
          <h3 className="legal-subheading">Who we are</h3>
          <p className="legal-text">
            Welcome to The Sponsor Hub. We are an extreme sports digital
            marketing and social media platform where athletes can publish their
            skills and talents to the world, meet like-minded sportspersons, and
            hook up with extreme sports brands and other sponsors.
          </p>
          <br />
          <p className="legal-text">
            <a className="legal-link" href="https://thesponsorhub.com/">
              <p className="legal-text">https://thesponsorhub.com/</p>
            </a>{" "}
            (<p className="legal-text-bolder">the/our website</p>) is provided
            by The Sponsor Hub Limited (
            <p className="legal-text-bolder">The Sponsor Hub, we, us, our</p>).
            We are a private limited company registered in England & Wales under
            company number 11467568, registered office at Walk Mill,
            Kirkwhelpington, Newcastle upon Tyne, Northumberland, England, NE19
            2SB.
          </p>
          <br />
          <h3 className="legal-subheading">Your privacy</h3>
          <p className="legal-text">
            We take your privacy very seriously. Please read this privacy policy
            carefully as it contains important information on how and why we
            collect, store, use and share any information relating to you (
            <p className="legal-text-bolder">your information, your data</p>).
          </p>
          <br />
          <p className="legal-text">
            It also explains your rights in relation to your information and how
            to contact us or the relevant regulator in the event you have a
            complaint.
          </p>
          <br />
          <p className="legal-text">
            We and our website are based in the UK. Our collection, storage, use
            and sharing of the information we obtain from you via your use of
            our website is regulated by law, including under the UK General Data
            Protection Regulation, as tailored by the Data Protection Act 2018 (
            <p className="legal-text-bolder">UK GDPR</p>).
          </p>
          <br />
          <p className="legal-text">
            The Sponsor Hub is the controller of your information obtained via
            the website, meaning we are the organisation legally responsible for
            deciding how and for what purposes it is used.
          </p>
          <br />
          <p className="legal-text">
            If you have any queries about this policy or about the treatment of
            your information by us, please contact us at{" "}
            <a className="legal-link" href="mailto:info@thesponsorhub.com">
              <p className="legal-text">info@thesponsorhub.com</p>
            </a>{" "}
            or by post to Walk Mill, Kirkwhelpington, Newcastle Upon Tyne,
            Northumberland NE19 2SB.
          </p>
          <br />
          <h3 className="legal-subheading">Minors</h3>
          <p className="legal-text">
            If you are aged under 6 years old you must not use the website as it
            is not designed for you. We do not intend to collect the information
            of anyone under 6. If you are aware that any information of anyone
            under 6 has been shared with the app, please let us know so that we
            can delete that data.
          </p>
          <br />
          <p className="legal-text">
            If you are aged 6 and over you may use the website.
          </p>
          <br />
          <p className="legal-text">
            This version of our privacy policy is written for adults. If you are
            a child user we have other information that you might find more
            helpful. We recommend you read the information most suitable for
            you:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                if you are aged{" "}
                <a className="legal-link" href="privacy_policy_13-17">
                  <p className="legal-text">13 – 17 years</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                if you are aged{" "}
                <a className="legal-link" href="privacy_policy_10-12">
                  <p className="legal-text">10 – 12 years</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                if you are aged{" "}
                <a className="legal-link" href="privacy+policy_6-9">
                  <p className="legal-text">6 – 9 years</p>
                </a>
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            If you are aged under 18, we recommend that you speak to an adult
            that you trust if you have any difficulties reaching an informed
            decision regarding the activation of any use of your information or
            our treatment of your information.
          </p>
          <br />
          <h3 className="legal-subheading">What this policy applies to</h3>
          <p className="legal-text">
            This privacy policy relates to your use of our website only.
          </p>
          <br />
          <p className="legal-text">
            The website links to other websites, social media platforms and
            services owned and operated by third parties. This allows us to make
            additional products, information and services available to you. This
            includes the websites of third party brands who use our website to
            offer goods and services including sponsorship to website users.
            These other websites or services may also gather information about
            you in accordance with their own separate privacy policies. For
            privacy information relating to these other websites or services,
            please consult their privacy policies as appropriate.
          </p>
          <br />
          <p className="legal-text-bolder">
            By uploading footage, edits and content about yourself and your
            sporting activity, you are providing us with consent to share your
            information with third parties.
          </p>
          <br />
          <p className="legal-text-bolder">
            You agree that we may repost your uploaded content and we may share
            such content via the website and also via The Sponsor Hub Mobile
            Application for Google Play or for Apple IOS (our app).
          </p>
          <br />
          <p className="legal-text-bolder">
            You also accept and agree that other website users (and app users as
            the case may be) will be able to view and share your uploaded
            content and your posts.
          </p>
          <br />
          <h3 className="legal-subheading">Information we collect about you</h3>
          <p className="legal-text">
            The information we collect about you depends on the particular
            activities that are carried out by you through the website. We will
            collect and use the following information about you:
          </p>
          <br />
          <table>
            <tr>
              <th>
                <p className="legal-text-bolder">Category of data</p>
              </th>
              <th>
                <p className="legal-text-bolder">In more detail</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Identity, account and contact data you input into the website
                </p>
                <br />
                <p className="legal-text">
                  Registration is mandatory in order to use all of the features
                  on the website
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p className="legal-text">
                      your name, address, and contact information, including
                      email address and telephone number and company details
                      where appropriate
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      information to check and verify your identity, eg date of
                      birth
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">your gender</p>
                  </li>
                  <li>
                    <p className="legal-text">
                      your account details, such as username and password
                    </p>
                  </li>
                </ul>
                <p className="legal-text">
                  If you are a brand wishing to use our website, we will collect
                  identity, account and contact data in order to register you as
                  a trusted third party using our website for the purpose of
                  spotting talent for sponsorship opportunities and providing
                  goods and services and special discounts to our website users.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Data collected when you use specific functions in the website
                </p>
              </td>
              <td>
                <p className="legal-text">
                  Data you store online with us using the website including
                  video content of you and social media handles (while such data
                  may not always be protected by the UK GDPR we will assume it
                  is and treat it in accordance with this policy).
                </p>
              </td>
            </tr>
            {/* <tr>
                    <td>
                        <p className="legal-text">Data collected when you permit the collection of location data</p>
                    </td>
                    <td>
                        <p className="legal-text">Details of your location with a high degree of precision, see the section ‘<p className="legal-text-bolder">Location services/data</p>’ below</p>
                    </td>
                </tr> */}
            <tr>
              <td>
                <p className="legal-text">
                  Other data the website collects automatically when you use it
                </p>
              </td>
              <td>
                <p className="legal-text">
                  Your activities on, and use of, the website which reveal your
                  preferences, interests or manner of use of the website and the
                  times of use, device type, device operating system, browser
                  type. All with Firebase Analytics.
                </p>
              </td>
            </tr>
          </table>
          <p className="legal-text">
            If you do not provide the information we ask for where it is
            indicated as required, it will delay and / or may prevent the
            website from providing services and functions to you. For example if
            you do not provide the required account information at the outset,
            you will not be able to use the Platform.
          </p>
          <br />
          <h3 className="legal-subheading">
            How your information is collected
          </h3>
          <p className="legal-text">
            We will collect information from you directly when you enter
            information on the website or send us information, for example when
            you sign up to an account, when you log in, when you post content,
            when send us feedback, when you enter our competitions, sign up for
            marketing emails, use mobile devices to access content on our
            website, and when you otherwise contact us via email or social
            media.
          </p>
          <br />
          <p className="legal-text">
            We also collect information automatically when you access our
            website using cookies and other similar technologies (please see our{" "}
            <a className="legal-link" href="/cookies_policy">
              <p className="legal-text">Cookie Policy</p>
            </a>{" "}
            for further information) when you browse our website and through
            your activity on the website.
          </p>
          <br />
          <h3 className="legal-subheading">
            How and why we use your information
          </h3>
          <p className="legal-text">
            Under data protection law, we can only use your information if we
            have a proper reason, eg:
          </p>
          <br />
          <ul>
            <li>
              <p className="legal-text">where you have given consent</p>
            </li>
            <li>
              <p className="legal-text">
                to comply with our legal and regulatory obligations
              </p>
            </li>
            <li>
              <p className="legal-text">
                for the performance of a contract with you or to take steps at
                your request before entering into a contract, or
              </p>
            </li>
            <li>
              <p className="legal-text">
                for our legitimate interests or those of a third party
              </p>
            </li>
          </ul>
          <p className="legal-text">
            A legitimate interest is when we have a business or commercial
            reason to use your information, so long as this is not overridden by
            your own rights and interests. We will carry out an assessment when
            relying on legitimate interests, to balance our interests against
            your own.{" "}
          </p>
          <br />
          <p className="legal-text">
            The table below explains what we use your information for and why.
          </p>
          <br />
          <table>
            <tr>
              <th>
                <p className="legal-text-bolder">
                  What we use your information for
                </p>
              </th>
              <th>
                <p className="legal-text-bolder">Our reasons</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Create and manage your account with us
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  For our legitimate interests, to be able to provide our
                  website and its services to you, to be as efficient as we can
                  so we can deliver the best service to you
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Providing website services and/or the functionalities of the
                  website to you
                </p>
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <br />
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      For our legitimate interests, to be able to provide our
                      website and its services to you, to be as efficient as we
                      can so we can deliver the best service to you.
                    </p>
                    <br />
                  </li>
                  {/* <li>
                                <p className="legal-text">In relation to the Add a Spot / Add a Business features we will use data relating to your location only based on your consent as described in ‘<p className="legal-text-bolder">Location services/data</p>’ (above)</p>
                            </li> */}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Conducting checks to identify you and verify your identity or
                  otherwise to help prevent and detect fraud against you or us
                </p>
              </td>
              <td>
                <p className="legal-text">
                  To comply with our legal and regulatory obligations;
                </p>
                <br />
                <p className="legal-text">
                  For our legitimate interests, ie to minimise the risk of
                  account or identity theft or fraud that could be damaging for
                  you, a third party or us
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To enforce legal rights or defend or undertake legal
                  proceedings
                </p>
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <br />
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      to comply with our legal and regulatory obligations.
                    </p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      in other cases, for our legitimate interests, ie to
                      protect our business, interests and rights
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Communications with you not related to marketing, including
                  about changes to our terms or policies or changes to the
                  website or service or other important notices
                </p>
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <br />
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      to comply with our legal and regulatory obligations.
                    </p>
                    <br />
                  </li>
                  <li>
                    <p className="legal-text">
                      in other cases, for our legitimate interests, ie to
                      provide the best service to you
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Protect the security of systems and data used to provide the
                  website and its services
                </p>
              </td>
              <td>
                <p className="legal-text">
                  To comply with our legal and regulatory obligations
                </p>
                <br />
                <p className="legal-text">
                  We may also use your information to ensure the security of
                  systems and data to a standard that goes beyond our legal
                  obligations, and in those cases our reasons are for our
                  legitimate interests, ie to protect systems and data and to
                  prevent and detect criminal activity that could be damaging
                  for you and/or us
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Operational reasons, such as improving efficiency, advancing
                  features, and quality control or to provide support to you
                </p>
              </td>
              <td>
                <p className="legal-text">
                  For our legitimate interests, ie to be as efficient as we can
                  so we can deliver the best service to you{" "}
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">Statistical analysis</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      analysis of what content has been viewed, by who and when
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      analysis of users interests and preferences to help us
                      understand our customer base
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <p className="legal-text">For our legitimate interests:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      ie to be as efficient as we can so we can deliver the best
                      service to you and so that we can continually improve
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      ie so that we can make our marketing more relevant to you
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  For internal administration eg for accounting purposes and for
                  updating and enhancing customer records.
                </p>
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      to comply with our legal and regulatory obligations
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      for our legitimate interests, eg making sure that we can
                      keep in touch with our customers about existing orders and
                      new products
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Disclosures and other activities necessary to comply with
                  legal and regulatory obligations, eg to record and demonstrate
                  evidence of your consent to our use of your information where
                  relevant
                </p>
              </td>
              <td>
                <p className="legal-text">
                  To comply with our legal and regulatory obligations
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Marketing our services to existing and former website users
                </p>
              </td>
              <td>
                <p className="legal-text">
                  For our legitimate interests, ie to promote our business to
                  existing and former website users.
                </p>
                <br />
                <p className="legal-text">
                  See ‘<p className="legal-text-bolder">Marketing</p>’ below for
                  further information.
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Should the circumstances arise, to share your information with
                  third parties that will or may take control or ownership of
                  some or all of our business (and professional advisors acting
                  on our or their behalf) in connection with a significant
                  corporate transaction or restructuring, including a merger,
                  acquisition, asset sale, initial public offering or in the
                  event of our insolvency
                </p>
                <br />
                <p className="legal-text">
                  In such cases, information will be anonymised where possible
                  and only shared where necessary
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      to comply with our legal and regulatory obligations
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      in other cases, for our legitimate interests, ie to
                      protect, realise or grow the value in our business and
                      assets
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  To place non-essential cookies, or other similar technology.
                  We provide more details of how we use cookies in our{" "}
                  <a className="legal-link" href="/cookies_policy">
                    <p className="legal-text">Cookie Policy</p>
                  </a>
                  . You can withdraw your consent to us placing these cookies at
                  any time through our Privacy Settings.
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">With your consent:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      or our legitimate business interest – to provide the best
                      website experience to you
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">To collect and log IP addresses</p>
              </td>
              <td>
                <p className="legal-text">
                  For our legitimate business interests, to manage and improve
                  our website.
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  When we respond to queries by you and to resolve complaints
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">Depending on the circumstances:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      to comply with our legal and regulatory obligations
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      for our legitimate interests, ie to manage customers
                      enquiries and ensure the best user experience
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
          <h3 className="legal-subheading">Marketing</h3>
          <p className="legal-text">
            We will use your information to send you updates (by email, text
            message, telephone or post) about our website, including exclusive
            discounts, offers, competitions or new features available.
          </p>
          <br />
          <p className="legal-text">
            We have a legitimate interest in using your information for
            marketing purposes (see above ‘
            <p className="legal-text-bolder">
              How and why we use your information
            </p>
            ’). This means we do not necessarily need your consent to send you
            marketing information in certain circumstances, however it is our
            practice to ask for your consent to send your marketing and we will
            do so clearly.
          </p>
          <br />
          <p className="legal-text">
            You have the right to opt out of receiving marketing communications
            at any time by:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                contacting us at{" "}
                <a className="legal-link" href="mailto:info@thesponsorhub.com">
                  <p className="legal-text">info@thesponsorhub.com</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                using the ‘unsubscribe’ link in emails; or
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                updating your marketing preferences in your account
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            We will always treat your information with the utmost respect and we
            will never sell it to other organisations for marketing purposes.
          </p>
          <br />
          <p className="legal-text">
            For more information on your right to object at any time to your
            information being used for marketing purposes, see ‘
            <p className="legal-text-bolder">Your rights</p>’ below.
          </p>
          <br />
          <h3 className="legal-subheading">Sharing your information</h3>
          <p className="legal-text">We routinely share your information with</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                service providers we use to help us run our business or provide
                the services or functionalities in the website for example
                server hosts, technology service providers and analytics
                providers
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                Brands who use our website, who have access to your videos,
                content, edits and your name when you upload and post content.
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            * Please note that if a brand is interested in offering you goods,
            services, or any form of commercial or financial support such as for
            example sponsorship, we may contact you directly (by email, text or
            telephone) and ask you for your express permission to share you
            contact details with the brand –{" "}
            <p className="legal-text-bolder">
              we will only share your contact details with your consent
            </p>
            .
          </p>
          <br />
          <p className="legal-text">
            We only allow the companies referred to above to handle your
            information if we are satisfied they take appropriate measures to
            protect your information. We also impose contractual obligations on
            service providers to ensure they can only use your information to
            provide services to us and to you.
          </p>
          <br />
          <p className="legal-text">
            We or the third parties mentioned above occasionally also need to
            share your information with:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                our or their external auditors, eg in relation to the audit of
                our or their accounts — the recipient of the information will be
                bound by confidentiality obligations
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                our or their professional advisors (such as lawyers and other
                advisors)—the recipient of the information will be bound by
                confidentiality obligations
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                law enforcement agencies, courts or tribunals and regulatory
                bodies to comply with legal and regulatory obligations
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                other parties that have or may acquire control or ownership of
                our business (and our or their professional advisers) in
                connection with a significant corporate transaction or
                restructuring, including a merger, acquisition, asset sale,
                initial public offering or in the event of our insolvency —
                usually, information will be anonymised but this may not always
                be possible.
              </p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            We may share your information with social media organisations when
            we use plug ins from such organisations (such as Twitter retweets
            and Facebook recommends) to share user edits and discounts. These
            organisations may receive and use your information about your visit
            to our website and our app. If you view content on our website or
            browse our website they may collect data which may be connected to
            your account on their site, for more information on how social media
            organisations use your information please read their privacy
            policies.
          </p>
          <br />
          <p className="legal-text">
            Please note that other website users/ athletes signed up to our
            website or our app may view your videos, content, edits and your
            name when you upload and post content. All users/ athletes within
            the Sponsor Hub community may see and share each other's uploads,
            with the exception of ‘Your Crew’ feature edits which can only been
            and shared with the members of ‘Your Crew’ Group and us.
          </p>
          <br />
          <p className="legal-text">
            By using the website you agree and consent to this.
          </p>
          <br />
          <h3 className="legal-subheading">
            How long your information will be kept
          </h3>
          <p className="legal-text">
            We will only keep your information as long as we need to. How long
            we need your information depends on what we are using it for, as set
            out in this privacy policy. For example, we may need to use it to
            continue providing our website and services to you as long as you
            are a website user and have an account with us. If we no longer need
            your data, we will delete it or make it anonymous by removing all
            details that identify you.
          </p>
          <br />
          <h3 className="legal-subheading">
            Transferring your information out of the UK
          </h3>
          <p className="legal-text">
            Countries outside the UK have differing data protection laws, some
            of which may provide different levels of protection of privacy.
          </p>
          <br />
          <p className="legal-text">
            It is sometimes necessary for us to transfer your information to
            countries outside the UK. In those cases, we will comply with
            applicable UK laws designed to ensure the privacy of your
            information.
          </p>
          <br />
          <p className="legal-text">
            We may transfer your information from the UK to:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                our service providers who are based outside the UK, and
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">brands who are based outside the UK</p>
              <br />
            </li>
          </ul>
          <p className="legal-text">
            You understand that by posting your content on our website, brands
            worldwide may see it, including your name. Should you decide to
            engage directly with a brand on our website which is based outside
            of the UK, for products or services including sponsorship, the data
            protection laws in the country in which that brand is based shall
            apply to their handling of your information. You should therefore
            read and be comfortable with their policy on data protection.
          </p>
          <br />
          <p className="legal-text">
            If/ when we transfer your information outside of the UK we will only
            transfer to countries where:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                the UK government has decided the particular country ensures an
                adequate level of protection of your information (known as an ‘
                <p className="legal-text-bolder">adequacy regulation</p>’)
                further to Article 45 of the UK GDPR
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                there are appropriate safeguards in place, together with
                enforceable rights and effective legal remedies for you, or
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                a specific exception applies under relevant data protection law.
              </p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">Your rights</h3>
          <p className="legal-text">
            You generally have the following rights, which you can usually
            exercise free of charge:
          </p>
          <br />
          <table>
            <tr>
              <td>
                <p className="legal-text">
                  Access to a copy of your information
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to be provided with a copy of your information
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Correction (also known as rectification)
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to require us to correct any mistakes in your
                  information
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Erasure (also known as the right to be forgotten)
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to require us to delete your information — in
                  certain situations
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">Restriction of use</p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to require us to restrict use of your information in
                  certain circumstances, eg if you contest the accuracy of the
                  data
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">Data portability</p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right to receive your information that you provided to us,
                  in a structured, commonly used and machine-readable format
                  and/or transmit that data to a third party — in certain
                  situations
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">To object to use</p>
                <br />
              </td>
              <td>
                <p className="legal-text">The right to object:</p>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <p className="legal-text">
                      at any time to your information being used for direct
                      marketing (including profiling)
                    </p>
                  </li>
                  <li>
                    <p className="legal-text">
                      in certain other situations to our continued use of your
                      information, eg where we use your information for our
                      legitimate interests unless there are compelling
                      legitimate grounds for the processing to continue or the
                      processing is required for the establishment, exercise or
                      defence of legal claims
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">
                  Not to be subject to decisions without human involvement
                </p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  The right not to be subject to a decision based solely on
                  automated processing (including profiling) that produces legal
                  effects concerning you or similarly significantly affects you
                </p>
                <br />
                <p className="legal-text">
                  We do not make any such decisions based on data collected by
                  the website
                </p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p className="legal-text">The right to withdraw consents</p>
                <br />
              </td>
              <td>
                <p className="legal-text">
                  If you have provided us with a consent to use your
                  information, you have a right to withdraw that consent easily
                  at any time by contacting us (see '
                  <p className="legal-text-bolder">Contact Us</p>' below) or by
                  any other method described in this privacy policy.
                </p>
                <br />
                <p className="legal-text">
                  Withdrawing a consent will not affect the lawfulness of our
                  use of your information in reliance on that consent before it
                  was withdrawn
                </p>
                <br />
              </td>
            </tr>
          </table>
          <p className="legal-text">
            For further information on each of those rights, including the
            circumstances in which they do and do not apply, please contact us
            (see ‘<p className="legal-text-bolder">How to contact us</p>’
            below). You may also find it helpful to refer to the guidance from
            the UK’s Information Commissioner on your rights under the UK GDPR.
          </p>
          <br />
          <p className="legal-text">
            If you would like to exercise any of those rights please contact us
            by email, call or write to us—see below: ‘
            <p className="legal-text-bolder">How to contact us</p>’. When
            contacting us please:
          </p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                provide enough information to identify yourself (eg your full
                name, address, email address) and any additional identity
                information we may reasonably request from you, and
              </p>
            </li>
            <li>
              <p className="legal-text">
                let us know which right(s) you want to exercise and the
                information to which your request relates
              </p>
            </li>
          </ul>
          <h3 className="legal-subheading">Keeping your information secure</h3>
          <p className="legal-text">
            We have appropriate security measures to prevent your information
            from being accidentally lost, or used or accessed unlawfully. We
            limit access to your information to those who have a genuine
            business need to access it.
          </p>
          <br />
          <p className="legal-text">
            We also have procedures in place to deal with any suspected data
            security breach. We will notify you and any applicable regulator of
            a suspected data security breach where we are legally required to do
            so.
          </p>
          <br />
          <p className="legal-text">
            If you want detailed guidance from Get Safe Online on how to protect
            your information and other information and your computers and
            devices against fraud, identity theft, viruses and many other online
            problems, please visit{" "}
            <a className="legal-link" href="www.getsafeonline.org">
              <p className="legal-text">www.getsafeonline.org</p>
            </a>
            . Get Safe Online is supported by HM Government and leading
            businesses.
          </p>
          <br />
          <h3 className="legal-subheading">
            Special Note for Brands using our website
          </h3>
          <p className="legal-text">
            We will collect data from you as a business entity once you register
            with us. Please see types of data we collect above (
            <p className="legal-text-bolder">
              information we collect about you
            </p>
            ). For you, this will in the main be contact and identity
            information, in order that we may register you to the website as one
            of our trusted third parties giving you access to the talent on our
            website and in order that we may pass on the discounts on your
            products and services that you may offer our talent. For further
            information about how and why we collect your information please see
            above (
            <p className="legal-text-bolder">
              how and why we use your information
            </p>
            ).{" "}
          </p>
          <br />
          <p className="legal-text">
            Should you see an athlete on our website that you would be
            interested in providing goods and/or services to or other commercial
            or financial support such as for example sponsorship, you should
            contact us directly using one the methods set out below (
            <p className="legal-text-bolder">How to contact us</p>). We will in
            turn contact that athlete on your behalf and if they wishe to engage
            with you, then with their consent, we will pass on their contact
            details to you.
          </p>
          <br />
          <p className="legal-text">
            You must comply with the provisions of this privacy policy when
            engaging with athletes accessed via The Sponsor Hub and also with
            the terms of any contract between you and us dealing with your
            access to website users/ athletes via our website In particular, you
            agree to comply with the data sharing agreement entered into between
            you and us. You must also provide the terms of any data protection
            policy that you must adhere to under the data protection laws of
            your country to athletes at the outset.
          </p>
          <br />
          <p className="legal-text">
            The privacy and the rights of our website users (our athletes) is
            sacrosanct. You must respect that.
          </p>
          <br />
          <h3 className="legal-subheading">How to complain</h3>
          <p className="legal-text">
            Please contact us if you have any queries or concerns about our use
            of your information (see below ‘
            <p className="legal-text-bolder">How to contact us</p>’). We hope we
            will be able to resolve any issues you may have.
          </p>
          <br />
          <p className="legal-text">
            You also have the right to lodge a complaint with the Information
            Commissioner.
          </p>
          <br />
          <p className="legal-text">
            The Information Commissioner can be contacted using the details at{" "}
            <a
              className="legal-link"
              href="https://ico.org.uk/make-a-complaint"
            >
              <p className="legal-text">https://ico.org.uk/make-a-complaint</p>
            </a>{" "}
            or telephone: 0303 123 1113.
          </p>
          <br />
          <h3 className="legal-subheading">Changes to this privacy policy</h3>
          <p className="legal-text">
            We may change this privacy policy from time to time. When we make
            significant changes we will take steps to inform you, for example
            via the website or by other means, such as email.
          </p>
          <br />
          <h3 className="legal-subheading">How to contact us</h3>
          <p className="legal-text">
            You can contact us by post, email or telephone if you have any
            questions about this privacy policy or the information we hold about
            you, to exercise a right under data protection law or to make a
            complaint.
          </p>
          <br />
          <p className="legal-text">Our contact details are shown below:</p>
          <br />
          <ul style={{ marginBottom: 0 }}>
            <li>
              <p className="legal-text">
                By email at{" "}
                <a className="legal-link" href="mailto:info@thesponsorhub.com">
                  <p className="legal-text">info@thesponsorhub.com</p>
                </a>
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">
                By writing to us at Walk Mill, Kirkwhelpington, Newcastle Upon
                Tyne, Northumberland NE19 2SB
              </p>
              <br />
            </li>
            <li>
              <p className="legal-text">By calling us on +44 (0)7966 593 208</p>
              <br />
            </li>
          </ul>
          <h3 className="legal-subheading">Do you need extra help?</h3>
          <p className="legal-text">
            If you would like this notice in another format (for example large
            print) please contact us (see above).
          </p>
          <br />
          <h3 className="legal-subheading">Changes to our Privacy Policy</h3>
          <p className="legal-text">This policy was published on 12/3/22.</p>
          <br />
          <h3 className="legal-subheading">Thanks</h3>
          <h3 className="legal-subheading">The Sponsor Hub</h3>
          <p className="legal-text">
            This document was produced by our Legal Services Partner{" "}
            <a
              style={{ display: "inline", padding: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nqlegal.co.uk/"
            >
              <img
                height={20}
                style={{ marginTop: -2 }}
                src="/Partners/nqLegalLogo.png"
                alt="ngLegal"
              />
            </a>
            .
          </p>
        </div>
      </MDBCol>
    </div>
  );
};

export default PrivacyPolicy18;
