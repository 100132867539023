import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBIcon } from "mdbreact";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import ErrorPage from "./../Errors/ErrorMessage";

const USER_ID_QUERY = gql`
  query {
    user {
      id
      name
      isBusiness
      newUser
    }
  }
`;

const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

const FOLLOW_MUTATION = gql`
  mutation FollowMutation($followId: ID!) {
    follow(followId: $followId) {
      id
      user
      follow {
        id
      }
    }
  }
`;

const DELETE_FOLLOW_MUTATION = gql`
  mutation DeleteFollowMutation($deleteId: ID!) {
    deleteFollow(id: $deleteId) {
      id
    }
  }
`;

const FOLLOW_VIDEOS_QUERY = gql`
  query FollowsQuery($currentUserId: ID!) {
    follows(id: $currentUserId) {
      follows {
        id
        user
        follow {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;
const CREW_MUTATION = gql`
  mutation CrewMutation($crewId: ID!) {
    crew(crewId: $crewId) {
      id
      user
      crew {
        id
      }
    }
  }
`;

const DELETE_CREW_MUTATION = gql`
  mutation DeleteCrewMutation($deleteId: ID!) {
    deleteCrew(id: $deleteId) {
      id
    }
  }
`;

const CREW_VIDEOS_QUERY = gql`
  query CrewQuery($currentUserId: ID!) {
    findCrew(id: $currentUserId) {
      crews {
        id
        user
        crew {
          id
          videos {
            id
          }
        }
      }
    }
  }
`;

class User extends Component {
  render() {
    return (
      <Query query={USER_ID_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <div></div>;
          if (error) return <ErrorPage error={error.message} />;
          const currentUser = data.user;
          return (
            <UserTile
              user={this.props.user}
              currentUser={currentUser}
              businessLogo={this.props.user.businessLogo}
              navigation={this.props.navigation}
            />
          );
        }}
      </Query>
    );
  }
}

class UserTile extends Component {
  state = {
    followed: false,
    followedCrew: false,
    profileImageResize: true,
    businessLogoResize: true,
  };

  render() {
    const id = this.props.user.id;
    const name = this.props.user.name;
    const country = this.props.user.country;
    const city = this.props.user.city;
    const category = this.props.user.category;
    const isBusiness = this.props.user.isBusiness;
    const businessLogo = this.props.user.businessLogo;
    const profileImage = this.props.user.profileImage;
    const currentUserId = this.props.currentUser.id;
    var { followed, followedCrew, profileImageResize, businessLogoResize } =
      this.state;
    var showCategory = category.join(", ");
    return (
      <MDBCol
        xs="12"
        sm="12"
        md="5"
        lg="3"
        className="my-3 mx-3"
        style={mainContainer}
      >
        <div
          className="pb-2"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {isBusiness || profileImage !== null ? (
              <div style={imgContainer}>
                {isBusiness ? (
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId: id }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <a
                        style={{ padding: 0 }}
                        onClick={() => {
                          if (!loading && !data) {
                            profileTrackerMutation();
                          }
                        }}
                        href={`/profile/${id}`}
                      >
                        <img
                          style={profileImg}
                          src={
                            businessLogoResize
                              ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${id}_250x250.jpeg`
                              : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${businessLogo}`
                          }
                          onError={() =>
                            this.setState({ businessLogoResize: false })
                          }
                          alt="Company Logo"
                        />
                      </a>
                    )}
                  </Mutation>
                ) : (
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId: id }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <a
                        style={{ padding: 0 }}
                        onClick={() => {
                          if (!loading && !data) {
                            profileTrackerMutation();
                          }
                        }}
                        href={`/profile/${id}`}
                      >
                        <img
                          style={profileImg}
                          src={
                            profileImageResize
                              ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${id}/${id}_250x250.jpeg`
                              : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/videos/${id}/${profileImage}`
                          }
                          onError={() =>
                            this.setState({ profileImageResize: false })
                          }
                          alt="Users Profile"
                        />
                      </a>
                    )}
                  </Mutation>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div style={{ minWidth: "70%" }}>
            <div>
              <Mutation
                mutation={USER_PROFILE_MUTATION}
                variables={{ currentUserId, userId: id }}
              >
                {(profileTrackerMutation, { loading, data }) => (
                  <a
                    style={{ padding: 0 }}
                    onClick={() => {
                      if (!loading && !data) {
                        profileTrackerMutation();
                      }
                    }}
                    href={`/profile/${id}`}
                  >
                    <h5 style={nameText}>{name}</h5>
                  </a>
                )}
              </Mutation>
              <p style={detailsText}>
                {city.replace(/ /g, "")}, {country}
              </p>
              <p style={detailsText}>{showCategory}</p>
            </div>
            <div className="pt-2" style={buttonsContainer}>
              {id === currentUserId ? (
                <></>
              ) : (
                <div>
                  <Query
                    query={FOLLOW_VIDEOS_QUERY}
                    variables={{ currentUserId }}
                  >
                    {({ loading, error, data, refetch }) => {
                      if (loading) return <div></div>;
                      if (error)
                        return (
                          <MDBBtn
                            id="btnSM"
                            outline
                            rounded
                            size="sm"
                            color="white"
                          >
                            Follow
                          </MDBBtn>
                        );
                      const numberOfFollows = data.follows.follows.length;
                      const followId = id;
                      var i;
                      var deleteId;
                      followed = false;
                      for (i = 0; i < numberOfFollows; i++) {
                        if (data.follows.follows[i].follow === null) {
                          refetch();
                        } else {
                          if (data.follows.follows[i].follow.id === followId) {
                            followed = true;
                            deleteId = data.follows.follows[i].id;
                          }
                        }
                      }
                      return (
                        <div>
                          {followed === false ? (
                            <Mutation
                              mutation={FOLLOW_MUTATION}
                              variables={{ followId: id }}
                              refetchQueries="FOLLOW_VIDEOS_QUERY"
                            >
                              {(followMutation, { loading }) => (
                                <MDBBtn
                                  onClick={() => {
                                    if (!loading) {
                                      followMutation();
                                      refetch();
                                    }
                                  }}
                                  outline
                                  id="btnSM"
                                  rounded
                                  size="sm"
                                  color="white"
                                >
                                  Follow
                                </MDBBtn>
                              )}
                            </Mutation>
                          ) : (
                            <Mutation
                              mutation={DELETE_FOLLOW_MUTATION}
                              variables={{ deleteId }}
                              refetchQueries="FOLLOW_VIDEOS_QUERY"
                            >
                              {(deleteFollowMutation, { loading }) => (
                                <MDBBtn
                                  onClick={() => {
                                    if (!loading) {
                                      deleteFollowMutation();
                                      refetch();
                                    }
                                  }}
                                  rounded
                                  id="btnSMWhite"
                                  size="sm"
                                  color="white"
                                >
                                  Following
                                </MDBBtn>
                              )}
                            </Mutation>
                          )}
                        </div>
                      );
                    }}
                  </Query>
                </div>
              )}
              {id === currentUserId ? (
                <></>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Query
                    query={CREW_VIDEOS_QUERY}
                    variables={{ currentUserId }}
                  >
                    {({ loading, error, data, refetch }) => {
                      if (loading) return <div></div>;
                      if (error)
                        return (
                          <MDBIcon style={iconStyle} fas icon="user-plus" />
                        );
                      const numberOfCrew = data.findCrew.crews.length;
                      const crewId = id;
                      var i;
                      var deleteId;
                      followedCrew = false;
                      for (i = 0; i < numberOfCrew; i++) {
                        if (data.findCrew.crews[i].crew === null) {
                          refetch();
                        } else {
                          if (data.findCrew.crews[i].crew.id === crewId) {
                            followedCrew = true;
                            deleteId = data.findCrew.crews[i].id;
                          }
                        }
                      }
                      return (
                        <>
                          {followedCrew === false ? (
                            <Mutation
                              mutation={CREW_MUTATION}
                              variables={{ crewId }}
                              refetchQueries="CREW_VIDEOS_QUERY"
                            >
                              {(crewMutation, { loading }) => (
                                <MDBIcon
                                  style={iconStyle}
                                  fas
                                  icon="user-plus"
                                  onClick={() => {
                                    if (!loading) {
                                      crewMutation();
                                      refetch();
                                    }
                                  }}
                                />
                              )}
                            </Mutation>
                          ) : (
                            <Mutation
                              mutation={DELETE_CREW_MUTATION}
                              variables={{ deleteId }}
                              refetchQueries="CREW_VIDEOS_QUERY"
                            >
                              {(deleteCrewMutation, { loading }) => (
                                <MDBIcon
                                  style={iconStyle}
                                  fas
                                  icon="users"
                                  onClick={() => {
                                    if (!loading) {
                                      deleteCrewMutation();
                                      refetch();
                                    }
                                  }}
                                />
                              )}
                            </Mutation>
                          )}
                        </>
                      );
                    }}
                  </Query>
                </div>
              )}
            </div>
          </div>
        </div>
      </MDBCol>
    );
  }
}

export default User;

const mainContainer = {
  background: "#000",
  borderBottom: "1px solid #f8f8ff",
  textAlign: "center",
  padding: "0.25rem",
  justifyContent: "space-between",
  flexDirection: "column",
  display: "flex",
};

const imgContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const profileImg = {
  height: 100,
  width: 100,
  objectFit: "cover",
  borderRadius: "0.4rem",
};

const nameText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  fontSize: "1rem",
  textAlign: "center",
  textTransform: "uppercase",
  marginTop: "0.25rem",
};

const detailsText = {
  color: "#f8f8ff",
  textAlign: "left",
  fontSize: "0.9rem",
  marginTop: "0rem",
  marginBottom: "0rem",
  fontFamily: "Oswald",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
};

const buttonsContainer = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

const iconStyle = {
  color: "#f8f8ff",
  fontSize: "1.2rem",
};
