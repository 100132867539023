import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import './../../styles/Background.css'

class FAQ extends Component {
  render() {
    return (
      <section id="faq">
        <div style={{backgroundColor: '#000'}} >                          
          <div className="pt-5 pb-3 px-3 text-center">
            <h2 className="mt-3" style={title}>FREQUENTLY ASKED QUESTIONS</h2>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <MDBCol sm="12" md="12" lg="10" xl="10" className="px-3 justify-content-center col-12">
              <MDBRow>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>Is The Sponsor Hub a free platform?</h5>
                  <p style={faqText}>The Sponsor Hub is a free platform for Athletes.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>Do I need to be a Professional Athlete to use the platform?</h5>
                  <p style={faqText}>The Sponsor Hub is for Athletes of all abilities, so no, you don't have to be a Professional to use the site.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>Do I need to upload an Edit to access Discounts?</h5>
                  <p style={faqText}>You don't need to upload an Edit to access Discounts, all you need is an account.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>Will I get sponsored through the platform?</h5>
                  <p style={faqText}>The Sponsor Hub unfortunately cannot guarantee sponsorship / ambassador roles. The platform however, gives athletes an opportunity to be discovered
                and find exclusive discounts from our amazing collaborative brands.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>How do I get sponsored?</h5>
                  <p style={faqText}>To have the opportunity to be sponsored / become a brand ambassador through the platform, you must upload a video edit. Brands will be able to see this
                and will get in contact with you via the email you have provided, if they are interested.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>How do the Discounts work?</h5>
                  <p style={faqText}>Every discount has a description, an amount, a code which can be copied, a link to the brands website as well as a selection of the Products on offer.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>Are the number of Discounts I can use limited?</h5>
                  <p style={faqText}>You have access to an unlimited amount of Discounts on the platform.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>Where is my data and uploads stored?</h5>
                  <p style={faqText}> User, image and video data will be stored on our secure servers. Only The Sponsor Hub will have access to this data, so you can be assured your information is kept safe.</p>
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" xl="4" className="px-3 pt-3">
                  <h5 style={labelText}>What do we use cookies for?</h5>
                  <p style={faqText}>Cookies help us customize and improve your app experience. For further details, see our <a style={{padding: 0, display: 'inline'}} href="/cookies_policy"><p style={faqTextItalic}>Cookie Policy</p></a>.</p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </div>
        </div>
      </section>
    );
  }
}

export default FAQ;

const title = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase'
}

const labelText = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff',
  fontSize: '1rem'
}

const faqText = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  fontWeight: '300',
  fontSize: '0.9rem'
}

const faqTextItalic = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  fontWeight: '300',
  fontSize: '0.9rem',
  fontStyle: 'italic',
  display: 'inline'
}