import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { MDBCol, MDBInput, MDBBtn, MDBRow, MDBFileInput } from 'mdbreact';
import "./../../styles/Forms.css"
import './../../styles/Background.css'
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'

const POST_DISCOUNT_MUTATION = gql`
  mutation PostMutation($amount: String!, $description: String!, $code: String!, $surf: Boolean, $skate: Boolean, $snowboard: Boolean, $ski: Boolean, $bmx: Boolean, $mtb: Boolean, $scooter: Boolean, $climbing: Boolean, $apparel: Boolean, $other: Boolean, $userId: String, $userName: String!, $userCountry: String!, $userCity: String!, $userBusinessRegion: String!, $filename1: String!, $filename2: String!, $filename3: String!, $productDescription1: String!, $productDescription2: String!, $productDescription3: String!, $inStore: Boolean, $under18Viewable: Boolean, $businessIsBricks: Boolean) {
    postDiscount(amount: $amount, description: $description, code: $code, surf: $surf, skate: $skate, snowboard: $snowboard, ski: $ski, bmx: $bmx, mtb: $mtb, scooter: $scooter, climbing: $climbing, apparel: $apparel, other: $other, userId: $userId, userName: $userName, userCountry: $userCountry, userCity: $userCity, userBusinessRegion: $userBusinessRegion, filename1: $filename1, filename2: $filename2, filename3: $filename3, productDescription1: $productDescription1, productDescription2: $productDescription2, productDescription3: $productDescription3, inStore: $inStore, under18Viewable: $under18Viewable, businessIsBricks: $businessIsBricks) {
      id
      amount
      description
      code
      userId
      userName
      userCountry
      userCity
      userBusinessRegion
      filename1
      filename2
      filename3
      productDescription1
      productDescription2
      productDescription3
      inStore
    }
  }
`

const UPLOAD_FILE_STREAM = gql`
  mutation DiscountProductImagesUpload($file: Upload!) {
    discountProductImagesUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`
const DISCOUNT_NOTIFICATION_QUERY = gql`
  query DiscountNotificationQuery($id: ID!){
    discountNotification(id: $id)
  }
`

const SEND_EMAIL_MUTATION = gql`
  mutation UploadedDiscountEmailMutation($email: String!, $name: String!) {
    uploadedDiscountEmail(email: $email, name: $name)
  }
`

class UploadDiscountForm extends Component {
  state = {
    amount: '',
    description: '',
    code: '',
    inStore: false,
    noCategory: true,
    surf: false,
    skate: false,
    snowboard: false,
    ski: false,
    bmx: false,
    mtb: false,
    scooter: false,
    climbing: false,
    apparel: false,
    other: true,
    under18Viewable: true,
    filename1: '',
    filename2: '',
    filename3: '',
    productDescription1: '',
    productDescription2: '',
    productDescription3: '',
    file1: '',
    file2: '',
    file3: '',
    fileError: '',
    modal: true,
    userId: this.props.user.id,
    userName: this.props.user.name,
    userCountry: this.props.user.country,
    userCity: this.props.user.city,
    userBusinessRegion: this.props.user.businessRegion,
    businessIsBricks: this.props.user.businessIsBricks,
    categorySelected: true,
    pageIndex: 0,
    fileUploaded: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  fileChangeHandler1 = files => {
    this.setState({ file1: files[0] })
  }

  fileChangeHandler2 = files => {
    this.setState({ file2: files[0] })
  }

  fileChangeHandler3 = files => {
    this.setState({ file3: files[0] })
  }

  handleNotifications = async () => {
    var { userId } = this.state
    await this.props.client.query({
      query: DISCOUNT_NOTIFICATION_QUERY,
      variables: { id: userId },
    })
  }

  sendEmail = async(email, name) => {
    await this.props.client.mutate({
        mutation: SEND_EMAIL_MUTATION,
        variables: { email, name },
    })
  }

  render() {
    const { amount, description, code, inStore, noCategory, surf, skate, snowboard, ski, bmx, mtb, scooter, climbing, apparel, other, under18Viewable, filename1, filename2, filename3, productDescription1, productDescription2, productDescription3, fileError, file1, file2, file3, userId, userName, userCountry, userCity, userBusinessRegion, businessIsBricks, pageIndex, fileUploaded } = this.state
    return (
      <div>
        <h3 style={titleStyle}>ADD DISCOUNT</h3>
        <Mutation mutation={POST_DISCOUNT_MUTATION} onCompleted={() => {
            this.handleNotifications()
            if(this.props.user.discounts.length === 0){
              this.sendEmail(this.props.user.email, this.props.user.name)
            }
            window.location.href = "/new_discounts"}}>
          {(postDiscount, { error, loading, data }) => (
            <form onSubmit={e => {
              e.preventDefault();
              if(file1.type === "image/jpeg" || file1.type === "image/png" || file1.type === "image/jpg"){
                if(amount === '') this.setState({fileError: 'Please add an Amount'})
                if(description === '') this.setState({fileError: 'Please add a Description'})
                if(code === '') this.setState({fileError: 'Please add a Code'})
                if(noCategory) this.setState({fileError: 'Please add a Category'})
                if(productDescription1 === '' || productDescription2 === '' || productDescription3 === '') this.setState({fileError: 'Please add a Product Description'})
                if(file1 === '' || file2 === '' || file3 === '') this.setState({fileError: 'Please select a File'})
                if(amount !== '' && description !== '' && code !== '' && !noCategory && productDescription1 !== '' && productDescription2 !== '' && productDescription3 !== '' && file1 !== '' && file2 !== '' && file3 !=='' && !loading && !data){
                  postDiscount({ variables: { amount, description, code, surf, skate, snowboard, ski, bmx, mtb, scooter, climbing, apparel, other, userId, userName, userCountry, userCity, userBusinessRegion, filename1, filename2, filename3, productDescription1, productDescription2, productDescription3, inStore, under18Viewable, businessIsBricks } })                  
                }
              }else{
                if(amount === '') this.setState({fileError: 'Please add an Amount'})
                if(description === '') this.setState({fileError: 'Please add a Description'})
                if(code === '') this.setState({fileError: 'Please add a Code'})
                if(noCategory) this.setState({fileError: 'Please add a Category'})
                if(productDescription1 === '') this.setState({fileError: 'Please add a Product Description'})
                if(file1 === '') this.setState({fileError: 'Please select a File'})
                if(amount !== '' && description !== '' && code !== '' && !noCategory && productDescription1 !== '' && file1 !== '' && !loading && !data){
                  postDiscount({ variables: { amount, description, code,  surf, skate, snowboard, ski, bmx, mtb, scooter, climbing, apparel, other, userId, userName, userCountry, userCity, userBusinessRegion, filename1, filename2, filename3, productDescription1, productDescription2, productDescription3, inStore, under18Viewable, businessIsBricks } })                  
                }
              }
            }}>
              {pageIndex === 0 ?
                <div>
                  <MDBInput
                    label="Amount*"
                    value={amount}
                    onChange={e => this.setState({ amount: e.target.value })}
                    type="text"
                    style={inputText}
                    required
                    maxLength="60"
                  />
                  <MDBInput
                    label="Description*"
                    type="textarea"
                    value={description}
                    onChange={e => this.setState({ description: e.target.value })}
                    style={inputText}
                    maxLength="100"
                    required
                  />
                  <MDBInput
                    label="Code*"
                    value={code}
                    onChange={e => this.setState({ code: e.target.value })}
                    style={inputText}
                    type="text"
                    required
                    maxLength="60"
                  />
                  <div style={{marginTop: "1.5rem", marginBottom: '1rem'}}>
                    <MDBInput
                      label="Is this Discount only In-Store?"
                      type='checkbox'
                      id='checkbox1'
                      value={inStore}
                      onChange={e => this.setState({ inStore: !inStore })}
                    />
                  </div>
                  <div>
                    <h5 style={labelText}>Select Sports*</h5>
                    <MDBRow className='mt-3' style={categoryRow}>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: surf || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!surf){
                            this.setState({surf: true, noCategory: false})
                          }else{
                            this.setState({surf: false})
                            if(!skate && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Surf</h5>
                          <img style={categoryImg} src="/Sport_Icons/surf.jpg" alt="Surf" />
                        </div>
                      </MDBCol>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: skate || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!skate){
                            this.setState({skate: true, noCategory: false})
                          }else{
                            this.setState({skate: false})
                            if(!surf && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Skate</h5>
                          <img style={categoryImg} src="/Sport_Icons/skate.jpeg" alt="Skate" />
                        </div>
                      </MDBCol>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: snowboard || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!snowboard){
                            this.setState({snowboard: true, noCategory: false})
                          }else{
                            this.setState({snowboard: false})
                            if(!surf && !skate && !ski && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Snowboard</h5>
                          <img style={categoryImg} src="/Sport_Icons/snowboard.jpg" alt="Snowboard" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className='mt-3' style={categoryRow}>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: ski || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!ski){
                            this.setState({ski: true, noCategory: false})
                          }else{
                            this.setState({ski: false})
                            if(!surf && !skate && !snowboard && !bmx && !mtb && !scooter && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Ski</h5>
                          <img style={categoryImg} src="/Sport_Icons/ski.jpg" alt="Ski" />
                        </div>
                      </MDBCol>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: bmx || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!bmx){
                            this.setState({bmx: true, noCategory: false})
                          }else{
                            this.setState({bmx: false})
                            if(!surf && !skate && !snowboard && !ski && !mtb && !scooter && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>BMX</h5>
                          <img style={categoryImg} src="/Sport_Icons/bmx.jpg" alt="BMX" />
                        </div>
                      </MDBCol>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: mtb || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!mtb){
                            this.setState({mtb: true, noCategory: false})
                          }else{
                            this.setState({mtb: false})
                            if(!surf && !skate && !snowboard && !ski && !bmx && !scooter && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>MTB</h5>
                          <img style={categoryImg} src="/Sport_Icons/mtb.jpeg" alt="MTB" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className='mt-3' style={categoryRow}>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: scooter || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!scooter){
                            this.setState({scooter: true, noCategory: false})
                          }else{
                            this.setState({scooter: false})
                            if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !climbing && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Scooter</h5>
                          <img style={categoryImg} src="/Sport_Icons/scooter.jpeg" alt="Scooter" />
                        </div>
                      </MDBCol>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: climbing || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!climbing){
                            this.setState({climbing: true, noCategory: false})
                          }else{
                            this.setState({climbing: false})
                            if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !scooter && !apparel && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Climbing</h5>
                          <img style={categoryImg} src="/Sport_Icons/climbing.jpeg" alt="Climbing" />
                        </div>
                      </MDBCol>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: other || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!other){
                            this.setState({other: true, noCategory: false})
                          }else{
                            this.setState({other: false})
                            if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !apparel){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Other</h5>
                          <img style={categoryImg} src="/Sport_Icons/Other.jpg" alt="Other" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className='mt-3' style={categoryRow}>
                      <MDBCol lg="3" md="3" sm="4" className='col-4'>
                        <div style={{border: '1px solid #202020', borderRadius: '0.25rem', textAlign: 'center', padding: '0.25rem', opacity: apparel || noCategory ? 1 : 0.5}} onClick={() => {
                          if(!apparel){
                            this.setState({apparel: true, noCategory: false})
                          }else{
                            this.setState({apparel: false})
                            if(!surf && !skate && !snowboard && !ski && !bmx && !mtb && !scooter && !climbing && !other){
                              this.setState({noCategory: true})
                            }
                          }
                        }}>
                          <h5 style={categoryTitle}>Apparel</h5>
                          <img style={categoryImg} src="/Sport_Icons/apparelCategory.jpg" alt="Apparel" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                  {amount !== "" && description !== "" && code !== "" && !noCategory ?
                    <div style={navigationContainer}>
                      <MDBBtn style={nextButton} color="white" rounded onClick={() => this.setState({pageIndex: 1})}>
                          Next
                      </MDBBtn>
                    </div>
                  :
                    <></>
                  }
                </div> 
              :
              <div>
                <div style={{marginBottom: '1rem'}}>
                  <MDBInput
                    label="Is this Discount suitable for under 18's?"
                    type='checkbox'
                    id='checkbox2'
                    value={under18Viewable}
                    onChange={e => this.setState({ under18Viewable: !under18Viewable })}
                  />
                </div>
                <div>
                  <p style={subText}>Upload either a Video or 3 Images</p>
                  <MDBInput
                    label="Description of 1st Product*"
                    value={productDescription1}
                    onChange={e => this.setState({ productDescription1: e.target.value })}
                    type="text"
                    style={inputText}
                    required
                    maxLength="120"
                  />
                  <MDBFileInput
                    btnColor="black"
                    btnTitle="Upload 1st Image or a Video*"
                    value={file1}
                    type="file"
                    getValue={this.fileChangeHandler1}
                    required
                  />
                  {file1.type === "image/jpeg" || file1.type === "image/png" || file1.type === "image/jpg" ?
                    <div>
                      <MDBInput
                        label="Description of 2nd Product*"
                        value={productDescription2}
                        onChange={e => this.setState({ productDescription2: e.target.value })}
                        type="text"
                        style={inputText}
                        required
                        maxLength="120"
                      />
                      <MDBFileInput
                        btnColor="black"
                        btnTitle="Upload 2nd Image*"
                        value={file2}
                        type="file"
                        getValue={this.fileChangeHandler2}
                        required
                      />
                      <MDBInput
                        label="Description of 3rd Product*"
                        value={productDescription3}
                        onChange={e => this.setState({ productDescription3: e.target.value })}
                        type="text"
                        style={inputText}
                        required
                        maxLength="120"
                      />
                      <MDBFileInput
                        btnColor="black"
                        btnTitle="Upload 3rd Image"
                        value={file3}
                        type="file"
                        getValue={this.fileChangeHandler3}
                        required
                      />
                      {fileError !== '' ?
                        <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                          <p style={fileErrorText}>{fileError}</p>
                        </div>
                      :
                        <></> 
                      }
                      <Mutation mutation={UPLOAD_FILE_STREAM} onCompleted={() => this.setState({fileUploaded: true})}>
                        {
                          (discountProductImagesUpload, { data, loading, error }) => {
                            return (
                              <div className="pt-2 text-center">
                                {loading &&
                                  <div>
                                      <p style={loadingText}>Your Images are being uploaded to the cloud ...</p>
                                      <Loader size={8} color="white"/>
                                  </div>
                                }
                                {error &&
                                  <p style={errorText}>{error.message === 'Network error: NetworkError when attempting to fetch resource.' ?
                                    'Connection Timed Out: Please Try Again' : error.message.includes('jwt expired') ?
                                    'Token Expired. Please Login again'
                                    :
                                    error.message.replace('GraphQL error: ', '')}</p>
                                }
                                {file1 !== '' && file2 !== '' && file3 !== '' && !data && !loading ?
                                  <MDBBtn
                                    color="white"
                                    style={uploadButton}
                                    rounded
                                    onClick={() => {
                                      const { file1, file2, file3 } = this.state
                                      const mimetype1 = file1.type
                                      const mimetype2 = file2.type
                                      const mimetype3 = file3.type
                                      var filename1 = file1.name
                                      filename1 = filename1.replace(/\s/g, '')
                                      var filename2 = file2.name
                                      filename2 = filename2.replace(/\s/g, '')
                                      var filename3 = file3.name
                                      filename3 = filename3.replace(/\s/g, '')
                                      if (file1.size > 50000000 || file2.size > 50000000 || file3.size > 50000000) {
                                        this.setState({ fileError: 'File too big, please upload a file of size less than 50Mb' })
                                      } else if ((mimetype1 === "image/jpeg" || mimetype1 === "image/png") && (mimetype2 === "image/jpeg" || mimetype2 === "image/png") && (mimetype3 === "image/jpeg" || mimetype3 === "image/png")) {
                                        if(mimetype1 === "image/png"){
                                          var tmpFilename1 = filename1.substr(0, filename1.lastIndexOf("."));
                                          filename1 = tmpFilename1 + '.jpg'
                                        }
                                        this.setState({ filename1: filename1 })
                                        if(mimetype2 === "image/png"){
                                          var tmpFilename2 = filename2.substr(0, filename2.lastIndexOf("."));
                                          filename2 = tmpFilename2 + '.jpg'
                                        }
                                        this.setState({ filename2: filename2 })
                                        if(mimetype3 === "image/png"){
                                          var tmpFilename3 = filename3.substr(0, filename3.lastIndexOf("."));
                                          filename3 = tmpFilename3 + '.jpg'
                                        }
                                        this.setState({ filename3: filename3 })
                                        this.setState({ fileError: '' })
                                        discountProductImagesUpload({ variables: { file: file1 } })
                                        discountProductImagesUpload({ variables: { file: file2 } })
                                        discountProductImagesUpload({ variables: { file: file3 } })
                                      } else {
                                        this.setState({ fileError: 'Image type not recognised, please upload file of type png, jpeg or jpg' })
                                      }
                                    }}
                                  >Upload Images</MDBBtn>
                                  :
                                  <></>
                                }
                              </div>
                            )
                          }
                        }
                      </Mutation>
                    </div>
                  :
                    <div>
                      {fileError !== '' ?
                        <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                          <p style={fileErrorText}>{fileError}</p>
                        </div>
                      :
                        <></> 
                      }
                      <Mutation mutation={UPLOAD_FILE_STREAM} context={{ timeout: 7500000 }} onCompleted={() => this.setState({fileUploaded: true})}>
                        {
                          (discountProductImagesUpload, { data, loading, error }) => {
                            return (
                              <div className="pt-2 text-center">
                                {loading &&
                                  <div>
                                      <p style={loadingText}>Your Video is being uploaded to the cloud ...</p>
                                      <Loader size={8} color="white"/>
                                  </div>
                                }
                                {error &&
                                  <p style={errorText}>{error.message === 'Network error: NetworkError when attempting to fetch resource.' ?
                                    'Connection Timed Out: Please Try Again' : error.message.includes('jwt expired') ?
                                    'Token Expired. Please Login again'
                                    :
                                    error.message.replace('GraphQL error: ', '')}</p>
                                 } 
                                {file1 !== '' && !data && !loading ?
                                  <MDBBtn
                                    color="white"
                                    style={uploadButton}
                                    rounded
                                    onClick={() => {
                                      const { file1 } = this.state
                                      const mimetype = file1.type
                                      var filename = file1.name
                                      filename = filename.replace(/[,\s]+|[,\s]+/g, '')
                                      var type = mimetype.substring(0,5)
                                      this.setState({mimetype: type})
                                      if (file1.size > 10000000000) {
                                        this.setState({ file1: '', fileError: 'File too big, please upload a file of size less than 10GB' })
                                      } else if (mimetype === "video/mp4" || mimetype === "video/3gpp" || mimetype === "video/3gp" || mimetype === "video/quicktime" || mimetype === "video/mov") {
                                        if(mimetype === "video/3gpp" || mimetype === "video/3gp" || mimetype === "video/quicktime" || mimetype === "video/mov"){
                                          var tmpFilename = filename.substr(0, filename.lastIndexOf("."));
                                          filename = tmpFilename + '.mp4'
                                        }
                                        this.setState({ filename1: filename })
                                        this.setState({ fileError: '' })
                                        discountProductImagesUpload({ variables: { file: file1 } })
                                      } else {
                                        this.setState({ file1: '', fileError: 'Content type not recognised, please upload file of type mp4, mov, quicktime or 3gpp' })
                                      }
                                    }}
                                  >Upload Video</MDBBtn>
                                :
                                  <></>
                                }
                              </div>
                            )
                          }
                        }
                      </Mutation>
                    </div>
                  }
                  <div className='pt-2' style={{textAlign: 'center'}}>
                    {error && <p style={errorText}>{error.message.includes('jwt expired') ?
                                                                'Token Expired. Please Login again' : error.message.replace('GraphQL error: ', '')}</p>}
                  </div>
                  {fileUploaded &&
                    <div className="text-center pt-2">
                      <MDBBtn style={postButton} color="white" rounded type="submit">{!loading ? "Post" : "Posting"}</MDBBtn>
                    </div>
                  }
                  {!loading ?
                    <div style={navigationContainer}>
                      <MDBBtn style={backButton} color="white" rounded onClick={() => this.setState({pageIndex: 0})}>
                          Back
                      </MDBBtn>
                    </div>
                  :
                    <></>
                  }
                </div>
              </div>
              }
            </form>
          )}
        </Mutation>
      </div>
    )
  }
}

export default withApollo(UploadDiscountForm)

const titleStyle = {
  fontFamily: 'Oswald',
  color: '#f8f8ff',
  textAlign: 'center',
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  marginBottom: '1.5rem'
}

const subText = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  textAlign: 'center',
  fontWeight: '300',
  fontSize: 15
}

const inputText = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald'
}

const categoryRow = {
  display: 'flex', 
  justifyContent: 'space-evenly'
}

const categoryTitle = {
  color: '#f8f8ff', 
  fontFamily: 'Oswald', 
  textAlign: 'center',
  fontSize: '1rem'
}

const categoryImg = {
  borderRadius: '0.25rem', 
  objectFit: 'cover', 
  width: '100%', 
  height: "80px"
}

const labelText = {
  fontFamily: 'Oswald', 
  color: '#f8f8ff',
  fontSize: '1rem'
}

const navigationContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '1rem'
}

const nextButton = {
  fontFamily: 'Oswald', 
  fontSize: '15px', 
  width: '35%',
  paddingTop: '0.15rem',
  paddingBottom: '0.22rem'
}

const backButton = {
  fontFamily: 'Oswald', 
  fontSize: '15px', 
  width: '35%',
  paddingTop: '0.15rem',
  paddingBottom: '0.2rem'
}

const fileErrorText = {
  color: 'red',
  fontFamily: 'Oswald',
  marginBottom: '0.5rem'
}

const errorText = {
  color: 'red',
  fontFamily: 'Oswald'
}

const uploadButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: '50%',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem',
  marginTop: '0.5rem'
}

const postButton = {
  fontFamily: 'Oswald', 
  fontSize: '18px', 
  width: '50%',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem'
}

const loadingText = {
  color: '#f8f8ff',
  fontFamily: 'Roboto',
  marginBottom: '0.5rem'
}
