import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
} from "mdbreact";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./../../styles/Discount.css";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const WEBSITE_TRACKER_MUTATION = gql`
  mutation WebsiteTrackerMutation(
    $business: String!
    $user: String!
    $discountId: String
  ) {
    websiteTracker(business: $business, user: $user, discountId: $discountId) {
      id
      business
      user
    }
  }
`;

const CODE_TRACKER_MUTATION = gql`
  mutation CodeTrackerMutation(
    $business: String!
    $user: String!
    $discountId: ID
  ) {
    codeTracker(business: $business, user: $user, discuntId: $discountId) {
      id
      business
      user
    }
  }
`;

const USER_DETAILS_QUERY = gql`
  {
    user {
      id
      name
    }
  }
`;

const DELETE_DISCOUNT_MUTATION = gql`
  mutation DeleteMutation($id: ID!) {
    deleteDiscount(id: $id) {
      id
    }
  }
`;

const SHARE_TRACKER_MUTATION = gql`
  mutation ShareTrackerMutation(
    $user: String!
    $userShared: String!
    $platform: String
  ) {
    shareTracker(user: $user, userShared: $userShared, platform: $platform) {
      id
      user
      userShared
    }
  }
`;

const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

const REPORT_DISCOUNT_MUTATION = gql`
  mutation ReportMutation($userId: ID!, $discountId: ID) {
    report(userId: $userId, discountId: $discountId) {
      id
    }
  }
`;

var called = true;

class Discount extends Component {
  state = {
    code: "",
    copied: false,
    modal: false,
    shareColour: false,
    flagged: false,
    isVideo: false,
    businessLogoResize: true,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <Query query={USER_DETAILS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <div></div>;
          if (error) return <div>Error</div>;

          const business = this.props.discount.postedBy.name;
          const userId = this.props.discount.postedBy.id;
          const currentUserId = data.user.id;
          const discountId = this.props.discount.id;

          const businessLogo = this.props.discount.postedBy.businessLogo;

          if (called) {
            if (
              this.props.discount.filename1.substr(
                this.props.discount.filename1.lastIndexOf("."),
                this.props.discount.filename1.length
              ) === ".mp4" ||
              this.props.discount.filename1.substr(
                this.props.discount.filename1.lastIndexOf("."),
                this.props.discount.filename1.length
              ) === ".MP4"
            ) {
              this.setState({ isVideo: true });
            }
            called = false;
          }

          const inStore = this.props.discount.inStore;

          const { copied, shareColour, flagged, isVideo, businessLogoResize } =
            this.state;
          return (
            <MDBCol xs="12" sm="12" md="12" lg="12" style={mainContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {businessLogo !== "" && businessLogo !== null ? (
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <div style={{ textAlign: "left" }}>
                        <a
                          style={{ padding: "0" }}
                          onClick={() => {
                            if (!loading && !data) {
                              profileTrackerMutation();
                            }
                          }}
                          href={`/profile/${userId}`}
                        >
                          <img
                            style={userImg}
                            src={
                              businessLogoResize
                                ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${userId}_50x50.jpeg`
                                : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/logos/${businessLogo}`
                            }
                            onError={() =>
                              this.setState({ businessLogoResize: false })
                            }
                            alt="Business Logo"
                          ></img>
                        </a>
                      </div>
                    )}
                  </Mutation>
                ) : (
                  <></>
                )}
                <div>
                  <Mutation
                    mutation={USER_PROFILE_MUTATION}
                    variables={{ currentUserId, userId }}
                  >
                    {(profileTrackerMutation, { loading, data }) => (
                      <div style={{ textAlign: "left" }}>
                        <a
                          style={{ padding: "0" }}
                          onClick={() => {
                            if (!loading && !data) {
                              profileTrackerMutation();
                            }
                          }}
                          href={`/profile/${userId}`}
                        >
                          <p style={nameText}>
                            {this.props.discount.postedBy.name} - Brand
                          </p>
                        </a>
                        <p style={locationText}>
                          {this.props.discount.postedBy.city},{" "}
                          {this.props.discount.postedBy.country}
                        </p>
                      </div>
                    )}
                  </Mutation>
                </div>
                <div style={topContainer}>
                  {userId === currentUserId ? (
                    <div style={{ marginBottom: 20 }}>
                      <MDBDropdown dropleft>
                        <MDBDropdownToggle
                          id="editDropdown"
                          color="transparent"
                        >
                          <MDBIcon
                            style={{ marginTop: "8px", color: "#f8f8ff" }}
                            size="2x"
                            icon="ellipsis-h"
                          />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                          <div style={editUploadContainer}>
                            <MDBDropdownItem>
                              <Mutation
                                mutation={DELETE_DISCOUNT_MUTATION}
                                variables={{ id: this.props.discount.id }}
                                onCompleted={() =>
                                  (window.location.href =
                                    window.location.pathname)
                                }
                              >
                                {(deleteVideoMutation, { loading, data }) => (
                                  <MDBBtn
                                    id="btnSM"
                                    style={deleteButton}
                                    rounded
                                    onClick={() => {
                                      if (!loading && !data) {
                                        deleteVideoMutation();
                                      }
                                    }}
                                  >
                                    Delete
                                  </MDBBtn>
                                )}
                              </Mutation>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                              <MDBBtn
                                id="btnSM"
                                style={editButton}
                                rounded
                                href={`/business/edit_discount/${this.props.discount.id}`}
                              >
                                Edit
                              </MDBBtn>
                            </MDBDropdownItem>
                          </div>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "inline-flex",
                        verticalAlign: "middle",
                      }}
                    >
                      <div>
                        <MDBDropdown id="shareDropdown" dropleft>
                          <MDBDropdownToggle
                            id="editDropdown"
                            style={{ marginTop: "-2px" }}
                            color="transparent"
                          >
                            <MDBIcon
                              style={{ color: "#f8f8ff", fontSize: "1.5em" }}
                              fas
                              icon="share-alt"
                              onClick={() =>
                                this.setState({ shareColour: !shareColour })
                              }
                            />
                          </MDBDropdownToggle>
                          <MDBDropdownMenu basic>
                            <MDBDropdownItem style={{ marginTop: -10 }}>
                              <div
                                style={{
                                  justifyContent: "space-around",
                                  display: "flex",
                                }}
                              >
                                <div style={{ backgroundColor: "#000" }}>
                                  <Mutation
                                    mutation={SHARE_TRACKER_MUTATION}
                                    variables={{
                                      user: currentUserId,
                                      userShared: userId,
                                      platform: "Email",
                                    }}
                                  >
                                    {(shareTrackerMutation, { loading }) => (
                                      <EmailShareButton
                                        url={`https://thesponsorhub.com/discounts/${this.props.discount.id}`}
                                        subject={`${business} - The Sponsor Hub`}
                                        body={`Check out ${business}'s Discount on The Sponsor Hub 🤙`}
                                      >
                                        <MDBIcon
                                          style={shareIcon}
                                          size="2x"
                                          icon="envelope"
                                          onClick={() => {
                                            if (!loading) {
                                              shareTrackerMutation();
                                            }
                                          }}
                                        />
                                      </EmailShareButton>
                                    )}
                                  </Mutation>
                                </div>
                                <div style={{ backgroundColor: "#000" }}>
                                  <Mutation
                                    mutation={SHARE_TRACKER_MUTATION}
                                    variables={{
                                      user: currentUserId,
                                      userShared: userId,
                                      platform: "Facebook",
                                    }}
                                  >
                                    {(shareTrackerMutation, { loading }) => (
                                      <FacebookShareButton
                                        quote={`Check out ${business}'s Discount on The Sponsor Hub 🤙`}
                                        url={`https://thesponsorhub.com/discounts/${this.props.discount.id}`}
                                      >
                                        <MDBIcon
                                          style={shareIcon}
                                          fab
                                          size="2x"
                                          icon="facebook-f"
                                          onClick={() => {
                                            if (!loading) {
                                              shareTrackerMutation();
                                            }
                                          }}
                                        />
                                      </FacebookShareButton>
                                    )}
                                  </Mutation>
                                </div>
                                <div style={{ backgroundColor: "#000" }}>
                                  <Mutation
                                    mutation={SHARE_TRACKER_MUTATION}
                                    variables={{
                                      user: currentUserId,
                                      userShared: userId,
                                      platform: "Twitter",
                                    }}
                                  >
                                    {(shareTrackerMutation, { loading }) => (
                                      <TwitterShareButton
                                        title={`Check out ${business}'s Discount on The Sponsor Hub 🤙`}
                                        url={`https://thesponsorhub.com/discounts/${this.props.discount.id}`}
                                      >
                                        <MDBIcon
                                          style={shareIcon}
                                          size="2x"
                                          fab
                                          icon="twitter"
                                          onClick={() => {
                                            if (!loading) {
                                              shareTrackerMutation();
                                            }
                                          }}
                                        />
                                      </TwitterShareButton>
                                    )}
                                  </Mutation>
                                </div>
                                <div style={{ backgroundColor: "#000" }}>
                                  <Mutation
                                    mutation={SHARE_TRACKER_MUTATION}
                                    variables={{
                                      user: currentUserId,
                                      userShared: userId,
                                      platform: "Whatsapp",
                                    }}
                                  >
                                    {(shareTrackerMutation, { loading }) => (
                                      <WhatsappShareButton
                                        title={`Check out ${business}'s Discount on The Sponsor Hub 🤙`}
                                        url={`https://thesponsorhub.com/discounts/${this.props.discount.id}`}
                                      >
                                        <MDBIcon
                                          style={shareIcon}
                                          size="2x"
                                          fab
                                          icon="whatsapp"
                                          onClick={() => {
                                            if (!loading) {
                                              shareTrackerMutation();
                                            }
                                          }}
                                        />
                                      </WhatsappShareButton>
                                    )}
                                  </Mutation>
                                </div>
                              </div>
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                      <div>
                        <Mutation
                          mutation={REPORT_DISCOUNT_MUTATION}
                          variables={{ userId: currentUserId, discountId }}
                        >
                          {(reportMutation, { loading, data }) => (
                            <div className="ml-3">
                              {!flagged ? (
                                <MDBIcon
                                  style={{
                                    color: "#f8f8ff",
                                    fontSize: "1.2em",
                                  }}
                                  fas
                                  icon="flag"
                                  onClick={() => {
                                    if (!loading && !data) {
                                      this.setState({ flagged: true });
                                      reportMutation();
                                    }
                                  }}
                                />
                              ) : (
                                <MDBIcon
                                  style={{ color: "red", fontSize: "1.2em" }}
                                  fas
                                  icon="flag"
                                  onClick={() =>
                                    this.setState({ flagged: false })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </Mutation>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 5 }}>
                <div>
                  {!isVideo ? (
                    <MDBCarousel
                      activeItem={1}
                      length={3}
                      showControls={true}
                      showIndicators={false}
                      className="z-depth-1"
                      slide
                      onHoverStop={true}
                    >
                      <MDBCarouselInner>
                        <MDBCarouselItem itemId="1">
                          <div
                            style={{
                              height: 300,
                              width: window.innerWidth >= 576 ? "auto" : 350,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ maxHeight: 300 }}
                              src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/discountImages/${userId}/${this.props.discount.filename1}`}
                              alt="First Product"
                            />
                          </div>
                          <p style={productDetailsText}>
                            {this.props.discount.productDescription1}
                          </p>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="2">
                          <div
                            style={{
                              height: 320,
                              width: window.innerWidth >= 576 ? "auto" : 350,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ maxHeight: 300 }}
                              src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/discountImages/${userId}/${this.props.discount.filename2}`}
                              alt="Second Product"
                            />
                          </div>
                          <p style={productDetailsText}>
                            {this.props.discount.productDescription2}
                          </p>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="3">
                          <div
                            style={{
                              height: 320,
                              width: window.innerWidth >= 576 ? "auto" : 350,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ maxHeight: 300 }}
                              src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/discountImages/${userId}/${this.props.discount.filename3}`}
                              alt="Third Product"
                            />
                          </div>
                          <p style={productDetailsText}>
                            {this.props.discount.productDescription3}
                          </p>
                        </MDBCarouselItem>
                      </MDBCarouselInner>
                    </MDBCarousel>
                  ) : (
                    <video id="video" controls preload="auto">
                      <source
                        src={`https://the-sponsor-hub.ams3.digitaloceanspaces.com/discountImages/${userId}/${this.props.discount.filename1}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              </div>
              <div style={{ marginTop: -5 }}>
                <p style={amountBox}>{this.props.discount.amount}</p>
                <p style={detailsText}>{this.props.discount.description}</p>
              </div>
              {inStore ? (
                <div className="pt-1">
                  <p style={detailsText}>
                    This Discount is only available In Store. Download our App
                    to access this Discounts and redeem In Store.
                  </p>
                </div>
              ) : (
                <></>
              )}
              <div
                className="pt-1"
                style={{ justifyContent: "space-evenly", display: "flex" }}
              >
                {!inStore ? (
                  <Mutation
                    mutation={CODE_TRACKER_MUTATION}
                    variables={{
                      business: userId,
                      user: currentUserId,
                      discountId,
                    }}
                  >
                    {(codeTrackerMutation, { loading, data }) => (
                      <CopyToClipboard
                        text={this.props.discount.code}
                        onCopy={() => this.setState({ copied: true })}
                      >
                        <MDBBtn
                          id="btnSM"
                          size="sm"
                          rounded
                          outline
                          color="blue"
                          onClick={() => {
                            if (!loading && !data) {
                              codeTrackerMutation();
                            }
                          }}
                        >
                          {copied ? "Copied!" : "Code"}
                        </MDBBtn>
                      </CopyToClipboard>
                    )}
                  </Mutation>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.thesponsorhub"
                    >
                      <MDBIcon
                        fab
                        icon="google-play"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                  </div>
                )}
                {inStore ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a
                      style={{ padding: 0 }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.apple.com/gm/app/the-sponsor-hub/id1576680095"
                    >
                      <MDBIcon
                        fab
                        icon="app-store"
                        size="lg"
                        className="white-text"
                      />
                    </a>
                  </div>
                ) : (
                  <></>
                )}
                <Mutation
                  mutation={WEBSITE_TRACKER_MUTATION}
                  variables={{
                    business: userId,
                    user: currentUserId,
                    discountId,
                  }}
                >
                  {(websiteTrackerMutation, { loading }) => (
                    <MDBBtn
                      id="btnSM"
                      rounded
                      outline
                      color="white"
                      onClick={() => {
                        if (!loading) {
                          websiteTrackerMutation();
                        }
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.discount.postedBy.website}
                      size="sm"
                    >
                      Website
                    </MDBBtn>
                  )}
                </Mutation>
              </div>
              <div
                style={{ borderBottom: "1px solid #f8f8ff", marginTop: 5 }}
              ></div>
            </MDBCol>
          );
        }}
      </Query>
    );
  }
}

export default Discount;

const mainContainer = {
  background: "#000",
  borderRadius: "0.4rem",
  textAlign: "center",
  padding: "0.25rem",
  justifyContent: "space-between",
  flexDirection: "column",
  display: "flex",
};

const userImg = {
  height: 40,
  width: "auto",
  objectFit: "cover",
  borderRadius: "0.4rem",
};

const nameText = {
  color: "#f8f8ff",
  marginLeft: "0.5rem",
  marginBottom: 0,
  fontFamily: "Oswald",
  textTransform: "uppercase",
  fontSize: "1rem",
};

const locationText = {
  color: "#f8f8ff",
  marginLeft: "0.5rem",
  fontFamily: "Oswald",
  marginBottom: 0,
  fontSize: "0.8rem",
};

const topContainer = {
  right: 0,
  position: "absolute",
};

const shareIcon = {
  backgroundColor: "#000",
  color: "#f8f8ff",
  marginLeft: "1rem",
};

const editUploadContainer = {
  right: "40px",
  textAlign: "center",
  position: "relative",
  display: "flex",
  marginTop: "-10px",
};

const deleteButton = {
  backgroundColor: "#000",
  border: "2px solid red",
  color: "red",
};

const editButton = {
  backgroundColor: "#000",
  border: "2px solid #f8f8ff",
  color: "#f8f8ff",
};

const detailsText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  marginBottom: 0,
  fontSize: "0.9rem",
  textAlign: "justify",
};

const productDetailsText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  marginTop: 5,
  marginBottom: 5,
  fontSize: "0.9rem",
  textAlign: "justify",
};

const amountBox = {
  color: "#121212",
  backgroundColor: "#f8f8ff",
  borderRadius: "0.25rem",
  fontFamily: "Oswald",
  fontSize: "0.9rem",
  textAlign: "center",
  width: "max-content",
  paddingLeft: 5,
  paddingRight: 5,
  zIndex: 2,
  marginTop: 5,
  marginBottom: 5,
};
