import React from "react";
import { MDBCol } from "mdbreact";
import './../../styles/Legal.css'

const SponsorshipTips = () => {
  return (
    <div className="px-3 py-4" style={{backgroundColor: '#fff', textAlign: 'justify'}}>
      <MDBCol lg="10" md="12" sm="12" xs="12" className="offset-lg-1">
        <div className="pt-5 pb-2">
          <h1 className="legal-title">Tips on getting Sponsorship</h1>
          <p className="legal-text">Please see below our tips for shredders looking for sponsorship or looking for team rider roles.</p><br/>
          <h3 className="legal-subheading">Have realistic expectations</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Don’t expect the world from potential sponsors, start small and work your way up. Companies that sponsor are investing money in you, so you have to be able to give them something back.</p><br/>
            </li>
            <li>
                <p className="legal-text">In the first instance be prepared for stickers, hats, and T-shirts, then promote and build from there, looking towards team rider roles and then sponsorship.</p><br/>
            </li>
            <li>
                <p className="legal-text">Once you have shown you’re working hard, promoting the brand by networking and updating your social media, it will be a lot easier to ‘sell’ yourself.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Practice</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">The more you practice, not only are you going to get better quicker, but it also means that you will spend more time interacting with other shredders. If you’re using a company’s kit people will see you repping that kit, meaning more exposure for the company and if you’re really good, people will want to know what kind of equipment you’re on.</p><br/>
            </li>
            <li>
                <p className="legal-text">So shred as much as you can.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Compete in Competitions</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">This is not always necessary, but it is a great way to stand out and get exposure. Start local and small then build up. Competitions can be in-person or online, both have advantages.</p><br/>
            </li>
            <li>
                <p className="legal-text">In-person allows you to meet up and mingle with likeminded athletes, learning from and encouraging each other to push that bit extra on that day.</p><br/>
            </li>
            <li>
                <p className="legal-text">Online comps reduce travel time, you have ‘local’ knowledge of your own favourite spot and the ability to pick the video of your best rides, not being defined solely by your performance on that day.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Take pictures / make videos</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Extreme sports is a content driven industry, with extreme sports athletes leading the charge at selling the sport and the lifestyle.</p><br/>
            </li>
            <li>
                <p className="legal-text">Taking pictures / making videos of you shredding is a great way to stay current, show sponsors your ability and that you like to create content. Ultimately that’s what they want to see. If you’re already doing that, it makes it a lot easier for them to justify sponsoring you.</p><br/>
            </li>
            <li>
                <p className="legal-text">Having a whole catalogue of pictures / videos on the platform not only shows your ability, but also your commitment, passion and progress.</p><br/>
            </li>
            <li>
                <p className="legal-text">Get varying footage and angles over a few sessions, download an editing app and become your own media editor.</p><br/>
            </li>
            <li>
                <p className="legal-text">Video as many sessions as possible – take turns with friends or get family members to film.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Be Cool</h3>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Not just for sponsorship, being a nice human being is an all-round good thing.</p><br/>
            </li>
            <li>
                <p className="legal-text">Sponsors will be looking for ‘good people’. You can have all the talent in the world, put out the best clips, be shredding, hitting tricks and airs; but if you’re not a nice person and you’re difficult to work with, no company will want to deal with you, let alone sponsor you.</p><br/>
            </li>
            <li>
                <p className="legal-text">When you represent a company, your actions reflect on them, if you’re salty, with a bad attitude it will be really hard for you to get support / sponsorship.</p><br/>
            </li>
          </ul>
          <h3 className="legal-subheading">Enjoy your sport, make friends, build crews, have fun</h3>
          <p className="legal-text">Keep a smile on your face, the best rider out there is the one having the most fun!</p><br/>
          <ul style={{marginBottom: 0}}>
            <li>
                <p className="legal-text">Have realistic expectations.</p><br/>
            </li>
            <li>
                <p className="legal-text">Start small and build.</p><br/>
            </li>
            <li>
                <p className="legal-text">Shred as much as you can.</p><br/>
            </li>
            <li>
                <p className="legal-text">Train.</p><br/>
            </li>
            <li>
                <p className="legal-text">Learn everything you can about your sport.</p><br/>
            </li>
            <li>
                <p className="legal-text">Give competitions a go.</p><br/>
            </li>
            <li>
                <p className="legal-text">Go spectate – Speak to people, learn.</p><br/>
            </li>
            <li>
                <p className="legal-text">Take a whole load of pictures / videos.</p><br/>
            </li>
            <li>
                <p className="legal-text">Learn to edit videos or find a friend who can show you.</p><br/>
            </li>
            <li>
                <p className="legal-text">Post out compelling videos of you shredding.</p><br/>
            </li>
            <li>
                <p className="legal-text">Build a portfolio of videos, showing your progress, passion and commitment.</p><br/>
            </li>
            <li>
                <p className="legal-text">Don’t be salty, be a good person (not just while looking for sponsorship).</p><br/>
            </li>
            <li>
                <p className="legal-text">Smile, enjoy yourself, meet cool people, make friends and most of all…</p><br/>
            </li>
            <li>
                <p className="legal-text">Have fun!</p><br/>
            </li>
          </ul>
        </div>
      </MDBCol>
    </div>
  );
};

export default SponsorshipTips;