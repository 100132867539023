import React from 'react';
import { MDBRow, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBIcon } from 'mdbreact';
import "./../../styles/Navbar.css";
import { withRouter } from 'react-router'
import { HashLink as Link } from 'react-router-hash-link';

class LoggedOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  render() {
    return (
      <div>
        <header>
          <MDBNavbar style={background} dark expand="md" scrolling fixed="top">
            <MDBNavbarBrand style={{alignItems: 'center'}}>
              {this.state.collapse ?
                <Link style={{padding: 0}} onClick={this.onClick} to="/#home">
                  <strong style={title}>The Sponsor Hub</strong>
                </Link>
                :
                <Link style={{padding: 0}} to="/#home">
                  <strong style={title}>The Sponsor Hub</strong>
                </Link>
              }
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.onClick} />
            <MDBCollapse style={{alignItems: 'center'}} isOpen={this.state.collapse} navbar>
              <MDBNavbarNav style={{display: window.innerWidth < 768 ? "inline" : "flex"}} right>
                <MDBNavItem style={linkItem}>
                  <Link style={linkText} onClick={this.onClick} to="/#create-an-account">Create an Account</Link>
                </MDBNavItem>
                <MDBNavItem style={linkItem}>
                  <Link style={linkText} onClick={this.onClick} to="/login">Login</Link>
                </MDBNavItem>
                <MDBNavItem style={linkItem}>
                  {window.innerWidth > 992 || this.state.collapse ?
                    <Link style={linkText} onClick={this.onClick} to="/#about-us">About</Link>
                  :
                    <></>
                  }
                </MDBNavItem>
                <MDBNavItem style={linkItem}>
                  {window.innerWidth > 992 || this.state.collapse ?
                    <Link style={linkText} onClick={this.onClick} to="/#sponsored">Sponsored</Link>
                  :
                    <></>
                  }
                </MDBNavItem>
                <MDBNavItem style={linkItem}>
                  {window.innerWidth > 992 || this.state.collapse ?
                    <Link style={linkText} onClick={this.onClick} to="/#testimonials">Testimonials</Link>
                    :
                    <></>
                  }
                </MDBNavItem>
                <MDBNavItem style={linkItem}>
                  {window.innerWidth > 992 || this.state.collapse ?
                    <Link id="hideNav" style={linkText} onClick={this.onClick} to="/#contact">Contact</Link>
                    :
                    <></>
                  }
                </MDBNavItem>
                {this.state.collapse ?
                  <MDBRow className='my-2' style={icons}>
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.thesponsorhub">
                      <MDBIcon fab icon="google-play" size="lg" className="white-text" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/gm/app/the-sponsor-hub/id1576680095">
                      <MDBIcon fab icon="app-store" size="lg" className="white-text" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thesponsorhub/?hl=en">
                      <MDBIcon fab icon="instagram" size="lg" className="white-text" />
                    </a>
                    <a id="hideSocialLogout" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TheSponsorHub/">
                      <MDBIcon fab icon="facebook-f" size="lg" className="white-text" />
                    </a>
                    <a id="hideSocialLogout" target="_blank" rel="noopener noreferrer" href="https://twitter.com/thesponsorhub">
                      <MDBIcon fab icon="twitter" size="lg" className="white-text" />
                    </a>
                  </MDBRow>
                :
                  <MDBRow style={{marginTop: -2}}>
                    <a style={iconLink} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thesponsorhub/?hl=en">
                      <MDBIcon fab icon="instagram" size="lg" className="white-text" />
                    </a>
                    <a style={iconLink} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TheSponsorHub/">
                      <MDBIcon fab icon="facebook-f" size="lg" className="white-text" />
                    </a>
                    <a style={iconLink} target="_blank" rel="noopener noreferrer" href="https://twitter.com/thesponsorhub">
                      <MDBIcon fab icon="twitter" size="lg" className="white-text" />
                    </a>
                  </MDBRow>
                }
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
        </header>
      </div>
    );
  }
}

export default withRouter(LoggedOut);

const background = {
  background: '#000',
  minHeight: '40px'
}

const title = {
  verticalAlign: 'middle', 
  fontSize: '24px', 
  textTransform: 'uppercase', 
  fontFamily: 'Bebas Neue', 
  color: '#f8f8ff'
}

const icons = {
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: -2
}

const linkItem = {
  display: 'flex',
  alignItems: 'center'
}

const linkText = {
  color: '#f8f8ff', 
  fontFamily: 'Bebas Neue', 
  fontSize: 20,
  marginTop: 2,
  marginBottom: 2,
  verticalAlign: 'middle', 
}

const iconLink = {
  display: 'flex',
  alignItems: 'center'
}
