import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Query, withApollo } from "react-apollo";
import gql from "graphql-tag";
import Video from "./Videos/Video";
import { MDBRow } from "mdbreact";
import Discount from "./Discounts/Discount";
import ErrorPage from "./Errors/ErrorMessage";

var VIDEO_SIZE = 5;

const HOME_FEED_QUERY = gql`
  query HomeFeedQuery(
    $id: ID!
    $category: [String!]
    $orientation: String
    $skip: Int
    $last: Int
  ) {
    homeFeed(
      id: $id
      category: $category
      orientation: $orientation
      skip: $skip
      last: $last
    ) {
      videos {
        id
        filename
        description
        filmerInstagram
        mimetype
        crewOnly
        postedBy {
          id
          name
          category
          country
          city
          instagram
          email
          businessLogo
          profileImage
          isBusiness
        }
        votes {
          id
          user {
            id
          }
        }
        videoViews {
          id
        }
      }
    }
  }
`;

const HOME_DISCOUNTS_FEED_QUERY = gql`
  query videoDiscountsQuery(
    $country: String!
    $city: String!
    $category: [String]!
  ) {
    videoDiscounts(country: $country, city: $city, category: $category) {
      discounts {
        id
        amount
        description
        code
        filename1
        filename2
        filename3
        productDescription1
        productDescription2
        productDescription3
        postedBy {
          id
          name
          website
          businessLogo
          city
          country
        }
        inStore
      }
    }
  }
`;

var orientation = "Landscape";

class HomeFeed extends Component {
  state = {
    videosAndDiscounts: [],
    skipVideo: 0,
    loadMore: false,
    firstLoad: true,
    firstLoadDiscount: true,
    hasMore: true,
    hasMoreDiscounts: true,
    id: this.props.id,
    category: this.props.category,
    city: this.props.city,
    country: this.props.country,
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this._executeFetchMoreVideos();
      if (this.state.videosAndDiscounts.length !== 0) {
        this._executeFetchMoreDiscounts();
      }
      var check = new Set();
      this.setState({
        videosAndDiscounts: this.state.videosAndDiscounts.filter(
          (obj) => !check.has(obj["id"]) && check.add(obj["id"])
        ),
      });
    }, 1000);
  };

  _executeFetchMoreVideos = async () => {
    var { id, category, skipVideo, firstLoad } = this.state;

    if ((skipVideo / 5 / 5) % 1 === 0 && !firstLoad) {
      if (orientation === "Landscape") {
        orientation = "Portrait";
      } else {
        orientation = "Landscape";
      }
    }

    const result = await this.props.client.query({
      query: HOME_FEED_QUERY,
      variables: {
        id,
        category,
        orientation,
        skip: skipVideo,
        last: VIDEO_SIZE,
      },
    });
    if (result.data.homeFeed.videos.length >= VIDEO_SIZE) {
      this.setState({
        videosAndDiscounts: [
          ...this.state.videosAndDiscounts,
          ...result.data.homeFeed.videos,
        ],
      });
    }
    if (result.data.homeFeed.videos.length === 0) {
      this.setState({ hasMore: false });
    } else {
      this.setState({ skipVideo: this.state.skipVideo + VIDEO_SIZE });
      if (this.state.firstLoad) {
        this._executeFetchMoreVideos();
      }
      this.setState({ hasMore: true, firstLoad: false });
    }
  };

  _executeFetchMoreDiscounts = async () => {
    var { city, country, category } = this.state;

    category.push("Apparel");

    const result = await this.props.client.query({
      query: HOME_DISCOUNTS_FEED_QUERY,
      variables: { city, country, category },
      fetchPolicy: "network-only",
    });
    if (
      result.data.videoDiscounts.discounts.length === 1 &&
      (!this.state.videosAndDiscounts.includes(
        result.data.videoDiscounts.discounts[0]
      ) ||
        this.state.videosAndDiscounts.length > 250)
    ) {
      if (
        this.state.videosAndDiscounts[this.state.videosAndDiscounts.length - 1]
          .__typename !== "Discount"
      ) {
        this.setState({
          videosAndDiscounts: [
            ...this.state.videosAndDiscounts,
            ...result.data.videoDiscounts.discounts,
          ],
        });
      }
    } else {
      this._executeFetchMoreDiscounts();
    }
    if (result.data.videoDiscounts.discounts.length === 0) {
      this.setState({ hasMoreDiscounts: false });
    } else {
      if (this.state.firstLoadDiscount) {
        this._executeFetchMoreDiscounts();
      }
      this.setState({ hasMoreDiscounts: true, firstLoadDiscount: false });
    }
  };

  render() {
    const { id, category } = this.state;
    return (
      <Query
        query={HOME_FEED_QUERY}
        variables={{ id, category, skip: 0, last: VIDEO_SIZE + 1 }}
      >
        {({ data, loading, error }) => {
          if (loading)
            return (
              <div
                style={{ minHeight: "100vh", backgroundColor: "transparent" }}
              ></div>
            );
          if (error) return <ErrorPage error={error.message} />;

          var initialVideosAndDiscounts = [];
          if (data) {
            initialVideosAndDiscounts = initialVideosAndDiscounts.concat(
              data.homeFeed.videos
            );
          }

          return (
            <div
              className="pt-1"
              style={{ minHeight: "100vh", backgroundColor: "transparent" }}
            >
              <InfiniteScroll
                dataLength={this.state.videosAndDiscounts.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  <div
                    className="pb-2"
                    style={{
                      textAlign: "center",
                      color: "#f8f8ff",
                      textTransform: "uppercase",
                      fontFamily: "helvetica",
                    }}
                    key={0}
                  >
                    <h5>Loading More Edits...</h5>
                  </div>
                }
              >
                {data && this.state.firstLoad ? (
                  <MDBRow className="justify-content-center">
                    {initialVideosAndDiscounts.map((item) => (
                      <FeedSorter key={item.id} item={item} />
                    ))}
                  </MDBRow>
                ) : (
                  <MDBRow className="justify-content-center">
                    {this.state.videosAndDiscounts.map((item) => (
                      <FeedSorter key={item.id} item={item} />
                    ))}
                  </MDBRow>
                )}
                {!this.state.hasMore ? (
                  <div
                    className="pb-2"
                    style={{
                      textAlign: "center",
                      color: "#f8f8ff",
                      textTransform: "uppercase",
                      fontFamily: "helvetica",
                    }}
                    key={0}
                  >
                    <h5>No More Edits!</h5>
                  </div>
                ) : (
                  <></>
                )}
              </InfiniteScroll>
            </div>
          );
        }}
      </Query>
    );
  }
}

class FeedSorter extends Component {
  state = {
    item: this.props.item,
  };
  render() {
    return (
      <>
        {this.state.item.__typename === "Discount" ? (
          <Discount key={this.state.item.id} discount={this.state.item} />
        ) : (
          <Video key={this.state.item.id} video={this.state.item} />
        )}
      </>
    );
  }
}

export default withApollo(HomeFeed);
