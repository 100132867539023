import { MDBCol } from "mdbreact";
import React from "react";

const LandingPageEdit = () => {
  return (
    <section id="home" style={{ backgroundColor: "#000" }}>
      <MDBCol
        xs="12"
        sm="12"
        md="12"
        lg="12"
        style={
          window.innerWidth > 576
            ? { padding: "1rem 0 0 0", backgroundColor: "#000" }
            : { padding: "1rem 0 0 0", backgroundColor: "#000" }
        }
      >
        <video
          width="100%"
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          poster={
            window.innerWidth > 576
              ? "https://the-sponsor-hub.ams3.digitaloceanspaces.com/LandingVideos/LandscapeLandingPageEdit.jpg"
              : "https://the-sponsor-hub.ams3.digitaloceanspaces.com/LandingVideos/PortraitLandingPageEdit.jpg"
          }
        >
          <source
            src={
              window.innerWidth > 576
                ? "https://the-sponsor-hub.ams3.digitaloceanspaces.com/LandingVideos/LandingPageEdit.mp4"
                : "https://the-sponsor-hub.ams3.digitaloceanspaces.com/LandingVideos/LandingPageEditMobileNew.mp4"
            }
          />
          Your browser does not support the video tag.
        </video>
      </MDBCol>
    </section>
  );
};

export default LandingPageEdit;
