import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { MDBBtn } from "mdbreact";
import NoMatch from "../Errors/404";
import Loading from "../Loading";
import ErrorPage from "../Errors/ErrorMessage";

const USER_ID_QUERY = gql`
  query {
    user {
      id
    }
  }
`;

const DELETE_ACCOUNT_CHECK_MUTATION = gql`
  mutation DeleteAccountCheckMutation {
    deleteAccountCheck
  }
`;

const ATHLETE_SURVEY_EMAIL_MUTATION = gql`
  mutation AthleteSurevyEmailMutation {
    athleteSurveyEmail
  }
`;

const BUSINESS_SURVEY_EMAIL_MUTATION = gql`
  mutation BusinessSurevyEmailMutation {
    businessSurveyEmail
  }
`;

const FORTNIGHTLY_ATHLETE_EMAIL_MUTATION = gql`
  mutation FortnightlyAthleteEmailMutation {
    fortnightlyAthleteEmail
  }
`;

const FORTNIGHTLY_BUSINESS_EMAIL_MUTATION = gql`
  mutation FortnightlyBusinessEmailMutation {
    fortnightlyBusinessEmail
  }
`;

const COMMUNITY_GUIDELINES_EMAIL_MUTATION = gql`
  mutation CommunityGuidelinesEmailMutation {
    communityGuidelinesEmail
  }
`;

const COOKIES_POLICY_EMAIL_MUTATION = gql`
  mutation CookiesPolicyEmailMutation {
    cookiesPolicyEmail
  }
`;

const DATA_RETENTION_AND_ERASURE_POLICY_EMAIL_MUTATION = gql`
  mutation DataRetentionAndErasurePolicyEmailMutation {
    dataRetentionAndErasurePolicyEmail
  }
`;

const ENVIRONMENTAL_AND_SUSTAINABILITY_POLICY_EMAIL_MUTATION = gql`
  mutation EnvironmentalAndSustainabilityPolicyEmailMutation {
    environmentalAndSustainabilityPolicyEmail
  }
`;

const PRIVACY_POLICY_6_EMAIL_MUTATION = gql`
  mutation PrivacyPolicy6EmailMutation {
    privacyPolicy6Email
  }
`;

const PRIVACY_POLICY_10_EMAIL_MUTATION = gql`
  mutation PrivacyPolicy10EmailMutation {
    privacyPolicy10Email
  }
`;

const PRIVACY_POLICY_13_EMAIL_MUTATION = gql`
  mutation PrivacyPolicy13EmailMutation {
    privacyPolicy13Email
  }
`;

const PRIVACY_POLICY_18_EMAIL_MUTATION = gql`
  mutation PrivacyPolicy18EmailMutation {
    privacyPolicy18Email
  }
`;

const PRIVACY_POLICY_ALL_EMAIL_MUTATION = gql`
  mutation PrivacyPolicyAllEmailMutation {
    privacyPolicyAllEmail
  }
`;

const SPONSORSHIP_TIPS_EMAIL_MUTATION = gql`
  mutation SponsorshipTipsEmailMutation {
    sponsorshipTipsEmail
  }
`;

const TERMS_AND_CONDITIONS_EMAIL_MUTATION = gql`
  mutation TermsAndConditionsEmailMutation {
    termsAndConditionsEmail
  }
`;

const TRUST_AND_SAFETY_PROCEDURE_EMAIL_MUTATION = gql`
  mutation TrustAndSafetyProcedureEmailMutation {
    trustAndSafetyProcedureEmail
  }
`;

const CHURN_RATE_MUTATION = gql`
  mutation ChurnRateMutation {
    churnRate
  }
`;

const RADIO_SILENCE_ATHLETE_EMAIL_MUTATION = gql`
  mutation RadioSilenceAthleteEmailMutation {
    radioSilenceAthleteEmail
  }
`;

const RADIO_SILENCE_BUSINESS_EMAIL_MUTATION = gql`
  mutation RadioSilenceBusinessEmailMutation {
    radioSilenceBusinessEmail
  }
`;

const FILMING_TIPS_ATHLETE_EMAIL_MUTATION = gql`
  mutation FilmingTipsAthleteEmailMutation {
    filmingTipsAthleteEmail
  }
`;

const SPONSORSHIP_TIPS_ATHLETE_EMAIL_MUTATION = gql`
  mutation SponsorshipTipsAthleteEmailMutation {
    sponsorshipTipsAthleteEmail
  }
`;

const ATHLETE_HOW_TO_USE_FEATURES_ATHLETE_EMAIL_MUTATION = gql`
  mutation AthleteHowToUseFeaturesAthleteEmailMutation {
    athleteHowToUseFeaturesAthleteEmail
  }
`;

const ATHLETE_HOW_TO_USE_FEATURES_BUSINESS_EMAIL_MUTATION = gql`
  mutation AthleteHowToUseFeaturesBusinessEmailMutation {
    athleteHowToUseFeaturesBusinessEmail
  }
`;

const BUSINESS_HOW_TO_USE_FEATURES_ATHLETE_EMAIL_MUTATION = gql`
  mutation BusinessHowToUseFeaturesAthleteEmailMutation {
    businessHowToUseFeaturesAthleteEmail
  }
`;

const BUSINESS_HOW_TO_USE_FEATURES_BUSINESS_EMAIL_MUTATION = gql`
  mutation AthleteHowToUseFeaturesAthleteEmailMutation {
    businessHowToUseFeaturesBusinessEmail
  }
`;

const BUSINESS_POSTING_EDITS_EMAIL_MUTATION = gql`
  mutation BusinessPostingEditsEmailMutation {
    businessPostingEditsEmail
  }
`;

class SendEmails extends Component {
  render() {
    return (
      <Query query={USER_ID_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorPage error={error.message} />;

          const id = data.user.id;

          if (id !== "ckck7xbsv025f0741j230h9w7") {
            return <NoMatch location={{ pathname: "Send Emails" }} />;
          } else {
            return (
              <div style={{ backgroundColor: "#000", minHeight: "100vh" }}>
                <h2 className="pt-5 mt-3" style={title}>
                  SEND EMAILS
                </h2>
                <h4 className="mt-3 mx-3" style={subTitle}>
                  MARKETING EMAILS
                </h4>
                <div
                  className="mx-3 mt-3"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Mutation mutation={FORTNIGHTLY_ATHLETE_EMAIL_MUTATION}>
                    {(fortnightlyAthleteEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={fortnightlyAthleteEmail}
                        size="md"
                      >
                        Fortnightly Athlete Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={FORTNIGHTLY_BUSINESS_EMAIL_MUTATION}>
                    {(fortnightlyBusinessEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={fortnightlyBusinessEmail}
                        size="md"
                      >
                        Fortnightly Business Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={ATHLETE_SURVEY_EMAIL_MUTATION}>
                    {(athleteSurveyEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={athleteSurveyEmail}
                        size="md"
                      >
                        Athletes Surevy Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={BUSINESS_SURVEY_EMAIL_MUTATION}>
                    {(businessSurveyEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={businessSurveyEmail}
                        size="md"
                      >
                        Businesses Surevy Email
                      </MDBBtn>
                    )}
                  </Mutation>
                </div>
                <h4 className="mt-3 mx-3" style={subTitle}>
                  POLICY EMAILS
                </h4>
                <div
                  className="mx-3 mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  <Mutation mutation={COMMUNITY_GUIDELINES_EMAIL_MUTATION}>
                    {(communityGuidelinesEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={communityGuidelinesEmail}
                        size="md"
                      >
                        Community Guidelines Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={COOKIES_POLICY_EMAIL_MUTATION}>
                    {(cookiesPolicyEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={cookiesPolicyEmail}
                        size="md"
                      >
                        Cookie Policy Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={DATA_RETENTION_AND_ERASURE_POLICY_EMAIL_MUTATION}
                  >
                    {(dataRetentionAndErasurePolicyEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={dataRetentionAndErasurePolicyEmail}
                        size="md"
                      >
                        Data Retention and Erasure Policy Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={
                      ENVIRONMENTAL_AND_SUSTAINABILITY_POLICY_EMAIL_MUTATION
                    }
                  >
                    {(environmentalAndSustainabilityPolicyEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={environmentalAndSustainabilityPolicyEmail}
                        size="md"
                      >
                        Environmental And Sustainability Policy Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={PRIVACY_POLICY_6_EMAIL_MUTATION}>
                    {(privacyPolicy6Email) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={privacyPolicy6Email}
                        size="md"
                      >
                        Privacy Policy Age 6-9 Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={PRIVACY_POLICY_10_EMAIL_MUTATION}>
                    {(privacyPolicy10Email) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={privacyPolicy10Email}
                        size="md"
                      >
                        Privacy Policy Age 10-12 Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={PRIVACY_POLICY_13_EMAIL_MUTATION}>
                    {(privacyPolicy13Email) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={privacyPolicy13Email}
                        size="md"
                      >
                        Privacy Policy Age 13-17 Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={PRIVACY_POLICY_18_EMAIL_MUTATION}>
                    {(privacyPolicy18Email) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={privacyPolicy18Email}
                        size="md"
                      >
                        Privacy Policy 18+ Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={PRIVACY_POLICY_ALL_EMAIL_MUTATION}>
                    {(privacyPolicyAllEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={privacyPolicyAllEmail}
                        size="md"
                      >
                        Privacy Policy All Email
                      </MDBBtn>
                    )}
                  </Mutation>

                  <Mutation mutation={SPONSORSHIP_TIPS_EMAIL_MUTATION}>
                    {(sponsorshipTipsEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={sponsorshipTipsEmail}
                        size="md"
                      >
                        Sponsorship Tips Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={TERMS_AND_CONDITIONS_EMAIL_MUTATION}>
                    {(termsAndConditionsEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={termsAndConditionsEmail}
                        size="md"
                      >
                        Terms and Conditions Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={TRUST_AND_SAFETY_PROCEDURE_EMAIL_MUTATION}
                  >
                    {(trustAndSafetyProcedureEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={trustAndSafetyProcedureEmail}
                        size="md"
                      >
                        Trust and Safety Procedure Email
                      </MDBBtn>
                    )}
                  </Mutation>
                </div>
                <h4 className="mt-3 mx-3" style={subTitle}>
                  DELETE ACCOUNT CHECK
                </h4>
                <div
                  className="mx-3 mt-3"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Mutation mutation={DELETE_ACCOUNT_CHECK_MUTATION}>
                    {(deleteAccountCheck) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={deleteAccountCheck}
                        size="md"
                      >
                        Check Deleted Accounts
                      </MDBBtn>
                    )}
                  </Mutation>
                </div>
                <h4 className="mt-3 mx-3" style={subTitle}>
                  ANALYTICS
                </h4>
                <div
                  className="mx-3 mt-3"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Mutation mutation={CHURN_RATE_MUTATION}>
                    {(churnRate) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={churnRate}
                        size="md"
                      >
                        Churn Rate
                      </MDBBtn>
                    )}
                  </Mutation>
                </div>
                <h4 className="mt-3 mx-3" style={subTitle}>
                  RADIO SILENCE EMAILS
                </h4>
                <div
                  className="mx-3 mt-3"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Mutation mutation={RADIO_SILENCE_ATHLETE_EMAIL_MUTATION}>
                    {(radioSilenceAthleteEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={radioSilenceAthleteEmail}
                        size="md"
                      >
                        Radio Silence Athlete Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={RADIO_SILENCE_BUSINESS_EMAIL_MUTATION}>
                    {(radioSilenceBusinessEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={radioSilenceBusinessEmail}
                        size="md"
                      >
                        Radio Silence Business Email
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={FILMING_TIPS_ATHLETE_EMAIL_MUTATION}>
                    {(filmingTipsAthleteEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={filmingTipsAthleteEmail}
                        size="md"
                      >
                        Athlete Filming Tips
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={SPONSORSHIP_TIPS_ATHLETE_EMAIL_MUTATION}>
                    {(sponsorshipTipsAthleteEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={sponsorshipTipsAthleteEmail}
                        size="md"
                      >
                        Athlete Sponsorship Tips
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={
                      ATHLETE_HOW_TO_USE_FEATURES_ATHLETE_EMAIL_MUTATION
                    }
                  >
                    {(athleteHowToUseFeaturesAthleteEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={athleteHowToUseFeaturesAthleteEmail}
                        size="md"
                      >
                        Athlete - How to use Features Athlete
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={
                      ATHLETE_HOW_TO_USE_FEATURES_BUSINESS_EMAIL_MUTATION
                    }
                  >
                    {(athleteHowToUseFeaturesBusinessEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={athleteHowToUseFeaturesBusinessEmail}
                        size="md"
                      >
                        Athlete - How to use Features Business
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={
                      BUSINESS_HOW_TO_USE_FEATURES_ATHLETE_EMAIL_MUTATION
                    }
                  >
                    {(businessHowToUseFeaturesAthleteEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={businessHowToUseFeaturesAthleteEmail}
                        size="md"
                      >
                        Business - How to use Features Athlete
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={
                      BUSINESS_HOW_TO_USE_FEATURES_BUSINESS_EMAIL_MUTATION
                    }
                  >
                    {(businessHowToUseFeaturesBusinessEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={businessHowToUseFeaturesBusinessEmail}
                        size="md"
                      >
                        Business - How to use Features Business
                      </MDBBtn>
                    )}
                  </Mutation>
                  <Mutation mutation={BUSINESS_POSTING_EDITS_EMAIL_MUTATION}>
                    {(businessPostingEditsEmail) => (
                      <MDBBtn
                        style={emailButton}
                        color="white"
                        rounded
                        outline
                        onClick={businessPostingEditsEmail}
                        size="md"
                      >
                        Business Posting Edits
                      </MDBBtn>
                    )}
                  </Mutation>
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

export default SendEmails;

const title = {
  color: "#f8f8ff",
  textAlign: "center",
  fontFamily: "Oswald",
};

const subTitle = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const emailButton = {
  fontSize: "14px",
  fontFamily: "Oswald",
  paddingTop: "0.15rem",
  paddingBottom: "0.3rem",
  marginTop: "1rem",
  marginBottom: "1rem",
};
