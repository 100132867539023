import React, { Component } from 'react'
import DiscountsLocal from './DiscountsLocal'

class LocalDiscountsPage extends Component {

  render() {
    return (
      <div style={{backgroundColor: "#000"}}>
        <h2 className="pt-5 mt-3" style={title}>LOCAL DISCOUNTS</h2>
        <DiscountsLocal localDiscountsCategory={true} />
      </div>
    );
  }
}

export default LocalDiscountsPage

const title = {
  color: '#f8f8ff', 
  textAlign: 'center', 
  fontFamily: 'Oswald'
}