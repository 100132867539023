import React, { Component } from "react";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  withGoogleMap,
  withScriptjs,
} from "google-maps-react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GEOCODING_TOKEN } from "../../constants";
import Loading from "../Loading";
import { MDBCol, MDBInput, MDBRow, MDBFileInput, MDBBtn } from "mdbreact";
import { Query, Mutation, withApollo } from "react-apollo";
import gql from "graphql-tag";
import ErrorMessage from "../Errors/ErrorMessage";
import Discount from "./MapDiscount";
import Video from "./MapVideo";
import Loader from "react-dots-loader";
import "react-dots-loader/index.css";
import Observer from "@researchgate/react-intersection-observer";

const USER_QUERY = gql`
  query {
    user {
      id
      name
      isBusiness
      country
      city
      category
      age
      website
    }
  }
`;

const SPOT_DETAILS_QUERY = gql`
  query SpotsQuery($userId: ID!, $category: [String]!, $skip: Int, $last: Int) {
    spots(userId: $userId, category: $category, skip: $skip, last: $last) {
      spots {
        id
        title
        description
        spotImage
        latitude
        longitude
        isBusiness
        userId
        userName
        userWebsite
        surf
        skate
        snowboard
        ski
        bmx
        mtb
        scooter
        climbing
        apparel
        other
        videos {
          id
          filename
          description
          filmerInstagram
          mimetype
          spotLocation
          crewOnly
          postedBy {
            id
            name
            category
            country
            city
            instagram
            email
            businessLogo
            profileImage
            isBusiness
          }
          votes {
            id
            user {
              id
            }
          }
          videoViews {
            id
          }
        }
      }
      discounts {
        id
        title
        amount
        description
        code
        filename1
        filename2
        filename3
        productDescription1
        productDescription2
        productDescription3
        postedBy {
          id
          name
          website
          businessLogo
          city
          country
        }
        inStore
      }
    }
  }
`;

const POST_SPOT_MUTATION = gql`
  mutation PostSpotMutation(
    $title: String!
    $description: String!
    $spotImage: String
    $latitude: Float!
    $longitude: Float!
    $isBusiness: Boolean!
    $userId: ID!
    $userName: String!
    $userWebsite: String
    $surf: Boolean
    $skate: Boolean
    $snowboard: Boolean
    $ski: Boolean
    $bmx: Boolean
    $mtb: Boolean
    $scooter: Boolean
    $climbing: Boolean
    $apparel: Boolean
    $other: Boolean
  ) {
    postSpot(
      title: $title
      description: $description
      spotImage: $spotImage
      latitude: $latitude
      longitude: $longitude
      isBusiness: $isBusiness
      userId: $userId
      userName: $userName
      userWebsite: $userWebsite
      surf: $surf
      skate: $skate
      snowboard: $snowboard
      ski: $ski
      bmx: $bmx
      mtb: $mtb
      scooter: $scooter
      climbing: $climbing
      apparel: $apparel
      other: $other
    ) {
      id
      title
      description
      spotImage
      latitude
      longitude
      isBusiness
      userId
      userName
    }
  }
`;

const UPDATE_SPOT_MUTATION = gql`
  mutation UpdateSpotMutation(
    $id: ID!
    $title: String!
    $description: String!
    $spotImage: String
    $latitude: Float!
    $longitude: Float!
    $isBusiness: Boolean!
    $userId: ID!
    $userName: String!
    $surf: Boolean
    $skate: Boolean
    $snowboard: Boolean
    $ski: Boolean
    $bmx: Boolean
    $mtb: Boolean
    $scooter: Boolean
    $climbing: Boolean
    $apparel: Boolean
    $other: Boolean
  ) {
    postSpotUpdate(
      id: $id
      title: $title
      description: $description
      spotImage: $spotImage
      latitude: $latitude
      longitude: $longitude
      isBusiness: $isBusiness
      userId: $userId
      userName: $userName
      surf: $surf
      skate: $skate
      snowboard: $snowboard
      ski: $ski
      bmx: $bmx
      mtb: $mtb
      scooter: $scooter
      climbing: $climbing
      apparel: $apparel
      other: $other
    ) {
      id
      title
      description
      spotImage
      latitude
      longitude
      isBusiness
      userId
      userName
    }
  }
`;

const DELETE_SPOT_MUTATION = gql`
  mutation DeleteMutation($id: ID!) {
    deleteSpot(id: $id) {
      id
    }
  }
`;

const UPLOAD_FILE_STREAM = gql`
  mutation SpotImageUpload($file: Upload!) {
    spotUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

const WEBSITE_TRACKER_MUTATION = gql`
  mutation WebsiteTrackerMutation(
    $business: String!
    $user: String!
    $discountId: String
  ) {
    websiteTracker(business: $business, user: $user, discountId: $discountId) {
      id
      business
      user
    }
  }
`;

const USER_PROFILE_MUTATION = gql`
  mutation ProfileTrackerMutation($currentUserId: String!, $userId: String!) {
    profileTracker(user: $currentUserId, profileId: $userId) {
      id
      user
      profileId
    }
  }
`;

const DISCOUNT_VIEWS_TRACKER_MUTATION = gql`
  mutation ViewsTrackerMutation($discountId: ID!) {
    discountViews(discountId: $discountId) {
      id
    }
  }
`;

const VIDEO_VIEWS_TRACKER_MUTATION = gql`
  mutation ViewsTrackerMutation($videoId: ID!) {
    videoViews(videoId: $videoId) {
      id
    }
  }
`;

var called = false;

const LoadingContainer = (props) => <Loading />;

export class MapContainer extends Component {
  state = {
    selectedPlace: "",
    city: "",
    country: "",
    addSpot: false,
    showSpot: false,
    title: "",
    description: "",
    isBusiness: false,
    file: "",
    fileError: "",
    inputError: "",
    spotImage: "",
    spotLatitude: "",
    spotLongitude: "",
    spotNumber: 0,
    editSpot: false,
    titleUpdated: "",
    descriptionUpdated: "",
    isBusinessUpdated: false,
    spotImageUpdated: "",
    place: "",
    loadedLocation: false,
    latitude: null,
    longitude: null,
    noCategory: true,
    surf: false,
    skate: false,
    snowboard: false,
    ski: false,
    bmx: false,
    mtb: false,
    scooter: false,
    climbing: false,
    apparel: false,
    other: false,
    noCategoryUpdated: false,
    surfUpdated: false,
    skateUpdated: false,
    snowboardUpdated: false,
    skiUpdated: false,
    bmxUpdated: false,
    mtbUpdated: false,
    scooterUpdated: false,
    climbingUpdated: false,
    apparelUpdated: false,
    otherUpdated: false,
    spotImageResize: true,
  };

  onMapClicked = (map, mapProps, clickEvent) => {
    this.setState({
      addSpot: true,
      spotLatitude: clickEvent.latLng.lat(),
      spotLongitude: clickEvent.latLng.lng(),
      latitude: clickEvent.latLng.lat(),
      longitude: clickEvent.latLng.lng(),
    });
  };

  onMarkerClick = (index) => {
    this.setState({ showSpot: true, spotNumber: index });
  };

  getLocation = (place) => {
    geocodeByAddress(place)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.setState({ loadedLocation: true, latitude: lat, longitude: lng });
      });
  };

  fileChangeHandler = (files) => {
    this.setState({ file: files[0] });
  };

  render() {
    var {
      city,
      country,
      showSpot,
      addSpot,
      title,
      description,
      isBusiness,
      file,
      fileError,
      inputError,
      spotImage,
      spotLatitude,
      spotLongitude,
      spotNumber,
      editSpot,
      titleUpdated,
      descriptionUpdated,
      isBusinessUpdated,
      spotImageUpdated,
      place,
      loadedLocation,
      latitude,
      longitude,
      surf,
      skate,
      snowboard,
      ski,
      bmx,
      mtb,
      scooter,
      climbing,
      apparel,
      other,
      noCategory,
      surfUpdated,
      skateUpdated,
      snowboardUpdated,
      skiUpdated,
      bmxUpdated,
      mtbUpdated,
      scooterUpdated,
      climbingUpdated,
      apparelUpdated,
      noCategoryUpdated,
      otherUpdated,
      spotImageResize,
    } = this.state;
    return (
      <Query query={USER_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) {
            city = "London";
            country = "United Kingdom";
          }

          const userId = data.user.id;
          const userName = data.user.name;
          const userCategory = data.user.category;
          const userIsBusiness = data.user.isBusiness;
          const userWebsite = data.user.website;
          city = data.user.city;
          country = data.user.country;
          place = city + ", " + country;

          if (!called) {
            this.getLocation(place);
            called = true;
          }

          return (
            <Query
              query={SPOT_DETAILS_QUERY}
              variables={{ userId, category: userCategory, skip: 0, last: 1 }}
              fetchPolicy="network-only"
            >
              {({ error, data, loading, refetch }) => {
                if (loading) return <Loading />;
                if (error) return <ErrorMessage error={error.message} />;
                var discountsUnique = [...new Set(data.spots.discounts)];

                return (
                  <div>
                    {latitude !== null &&
                    longitude !== null &&
                    loadedLocation ? (
                      <Map
                        google={this.props.google}
                        initialCenter={{
                          lat: latitude,
                          lng: longitude,
                        }}
                        center={{
                          lat: latitude,
                          lng: longitude,
                        }}
                        disableDoubleClickZoom={true}
                        zoom={15}
                        onDblclick={this.onMapClicked}
                        onClick={() =>
                          this.setState({
                            showSpot: false,
                            addSpot: false,
                            editSpot: false,
                          })
                        }
                        mapTypeControl={false}
                        scaleControl={false}
                        panControl={false}
                        rotateControll={false}
                        fullscreenControl={false}
                      >
                        {data.spots.spots.map((spot, index) => {
                          var latlng = {
                            lat: spot.latitude,
                            lng: spot.longitude,
                          };

                          var key = String(Math.random());
                          return (
                            <Marker
                              id={key}
                              position={latlng}
                              onClick={() => {
                                this.onMarkerClick(index);
                                this.setState({
                                  latitude: spot.latitude,
                                  longitude: spot.longitude,
                                  addSpot: false,
                                  editSpot: false,
                                });
                              }}
                              name={spot.title}
                              icon={{
                                url: spot.isBusiness
                                  ? "/Map/tsh_pin_full_black.png"
                                  : "/Map/tsh_pin_full_white.png",
                                anchor: new this.props.google.maps.Point(
                                  32,
                                  32
                                ),
                                scaledSize: new this.props.google.maps.Size(
                                  85,
                                  64
                                ),
                              }}
                            />
                          );
                        })}
                        <div className="mt-2 pt-5 px-3">
                          <MDBCol
                            sm="10"
                            md="6"
                            lg="6"
                            className="col-12 offset-sm-1 offset-md-3 offset-lg-3"
                          >
                            <GooglePlacesAutocomplete
                              apiKey={GEOCODING_TOKEN}
                              selectProps={{
                                value: place,
                                onChange: (e) => {
                                  this.getLocation(e.label);
                                  this.setState({ place: e.label });
                                },
                              }}
                            />
                          </MDBCol>
                        </div>
                      </Map>
                    ) : (
                      <Loading />
                    )}
                    {addSpot ? (
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="3"
                        style={
                          window.innerWidth >= 576
                            ? {
                                backgroundColor: "#000",
                                minHeight: "100vh",
                                marginTop: "2.5rem",
                              }
                            : {
                                backgroundColor: "#000",
                                height: "50vh",
                                bottom: 0,
                                position: "absolute",
                                overflowY: "auto",
                                marginTop: "0",
                              }
                        }
                        className="col-12 offset-md-6 offset-lg-9 pt-2"
                      >
                        <h2 className="mb-3" style={spotTitle}>
                          ADD SPOT
                        </h2>
                        <Mutation
                          mutation={POST_SPOT_MUTATION}
                          onCompleted={() => {
                            this.setState({
                              addSpot: false,
                              title: "",
                              description: "",
                              spotImage: "",
                              isBusiness: false,
                              file: "",
                              fileError: "",
                              surf: false,
                              skate: false,
                              snowboard: false,
                              ski: false,
                              bmx: false,
                              mtb: false,
                              scooter: false,
                              climbing: false,
                              apparel: false,
                              other: false,
                              noCategory: true,
                            });
                            setTimeout(() => {
                              refetch();
                            }, 5000);
                          }}
                        >
                          {(postSpot, { error, loading }) => (
                            <div>
                              <MDBInput
                                label="Title*"
                                value={title}
                                onChange={(e) =>
                                  this.setState({ title: e.target.value })
                                }
                                type="text"
                                style={inputText}
                                required
                                maxLength="60"
                              />
                              <MDBInput
                                label="Description*"
                                type="textarea"
                                value={description}
                                onChange={(e) =>
                                  this.setState({ description: e.target.value })
                                }
                                style={inputText}
                                maxLength="100"
                                required
                              />
                              <div>
                                <h5 style={labelText}>Select Sports*</h5>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity: surf || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!surf) {
                                          this.setState({
                                            surf: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ surf: false });
                                          if (
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Surf</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/surf.jpg"
                                        alt="Surf"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity: skate || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!skate) {
                                          this.setState({
                                            skate: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ skate: false });
                                          if (
                                            !surf &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Skate</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/skate.jpeg"
                                        alt="Skate"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          snowboard || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!snowboard) {
                                          this.setState({
                                            snowboard: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ snowboard: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Snowboard</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/snowboard.jpg"
                                        alt="Snowboard"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity: ski || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!ski) {
                                          this.setState({
                                            ski: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ ski: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Ski</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/ski.jpg"
                                        alt="Ski"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity: bmx || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!bmx) {
                                          this.setState({
                                            bmx: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ bmx: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>BMX</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/bmx.jpg"
                                        alt="BMX"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity: mtb || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!mtb) {
                                          this.setState({
                                            mtb: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ mtb: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>MTB</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/mtb.jpeg"
                                        alt="MTB"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          scooter || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!scooter) {
                                          this.setState({
                                            scooter: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ scooter: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !climbing &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Scooter</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/scooter.jpeg"
                                        alt="Scooter"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          climbing || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!climbing) {
                                          this.setState({
                                            climbing: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ climbing: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !apparel &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Climbing</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/climbing.jpeg"
                                        alt="Climbing"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity: other || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!other) {
                                          this.setState({
                                            other: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ other: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !apparel
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Other</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/Other.jpg"
                                        alt="Other"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          apparel || noCategory ? 1 : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!apparel) {
                                          this.setState({
                                            apparel: true,
                                            noCategory: false,
                                          });
                                        } else {
                                          this.setState({ apparel: false });
                                          if (
                                            !surf &&
                                            !skate &&
                                            !snowboard &&
                                            !ski &&
                                            !bmx &&
                                            !mtb &&
                                            !scooter &&
                                            !climbing &&
                                            !other
                                          ) {
                                            this.setState({ noCategory: true });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Apparel</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/apparelCategory.jpg"
                                        alt="Apparel"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              </div>
                              {userIsBusiness ? (
                                <div
                                  style={{
                                    marginTop: "1.5rem",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <MDBInput
                                    label="Is this your Business Location?"
                                    type="checkbox"
                                    id="checkbox1"
                                    value={isBusiness}
                                    onChange={(e) =>
                                      this.setState({ isBusiness: !isBusiness })
                                    }
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              <div>
                                <MDBFileInput
                                  btnColor="black"
                                  btnTitle="Upload a Spot Image"
                                  value={file}
                                  type="file"
                                  getValue={this.fileChangeHandler}
                                />
                                {fileError !== "" ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginBottom: "1.5rem",
                                    }}
                                  >
                                    <p style={fileErrorText}>{fileError}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <Mutation mutation={UPLOAD_FILE_STREAM}>
                                  {(spotUpload, { data, loading, error }) => {
                                    return (
                                      <div className="text-center">
                                        {loading && (
                                          <div>
                                            <p style={loadingText}>
                                              Your Image is being uploaded to
                                              the cloud ...
                                            </p>
                                            <Loader size={8} color="white" />
                                          </div>
                                        )}
                                        {error && (
                                          <p style={errorText}>
                                            {error.message ===
                                            "Network error: NetworkError when attempting to fetch resource."
                                              ? "Connection Timed Out: Please Try Again"
                                              : error.message.includes(
                                                  "jwt expired"
                                                )
                                              ? "Token Expired. Please Login again"
                                              : error.message.replace(
                                                  "GraphQL error: ",
                                                  ""
                                                )}
                                          </p>
                                        )}
                                        {file !== "" && !data && !loading ? (
                                          <MDBBtn
                                            color="white"
                                            style={uploadButton}
                                            rounded
                                            onClick={() => {
                                              const fileSelected = file;
                                              const mimetype =
                                                fileSelected.type;
                                              var filename = fileSelected.name;
                                              filename = filename.replace(
                                                /[,\s]+|[,\s]+/g,
                                                ""
                                              );
                                              if (
                                                fileSelected.size > 50000000
                                              ) {
                                                this.setState({
                                                  fileError:
                                                    "File too big, please upload a file of size less than 50Mb",
                                                });
                                                this.setState({ file: "" });
                                              } else if (
                                                (mimetype === "image/jpeg" ||
                                                  mimetype === "image/png" ||
                                                  mimetype === "image/jpg") &&
                                                !loading &&
                                                !data
                                              ) {
                                                this.setState({
                                                  spotImage: filename,
                                                });
                                                spotUpload({
                                                  variables: {
                                                    file,
                                                  },
                                                });
                                                this.setState({
                                                  fileError: "",
                                                });
                                              } else {
                                                this.setState({
                                                  fileError:
                                                    "Image type not recognised, please upload file of type PNG, JPEG or JPG",
                                                });
                                                this.setState({ file: "" });
                                              }
                                            }}
                                          >
                                            Upload Image
                                          </MDBBtn>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    );
                                  }}
                                </Mutation>
                                <div style={{ textAlign: "center" }}>
                                  {error && (
                                    <p className="py-2" style={errorText}>
                                      {error.message.includes("jwt expired")
                                        ? "Token Expired. Please Login again"
                                        : error.message.replace(
                                            "GraphQL error: ",
                                            ""
                                          )}
                                    </p>
                                  )}
                                  {inputError !== "" ? (
                                    <p className="py-2" style={errorText}>
                                      {inputError}
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="text-center pb-3">
                                  <MDBBtn
                                    onClick={() => {
                                      if (title === "") {
                                        this.setState({
                                          inputError:
                                            "Please enter a spot title",
                                        });
                                      } else if (description === "") {
                                        this.setState({
                                          inputError:
                                            "Please enter a spot description",
                                        });
                                      } else if (noCategory) {
                                        this.setState({
                                          inputError: "Please select a sport",
                                        });
                                      } else {
                                        this.setState({ inputError: "" });
                                        if (
                                          title !== "" &&
                                          description !== "" &&
                                          !noCategory &&
                                          !loading
                                        ) {
                                          if (spotImage !== "") {
                                            postSpot({
                                              variables: {
                                                title,
                                                description,
                                                spotImage: spotImage,
                                                latitude: spotLatitude,
                                                longitude: spotLongitude,
                                                isBusiness,
                                                userId,
                                                userName,
                                                userWebsite,
                                                surf,
                                                skate,
                                                snowboard,
                                                ski,
                                                bmx,
                                                mtb,
                                                scooter,
                                                climbing,
                                                apparel,
                                                other,
                                              },
                                            });
                                          } else {
                                            postSpot({
                                              variables: {
                                                title,
                                                description,
                                                spotImage,
                                                latitude: spotLatitude,
                                                longitude: spotLongitude,
                                                isBusiness,
                                                userId,
                                                userName,
                                                userWebsite,
                                                surf,
                                                skate,
                                                snowboard,
                                                ski,
                                                bmx,
                                                mtb,
                                                scooter,
                                                climbing,
                                                apparel,
                                                other,
                                              },
                                            });
                                          }
                                        } else {
                                          this.setState({
                                            inputError: "Error adding spot",
                                          });
                                        }
                                      }
                                    }}
                                    style={postButton}
                                    color="white"
                                    rounded
                                    type="submit"
                                  >
                                    {!loading ? "Add Spot" : "Adding Spot"}
                                  </MDBBtn>
                                </div>
                              </div>
                            </div>
                          )}
                        </Mutation>
                      </MDBCol>
                    ) : (
                      <></>
                    )}
                    {showSpot ? (
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="3"
                        style={
                          window.innerWidth >= 576
                            ? {
                                backgroundColor: "#000",
                                borderRadius: "0.4rem",
                                marginTop: "2.5rem",
                              }
                            : {
                                backgroundColor: "#000",
                                borderRadius: "0.4rem",
                                bottom: 0,
                                position: "absolute",
                                height: "50vh",
                                overflowY: "auto",
                              }
                        }
                        className="col-12 offset-md-6 offset-lg-9 py-2"
                      >
                        <h2 className="mb-3" style={spotTitle}>
                          {data.spots.spots[spotNumber].title}
                        </h2>
                        <div className="px-3 text-center">
                          {data.spots.spots[spotNumber].spotImage !== "" ||
                          data.spots.spots[spotNumber].spotImage !== null ? (
                            <img
                              style={{
                                borderRadius: "0.4rem",
                                maxHeight: "20vh",
                              }}
                              src={
                                spotImageResize
                                  ? `https://the-sponsor-hub.ams3.digitaloceanspaces.com/spots/${
                                      data.spots.spots[spotNumber].userId
                                    }/${data.spots.spots[
                                      spotNumber
                                    ].spotImage.replace(
                                      ".jpeg",
                                      ""
                                    )}_960x540.jpeg`
                                  : `https://the-sponsor-hub.ams3.digitaloceanspaces.com/spots/${data.spots.spots[spotNumber].userId}/${data.spots.spots[spotNumber].spotImage}`
                              }
                              onError={() =>
                                this.setState({ spotImageResize: false })
                              }
                              alt="Spot"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="px-3 mt-3">
                          <p style={detailsText}>
                            {data.spots.spots[spotNumber].description}
                          </p>
                        </div>
                        <div className="text-center">
                          {data.spots.spots[spotNumber].isBusiness === false ? (
                            <></>
                          ) : (
                            <>
                              {discountsUnique.map((discount) => (
                                <>
                                  {discount.postedBy.id ===
                                  data.spots.spots[spotNumber].userId ? (
                                    <Observer
                                      onChange={(event) => {
                                        if (
                                          event.isIntersecting &&
                                          event.intersectionRatio >= 1
                                        ) {
                                          this.recordedTimeout = setTimeout(
                                            async () => {
                                              await this.props.client.mutate({
                                                mutation:
                                                  DISCOUNT_VIEWS_TRACKER_MUTATION,
                                                variables: {
                                                  discountId: discount.id,
                                                },
                                              });
                                            },
                                            1000
                                          );
                                          return;
                                        }
                                        clearTimeout(this.recordedTimeout);
                                      }}
                                      threshold={1}
                                    >
                                      <Discount
                                        key={discount.id}
                                        discount={discount}
                                      />
                                    </Observer>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </>
                          )}
                          {data.spots.spots[spotNumber].videos.map(
                            (video, index) => (
                              <>
                                {index === 0 ? (
                                  <Observer
                                    onChange={(event) => {
                                      if (
                                        event.isIntersecting &&
                                        event.intersectionRatio >= 1
                                      ) {
                                        this.recordedTimeout = setTimeout(
                                          async () => {
                                            await this.props.client.mutate({
                                              mutation:
                                                VIDEO_VIEWS_TRACKER_MUTATION,
                                              variables: {
                                                videoId: this.props.video.id,
                                              },
                                            });
                                          },
                                          1000
                                        );
                                        return;
                                      }
                                      clearTimeout(this.recordedTimeout);
                                    }}
                                    threshold={1}
                                  >
                                    <Video key={video.id} video={video} />
                                  </Observer>
                                ) : (
                                  <></>
                                )}
                              </>
                            )
                          )}
                          {data.spots.spots[spotNumber].videos.length > 0 ? (
                            <a
                              className="pt-3"
                              href={`map_edits/${data.spots.spots[spotNumber].id}`}
                              style={{ padding: 0 }}
                            >
                              <p style={moreText}>
                                View more Edits from this Spot
                              </p>
                            </a>
                          ) : data.spots.spots[spotNumber].videos.length ===
                              0 &&
                            (data.spots.spots[spotNumber].isBusiness ===
                              false ||
                              userId ===
                                data.spots.spots[spotNumber].userId) ? (
                            <a
                              className="pt-3"
                              href="upload_edit"
                              style={{ padding: 0 }}
                            >
                              <p style={moreText}>
                                Add Edits to Spot{" "}
                                {data.spots.spots[spotNumber].title}
                              </p>
                            </a>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {userId === data.spots.spots[spotNumber].userId ? (
                            <div>
                              <Mutation
                                mutation={DELETE_SPOT_MUTATION}
                                variables={{
                                  id: data.spots.spots[spotNumber].id,
                                }}
                                onCompleted={() => {
                                  this.setState({ showSpot: false });
                                  refetch();
                                }}
                              >
                                {(deleteSpotMutation) => (
                                  <MDBBtn
                                    id="btnSM"
                                    style={deleteButton}
                                    rounded
                                    onClick={deleteSpotMutation}
                                  >
                                    Delete
                                  </MDBBtn>
                                )}
                              </Mutation>
                              <MDBBtn
                                id="btnSM"
                                style={editButton}
                                rounded
                                onClick={() =>
                                  this.setState({
                                    showSpot: false,
                                    editSpot: true,
                                    titleUpdated:
                                      data.spots.spots[spotNumber].title,
                                    descriptionUpdated:
                                      data.spots.spots[spotNumber].description,
                                    spotImageUpdated:
                                      data.spots.spots[spotNumber].spotImage,
                                    isBusinessUpdated:
                                      data.spots.spots[spotNumber].isBusiness,
                                    surfUpdated:
                                      data.spots.spots[spotNumber].surf,
                                    skateUpdated:
                                      data.spots.spots[spotNumber].skate,
                                    snowboardUpdated:
                                      data.spots.spots[spotNumber].snowboard,
                                    skiUpdated:
                                      data.spots.spots[spotNumber].ski,
                                    bmxUpdated:
                                      data.spots.spots[spotNumber].bmx,
                                    mtbUpdated:
                                      data.spots.spots[spotNumber].mtb,
                                    scooterUpdated:
                                      data.spots.spots[spotNumber].scooter,
                                    climbingUpdated:
                                      data.spots.spots[spotNumber].climbing,
                                    apparelUpdated:
                                      data.spots.spots[spotNumber].apparel,
                                    otherUpdated:
                                      data.spots.spots[spotNumber].other,
                                  })
                                }
                              >
                                Edit
                              </MDBBtn>
                            </div>
                          ) : (
                            <></>
                          )}
                          {userId !== data.spots.spots[spotNumber].userId &&
                          data.spots.spots[spotNumber].isBusiness ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Mutation
                                mutation={USER_PROFILE_MUTATION}
                                variables={{
                                  userId: data.spots.spots[spotNumber].userId,
                                  currentUserId: userId,
                                }}
                              >
                                {(profileTrackerMutation, { loading }) => (
                                  <MDBBtn
                                    id="btnSM"
                                    outline
                                    rounded
                                    color="white"
                                    href={`/profile/${data.spots.spots[spotNumber].userId}`}
                                    onClick={() => {
                                      if (!loading) {
                                        profileTrackerMutation();
                                      }
                                    }}
                                    size="sm"
                                  >
                                    Profile
                                  </MDBBtn>
                                )}
                              </Mutation>
                              <Mutation
                                mutation={WEBSITE_TRACKER_MUTATION}
                                variables={{
                                  business: data.spots.spots[spotNumber].userId,
                                  user: userId,
                                  discountId: "Map",
                                }}
                              >
                                {(websiteTrackerMutation, { loading }) => (
                                  <MDBBtn
                                    id="btnSM"
                                    outline
                                    rounded
                                    color="white"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                      data.spots.spots[spotNumber].userWebsite
                                    }
                                    onClick={() => {
                                      if (!loading) {
                                        websiteTrackerMutation();
                                      }
                                    }}
                                    size="sm"
                                  >
                                    Website
                                  </MDBBtn>
                                )}
                              </Mutation>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </MDBCol>
                    ) : (
                      <></>
                    )}
                    {editSpot ? (
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="3"
                        style={
                          window.innerWidth >= 576
                            ? {
                                backgroundColor: "#000",
                                minHeight: "100vh",
                                marginTop: "2.5rem",
                              }
                            : {
                                backgroundColor: "#000",
                                height: "50vh",
                                bottom: 0,
                                position: "absolute",
                                overflowY: "auto",
                              }
                        }
                        className="col-12 offset-md-6 offset-lg-9 pt-2"
                      >
                        <h2 className="mb-3" style={spotTitle}>
                          EDIT SPOT
                        </h2>
                        <Mutation
                          mutation={UPDATE_SPOT_MUTATION}
                          onCompleted={() => {
                            this.setState({
                              editSpot: false,
                              titleUpdated: "",
                              descriptionUpdated: "",
                              categoryUpdated: "",
                              spotImageUpdated: "",
                              isBusinessUpdated: false,
                              file: "",
                              fileError: "",
                            });
                            setTimeout(() => {
                              refetch();
                            }, 5000);
                          }}
                        >
                          {(postSpotUpdate, { error, loading }) => (
                            <div>
                              <MDBInput
                                label="Title*"
                                value={titleUpdated}
                                onChange={(e) =>
                                  this.setState({
                                    titleUpdated: e.target.value,
                                  })
                                }
                                type="text"
                                style={inputText}
                                required
                                maxLength="60"
                              />
                              <MDBInput
                                label="Description*"
                                type="textarea"
                                value={descriptionUpdated}
                                onChange={(e) =>
                                  this.setState({
                                    descriptionUpdated: e.target.value,
                                  })
                                }
                                style={inputText}
                                maxLength="100"
                                required
                              />
                              <div>
                                <h5 style={labelText}>Select Sports*</h5>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          surfUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!surfUpdated) {
                                          this.setState({
                                            surfUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({ surfUpdated: false });
                                          if (
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Surf</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/surf.jpg"
                                        alt="Surf"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          skateUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!skateUpdated) {
                                          this.setState({
                                            skateUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({
                                            skateUpdated: false,
                                          });
                                          if (
                                            !surfUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Skate</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/skate.jpeg"
                                        alt="Skate"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          snowboardUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!snowboardUpdated) {
                                          this.setState({
                                            snowboardUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({
                                            snowboardUpdated: false,
                                          });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Snowboard</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/snowboard.jpg"
                                        alt="Snowboard"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          skiUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!skiUpdated) {
                                          this.setState({
                                            skiUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({ skiUpdated: false });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Ski</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/ski.jpg"
                                        alt="Ski"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          bmxUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!bmxUpdated) {
                                          this.setState({
                                            bmxUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({ bmxUpdated: false });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>BMX</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/bmx.jpg"
                                        alt="BMX"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          mtbUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!mtbUpdated) {
                                          this.setState({
                                            mtbUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({ mtbUpdated: false });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>MTB</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/mtb.jpeg"
                                        alt="MTB"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          scooterUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!scooterUpdated) {
                                          this.setState({
                                            scooterUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({
                                            scooterUpdated: false,
                                          });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !climbingUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Scooter</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/scooter.jpeg"
                                        alt="Scooter"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          climbingUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!climbingUpdated) {
                                          this.setState({
                                            climbingUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({
                                            climbingUpdated: false,
                                          });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !apparelUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Climbing</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/climbing.jpeg"
                                        alt="Climbing"
                                      />
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          otherUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!otherUpdated) {
                                          this.setState({
                                            otherUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({
                                            otherUpdated: false,
                                          });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !apparelUpdated &&
                                            !climbingUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Other</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/Other.jpg"
                                        alt="Other"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3" style={categoryRow}>
                                  <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    className="col-4 mt-3"
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #202020",
                                        borderRadius: "0.25rem",
                                        textAlign: "center",
                                        padding: "0.25rem",
                                        opacity:
                                          apparelUpdated || noCategoryUpdated
                                            ? 1
                                            : 0.5,
                                      }}
                                      onClick={() => {
                                        if (!apparelUpdated) {
                                          this.setState({
                                            apparelUpdated: true,
                                            noCategoryUpdated: false,
                                          });
                                        } else {
                                          this.setState({
                                            apparelUpdated: false,
                                          });
                                          if (
                                            !surfUpdated &&
                                            !skateUpdated &&
                                            !snowboardUpdated &&
                                            !skiUpdated &&
                                            !bmxUpdated &&
                                            !mtbUpdated &&
                                            !scooterUpdated &&
                                            !climbingUpdated &&
                                            !otherUpdated
                                          ) {
                                            this.setState({
                                              noCategoryUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <h5 style={categoryTitle}>Apparel</h5>
                                      <img
                                        style={categoryImg}
                                        src="/Sport_Icons/apparelCategory.jpg"
                                        alt="Apparel"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              </div>
                              {userIsBusiness ? (
                                <div
                                  style={{
                                    marginTop: "1.5rem",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <MDBInput
                                    label="Is this your Business Location?"
                                    type="checkbox"
                                    id="checkbox1"
                                    value={isBusinessUpdated}
                                    checked={isBusinessUpdated}
                                    onChange={(e) =>
                                      this.setState({
                                        isBusinessUpdated: !isBusinessUpdated,
                                      })
                                    }
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              <div>
                                <MDBFileInput
                                  btnColor="black"
                                  btnTitle="Upload a new Spot Image"
                                  value={file}
                                  type="file"
                                  getValue={this.fileChangeHandler}
                                />
                                {fileError !== "" ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginBottom: "1.5rem",
                                    }}
                                  >
                                    <p style={fileErrorText}>{fileError}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <Mutation mutation={UPLOAD_FILE_STREAM}>
                                  {(spotUpload, { data, loading, error }) => {
                                    return (
                                      <div className="text-center">
                                        {loading && (
                                          <div>
                                            <p style={loadingText}>
                                              Your Image is being uploaded to
                                              the cloud ...
                                            </p>
                                            <Loader size={8} color="white" />
                                          </div>
                                        )}
                                        {error && (
                                          <p style={errorText}>
                                            {error.message ===
                                            "Network error: NetworkError when attempting to fetch resource."
                                              ? "Connection Timed Out: Please Try Again"
                                              : error.message.includes(
                                                  "jwt expired"
                                                )
                                              ? "Token Expired. Please Login again"
                                              : error.message.replace(
                                                  "GraphQL error: ",
                                                  ""
                                                )}
                                          </p>
                                        )}
                                        {file !== "" && !data && !loading ? (
                                          <MDBBtn
                                            color="white"
                                            style={uploadButton}
                                            rounded
                                            onClick={() => {
                                              const fileSelected = file;
                                              const mimetype =
                                                fileSelected.type;
                                              var filename = fileSelected.name;
                                              filename = filename.replace(
                                                /[,\s]+|[,\s]+/g,
                                                ""
                                              );
                                              if (
                                                fileSelected.size > 50000000
                                              ) {
                                                this.setState({
                                                  fileError:
                                                    "File too big, please upload a file of size less than 50Mb",
                                                });
                                                this.setState({ file: "" });
                                              } else if (
                                                (mimetype === "image/jpeg" ||
                                                  mimetype === "image/png" ||
                                                  mimetype === "image/jpg") &&
                                                !loading &&
                                                !data
                                              ) {
                                                this.setState({
                                                  spotImageUpdated: filename,
                                                });
                                                spotUpload({
                                                  variables: {
                                                    file,
                                                  },
                                                });
                                                this.setState({
                                                  fileError: "",
                                                });
                                              } else {
                                                this.setState({
                                                  fileError:
                                                    "Image type not recognised, please upload file of type PNG, JPEG or JPG",
                                                });
                                                this.setState({ file: "" });
                                              }
                                            }}
                                          >
                                            Upload Image
                                          </MDBBtn>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    );
                                  }}
                                </Mutation>
                                <div style={{ textAlign: "center" }}>
                                  {error && (
                                    <p className="py-2" style={errorText}>
                                      {error.message.includes("jwt expired")
                                        ? "Token Expired. Please Login again"
                                        : error.message.replace(
                                            "GraphQL error: ",
                                            ""
                                          )}
                                    </p>
                                  )}
                                  {inputError !== "" ? (
                                    <p className="py-2" style={errorText}>
                                      {inputError}
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="text-center pb-3">
                                  <MDBBtn
                                    onClick={() => {
                                      if (titleUpdated === "") {
                                        this.setState({
                                          inputError:
                                            "Please enter a spot title",
                                        });
                                      } else if (descriptionUpdated === "") {
                                        this.setState({
                                          inputError:
                                            "Please enter a spot description",
                                        });
                                      } else if (noCategoryUpdated) {
                                        this.setState({
                                          inputError: "Please select a sport",
                                        });
                                      } else {
                                        this.setState({ inputError: "" });
                                        if (
                                          titleUpdated !== "" &&
                                          descriptionUpdated !== "" &&
                                          !noCategoryUpdated &&
                                          !loading
                                        ) {
                                          if (spotImageUpdated !== "") {
                                            postSpotUpdate({
                                              variables: {
                                                id: data.spots.spots[spotNumber]
                                                  .id,
                                                title: titleUpdated,
                                                description: descriptionUpdated,
                                                spotImage: spotImageUpdated,
                                                latitude:
                                                  data.spots.spots[spotNumber]
                                                    .latitude,
                                                longitude:
                                                  data.spots.spots[spotNumber]
                                                    .longitude,
                                                isBusiness: isBusinessUpdated,
                                                userId,
                                                userName,
                                                surf: surfUpdated,
                                                skate: skateUpdated,
                                                snowboard: snowboardUpdated,
                                                ski: skiUpdated,
                                                bmx: bmxUpdated,
                                                mtb: mtbUpdated,
                                                scooter: scooterUpdated,
                                                climbing: climbingUpdated,
                                                apparel: apparelUpdated,
                                                other: otherUpdated,
                                              },
                                            });
                                          } else {
                                            postSpotUpdate({
                                              variables: {
                                                id: data.spots.spots[spotNumber]
                                                  .id,
                                                title: titleUpdated,
                                                description: descriptionUpdated,
                                                spotImage: spotImageUpdated,
                                                latitude:
                                                  data.spots.spots[spotNumber]
                                                    .latitude,
                                                longitude:
                                                  data.spots.spots[spotNumber]
                                                    .longitude,
                                                isBusiness: isBusinessUpdated,
                                                userId,
                                                userName,
                                                surf: surfUpdated,
                                                skate: skateUpdated,
                                                snowboard: snowboardUpdated,
                                                ski: skiUpdated,
                                                bmx: bmxUpdated,
                                                mtb: mtbUpdated,
                                                scooter: scooterUpdated,
                                                climbing: climbingUpdated,
                                                apparel: apparelUpdated,
                                                other: otherUpdated,
                                              },
                                            });
                                          }
                                        } else {
                                          this.setState({
                                            inputError: "Error adding spot",
                                          });
                                        }
                                      }
                                    }}
                                    style={postButton}
                                    color="white"
                                    rounded
                                    type="submit"
                                  >
                                    {!loading ? "Update Spot" : "Updating Spot"}
                                  </MDBBtn>
                                </div>
                              </div>
                            </div>
                          )}
                        </Mutation>
                      </MDBCol>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GEOCODING_TOKEN,
  LoadingContainer: LoadingContainer,
  withScriptjs,
  withGoogleMap,
})(withApollo(MapContainer));

const spotTitle = {
  color: "#f8f8ff",
  textAlign: "center",
  fontFamily: "Oswald",
  textTransform: "uppercase",
};

const inputText = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
};

const labelText = {
  fontFamily: "Oswald",
  color: "#f8f8ff",
  fontSize: "1rem",
};

const categoryRow = {
  display: "flex",
  justifyContent: "space-evenly",
};

const categoryTitle = {
  color: "#f8f8ff",
  fontFamily: "Oswald",
  textAlign: "center",
  fontSize: "1rem",
};

const categoryImg = {
  borderRadius: "0.25rem",
  objectFit: "cover",
  width: "100%",
  height: "80px",
};

const fileErrorText = {
  color: "red",
  fontFamily: "Oswald",
  marginBottom: "0.5rem",
};

const errorText = {
  color: "red",
  fontFamily: "Oswald",
};

const uploadButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
  marginTop: "0rem",
  marginBottom: "1rem",
};

const postButton = {
  fontFamily: "Oswald",
  fontSize: "18px",
  width: "50%",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
};

const detailsText = {
  color: "#f8f8ff",
  textAlign: "justify",
  fontFamily: "Oswald",
};

const moreText = {
  color: "#f8f8ff",
  textAlign: "center",
  fontFamily: "Oswald",
};

const deleteButton = {
  backgroundColor: "#000",
  border: "2px solid red",
  color: "red",
};

const editButton = {
  backgroundColor: "#000",
  border: "2px solid #f8f8ff",
  color: "#f8f8ff",
};

const loadingText = {
  color: "#f8f8ff",
  fontFamily: "Roboto",
  marginBottom: "0.5rem",
};
